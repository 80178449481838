/*
 * @Author: yusha
 * @Date: 2023-12-15 13:18:46
 * @LastEditors: yusha
 * @LastEditTime: 2023-12-25 10:04:07
 * @Description:
 */

import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * 获取缓存
 * @param storage_Key
 * @returns {Promise<any>}
 */
export const getItem = async (storage_Key) => {
	try {
		const storedValue = await AsyncStorage.getItem(storage_Key);
		const jsonValue = JSON.parse(storedValue);
		return jsonValue;
	} catch (e) {
		console.log('读取缓存数据失败');
	}
};

/**
 * 设置缓存
 * @param storage_Key
 * @returns {Promise<any>}
 */
export const setItem = async (storage_Key, storage_Value) => {
	try {
		await AsyncStorage.setItem(storage_Key, JSON.stringify(storage_Value));
	} catch (e) {
		console.log('设置缓存数据失败');
	}
};

/**
 * 删除缓存 key
 * @param storage_Key
 * @returns {Promise<void>}
 */
export const removeItem = async (storage_Key) => {
	try {
		return await AsyncStorage.removeItem(storage_Key);
	} catch (e) {
		console.log('删除缓存数据失败');
	}
};

/**
 * 删除所有缓存
 * @returns {Promise<*>}
 */
export const clearItem = async () => {
	try {
		return await AsyncStorage.clear();
	} catch (e) {
		console.log('清除所有缓存数据失败');
	}
};
