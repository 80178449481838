/*
 * @Author: shiguang
 * @Date: 2023-12-08 16:35:07
 * @LastEditors: tianzhitong laotianwy@163.com
 * @LastEditTime: 2024-01-26 14:17:12
 * @Description:
 */
import Cookie from 'js-cookie';
import { createJsbridge } from '@sniff/jsbridge';
import { TokenSignCookie, checkLogin, cookieEnv } from '@/config/request/login';

interface HandleCallbackMessageData {
	/** 回调ID */
	callbackId: string;
	/** 回调方法的 data */
	methodData: any;
	/** 基础数据 */
	baseData: any;
}

declare global {
	interface Window {
		ReactNativeWebView: Pick<Window, 'postMessage'>;
	}
}
/** app存储token */
export const goToCheckLogin = async ({ token, appMount }) => {
	// 设置token
	await Cookie.set(TokenSignCookie, token ?? '', {
		path: '/',
		domain: cookieEnv
	});
	await checkLogin();
};

export const jsBridge = createJsbridge();
