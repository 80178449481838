/*
 * @Author: huajian
 * @Date: 2023-11-16 14:51:54
 * @LastEditors: huajian
 * @LastEditTime: 2023-12-12 10:33:24
 * @Description: 徽标
 * 有个坑就是徽标的宽度不能大于children的宽度 否则换行
 */
import { StyleProp, Text, TextStyle, View, ViewProps } from 'react-native';
import { useState } from 'react';
import { styles } from './styles';

interface Props extends ViewProps {
	children?: React.ReactNode;
	/** 位置 默认 top-right */
	position?: 'top-right' | 'top-left';
	/** 文本样式 */
	textStyle?: StyleProp<TextStyle>;
	/** 徽标样式 */
	iconStyle?: StyleProp<TextStyle>;
	dot?: boolean;
	value?: number;
	max?: undefined;
}

interface PropsA extends Omit<Props, 'value' | 'max'> {
	value: number;
	max: number;
}

// key 防止onlayout 被rerender 导致onLayout 没有被执行
let keyCount = 1;

export const Badge = (props: Props | PropsA) => {
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
	const [size, setSize] = useState<{ width: number; height: number }>();

	if (!props.value && !props.dot) {
		return <>{props.children}</>;
	}

	let value: string = props.value ? props.value.toString() : '';

	if (props.max && props.max < props.value) {
		value = `${props.max}+`;
	}
	keyCount++;

	return (
		<>
			{!size && (
				<View
					key={keyCount}
					style={styles.temp}
					onLayout={(e) => {
						setSize({
							width: e.nativeEvent.layout.width,
							height: e.nativeEvent.layout.height
						});
					}}
				>
					{props.children}
				</View>
			)}

			{size && !props.dot && (
				<View {...props} style={[styles.container, size, props.style]}>
					{props.children}
					<View
						style={[
							styles.textWrap,
							props.position === 'top-left'
								? styles.topLeft
								: styles.topRight,
							props.iconStyle,
							{
								transform: [
									{
										translateX:
											props.position === 'top-left'
												? -width / 2
												: width / 2
									},
									{ translateY: -height / 2 }
								]
							}
						]}
						onLayout={(e) => {
							setWidth(e.nativeEvent.layout.width);
							setHeight(e.nativeEvent.layout.height);
						}}
					>
						<Text style={[styles.text, props.textStyle]}>
							{value}
						</Text>
					</View>
				</View>
			)}

			{size && props.dot && (
				<View {...props} style={[styles.container, props.style]}>
					{props.children}
					<View
						style={[
							styles.dotContainer,
							props.position === 'top-left'
								? styles.topLeft
								: styles.topRight,
							props.iconStyle
						]}
					/>
				</View>
			)}
		</>
	);
};
