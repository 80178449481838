const serverCode = {
	noMatch: 'エラーあり カスタマーサービスへご連絡下さい',
	24010056: '此手机号未注册,请先注册',
	24010021: '邮箱已经存在',
	24010054: '验证码不正确',
	24010055: '手机号已经存在',
	24010061: '未登録',
	21000000: '店舗連携失敗',
	21000001:
		'店舗連携失敗　当該店舗は他アカウント連携済、現設定解除後、API連携を再試行して下さい',
	21000002: '店舗名 該当なし',
	21190002: '注文取込み中、暫くお待ちください',
	21000003: '当該店舗 API未連携',
	21000004: 'エラーあり 時間をおいて再試行して下さい',
	21000005: '111111111',
	21000006: '接続エラー API接続上限時間をおいて再試行して下さい ',
	21000007: '当該店舗 API未連携',
	21000008: '当該店舗 API未連携',
	21000009: '商品取込み失敗',
	21000010: '商品情報取込み失敗',
	21000011: '必須項目productCodeを入力して下さい ',
	21000012:
		'発注ショップ未紐付け連携済店舗商品と発注ショップ情報を紐付けて下さい ',
	21000013: 'API連携済 商品SKU紐付けを実行して下さい',
	21000014: 'SKU紐付け済商品SKU紐付けを解除して下さい ',
	21000015: 'データ同期中',
	21000016:
		'入力不備　API連携用IDまたはAPI連携用secret入力内容を確認し再実行して下さい ',
	21000017: '指定注文 該当なし',
	21000018: '追跡番号更新エラー',
	21000019: 'エラーあり',
	21000020: '指定商品 該当なし',
	21000021: 'アップロードエラー商品情報のアップロードに失敗しました ',
	210000393:
		'商品のプロパティ数が100個超えました，該当商品は自動登録できません',
	21000022:
		'形式不正 Shopify店舗URL「https: //○○○.myshopify.com」のURLを入力して下さい',
	21000025: '削除不可商品連携済のため削除不可 ',
	21000026: '物流会社 該当なし',
	21000027: '注文処理中、暫くお待ちください',
	21000127: '有効な店舗URLを入力して下さい 例：https://xxx.myshopify.com',
	21000028: '注文完了',
	21000029: '仕入れルート不備あり',
	22040010: '納品書をアップロードして下さい',
	24030001: 'デフォルト設定完了済',
	24030002: '必須項目カテゴリーを選択して下さい ',
	24030003: 'カテゴリー 該当なし',
	24030004: 'オプション 該当なし',
	24030005: '設定内容 該当なし',
	24030006: '発送用オプション 設定済',
	24030007: '発送オプション設定不要B2Bユーザはオプション設定不要です ',
	24030008: '当該オプション削除不可',
	24030009: '資材紐付けエラー',
	24030010: 'オプション 該当なし',
	24030011: '指定発送オプション 該当なし',
	24010001: '必須項目 ユーザー名',
	24010002: 'ユーザー名 登録済別ユーザー名を入力して下さい ',
	24010003:
		'形式不正 ユーザー名またはパスワード正しい形式のユーザー名、 パスワードを入力して下さい ',
	24010006: '形式不正 ユーザー名',
	24010007: '氏名を入力して下さい',
	24010008: '形式不正 氏名',
	24010009: '形式不正 氏名12 文字以内で氏名を入力して下さい ',
	24010011: '必須項目 パスワード',
	24010012: 'パスワード（確認用）を入力して下さい',
	24010013: 'パスワード不一致',
	24010015: '新しいパスワードを入力して下さい',
	24010016: '新しいパスワード（確認用）を入力して下さい',
	24010017: 'パスワード入力不備',
	24010018: 'パスワード変更失敗',
	24010019:
		'パスワード入力不備パスワードは、 6~20 文字以内の半角英数で設定して下さい ',
	24010020:
		'パスワード入力不備新パスワードは現パスワードと相違する値を設定して下さい ',
	24010022: '必須項目 メールアドレス',
	24010023: 'メール送信失敗',
	24010024: '利用規約に同意後、次へ',
	24010025: '必須項目 国',
	24010026: '電話番号を入力して下さい',
	24010027: '認証コードを入力して下さい',
	24010028:
		'認証コード無効認証コードを再送付し、 正しい認証番号を入力して下さい ',
	24010029: '形式不正 メールアドレス',
	24010030: 'メールアドレス 該当なし',
	24010032: 'ユーザー名 該当なし',
	24010033: '当該アカウント停止済',
	24010035: '銀行口座 該当なし',
	24010036: '貨幣種類を入力して下さい',
	24010038: '必須項目為替レートを入力して下さい ',
	24010040: '必須項目商品IDを入力して下さい ',
	24010041: '必須項目ブランド名を入力して下さい ',
	24010042: '更新失敗ユーザー情報更新に失敗しました ',
	24010043: '形式不正 電話番号正しい電話番号を入力して下さい ',
	24010044: '必須項目仕入れ元を入力して下さい ',
	24010045: '仕入れ元情報不備あり発注ショップ情報に不備あり ',
	24010046: '認証コード申請 更新過多時間を置いて、 再実行して下さい ',
	24010049: '形式不正 メールアドレス正しいメールアドレスを入力して下さい ',
	24010068: 'メールアドレス 未登録新規登録を進めて下さい ',
	24020001: '店舗名を入力して下さい',
	24020002: '店舗名 該当なし',
	24020003: '当該店舗 API未連携',
	24020004:
		'子アカウント 店舗作成不可親アカウントにログインし再試行して下さい ',
	24020005: '子アカウント作成完了',
	24020009: '変更不可API連携済のため店舗情報変更不可 ',
	24020008: '連携用ID、連携用secreを入力して下さい',
	240200067: '店舗名 登録済別店舗名を入力して下さい ',
	24200002:
		'上限に達しました本日、 これ以上のおすすめ商品から削除操作はできません ',
	24100001: '店舗名 該当なし',
	24100002: '入力URL不備   URLを確認の上、再度お試し下さい ',
	20000002: 'お支払い中',
	20000003: '注文支払完了',
	20000004: 'お支払い待ち',
	20000005: '発注不可会員登録実施後、 発注可能 ',
	20000007: '商品 該当なし',
	20000008: '注文明細 該当なし',
	20000009: 'お支払い明細 該当なし',
	20000011: '注文清算中',
	20000012: '注文清算完了',
	20000013: 'お支払い失敗',
	20000015: 'キャンセル不可買い付け対応中 ',
	20000017: '必須項目商品IDを入力して下さい ',
	20010001: '残高不足口座残高をチャージして下さい ',
	20020002: '子商品の数を入力して下さい',
	20020003: '必須項目購入数量 ',
	20020004: 'セット組対応済 重複セット組 作成不可 ',
	20020005: 'セット組商品は1点以上設定が必要です',
	20020006: 'セット注文 該当なし',
	20020008: 'セット組注文明細 該当なし',
	20020010: '該当注文書 未精算',
	22050002: '1セット組商品には、単品商品が2件以上必要です',
	22050003: '1注文で最大30点までセット組可能です',
	22050005: 'カート追加失敗',
	22040003: '必須項目発送時オプションを選択して下さい ',
	22040004: '必須項目商品ラベル貼付オプションを選択して下さい ',
	22040005: '国際配送依頼 作成失敗',
	22040006: '配送可能数量不足',
	22040007: '為替レート（RMB→ドル） 検索失敗',
	22040008: '在庫ロック失敗',
	22040009: '必須項目箱ラベル貼付オプションを選択して下さい ',
	22010001: '発注不可 商品在庫切れ',
	22010002: '残高不足口座残高をチャージして下さい ',
	22010003: '注文上限額超過D2C直送の注文合計額上限は2000元 / 1 注文 です ',
	22010007: '商品が存在しません',
	22010010: '変更不可検品中のため資材変更不可 ',
	22010011: '商品選択後、カート追加して下さい',
	22010012: '商品skuを入力して下さい',
	22010013: '必須項目購入数量を入力して下さい ',
	22010014: '発注不可 商品在庫切れ',
	22010019: '1商品につき最大10000点までカート追加可能です',
	22010021: '物流会社 該当なし',
	22010025: '操作不可直送注文、 残在庫を利用不可 ',
	22010026: 'キャンセル不可配送済 ',
	22010040: '取消不可審査中状態のみ取消可能 ',
	22010049: '取消不可在庫保管注文 取消不可 ',
	22010051: '分納発送不可在庫保管商品は処理できません ',
	22010052: '分納発送不可配送待ち商品ではなく処理できません ',
	22010057: '店舗名 該当なし',
	22010058: 'AmazonAPI未連携',
	22010060:
		'選択商品 納品対応中出荷済または納品プランキャンセル後、 再試行して下さい ',
	22010064: '購入数量不備購入数量が最低ロット数を満たしていません ',
	22010068: 'キャンセル可能な商品がありません',
	22030004: '必須項目商品SKUを入力して下さい ',
	22030005: '必須項目日本倉庫の管理SKUを入力して下さい ',
	22030006: '商品取込失敗当該店舗の商品取込が失敗しました ',
	22030007: 'SKU未紐付けAmazonSKUと未紐付け商品あり ',
	22030008:
		'Amazon上 商品登録情報不備ありAmazon上で修正後、 再試行して下さい ',
	24010062: '登録き期限が切れる',
	10000000: '未登录',
	22030009: '必須項目 AmazonSKUと紐付けてください ',
	210000390:
		'skuはバインドされていますので、バインドを繰り返さないでください',
	280203014: '口座残高不足 資金をチャージ後、会員費支払いをお願いします',
	24100004: '当商品発注できません、カスタマサービスにご連絡ください',
	28000026: '最低輸送kg数を下回っています',
	210000391: '必須項目：発送用オプションを選択して下さい',
	240300006: '対応中申請があり、処理が完了してから再度申請して下さい',
	24010066: '【ご確認下さい】店舗未登録。店舗作成後、再試行して下さい',
	22010073: '価格を更新された商品あり、最新の商品価格に更新が完了しました。',
	210000394: '在庫不足商品があります',
	26020006: '編集不可　倉庫対応中の商品は編集できません',
	240201001:
		'該当PayPalN支払請求が見つかりません、オンラインカスタマサービスまでご連絡下さい',
	240201002:
		'PayPalからの支払請求ではありません、オンラインカスタマサービスまでご連絡下さい',
	240201003: 'PayPalでの支払いが完了しました',
	240201004:
		'PayPalでの支払い手続きが完了していません、手順に沿ってお支払い手続きを完了してください',
	240201005:
		'PayPalの支払い手続きページには進めません、手順に沿ってお支払い手続きを完了してください',
	240201006: '支払いは完了していません　再度お支払い手続きをお試し下さい',
	240201007:
		'"金融機関に登録されている請求先住所を確認できず、またはカートの利用限度額を超えるため、カード会社にによる決済を拒否されましたお手数ですが、カード発行会社へ直接ご確認下さい"',
	240201008:
		'PayPal での支払いが完了していません　PayPal へ直接お問い合わせ下さい',
	210000395: '商品オプションをご選択下さい',
	26000095:
		'該当物流会社のステータスは表記できないため、お手数ですが、該当物流会社の追跡サイトからご確認くださいませ。',
	22040012: '商品SKUラベルをアップロードして下さい',
	22040011: '箱ラベルをアップロードして下さい',
	22010074: '注文商品数は300を超えてはいけません',
	22040013:
		'アップロードデータは追加された商品SKUと異なり、ご確認頂いた上で、再度アップロードして下さい',
	22010075: '既に発送済の注文には、追跡番号の自動反映を解除できません',
	2000008001: '商品重复请更换商品',
	20000023: '重拍次数达到上限',
	26000082:
		'アフター補償申請不可。アフター補償申請の有効期間は商品購入後2ヶ月以内です',
	24040051: '会员等级模板价格未同步paypal',
	240300009: 'ベータユーザーではない',
	// 240300020: '目前app仅限日本用户使用，请去电脑端使用系统',
	// 28020009: '充值余额失败：请购买或者续费会员',
	// 服务器返回的 apiCode.js 对应的文本输出
	1001: '入力不備　お届け先情報 有効な住所を入力して下さい',
	1002: '注文中のアイテムは存在しません',
	1003: '直行便商品と紐付けない商品があります',
	1004: '発注不可，運送会社設定実施後、発注可能',
	1005: '該当店舗の口座残高不足，資金をチャージ後、発注をお願いします',
	1006: '在庫不足商品があります',
	1007: '商品オプションをご選択下さい',
	1008: '注文上限額超過，D2C直送の注文合計額上限は、2000元/回　です',
	240100468: '邮箱未注册',
	33000011: '抱歉，活动商品一个账户限购1个，无法添加数量',
	33000010: '抱歉，商品已售罄',
	33000009: '抱歉，该商品已下架',
	24100008: '商品已下架，再看看其他商品吧',
	33000003: '抱歉，1个账户限购1个活动商品，您已购买过',
	33000001: '下单人地址不能超过100个',
	33000002: '收货地址不能超过100个',
	33000013: '购物车加购数量不能超过200',
	33000014: '1日元商品限购1件',
	33000015: '购买数量未达到起购数量',
	2002: '账户资金不足',
	3006: '该笔订单已支付，请勿重复支付',
	33000018: '当前没有获取到运费，无法下单，请刷新后重试',
	33000017: '商品不存在，请刷新页面后重试',
	33000019: '单个商品SKU购买数量不能超过99999',
	33000000: '加购数量不正确',
	31011004: '活动不适用此商品',
	31011001: '活动不存在',
	31011002: '活动已结束',
	31011003: '活动无效',
	24000000: '该商品已删除'
};

/**
 * 中文字幕
 */

export default {
	...serverCode,

	验证码格式非法: '验证码格式非法',
	首页: '首页',
	分类搜索: '分类搜索',
	'URL、商品名搜索': 'URL、商品名搜索',
	日语: '日语',
	搜索: '搜索',
	我的频道: '我的频道',
	透明购: '透明购',
	市场购: '市场购',
	从采购网站: '从采购网站',
	查看更多: '查看更多',
	从销售网站: '从销售网站',
	青蛙频道: '青蛙频道',
	昨日店铺数据: '昨日店铺数据',
	余额: '余额',
	API下单量: 'API下单量',
	完成订单数量: '完成订单数量',
	订单数量: '订单数量',
	下单金额: '下单金额',
	我的收藏: '我的收藏',
	足迹: '足迹',
	商品: '商品',
	店铺: '店铺',
	购买过: '购买过',
	推荐店铺: '推荐店铺',
	推荐商品: '推荐商品',
	男士: '男士',
	女士: '女士',
	儿童: '儿童',
	杂货: '杂货',
	热卖排行: '热卖排行',
	购物车: '购物车',
	消息: '消息',
	我的: '我的',
	用户名登录: '用户名登录',
	邮箱登录: '邮箱登录',
	用户名: '用户名',
	忘记密码: '忘记密码',
	登录: '登录',
	密码: '密码',
	我已阅读并同意: '我已阅读并同意',
	使用条款: '使用条款',
	'还没有账号？去注册': '还没有账号？去注册',
	请输入用户名: '请输入用户名',
	请输入密码: '请输入密码',
	电子邮箱: '电子邮箱',
	请输入电子邮箱: '请输入电子邮箱',
	电子邮箱授权码: '电子邮箱授权码',
	请输入电子邮箱授权码: '请输入电子邮箱授权码',
	获取授权码: '获取授权码',
	用户名不能为空: '用户名不能为空',
	密码格式错误: '密码格式错误',
	请阅读并勾选用户协议: '请阅读并勾选用户协议',
	登录成功: '登录成功',
	邮箱格式不正确: '邮箱格式不正确',
	验证码发送成功: '验证码发送成功',
	邮箱授权码不正确: '邮箱授权码不正确',
	注册: '注册',
	请输入确认密码: '请输入确认密码',
	确认密码: '确认密码',
	电话号码: '电话号码',
	请输入电话号码: '请输入电话号码',
	确认密码不能为空: '确认密码不能为空',
	注册成功: '注册成功',
	获取验证码: '获取验证码',
	两次密码不一致: '两次密码不一致',
	密码修改成功: '密码修改成功',
	店舗追加: '店舗追加',
	全部站点: '全部站点',
	全部: '全部',
	时尚网站: '时尚网站',
	'3C网站': '3C网站',
	搜索记录: '搜索记录',
	人气关键词: '人气关键词',
	中文: '中文',
	删除搜索记录: '删除搜索记录',
	推荐: '推荐',
	热销: '热销',
	价格升序: '价格升序',
	价格降序: '价格降序',
	所有来源: '所有来源',
	淘宝: '淘宝',
	天猫: '天猫',
	拼多多: '拼多多',
	1688: '1688',
	广州女装市场: '广州女装市场',
	广州男装市场: '广州男装市场',
	杭州女装市场: '杭州女装市场',
	杭州男装市场: '杭州男装市场',
	广州鞋业市场: '广州鞋业市场',
	义乌杂货批发市场: '义乌杂货批发市场',
	'价格区间(元)': '价格区间(元)',
	'０元': '０元',
	无上限: '无上限',
	重置: '重置',
	按此条件搜索: '按此条件搜索',
	筛选: '筛选',
	关闭: '关闭',
	商品详情: '商品详情',
	选择: '选择',
	'颜色分类/尺码': '颜色分类/尺码',
	运费: '运费',
	国际运费及购买成本估算: '国际运费及购买成本估算',
	查看原网址: '查看原网址',
	类似商品: '类似商品',
	类似店铺: '类似店铺',
	关联到我的店铺商品: '关联到我的店铺商品',
	传输到我的店铺: '传输到我的店铺',
	加入购物车: '加入购物车',
	'店铺名（支持日文）': '商品名/店铺名（支持日文）',
	去登录: '去登录',
	日语搜索: '日语搜索',
	暂不查看: '暂不查看',
	立即查看: '立即查看',
	未搜索到商品: '未搜索到商品',
	收藏成功: '收藏成功',
	取消收藏: '取消收藏',
	'感谢反馈，我们将为你推荐更加准确的商品':
		'感谢反馈，我们将为你推荐更加准确的商品',
	'加载更多商品中，请稍后': '加载更多商品中，请稍后...',
	暂无相关产品: '暂无相关产品',
	没有更多了: '没有更多了',
	清爽模式: '清爽模式',
	高效模式: '高效模式',
	常用分类: '常用分类',
	更多分类: '更多分类',
	找同款更低: '找同款更低',
	购买成本预估: '购买成本预估',
	'此页面仅供支持成本预估，最终费用以下单时为准':
		'此页面仅供支持成本预估，最终费用以下单时为准',
	商品数量: '商品数量',
	国际物流: '国际物流',
	公斤: '公斤',
	商品附加项: '商品附加项',
	请选择: '请选择',
	点击计算预估成本: '点击计算预估成本',
	去挖更多: '去挖更多',
	订单管理: '订单管理',
	售罄: '售罄',
	库存: '库存',
	默认规格: '默认规格',
	暂未发现收藏的店铺: '暂未发现收藏的店铺',
	商品已添加至购物车: '商品已添加至购物车',
	C端直发: 'C端直发',
	'确定要删除该商品吗？': '确定要删除该商品吗？',
	删除成功: '删除成功',
	请填写收货地址: '请填写收货地址',
	请选择发货附加项: '请选择发货附加项',
	请选择国际物流线路: '请选择国际物流线路',
	完成: '完成',
	删除: '删除',
	最小起订量为: '最小起订量为',
	无需选项: '无需选项',
	'合计(概算)': '合计(概算):',
	'余额：': '余额：',
	全选: '全选',
	提交订单: '提交订单',
	收货地址: '收货地址',
	请输入收货人姓名: '请输入收货人姓名',
	收货人: '收货人',
	联系电话: '联系电话',
	请输入手机号: '请输入手机号',
	都道府县: '都道府县',
	请选择都道府县: '请选择都道府县',
	市: '市',
	请选择城市: '请选择城市',
	详细地址: '详细地址',
	详请输入详细住址: '请输入详细住址',
	邮编: '邮编',
	请输入邮编: '请输入邮编',
	请先选择市: '请先选择市',
	收件人名字不能为空: '收件人名字不能为空',
	请输入有效的邮政编码: '请输入有效的邮政编码',
	保存成功: '保存成功',
	日本: '日本',
	发货附加项: '发货附加项',
	国际物流线路: '国际物流线路',
	选择发货附加项: '选择发货附加项',
	发货袋附加项必须选择一种: '发货袋附加项必须选择一种',
	'您还未保存设置，是否离开当前页？': '您还未保存设置，是否离开当前页？',
	直行便推荐: '直行便推荐',
	选择商品附加项: '选择商品附加项',
	费用明细: '费用明细',
	商品预估价格: '商品预估价格',
	商品预估附加项价格: '商品预估附加项价格',
	订单预估总价: '订单预估总价',
	'此金额为预估报价，您下单后，该部分金额将会被冻结。等到该订单采购处理全部完成后，我们会自动解冻这部分金额，并按照实际产生的金额进行扣费。实际扣费详情您可以后续在资金明细页面或订单详情页面查看。':
		'此金额为预估报价，您下单后，该部分金额将会被冻结。等到该订单采购处理全部完成后，我们会自动解冻这部分金额，并按照实际产生的金额进行扣费。实际扣费详情您可以后续在资金明细页面或订单详情页面查看。',
	请将收货人信息填写完整后提交订单: '请将收货人信息填写完整后提交订单',
	订单确认: '订单确认',
	您能接受的采购价格上涨: '您能接受的采购价格上涨',
	次品付费重拍: '次品付费重拍',
	接受次品重拍: '接受次品重拍',
	店铺名: '店铺名',
	订单类型: '订单类型',
	采购到库存: '采购到库存',
	订单标题: '订单标题',
	明细: '明细',
	支付成功: '支付成功',
	建议: '建议',
	打开消息通知: '打开消息通知',
	'，以便我们及时为您发送最': '，以便我们及时为您发送最',
	查看订单: '查看订单',
	回到首页: '回到首页',
	资金管理: '资金管理',
	主钱包: '主钱包',
	'可用余额(元)': '可用余额(元)',
	'未入账余额(元)': '未入账余额(元)',
	去充值: '去充值',
	子钱包: '子钱包',
	'冻结余额(元)': '冻结余额(元)',
	查看明细: '查看明细',
	充值历史: '充值历史',
	资金明细: '资金明细',
	充值: '充值',
	输入金额: '输入金额',
	充值金额: '充值金额',
	支付方式: '支付方式',
	立即充值: '立即充值',
	请输入充值金额: '请输入充值金额',
	充值金额不能小于0: '充值金额不能小于0',
	主钱包明细: '主钱包明细',
	充值成功: '充值成功',
	'充值成功！': '充值成功！',
	感谢您的支持: '感谢您的支持',
	充值时间: '充值时间',
	返回资金首页: '返回资金首页',
	'支付成功后，可能存在支付信息获取延迟，请耐心等待':
		'支付成功后，可能存在支付信息获取延迟，请耐心等待',
	充值失败: '充值失败',
	'充值失败！': '充值失败！',
	请重新充值: '请重新充值',
	重新充值: '重新充值',
	日期筛选: '日期筛选',
	所有类型: '所有类型',
	开始日期: '开始日期',
	请选择开始日期: '请选择开始日期',
	结束日期: '结束日期',
	请选择结束日期: '请选择结束日期',
	清空: '清空',
	充值记录: '充值记录',
	年: '年',
	月: '月',
	日: '日',
	出账: '出账',
	入账: '入账',
	流水号: '流水号',
	资金充值: '资金充值',
	提现: '提现',
	'转账（入金）': '转账（入金）',
	'转账（出金）': '转账（出金）',
	会员费: '会员费',
	'支付委托书（出金）': '支付委托书（出金）',
	子钱包明细: '子钱包明细',
	订单号: '订单号',
	搜索订单号: '搜索订单号',
	商品代金: '商品代金',
	国内送料: '国内送料',
	国際送料: '国際送料',
	售后服务退款: '售后服务退款',
	押金: '押金',
	押金退还: '押金退还',
	国際送料扣除差额部分: '国際送料扣除差额部分',
	国際送料追加请求总额: '国際送料追加请求总额',
	見積もり相談費用: '見積もり相談費用',
	見積もり相談費用の返金: '見積もり相談費用の返金',
	返金: '返金',
	客户的退款: '客户的退款',
	提取失败: '提取失败',
	国内送料返金: '国内送料返金',
	消息通知: '消息通知',
	全部已读: '全部已读',
	查看详情: '查看详情',
	详情: '详情',
	我的订单: '我的订单',
	待支付: '待支付',
	处理中: '处理中',
	可发货: '可发货',
	部分发货: '部分发货',
	已完成: '已完成',
	订单详情: '订单详情',
	加购成功: '加购成功',
	异常订单: '异常订单',
	异常订单记录: '异常订单记录',
	售后赔偿申请: '售后赔偿申请',
	取消记录: '取消记录',
	未处理: '未处理',
	已处理: '已处理',
	取消发货记录: '取消发货记录',
	取消采购记录: '取消采购记录',
	设置: '设置',
	关于直行便: '关于直行便',
	版本号: '版本号',
	直行便简介: '直行便简介',
	给我们评分: '给我们评分',
	联系我们: '联系我们',
	立即开启: '立即开启',
	用户服务协议: '用户服务协议',
	隐私政策: '隐私政策',
	清除缓存: '清除缓存',
	退出登录: '退出登录',
	立即退出: '立即退出',
	'开启推送通知，请在系统设置的通知中心打开':
		'开启推送通知，请在系统设置的通知中心打开',
	暂时不要: '暂时不要',
	反馈: '反馈',
	未关联商品: '未关联商品',
	经济舱: '经济舱',
	标准舱: '标准舱',
	商务舱: '商务舱',
	头等舱: '头等舱',
	入会天数: '入会天数',
	異常订单: '異常订单',
	API同步: 'API同步',
	购买过的: '购买过的',
	'商品/店舗': '商品/店舗',
	浏览历史: '浏览历史',
	在庫管理: '在庫管理',
	组合: '组合',
	商品一覧: '商品一覧',
	关联管理: '关联管理',
	支付委托书: '支付委托书',
	国際配送管理: '国際配送管理',
	'"FBA納品\n" + "一覧"': 'FBA納品\n' + '一覧',
	'"国際配送\n" + "一覧"': '国際配送\n' + '一覧',
	'"售后\n" + "補償申請"': '售后\n' + '補償申請',
	OEM: 'OEM',
	'"OEM\n" + "寻源"': 'OEM\n' + '寻源',
	'"OEM\n" + "寻源管理"': 'OEM\n' + '寻源管理',
	'"OEM\n" + "复购"': 'OEM\n' + '复购',
	店舗管理: '店舗管理',
	'"店铺\n" + "设置"': '店铺\n' + '设置',
	'"API/\n" + "子账号设置"': 'API/\n' + '子账号设置',
	收藏: '收藏',
	买过的: '买过的',
	浏览记录: '浏览记录',
	去挑点喜欢的商品加入购物车吧: '去挑点喜欢的商品加入购物车吧',
	去逛逛: '去逛逛',
	暂无相关数据: '暂无相关数据',
	批量编辑商品附加项: '批量编辑商品附加项',
	银行转账支付: '银行转账支付',
	货币种类: '货币种类',
	转账金额: '转账金额',
	转账银行名: '转账银行名',
	转账人姓名: '转账人姓名',
	我司应收银行: '我司应收银行',
	横滨站前支行: '横滨站前支行',
	账号: '账号',
	账户名: '账户名',
	みずほ銀行: 'みずほ銀行',
	店番292: '店番292',
	'普通預金 4014779': '普通預金 4014779',
	'株式会社 SNIFF JAPAN': '株式会社 SNIFF JAPAN',
	'收款·转账凭证': '收款·转账凭证',
	提交: '提交',
	没有开启相册权限: '没有开启相册权限',
	请输入转账人姓名: '请输入转账人姓名',
	请输入转账银行名: '请输入转账银行名',
	请输入转账金额: '请输入转账金额',
	请上传转账凭证: '请上传转账凭证',
	国际料金表: '国际料金表',
	客户SKU: '客户SKU',
	您的姓名: '您的姓名',
	请输入您的名字: '请输入您的名字',
	名字不能为空: '名字不能为空',
	请选择进货方式: '请选择进货方式',
	创建店铺: '创建店铺',
	'请输入店铺名/品牌名': '请输入店铺名/品牌名',
	请输入店铺访问链接: '请输入店铺访问链接',
	请输入店铺或品牌名: '请输入店铺或品牌名',
	访问令牌: '访问令牌:',
	请输入正确的访问令牌: '请输入正确的访问令牌',
	平台名: '平台名',
	店铺创建成功: '店铺创建成功',
	'店铺/品牌名': '店铺/品牌名',
	店铺URL地址: '店铺URL地址',
	'链接输入有误，重新输入链接': '链接输入有误，重新输入链接',
	请输入链接: '请输入链接',
	'以后的商品为次品时，请都默认处理为次品退货退款并付费重拍':
		'以后的商品为次品时，请都默认处理为次品退货退款并付费重拍',
	问题处理: '问题处理',
	问题原因: '问题原因',
	处理详情: '处理详情',
	'重新发起(处理中)': '重新发起(处理中)',
	结算时应退金额: '结算时应退金额',
	'已上架(已清算)': '已上架(已清算)',
	复制: '复制',
	'代买手续费/档口手续费': '代买手续费/档口手续费',
	运输公司: '运输公司',
	运单号: '运单号',
	商品原网址: '商品原网址',
	部分转库存: '部分转库存',
	订单编号: '订单编号',
	下单时间: '下单时间',
	订单来源: '订单来源',
	客户订单编号: '客户订单编号',
	物流公司: '物流公司',
	国际物流单号: '国际物流单号',
	发货时间: '发货时间',
	发货数量: '发货数量',
	最终采购价: '最终采购价',
	会员中心: '会员中心',
	'より多くのサービスをご利用頂く場合、下記のリンクをコピーしてパソコンから操作して下さいURL':
		'より多くのサービスをご利用頂く場合、下記のリンクをコピーしてパソコンから操作して下さいURL',
	クリックしてコピー: 'クリックしてコピー',
	主な業務: '主な業務',
	OEMとセット組商品の仕入れはパソコンから操作して下さい:
		'OEMとセット組商品の仕入れはパソコンから操作して下さい',
	仕入れ代行: '仕入れ代行',
	购买数量: '购买数量',
	'"検品済み\n" + "(配送可能)"': '検品済み\n' + '(配送可能)',
	'"確認待ち\n" + "納品プラン"': '確認待ち\n' + '納品プラン',
	'"未紐付け\n" + "商品"': '未紐付け\n' + '商品',
	'抱歉，订单正在打包中，不允许修改地址':
		'抱歉，订单正在打包中，不允许修改地址',
	'チャージ上限額は99999999.99円です': 'チャージ上限額は99999999.99円です',
	发货量: '发货量',
	'店舗未登録，店舗作成後、再試行して下さい':
		'店舗未登録，店舗作成後、再試行して下さい',
	添加新店铺: '',
	'当前账号下无店铺，请创建店铺后再执行操作':
		'当前账号下无店铺，请创建店铺后再执行操作',
	お知らせ: 'お知らせ',
	'カラー・サイズ不明': 'カラー・サイズ不明',
	在庫切れ: '在庫切れ',
	予約販売: '予約販売',
	最小注文ロット数あり: '最小注文ロット数あり',
	不良品: '',
	'不良品(自動再仕入れ回数上限に達していても、出荷可能良品在庫が足りません)':
		'不良品(自動再仕入れ回数上限に達していても、出荷可能良品在庫が足りません)',
	'不良品(不良品対応設定で「返品返金」ご指定)':
		'不良品(不良品対応設定で「返品返金」ご指定)',
	'在庫切れ(自動再仕入れ回数上限に達していても、出荷可能良品在庫が足りません)':
		'在庫切れ(自動再仕入れ回数上限に達していても、出荷可能良品在庫が足りません)',
	全サイト在庫切れ: '全サイト在庫切れ',
	'お客様申請(仕入れキャンセル済)': 'お客様申請(仕入れキャンセル済)',
	不良品返品完了: '不良品返品完了',
	'在庫切れ(自動再仕入れ回数上限に達した)':
		'在庫切れ(自動再仕入れ回数上限に達した)',
	'不良品(自動再仕入れ回数上限に達した)':
		'不良品(自動再仕入れ回数上限に達した)',
	在庫切れ再発注完了: '在庫切れ再発注完了',
	お支払待ち: 'お支払待ち',
	'お支払いますか？': '支払いへ',
	支払いへ: '支払いへ',
	'枠線を移動すると、検索したい商品図領域を選択可能です':
		'枠線を移動すると、検索したい商品図領域を選択可能です',
	お気に入り店舗から削除: 'お気に入り店舗から削除',
	最小注文ロットを満たしていない商品があります:
		'最小注文ロットを満たしていない商品があります',
	'メールでのお問い合わせ：INFO@THECKB.COM':
		'メールでのお問い合わせ：INFO@THECKB.COM',
	'お電話でのお問い合わせ：080-6583-7346':
		'お電話でのお問い合わせ：080-6583-7346',
	'電話受付：平日 10:00～17:00': '電話受付：平日 10:00～17:00',
	LINE公式アカウント: 'LINE公式アカウント',
	'LINEでのお問い合わせ：': 'Lineでのお問い合わせ：',
	電話をする: '電話をする',
	メールアドレスを開く: 'メールアドレスを開く',
	キャッシュクリア完了: 'キャッシュクリア完了',
	商品管理: '商品管理',
	見つかりました: '見つかりました',
	選択商品を選択して下さい: '選択商品を選択して下さい',
	データなし: 'データなし',
	お気に入りの商品を選んでカートに追加しましょう:
		'お気に入りの商品を選んでカートに追加しましょう',
	お気に入り商品はありません: 'お気に入り商品はありません',
	該当する商品はありません: '該当する商品はありません',
	メッセージはまだありません: 'メッセージはまだありません',
	該当する情報はありませんでした: '該当する情報はありませんでした',
	'インターネットに接続されていません。インターネット設定を確認して再度お試し下さい':
		'インターネットに接続されていません。インターネット設定を確認して再度お試し下さい',
	商品種類と数量を選択して下さい: '商品種類と数量を選択して下さい',
	種類を選択: '種類を選択',
	選択済み: '選択済み',
	日本語での検索が可能です: '日本語での検索が可能です',
	該当商品は買付対応出来かねます: '該当商品は買付対応出来かねます',
	在庫: '在庫',
	申請時間: '申請時間',
	スタイルを変更: 'スタイルを変更',
	商品をキャンセル: '商品をキャンセル',
	'B2B-FBA': 'B2B-FBA',
	'B2B-普通': 'B2B-普通',
	D2C: 'D2C',
	異常返金: '異常返金',
	在庫移動: '在庫移動',
	SDカードのアクセス権限: 'SDカードのアクセス権限',
	'カメラ・写真へアクセスを「THECKB]に許可しますか？':
		'カメラ・写真へアクセスを「THECKB]に許可しますか？',
	許可しない: '許可しない',
	許可: '許可',
	'振込日、振込依頼人、お受取人名、振込金額の情報が含まれる画像をお願いします。':
		'振込日、振込依頼人、お受取人名、振込金額の情報が含まれる画像をお願いします。',
	'単品（概算）': '単品（概算）',
	'国際送料（概算）': '国際送料（概算）',
	位置を占める: '位置を占める',
	详细情况请从电脑上确认: '详细情况请从电脑上确认',
	'元/送り状': '元/送り状',
	チェックした商品はありません: 'チェックした商品はありません',
	チェックした店舗はありません: 'チェックした店舗はありません',
	組合せ対応待ち: '組合せ対応待ち',
	組合せ対応中: '組合せ対応中',
	組合せ対応完了: '組合せ対応完了',
	コピー済み: 'コピー済み',
	取消数量: '取消数量',
	表示切替: '表示切替',
	'アプリの新しい \nバージョンがあります':
		'アプリの新しい \nバージョンがあります',
	'该页为第三方登录页。登录后，即可正常浏览':
		'该页为第三方登录页。登录后，即可正常浏览',
	商品问题原因: '商品问题原因',
	请选择处理方式: '请选择处理方式',
	只可选择一种: '只可选择一种',
	更换颜色: '更换颜色',
	取消商品: '取消商品',
	换个链接: '换个链接',
	平台商品SKU: '平台商品SKU',
	直行便订单编号: '直行便订单编号',
	申请售后时间: '申请售后时间',
	售后进度: '售后进度',
	售后原因: '售后原因',
	导出订单: '导出订单',
	重新发起: '重新发起',
	撤销: '撤销',
	申请售后: '申请售后',
	撤销售后: '撤销售后',
	申请时间: '申请时间',
	取消进度: '取消进度',
	'该操作，会将已发起售后商品信息撤销，请确认是否撤销该商品售后？':
		'该操作，会将已发起售后商品信息撤销，请确认是否撤销该商品售后？',
	撤销商品不可再次发起撤销: '撤销商品不可再次发起撤销',
	请选择售后原因: '请选择售后原因',
	详细说明: '详细说明',
	申请售后数量: '申请售后数量',
	售后退款金额: '售后退款金额',
	'点击上传图片（最多上传5张）(只能上传jpg/png文件，且不超过10000kb)':
		'点击上传图片（最多上传5张）(只能上传jpg/png文件，且不超过10000kb)',
	撤销成功: '撤销成功',
	问题处理2: '问题处理',
	售后: '售后',
	申请取消: '申请取消',
	商品取消履历: '商品取消履历',
	待处理: '待处理',
	业务处理中: '业务处理中',
	财务处理中: '财务处理中',
	已退款: '已退款',
	已拒绝: '已拒绝',
	已拒绝2: '已拒绝',
	'重新发起（处理中）': '重新发起（处理中）',
	已撤销: '已撤销',
	次品: '次品',
	次品2: '次品',
	相异品: '相异品',
	数量不足: '数量不足',
	保留库存: '保留库存',
	取消成功: '取消成功',
	商品信息: '商品信息',
	问题生成时间: '问题生成时间',
	处理方式: '处理方式',
	退款金额: '退款金额',
	申请售后件数: '申请售后件数',
	拒绝原因: '拒绝原因',
	拒绝原因2: '拒绝原因',
	退款总金额: '退款总金额',
	退款说明: '退款说明',
	不可优先发货: '不可优先发货',
	'可优先发货(订单下部分商品已全部上架)':
		'可优先发货(订单下部分商品已全部上架)',
	// 我的订单
	商品sku: '商品sku',
	组合附加项: '组合附加项',
	下单组合数: '下单组合数',
	组合完成数: '组合完成数',
	'服务费（元）': '服务费（元）',
	组合状态: '组合状态',
	商品画像: '商品画像',
	本单质检上架数: '本单质检上架数',
	转结落单库存数: '转结落单库存数',
	组合落单库存处理情况: '组合落单库存处理情况',
	国家: '国家',
	都道府県: '都道府県',
	序号: '序号',
	商品单价: '商品单价',
	'总价（RMB）': '总价（RMB）',
	商品状态: '商品状态',
	详情费用: '详情费用',
	代买手续费: '代买手续费',
	入库手续费: '入库手续费',
	质检手续费: '质检手续费',
	发货手续费: '发货手续费',
	发货单号: '发货单号',
	生成时间: '生成时间',
	国际运单号: '国际运单号',
	重量g: '重量g',
	是否发货: '是否发货',
	商品番号: '商品番号',
	其他费用: '其他费用',
	费用类型: '费用类型',
	时间: '时间',
	地址详情: '地址详情',
	详情地址: '详情地址',
	编辑地址: '编辑地址',
	取消并退款: '取消并退款',
	已上架: '已上架',
	检品中: '检品中',
	异常: '异常',
	国内运输中: '国内运输中',
	已发货: '已发货',
	已采购: '已采购',
	采购中: '采购中',
	已入库: '已入库',
	待采购: '待采购',
	查看: '查看',
	发货单详情: '发货单详情',
	未发货: '未发货',
	编辑收货地址: '编辑收货地址',
	费用详情: '费用详情',
	请确认取消信息: '请确认取消信息',
	'确认是否取消？': '确认是否取消？',
	合: '合',
	等待审核: '等待审核',
	下单成功: '下单成功',
	国际物流信息详情: '国际物流信息详情',
	业务类型: '业务类型',
	取消结果: '取消结果',
	所有订单: '所有订单',
	待付款: '待付款',
	已完成2: '已完成',
	是否合单: '是否合单',
	Base: 'Base',
	Shopify: 'Shopify',
	Stores: 'Stores',
	Amazon: 'Amazon',
	直发订单: '直发订单',
	囤货订单: '囤货订单',
	可优先发货: '可优先发货',
	未组合: '未组合',
	组合中: '组合中',
	组合完成: '组合完成',
	取消组合: '取消组合',
	日志: '日志',
	优先发货: '优先发货',
	去支付: '去支付',
	是否去支付: '是否去支付',
	国际物流单号信息: '国际物流单号信息',
	商品日志详情: '商品日志详情',
	结束时间: '结束时间',
	商品搜索: '商品搜索',
	下单日: '下单日',
	发货日: '发货日',
	客户商品信息: '客户商品信息',
	客户商品SKU: '客户商品SKU',
	客户商品名称: '客户商品名称',
	客户商品规格: '客户商品规格',
	客户商品图片: '客户商品图片',
	采购商品SKU: '采购商品SKU',
	采购商品信息: '采购商品信息',
	采购商品名称: '采购商品名称',
	采购商品规格: '采购商品规格',
	采购商品图片: '采购商品图片',
	商品采购渠道: '商品采购渠道',
	商品状态变更日志: '商品状态变更日志',
	操作记录: '操作记录',
	变更时间: '变更时间',
	'已上架（已清算）': '已上架（已清算）',

	// 授权店铺
	'你授权的xxx店铺取消授权后，店铺订单和商品将不再同步，是否继续取消？':
		'你授权的xxx店铺取消授权后，店铺订单和商品将不再同步，是否继续取消？',
	是: '是',
	否: '否',
	解绑成功: '解绑成功',
	创建时间: '创建时间',
	状态: '状态',
	店铺钱包可用余额: '店铺钱包可用余额',
	店铺账号: '店铺账号',
	api权限: 'api权限',
	店铺管理: '店铺管理',
	授权店铺管理: '授权店铺管理',
	激活子账号: '激活子账号',
	配置店铺: '配置店铺',
	开启自动同步下单: '开启自动同步下单',
	取消授权: '取消授权',
	创建成功: '创建成功',
	创建成功2: '创建成功',
	修改成功: '修改成功',
	物流配置修改成功: '物流配置修改成功',
	// 我的钱包
	水洗标制作: '水洗标制作',
	日本配送公司: '日本配送公司',
	国际发货单号: '国际发货单号',
	偏远地区附加费: '偏远地区附加费',
	'出账 / 入账': '出账 / 入账',
	日期: '日期',
	可用余额: '可用余额',
	直行发货单号: '直行发货单号',
	转账: '转账',
	表格导出: '表格导出',
	的钱包: '的钱包',
	主账号钱包: '主账号钱包',
	我的店铺钱包: '我的店铺钱包',
	冻结金额: '冻结金额',
	未入账金额: '未入账金额',
	查询: '查询',
	收据下载: '收据下载',
	银行名: '银行名',
	金额: '金额',
	币种: '币种',
	上传凭证: '上传凭证',
	'振込金額(日本円)': '振込金額(日本円)',
	公司名称: '公司名称',
	店铺主页入口: '店铺主页入口',
	姓氏: '姓氏',
	名字: '名字',
	邮箱地址: '邮箱地址',
	国际: '国际',
	存入金额: '存入金额',
	'加载完成！': '加载完成！',
	收款账号: '收款账号',
	最多可转账金额: '最多可转账金额',
	'商品sku/店铺商品sku/海外仓sku': '商品sku/店铺商品sku/海外仓sku',
	选择地址: '选择地址',
	输入的手机号位数不正确: '输入的手机号位数不正确',
	区町村不能为空: '区町村不能为空',
	最少3个文字以上: '最少3个文字以上',
	请输入正确的邮编: '请输入正确的邮编',
	地址添加成功: '地址添加成功',
	'sku/平台商品信息': 'sku/平台商品信息',
	库存数量: '库存数量',
	海外仓sku: '海外仓sku',
	店铺名称: '店铺名称',
	需要填写清关地址: '需要填写清关地址',
	选择清关地址: '选择清关地址',
	添加清关地址: '添加清关地址',
	补货批次标题: '补货批次标题',
	箱唛上传: '箱唛上传',
	物流方式: '物流方式',
	kg: 'kg',
	计算: '计算',
	预估物流价: '预估物流价',
	'注意：您可以在此输入预计的重量 大致估算出物流价格，货品发出前将根据实际扣除物流费用，请确保您的账户余额充足，余额不足无法扣款将影响您的发货进度。':
		'注意：您可以在此输入预计的重量 大致估算出物流价格，货品发出前将根据实际扣除物流费用，请确保您的账户余额充足，余额不足无法扣款将影响您的发货进度。',
	填写: '填写',
	移除: '移除',
	请选择商品: '请选择商品',
	'区町村-详细地址': '区町村-详细地址',
	国际发货标题不能为空: '国际发货标题不能为空',
	必填: '必填',
	物流方式不能为空: '物流方式不能为空',
	'文件大小不超过50m；支持格式doc;excel;pdf;':
		'文件大小不超过50m；支持格式doc;excel;pdf;',
	箱唛上传不能为空: '箱唛上传不能为空',
	拣货中: '拣货中',
	打标中: '打标中',
	打包中: '打包中',
	打包完成: '打包完成',
	标题: '标题',
	大件fba: '大件fba',
	大件普通: '大件普通',
	小件: '小件',
	次品退货: '次品退货',
	异常退货: '异常退货',
	仓库调拨: '仓库调拨',
	创建补货计划: '创建补货计划',
	'Amazon sku': 'Amazon sku',
	sku: 'sku',
	补货批次号: '补货批次号',
	国际运费号: '国际运费号',
	运输方式: '运输方式',
	合计总额: '合计总额',
	AMAZON商品名称: 'AMAZON商品名称',
	计划发货数量: '计划发货数量',
	基本详情: '基本详情',
	发货重量: '发货重量',
	计费重量: '计费重量',
	国际发货状态: '国际发货状态',
	'收货仓/地址': '收货仓/地址',
	配送件数: '配送件数',
	箱数: '箱数',
	装箱清单: '装箱清单',
	装箱清单2: '装箱清单',
	商品内容: '商品内容',
	创建国际发货: '创建国际发货',
	// oem
	寻源表: '寻源表',
	寻源种类: '寻源种类',
	类别: '类别',
	X类: 'X类',
	类别注明: '类别注明',
	产品名称: '产品名称',
	参考图片: '参考图片',
	提供项: '提供项',
	是否提供: '是否提供',
	具体内容: '具体内容',
	参考链接: '参考链接',
	样衣: '样衣',
	物流单号: '物流单号',
	需要改动的地方: '需要改动的地方',
	'描述内容(材质，设计，颜色，尺寸等)': '描述内容(材质，设计，颜色，尺寸等)',
	附件: '附件',
	式样书: '式样书',
	'附件(包含产品的色卡、尺寸、特殊工艺、面料信息等)':
		'附件(包含产品的色卡、尺寸、特殊工艺、面料信息等)',
	面料: '面料',
	需求描述: '需求描述',
	面料信息: '面料信息',
	色卡信息: '色卡信息',
	Logo: 'Logo',
	'详细说明(颜色、尺寸、位置、工艺等)': '详细说明(颜色、尺寸、位置、工艺等)',
	辅料种类: '辅料种类',
	直行便预选方案选择: '直行便预选方案选择',
	分类: '分类',
	选择方案: '选择方案',
	定制补充部分: '定制补充部分',
	文字描述: '文字描述',
	材质方案: '材质方案',
	款式方案: '款式方案',
	尺寸方案: '尺寸方案',
	颜色方案: '颜色方案',
	款式描述: '款式描述',
	材质描述: '材质描述',
	尺寸描述: '尺寸描述',
	颜色描述: '颜色描述',
	设计图或参考图片: '设计图或参考图片',
	产品信息: '产品信息',
	具体尺码信息: '具体尺码信息',
	对应关系: '对应关系',
	自己提供: '自己提供',
	尺码表: '尺码表',
	参考链接尺寸: '参考链接尺寸',
	直行便提供: '直行便提供',
	其他补充: '其他补充',
	下单需求: '下单需求',
	期望价格: '期望价格',
	期望交期: '期望交期',
	期望起订量范围: '期望起订量范围',
	侧重点: '侧重点',
	品质: '品质',
	交期: '交期',
	Logo需求情况: 'Logo需求情况',
	需要: '需要',
	不需要: '不需要',
	'工厂、资质要求': '工厂、资质要求',
	特殊需求: '特殊需求',
	服装: '服装',
	辅料: '辅料',
	内容: '内容',
	备注说明: '备注说明',
	操作人: '操作人',
	报价信息: '报价信息',
	是否加Logo: '是否加Logo',
	是否为特殊面料: '是否为特殊面料',
	次品处理方式: '次品处理方式',
	风险: '风险',
	打样费: '打样费',
	打样时间: '打样时间',
	天: '天',
	时: '时',
	分: '分',
	秒: '秒',
	最小起订量: '最小起订量',
	单尺码: '单尺码',
	多尺码: '多尺码',
	商品数量区间: '商品数量区间',
	阶梯价格: '阶梯价格',
	报价有效期: '报价有效期',
	国际运费说明: '国际运费说明',
	全部订单: '全部订单',
	未发布: '未发布',
	等待报价: '等待报价',
	已报价: '已报价',
	寻源成功: '寻源成功',
	报价失效: '报价失效',
	已取消: '已取消',
	已取消2: '已取消',
	重新发布: '重新发布',
	再次买入单号: '再次买入单号',
	类型: '类型',
	申请表: '申请表',
	商品名: '商品名',
	发起时间: '发起时间',
	寻源单编号: '寻源单编号',
	拒绝备注: '拒绝备注',
	是否确认编辑: '是否确认编辑',
	是否确认发布寻源单: '是否确认发布寻源单',
	发布: '发布',
	是否确认取消待发布寻源单: '是否确认取消待发布寻源单',
	是否确定下样品单: '是否确定下样品单',
	下样品单: '下样品单',
	是否确定下大货单: '是否确定下大货单',
	下大货单: '下大货单',
	是否确认申请退款: '是否确认申请退款',
	申请退款: '申请退款',
	报价过期: '报价过期',
	是否确认申请复购: '是否确认申请复购',
	复购申请: '复购申请',
	是否确认重新编辑: '是否确认重新编辑',
	重新编辑: '重新编辑',
	重新编辑2: '重新编辑',
	操作日志: '操作日志',
	发布成功: '发布成功',
	退款成功: '退款成功',
	已提交申请复购: '已提交申请复购',
	复购申请中: '复购申请中',
	复购已拒绝: '复购已拒绝',
	复购已报价: '复购已报价',
	//
	未关联: '未关联',
	已关联: '已关联',
	关联异常: '关联异常',
	待关联: '待关联',
	售卖价格: '售卖价格',
	关联状态: '关联状态',
	已关联SKU数量: '已关联SKU数量',
	采购商品名: '采购商品名',
	采购价格: '采购价格',
	采购商品图: '采购商品图',
	创建关联商品: '创建关联商品',
	'该店铺未授权同步数据功能，授权后可绑定商品，享受自动下单功能。':
		'该店铺未授权同步数据功能，授权后可绑定商品，享受自动下单功能。',
	'因采购链接发生变动，请重新绑定': '因采购链接发生变动，请重新绑定',
	// 占行
	编辑关联: '编辑关联',
	番号保存成功: '番号保存成功',
	组合商品SKU: '组合商品SKU',
	组合在库状态: '组合在库状态',
	'单价（元）': '单价（元）',
	'待组合库存数量（截止时间）': '待组合库存数量（截止时间）',
	组合商品管理: '组合商品管理',
	扩大加购: '扩大加购',
	完结: '完结',
	加购: '加购',
	附加项修改成功: '附加项修改成功',
	添加成功: '添加成功',
	完结成功: '完结成功',
	存在待组合: '存在待组合',
	不存在待组合: '不存在待组合',
	找相似: '找相似',
	断开: '断开',
	店铺商品sku解绑成功: '店铺商品sku解绑成功',
	选定: '选定',
	已连接其他SKU: '已连接其他SKU',
	断开原有链接: '断开原有链接',
	请选择采购商品sku: '请选择采购商品sku',
	请选择店铺商品sku: '请选择店铺商品sku',
	请选择商品附加项: '请选择商品附加项',
	店铺商品sku绑定成功: '店铺商品sku绑定成功',
	同步成功: '同步成功',
	'10-20元': '10-20元',
	'20-30元': '20-30元',
	'30-50元': '30-50元',
	'50-100元': '50-100元',
	'100-150元': '100-150元',
	'200-300元': '200-300元',
	'300元以上': '300元以上',
	选择要链接的商品: '选择要链接的商品',
	选择要链接商品的SKU: '选择要链接商品的SKU',
	自动同步采购订单: '自动同步采购订单',
	'同步已授权的店铺内商品，并进行绑定': '同步已授权的店铺内商品，并进行绑定',
	//
	搜索青蛙商品: '搜索青蛙商品',
	商品链接或商品名: '商品链接或商品名',
	类目筛选: '类目筛选',
	价格筛选: '价格筛选',
	采购商品: '采购商品',
	店铺商品: '店铺商品',
	重新选择商品: '重新选择商品',
	创建关联: '创建关联',
	系统查询到您有辅料未添加到附加项配置:
		'系统查询到您有辅料未添加到附加项配置',
	平均单价: '平均单价',
	锁定: '锁定',
	可用: '可用',
	待处理次品: '待处理次品',
	辅料图片: '辅料图片',
	辅料名称: '辅料名称',
	关联附加项: '关联附加项',
	辅料规格: '辅料规格',
	辅料skuid: '辅料skuid',
	待入库: '待入库',
	次品图: '次品图',
	说明: '说明',
	丢弃: '丢弃',
	转为正常商品: '转为正常商品',
	我的商品库存: '我的商品库存',
	我的物料: '我的物料',
	未关联附加项: '未关联附加项',
	'未关联附加项的辅料，将不会再订单商品中自动使用':
		'未关联附加项的辅料，将不会再订单商品中自动使用',
	配置附加项: '配置附加项',
	辅料绑定提醒: '辅料绑定提醒',
	'物料名/sku': '物料名/sku',
	已有关联附加项: '已有关联附加项',
	未有关联附加项: '未有关联附加项',
	可用库存数量筛选: '可用库存数量筛选',
	至: '至',
	批量加入购物车: '批量加入购物车',
	'关闭自动续费后，您的会员将于': '关闭自动续费后，您的会员将于',
	到期请前往paypal平台关闭自动续费: '到期请前往paypal平台关闭自动续费',
	'到期是否现在关闭？': '到期是否现在关闭？',
	'恭喜！！！已为您开启自动续费，记得保持余额充足':
		'恭喜！！！已为您开启自动续费，记得保持余额充足',
	购买明细: '购买明细',
	会员信息: '会员信息',
	原价: '原价',
	优惠金额: '优惠金额',
	か月: 'か月',
	有效期: '有效期',
	总计: '总计',
	立即支付: '立即支付',
	立即支付2: '立即支付',
	'购买会员到期后我们会为您自动续费，您可在工作台-会员管理中关闭自动续费服务':
		'购买会员到期后我们会为您自动续费，您可在工作台-会员管理中关闭自动续费服务',
	会员办理: '会员办理',
	每月: '每月',
	选择办理会员周期: '选择办理会员周期',
	请联系主账号更改会员等级: '请联系主账号更改会员等级',
	您的账户余额不足: '您的账户余额不足',
	确认支付: '确认支付',
	'立即购买（自动续费）': '立即购买（自动续费）',
	'购买后新会员等级立即生效，原有等级作废，如有疑问请联系客服':
		'购买后新会员等级立即生效，原有等级作废，如有疑问请联系客服',
	请选择会员套餐: '请选择会员套餐',
	自动续费中: '自动续费中',
	开通自动续费: '开通自动续费',
	到期: '到期',
	关闭成功: '关闭成功',
	// 我的消息
	我的消息: '我的消息',
	已读: '已读',
	'未读/已读': '未读/已读',
	// 表单
	姓名: '姓名',
	请输入姓名: '请输入姓名',
	手机号: '手机号',
	请输入市区町村: '请输入市区町村',
	请输入详细地址: '请输入详细地址',
	请输入邮箱: '请输入邮箱',
	'你的子账号没有修改权限，请联系主账号管理者修改你的密码':
		'你的子账号没有修改权限，请联系主账号管理者修改你的密码',
	无效内容: '无效内容',
	无效的地址: '无效的地址',
	收件人姓名不能为空: '收件人姓名不能为空',
	市不能为空: '市不能为空',
	添加地址: '添加地址',
	// 确认框
	确定删除该商品: '确定删除该商品？',
	确定不再显示该商品: '确定不再显示该商品？',
	确定清空购物车: '确定清空购物车？',
	确定删除此配置: '确定删除此配置？',
	确定删除此地址: '确定删除此地址？',
	'当前页面的修改内容未保存，是否离开':
		'当前页面的修改内容未保存，是否离开？',
	// 消息框
	请至少选择一个配置类目: '请至少选择一个配置类目',
	'单笔订单价值不得超过XX元，请调整商品数量':
		'单笔订单价值不得超过XX元，请调整商品数量',
	添加附加项模板成功: '添加附加项模板成功',
	修改附加项模板成功: '修改附加项模板成功',
	修改邮箱成功: '修改邮箱成功',
	// 登录
	请登录: '请登录',
	请先登录: '请先登录',
	密码登录: '密码登录',
	邮箱验证登录: '邮箱验证登录',
	'没有账号?去注册会员': '没有账号?去注册会员',
	'用户名或邮箱不正确，请重新输入1-10位用户名或邮箱':
		'用户名或邮箱不正确，请重新输入1-10位用户名或邮箱',
	'输入的密码格式不正确，密码由6-20位字母或数字组成':
		'输入的密码格式不正确，密码由6-20位字母或数字组成',
	'邮箱格式不正确，请重新输入': '邮箱格式不正确，请重新输入',
	'验证码格式不正确，请重新输入': '验证码格式不正确，请重新输入',
	// 占行
	'验证码正在发送到您的邮箱，请注意查收':
		'验证码正在发送到您的邮箱，请注意查收',
	'密码不正确，请重新输入': '密码不正确，请重新输入',
	'用户名或密码不正确，请重新输入': '用户名或密码不正确，请重新输入',
	'邮箱或验证码错误，请重新输入': '邮箱或验证码错误，请重新输入',
	密码不能为空: '密码不能为空',
	输入用户名: '输入用户名',
	输入密码: '输入密码',
	输入邮箱: '输入邮箱',
	输入验证码: '输入验证码',
	找回密码: '找回密码',
	重设密码: '重设密码',
	注册邮箱: '注册邮箱',
	邮箱验证码: '邮箱验证码',
	新密码设置: '新密码设置',
	再次确认新密码: '再次确认新密码',
	'密码修改成功，请重新登录': '密码修改成功，请重新登录',
	免费注册: '免费注册',
	'已有会员，': '已有会员，',
	立即登录: '立即登录',
	请选择适合你的业务类型: '请选择适合你的业务类型',
	适合想要进货到日本仓库的用户: '适合想要进货到日本仓库的用户',
	适合想要从中国直发商品到末端客户的用户:
		'适合想要从中国直发商品到末端客户的用户',
	立即进入: '立即进入',
	联系人姓名: '联系人姓名',
	再次确认密码: '再次确认密码',
	性别: '性别',
	先生: '先生',
	邮箱: '邮箱',
	验证码: '验证码',
	已发送: '已发送',
	该用户名已被注册: '该用户名已被注册',
	'用户名不可用，请重新设置1-18位用户名不支持标点符号':
		'用户名不可用，请重新设置1-18位用户名不支持标点符号',
	'用户名不可用，请重新设置6-12位用户名不支持标点符号':
		'用户名不可用，请重新设置6-12位用户名不支持标点符号',
	联系人姓名不能为空: '联系人姓名不能为空',
	'格式不正确，请输入真实姓名，包括假名和文字及英文':
		'格式不正确，请输入真实姓名，包括假名和文字及英文',
	'输入的密码格式不正确，请设置6-32位字母或数字，区分大小写':
		'输入的密码格式不正确，请设置6-32位字母或数字，区分大小写',
	邮箱已被注册: '邮箱已被注册',
	无效的手机号码: '无效的手机号码',
	请设置用户名: '请设置用户名',
	'你的名字是？': '你的名字是？',
	设置你的登陆密码: '设置你的登陆密码',
	请再次输入您的密码: '请再次输入您的密码',
	'请输入您的手机号码（选填）': '请输入您的手机号码（选填）',
	'请输入你的Chatwork ID （选填）': '请输入你的Chatwork ID （选填）',
	'请输入你的Skype （选填）': '请输入你的Skype （选填）',
	注册并登录: '注册并登录',
	同意以下条款: '同意以下条款',
	会员条约: '会员条约',
	默认业务类型: '默认业务类型',
	新密码: '新密码',
	原密码: '原密码',
	旧密码重置密码: '旧密码重置密码',
	邮箱重置密码: '邮箱重置密码',
	请输入原密码: '请输入原密码',
	请输入新密码: '请输入新密码',
	'已有账号，马上登录': '已有账号，马上登录',
	// 提现
	提现渠道: '提现渠道',
	银行名称: '银行名称',
	支店名: '支店名',
	口座类别: '口座类别',
	银行账号: '银行账号',
	收款人名称: '收款人名称',
	'提现金额（RMB）': '提现金额（RMB）',
	汇率: '汇率',
	费率: '费率',
	可提现金额: '可提现金额',
	提现手续费: '提现手续费',
	'提现金额（JPY）': '提现金额（JPY）',
	提现原因: '提现原因',
	电子邮件地址: '电子邮件地址',
	银行卡: '银行卡',
	普通: '普通',
	发送: '发送',
	请选择提现渠道: '请选择提现渠道',
	请输入银行名称: '请输入银行名称',
	请输入支店名: '请输入支店名',
	请选择口座类别: '请选择口座类别',
	请输入银行账号: '请输入银行账号',
	请输入收款人名称: '请输入收款人名称',
	请输入提现金额: '请输入提现金额',
	请输入验证码: '请输入验证码',
	请输入提现原因: '请输入提现原因',
	请输入邮箱验证码: '请输入邮箱验证码',
	// Bar
	个人中心: '个人中心',
	帮助: '帮助',
	历史订单: '历史订单',
	我的库存: '我的库存',
	用户信息: '用户信息',
	OEM寻源单: 'OEM寻源单',
	OEM发起寻源: 'OEM发起寻源',
	同步的订单: '同步的订单',
	插件安装: '插件安装',
	购买会员等级: '购买会员等级',
	我的店铺: '我的店铺',
	我的钱包: '我的钱包',
	登录账户管理: '登录账户管理',
	授权店铺: '授权店铺',
	最近加入的商品: '最近加入的商品',
	选择你经常使用的功能: '选择你经常使用的功能',
	最多可选择X个: '最多可选择X个',
	请选择你经常使用的类目: '请选择你经常使用的类目',
	// 用户
	欢迎来到直行便: '欢迎来到直行便',
	会员等级: '会员等级',
	切换账号: '切换账号',
	账号设置: '账号设置',
	登录账号管理: '登录账号管理',
	我收藏的商品和店铺: '我收藏的商品/店铺',
	我买过的商品和店铺: '我买过的商品/店铺',
	我看过的商品和店铺: '我看过的商品/店铺',
	关联商品: '关联商品',
	当前账号已退出: '当前账号已退出',
	修改邮箱: '修改邮箱',
	新邮箱地址: '新邮箱地址',
	注销子账号: '注销子账号',
	用户信息更新成功: '用户信息更新成功',
	邮箱修改成功: '邮箱修改成功',
	目前您还没有创建店铺: '目前您还没有创建店铺',
	快速创建: '快速创建',
	切换B2B囤货业务: '切换B2B囤货业务',
	切换D2C代发业务: '切换D2C代发业务',
	店铺采购管理: '店铺采购管理',
	我的地址: '我的地址',
	// 用户配置项
	店铺平台: '店铺平台',
	店铺配置: '店铺配置',
	登录信息: '登录信息',
	修改密码: '修改密码',
	采购配置: '采购配置',
	附加项配置: '附加项配置',
	国际物流配置: '国际物流配置',
	次品付费重购: '次品付费重购',
	'接受次品重拍，有助于我们更好的为您完成C端订单':
		'接受次品重拍，有助于我们更好的为您完成C端订单',
	次品退货退款: '次品退货退款',
	'采购商品时，没有指定类目附加项时，使用全部附加项，两者不重复':
		'采购商品时，没有指定类目附加项时，使用全部附加项，两者不重复',
	'当您订单下有该类目商品时，我们将默认为您添加以下附加服务':
		'当您订单下有该类目商品时，我们将默认为您添加以下附加服务',
	水洗标信息配置: '水洗标信息配置',
	配置品牌专属水洗标: '配置品牌专属水洗标',
	打开全部: '打开全部',
	去配置: '去配置',
	使用类目: '使用类目',
	类目失效: '类目失效',
	选择物流公司: '选择物流公司',
	请选择物流公司: '请选择物流公司',
	国际面单主设定: '国际面单主设定',
	国际面单依赖主设定: '国际面单依赖主设定',
	联系方式: '联系方式',
	添加新地址: '添加新地址',
	会社编号: '会社编号',
	日文收货地址: '日文收货地址',
	英文收货地址: '英文收货地址',
	修改地址: '修改地址',
	// 附加项
	一键自动配置: '一键自动配置',
	一键配置: '一键配置',
	我的类目: '我的类目',
	选择您要配置的类目: '选择您要配置的类目',
	选择商品附加服务: '选择商品附加服务',
	选择发货附加服务: '选择发货附加服务',
	选择我的物料: '选择我的物料',
	附加项: '附加项',
	辅料sku: '辅料sku',
	辅料名: '辅料名',
	预计在库数: '预计在库数',
	去补货: '去补货',
	编辑国际物流附加项: '编辑国际物流附加项',
	// 首页头部
	D2C无仓库代发: 'D2C无仓库代发',
	B2B仓库囤货: 'B2B仓库囤货',
	采购平台: '采购平台',
	类目: '类目',
	中国语入力: '中国语入力',
	日本语入力: '日本语入力',
	详细检索: '详细检索',
	// 搜索
	直行便: '直行便',
	TaoBao: 'TaoBao',
	Tmall: 'Tmall',
	搜款网: '搜款网',
	网商园: '网商园',
	全部类目: '全部类目',
	搜索范围: '搜索范围',
	搜索关键词: '搜索关键词',
	重置条件: '重置条件',
	搜索历史: '搜索历史',
	热门推荐词: '热门推荐词',
	// 搜索列表
	采购渠道: '采购渠道',
	采购市场: '采购市场',
	关联我的商品: '关联我的商品',
	商品来源: '商品来源',
	加入收藏夹: '加入收藏夹',
	商品金额: '商品金额',
	已选清单: '已选清单',
	手续费总额: '手续费总额',
	国际运费: '国际运费',
	附加项费用: '附加项费用',
	单价预估成本: '单价预估成本',
	请选择附加项: '请选择附加项',
	单品预估成本价: '单品预估成本价',
	相似商品: '相似商品',
	相似店铺: '相似店铺',
	下载商品图: '下载商品图',
	X件可售: 'X件可售',
	商品添加至购物车: '商品添加至购物车',
	商品图下载成功: '商品图下载成功',
	'部分图片读取失败，请按需手动下载，正在开启自动下载':
		'部分图片读取失败，请按需手动下载，正在开启自动下载',
	// 子账号店铺
	创建子账号: '创建子账号',
	开店平台: '开店平台',
	店铺品牌名: '店铺/品牌名',
	店铺密钥: '店铺密钥',
	创建并授权店铺数据: '创建并授权店铺数据',
	请输入店铺所在的平台名称: '请输入店铺所在的平台名称（选填）',
	如需授权店铺API请阅读并同意: '如需授权店铺API请阅读并同意',
	利用规约: '利用规约',
	店铺地址不正确: '店铺地址不正确',
	创建店铺成功: '创建店铺成功',
	修改店铺成功: '修改店铺成功',
	店铺授权成功: '店铺授权成功',
	修改店铺信息: '修改店铺信息',
	授权状态: '授权状态',
	保存店铺: '保存店铺',
	未授权: '未授权',
	已授权: '已授权',
	去授权: '去授权',
	应用密钥: '应用密钥',
	应用ID: '应用ID',
	// 类目
	站外搜索: '站外搜索',
	// 市场购
	中国档口七日爆款: '中国档口七日爆款',
	推荐档口: '推荐档口',
	进入店铺: '进入店铺',
	// 我的频道
	我收藏的店铺: '我收藏的店铺',
	我看过的店铺: '我看过的店铺',
	我买过的店铺: '我买过的店铺',
	'担当推荐店铺（含档口）': '担当推荐店铺（含档口）',
	'推荐店铺（含档口）': '推荐店铺（含档口）',
	我看过的商品: '我看过的商品',
	我买过的商品: '我买过的商品',
	我收藏的商品: '我收藏的商品',
	担当推荐商品: '担当推荐商品',
	猜你喜欢的商品: '猜你喜欢的商品',
	// 平台频道
	平台频道: '平台频道',
	去购买: '去购买',
	去选品: '去选品',
	去看看: '去看看',
	主题市场: '主题市场',
	猜你喜欢的店铺: '猜你喜欢的店铺',
	品类市场: '品类市场',
	市场: '市场',

	// 青蛙频道
	更多最新文章: '更多最新文章',
	采购相关: '采购相关',
	物流相关: '物流相关',
	优惠相关: '优惠相关',
	OME相关: 'OME相关',
	他社合作相关: '他社合作相关',
	热门文章: '热门文章',
	档案: '档案',
	// 文章
	上一篇: '上一篇',
	下一篇: '下一篇',
	// 购物车
	选择店铺: '选择店铺',
	请选择店铺: '请选择店铺',
	检索平台: '检索平台',
	价格浮动百分比: '价格浮动百分比',
	'原有的商品缺货时，我们会帮你查找新的采购链接，所以请告诉我您能接受的采购价格区间变动':
		'原有的商品缺货时，我们会帮你查找新的采购链接，所以请告诉我您能接受的采购价格区间变动',
	创建组合商品: '创建组合商品',
	确认并支付: '确认并支付',
	收件人: '收件人',
	市区町村: '市区町村',
	区町村: '区町村',
	自定义订单编号: '自定义订单编号',
	购买店铺: '购买店铺',
	购买类型: '购买类型',
	收件人不能为空: '收件人不能为空',
	联系电话不能为空: '联系电话不能为空',
	都道府县不能为空: '都道府县不能为空',
	详细地址不能为空: '详细地址不能为空',
	邮编不能为空: '邮编不能为空',
	当前余额: '当前余额',
	'提示：此金额为预估报价，您下单后，该部分金额将会被冻结。等到该订单采购处理全部完成后，我们会自动解冻这部分金额，并按照实际产生的金额进行扣费。实际扣费详情您可以后续在资金明细页面或订单详情页面查看。':
		'提示：此金额为预估报价，您下单后，该部分金额将会被冻结。等到该订单采购处理全部完成后，我们会自动解冻这部分金额，并按照实际产生的金额进行扣费。实际扣费详情您可以后续在资金明细页面或订单详情页面查看。',
	// 组合
	组合商品名称不能为空: '组合商品名称不能为空',
	组合商品名称: '组合商品名称',
	组合商品规格: '组合商品规格',
	商品图片: '商品图片',
	商品规格: '商品规格',
	单组数量: '单组数量',
	保存并加入购物车: '保存并加入购物车',
	组合商品附加项: '组合商品附加项',
	选择附加项: '选择附加项',
	组合详情: '组合详情',
	组合图片: '组合图片',
	// 通用
	返回: '返回',
	其他: '其他',
	平台: '平台',
	变更: '变更',
	商品名称: '商品名称',
	规格: '规格',
	在库数量: '在库数量',
	'可用库存/采购数量': '可用库存/采购数量',
	下单数量: '下单数量',
	起订量: '起订量',
	起订量件: '起订量(件)',
	单价: '单价',
	单价元: '单价(元)',
	总价元: '总价(元)',
	版费元: '版费(元)',
	小计: '小计',
	商品附加项费用: '商品附加项费用',
	操作: '操作',
	编辑: '编辑',
	修改: '修改',
	合计: '合计',
	合计2: '合计',
	支付: '支付',
	工作台: '工作台',
	意见反馈: '意见反馈',
	提示: '提示',
	提示2: '提示',
	提示3: '提示',
	创建: '创建',
	确定: '确定',
	确认: '确认',
	保存: '保存',
	同意: '同意',
	取消: '取消',
	撤回: '撤回',
	元: '元',
	円: '円',
	件: '件',
	价格区间: '价格区间',
	风格: '风格',
	颜色: '颜色',
	尺码: '尺码',
	其他条件: '其他条件',
	综合: '综合',
	价格: '价格',
	成交额: '成交额',
	信用: '信用',
	销量: '销量',
	数量: '数量',
	排序: '排序',
	自定义: '自定义',
	去结算: '去结算',
	默认: '默认',
	'上传中...': '上传中...',
	'正在发送请求，请稍侯': '正在发送请求，请稍侯',
	离开: '离开',
	注意: '注意',
	无: '無',
	暂无: '暂无',
	选填: '选填',
	批量删除: '批量删除',
	公司名: '公司名',
	清关地址: '清关地址',
	设为默认: '设为默认',
	姓名En: '姓名(英文)',
	都道府县En: '都道府县(英文)',
	市区町村En: '市区町村(英文)',
	详细地址En: '详细地址(英文)',
	添加商品: '添加商品',
	购物车商品: '购物车商品',
	我的商品: '我的商品',
	图片: '图片',
	商品类型: '商品类型',
	至少选一个: '至少选一个',
	'商品名称/SKU': '商品名称/SKU',
	加入组合: '加入组合',
	普通商品: '普通商品',
	OEM商品: 'OEM商品',
	组合商品: '组合商品',
	购物车为空: '购物车为空',
	上传图片: '上传图片',
	// 长文字说明
	'仅支持已授权店铺上传商品，该店铺未授权':
		'仅支持已授权店铺上传商品，该店铺未授权',
	'当您采购的商品价格出现变化时，或不能采购时我们将为您寻找其他相同的商品，所以请告诉我您能接受的价格变动幅度。':
		'当您采购的商品价格出现变化时，或不能采购时我们将为您寻找其他相同的商品，所以请告诉我您能接受的价格变动幅度。',
	'当商品出现问题时，我们将为您免费重新换货X次。超过X次每次Y元，最多不超过Z次。当不勾选时，每次出现次品时，我们都会询问您的意见，在进行处理，会影响您的购买效率。':
		'当商品出现问题时，我们将为您免费重新换货X次。超过X次每次Y元，最多不超过Z次。当不勾选时，每次出现次品时，我们都会询问您的意见，在进行处理，会影响您的购买效率。',
	// 占行
	// 占行
	// 占行
	// OEM问题
	创建寻源单: '创建寻源单',
	寻源单列表: '寻源单列表',
	复购申请列表: '复购申请列表',
	待接单: '待接单',
	待报价: '待报价',
	待商榷: '待商榷',
	寻源失败: '寻源失败',
	发布时间: '发布时间',
	起始日: '开始日',
	结束日: '终了日',
	寻源单详情: '寻源单详情',
	订单状态: '订单状态',
	下一步: '下一步',
	拒绝: '拒绝',
	拒绝2: '拒绝',
	确定下样品单: '确定下样品单',
	确定下大货单: '确定下大货单',
	评价: '评价',
	有: '有',
	没有: '没有',
	取消寻源单: '取消寻源单',
	确认下单: '确认下单',
	确认重新编辑: '确认重新编辑',
	确认申请退款: '确认申请退款',
	确认申请复购: '确认申请复购',
	'发布此寻源单需要收取XXX元服务费，请问是否确定发布':
		'发布此寻源单需要收取XXX元服务费，请问是否确定发布',
	'发布此寻源单无需收费，请问是否确定发布':
		'发布此寻源单无需收费，请问是否确定发布',
	示例: '示例',
	以上没有我需要的: '以上没有我需要的',
	'您想要一个什么类型的商品？': '您想要一个什么类型的商品？',
	'请问你要做什么辅料？': '请问你要做什么辅料？',
	请填写您需要的辅料: '请填写您需要的辅料',
	文件上传: '文件上传',
	'请问你有自己的方案吗？': '请问你有自己的方案吗？',
	'我有自己的想法，请直行便根据我的要求帮我生产':
		'我有自己的想法，请直行便根据我的要求帮我生产',
	'我没有自己的想法，希望直行便帮我推荐方案':
		'我没有自己的想法，希望直行便帮我推荐方案',
	'我有自己的想法，但是我也想看一下直行便的推荐方案':
		'我有自己的想法，但是我也想看一下直行便的推荐方案',
	请详细表述并上传您需要定制的辅料设计图或参考图片:
		'请详细表述并上传您需要定制的辅料设计图或参考图片',
	请详细描述您需要定制的款式信息: '请详细描述您需要定制的款式信息',
	请详细描述您需要定制的材质信息: '请详细描述您需要定制的材质信息',
	请详细描述您需要定制的尺寸信息: '请详细描述您需要定制的尺寸信息',
	请查看我们的款式推荐方案: '请查看我们的款式推荐方案',
	请查看我们的材质推荐方案: '请查看我们的材质推荐方案',
	请查看我们的尺寸推荐方案: '请查看我们的尺寸推荐方案',
	请查看我们的颜色推荐方案: '请查看我们的颜色推荐方案',
	请选择Logo印刷方案: '请选择Logo印刷方案',
	价格和起订量: '价格和起订量',
	总价: '总价',
	版费: '版费',
	选择色卡: '选择色卡',
	搜索色号: '搜索色号',
	色号或色卡名称: '色号或色卡名称',
	请至少选择一种色卡: '请至少选择一种色卡',
	已选色卡: '已选色卡',
	//
	'请问你对本次杂货OEM的初步需求是怎样的？':
		'请问你对本次杂货OEM的初步需求是怎样的？',
	'根据图片或样品，帮我生产出一模一样的产品。（包含加Logo）':
		'根据图片或样品，帮我生产出一模一样的产品。（包含加Logo）',
	'在既成品的基础上，我有一些改良的需求 。（包括颜色、材质、 功能、尺寸、设计等的改良）':
		'在既成品的基础上，我有一些改良的需求 。（包括颜色、材质、 功能、尺寸、设计等的改良）',
	'既无参考图片也无式样书的情况，无法发起寻源，请联系对应担当提供制作式样书服务':
		'既无参考图片也无式样书的情况，无法发起寻源，请联系对应担当提供制作式样书服务',
	'请问您是否有样品可邮寄？': '请问您是否有样品可邮寄？',
	'请问您需要附加Logo吗？': '请问您需要附加Logo吗？',
	请完善Logo信息: '请完善Logo信息',
	'请对logo颜色、尺寸、工艺、位置做说明':
		'请对logo颜色、尺寸、工艺、位置做说明',
	'请问您对本次寻源的工厂或产品资质有特殊要求吗？':
		'请问您对本次寻源的工厂或产品资质有特殊要求吗？',
	资质要求: '资质要求',
	请上传您的参考链接或图片: '请上传您的参考链接或图片',
	'请问您是否有式样书？': '请问您是否有式样书？',
	'我需要定制全新的产品，目前市场上没有既成品可参考。':
		'我需要定制全新的产品，目前市场上没有既成品可参考。',
	请上传式样书: '请上传式样书',
	'式样书必须包含产品的色卡、尺寸、特殊工艺、面料信息等':
		'式样书必须包含产品的色卡、尺寸、特殊工艺、面料信息等',
	使用直行便帮助您制作式样书: '使用直行便帮助您制作式样书',
	'由于信息不足，直行便暂时无法为您提供寻源服务':
		'由于信息不足，直行便暂时无法为您提供寻源服务',
	请详细说明您需要改动的地方: '请详细说明您需要改动的地方',
	'如果需要改动的点已经在式样书中完全展现，可以略过，否则可做补充说明':
		'如果需要改动的点已经在式样书中完全展现，可以略过，否则可做补充说明',
	补充说明: '补充说明',
	上传文件: '上传文件',
	请完善信息: '请完善信息',
	请问您是否可以提供样衣邮寄: '请问您是否可以提供样衣邮寄',
	我有样衣: '我有样衣',
	我没有样衣: '我没有样衣',
	直行便地址: '直行便地址',
	请填写物流单号: '请填写物流单号',
	//
	'请问你对本次服装OEM的初步需求是怎样的？':
		'请问你对本次服装oem的初步需求是怎样的？',
	'只需要生产出和图片或链接看起来一样的产品，对面料没有任何要求，且不需要有任何设计上的改动。':
		'只需要生产出和图片或链接看起来一样的产品，对面料没有任何要求，且不需要有任何设计上的改动。',
	'风险告知：A的情况，此选项不寄实物样品给客户，只通过样衣图片在线上进行确认。此选项优点为出样快，缺点为直行便只保证成衣外观和客户提供的图片接近一致无法保证和原链接衣服材质保持一致。请知悉！':
		'风险告知：A的情况，此选项不寄实物样品给客户，只通过样衣图片在线上进行确认。此选项优点为出样快，缺点为直行便只保证成衣外观和客户提供的图片接近一致无法保证和原链接衣服材质保持一致。请知悉！',
	'我有样衣，希望直行便帮我生产出和样衣一模一样的衣服。':
		'我有样衣，希望直行便帮我生产出和样衣一模一样的衣服。',
	'我有样衣，但我对设计/面料/尺寸等，有希望改动的地方。':
		'我有样衣，但我对设计/面料/尺寸等，有希望改动的地方。',
	'我只有参考图片或链接，且我有需要改动的地方或我对面料有拘泥。':
		'我只有参考图片或链接，且我有需要改动的地方或我对面料有拘泥。',
	'此种情况您需要尽可能提供一件样衣到直行便作为参考，或者您需要提供详细的式样书，如果您没有式样书，可以联系直行便使用式样书制作服务':
		'此种情况您需要尽可能提供一件样衣到直行便作为参考，或者您需要提供详细的式样书，如果您没有式样书，可以联系直行便使用式样书制作服务',
	'我没有样衣或参考图片/链接，但我有式样书，希望直行便根据式样书帮我进行生产。':
		'我没有样衣或参考图片/链接，但我有式样书，希望直行便根据式样书帮我进行生产。',
	'既无参考图片也无式样书的情况，无法发起寻源，请联系对应担当提供制作式样书服务；式样书必须包含产品的色卡、尺寸、特殊工艺、面料信息等':
		'既无参考图片也无式样书的情况，无法发起寻源，请联系对应担当提供制作式样书服务；式样书必须包含产品的色卡、尺寸、特殊工艺、面料信息等',
	请填写你想定制产品的基本信息: '请填写你想定制产品的基本信息',
	请填写产品名称: '请填写产品名称',
	请至少填写一条完整的产品信息: '请至少填写一条完整的产品信息',
	产品信息说明:
		'商品のサイズ分類を記載してください。サイズが複数ある場合は、行追加しサイズを入力してください。例 M,L,XL,30cm＊40cm＊20cm　など',
	'不知道，但是我可以邮寄面料样布，希望直行便帮我找到和样布一样的面料':
		'不知道，但是我可以邮寄面料样布，希望直行便帮我找到和样布一样的面料',
	'请问你是否知道面料具体信息？': '请问你是否知道面料具体信息？',
	'知道，我可以提供面料信息和具体的色卡信息':
		'知道，我可以提供面料信息和具体的色卡信息',
	'不知道，希望直行便帮我找到和样衣或参考图片一样的面料':
		'不知道，希望直行便帮我找到和样衣或参考图片一样的面料',
	'不知道，希望直行便可以根据我的需求，给我推荐合适的面料':
		'不知道，希望直行便可以根据我的需求，给我推荐合适的面料',
	请提供具体的色卡信息: '请提供具体的色卡信息',
	请描述一下您对面料的需求: '请描述一下您对面料的需求',
	'请大致描述一下希望布料的外观或功能上的需求，如:弹性好，柔软等':
		'请大致描述一下希望布料的外观或功能上的需求，如:弹性好，柔软等',
	上传面料图片: '上传面料图片',
	'请大致描述一下希望改动哪些方面,如:材质、设计、颜色、尺寸等':
		'请大致描述一下希望改动哪些方面,如:材质、设计、颜色、尺寸等',
	'请问你能够提供尺码的具体尺寸吗？': '请问你能够提供尺码的具体尺寸吗？',
	'我有尺码对应的尺寸数值。': '我有尺码对应的尺寸数值。',
	'根据服装生产标准，尺寸相差 1-3cm 都属于正常现象，不作为不良品判断，请知悉':
		'根据服装生产标准，尺寸相差 1-3cm 都属于正常现象，不作为不良品判断，请知悉',
	'我没有数值，请参考链接尺寸。': '我没有数值，请参考链接尺寸。',
	'我没有数值，请直行便提供。': '我没有数值，请直行便提供。',
	请提供尺码的具体尺寸: '请提供尺码的具体尺寸',
	尺码模板: '尺码模板',
	模板下载: '模板下载',
	上传模板: '上传模板',
	'请先下载对应模板，填写完成后上传': '请先下载对应模板，填写完成后上传',
	请输入产品的下单需求: '请输入产品的下单需求',
	'直行便的报价与希望起订量和价格是否匹配，是我们判定是否为您寻源成功的判断依据。请您客观的填写。如果您的希望和市场行情差异过大，我们会之后先联系您，提出修改建议，再开始为您寻源报价。':
		'直行便的报价与希望起订量和价格是否匹配，是我们判定是否为您寻源成功的判断依据。请您客观的填写。如果您的希望和市场行情差异过大，我们会之后先联系您，提出修改建议，再开始为您寻源报价。',
	寻源备注: '寻源备注',
	'如果您对本次寻源还有其他特殊需求，请在以下输入您的需求':
		'如果您对本次寻源还有其他特殊需求，请在以下输入您的需求',
	已成功创建寻源单: '已成功创建寻源单',
	创建寻源单说明:
		'まだ、対応開始されていません。管理画面で内容確認の上、依頼提出をお願いします',
	'式样书(例)': '式样书(例)',
	棉100: '綿100%',
	可多选: '可多选',
	尺码信息: '尺码信息',
	是否需要加logo: '是否需要加logo',
	期望交期时间: '期望交期时间',
	期望最低起订量: '期望最低起订量',
	客户补充部分: '客户补充部分',
	附加说明: '附加说明',
	已选: '已选',
	未选: '未选',
	您可以点击此处: '您可以点击此处',
	产品图片: '产品图片',
	产品规格: '产品规格',
	备注: '备注',
	附加费选项: '附加费选项',
	品牌: '品牌',
	总计数量: '总计数量',
	'总计(折扣前RMB)': '总计(折扣前RMB)',
	'总计(折扣后RMB)': '总计(折扣后RMB)',
	估计金额: '估计金额',
	购物车数量不能小于X: '购物车数量不能小于X',
	普通订单: '普通订单',
	组合商品订单: '组合商品订单',
	OEM订单: 'OEM订单',
	//
	报价已过期: '报价已过期',
	复购申请编号: '复购申请编号',
	申请状态: '申请状态',
	//
	请填写: '请填写',
	请上传: '请上传',
	请至少选择一种色卡或上传图片: '请至少选择一种色卡或上传图片',
	链接和图片必须填一个: '链接和图片必须填一个',
	请提供面料材质和色卡信息: '请提供面料材质和色卡信息',
	请填写具体的面料材质信息: '请填写具体的面料材质信息',
	'我没有数值，请参考样衣尺寸。': '我没有数值，请参考样衣尺寸。',
	'以上为参考报价，具体报价需要以最终报价为准 ':
		'以上为参考报价，具体报价需要以最终报价为准',
	该订单不支持修改属性: '该订单不支持修改属性',
	请至少选择或上传一种色卡: '请至少选择或上传一种色卡',
	请填写款式说明: '请填写款式说明',
	请填写材质详细说明: '请填写材质详细说明',
	请填写尺寸详细说明: '请填写尺寸详细说明',
	请上传Logo: '请上传Logo',
	请上传尺码表: '请上传尺码表',
	请填写期望价格: '请填写期望价格',
	请填写期望最低起订量: '请填写期望最低起订量',
	请选择期望交期时间: '请选择期望交期时间',
	重新创建寻源单: '重新创建寻源单',
	查看寻源单列表: '查看寻源单列表',
	请输入您想要的详细信息: '请输入您想要的详细信息',
	直行便的款式推荐方案: '直行便的款式推荐方案',
	直行便的材质推荐方案: '直行便的材质推荐方案',
	直行便的尺寸推荐方案: '直行便的尺寸推荐方案',
	印刷方案: '印刷方案',
	请输入您的订单信息: '请输入您的订单信息',
	规格及起订量: '规格及起订量',
	'棉100%': '棉100%',
	'上次寻源未完成，是否继续': '上次寻源未完成，是否继续',
	/**
	 *
	 * 没有可优先发货的商品1
	 *
	 *
	 *
	 *
	 *
	 *
	 *
	 *
	 *
	 *
	 *
	 *
	 */
	重新寻源: '重新寻源',
	店铺不存在: '店铺不存在',
	暂无平台: '暂无平台',
	输入FAQs关键词: '输入FAQs关键词',
	收藏商品: '收藏商品/特辑',
	收藏店铺: '收藏店铺',
	多为我推荐此类型商品: '多为我推荐此类型商品',
	多为我推荐此类型店铺: '多为我推荐此类型店铺',
	不再推荐此商品: '不再推荐此商品',
	不再推荐此店铺: '不再推荐此店铺',
	商品收藏成功: '商品收藏成功',
	店铺收藏成功: '店铺收藏成功',
	'感谢反馈，我们将为你推荐更加准确的店铺':
		'感谢反馈，我们将为你推荐更加准确的店铺',
	注销账号: '注销账号',
	'您的账号中存在以下未处理完结事件，不能注销。':
		'您的账号中存在以下未处理完结事件，不能注销。',
	账户余额X元: '账户余额X元',
	提现申请: '提现申请',
	X笔采购订单: 'X笔采购订单',
	X条充值任务未完成: 'X条充值任务未完成',
	批量编辑附加项: '批量编辑附加项',
	工厂直营: '工厂直营',
	组合商品不能再组合: '组合商品不能再组合',
	'订单已生成，请随时关注订单动态': '订单已生成，请随时关注订单动态',
	'5s后跳转订单详情': '5s后跳转订单详情',
	'系统检查到，您未安装采购扩展程序，现在安装采购更方便直接在1688，天猫，淘宝官网THE直达商品可以添加到采购系统购物车中。现在马上安装':
		'系统检查到，您未安装采购扩展程序，现在安装采购更方便直接在1688，天猫，淘宝官网THE直达商品可以添加到采购系统购物车中。现在马上安装',
	包邮: '包邮',
	赠送退货运费险: '赠送退货运费险',
	新品: '新品',
	正品保障: '正品保障',
	公益宝贝: '公益宝贝',
	'7+天内退货': '7+天内退货',
	折扣: '折扣',
	搭配满减: '搭配满减',
	满就减: '满就减',
	货到付款: '货到付款',
	'48小时内发货': '48小时内发货',
	实力商家: '实力商家',
	超级工厂: '超级工厂',
	加工定制: '加工定制',
	人气: '人气',
	请填写正确的访问令牌: '请填写正确的访问令牌',
	组合商品规格不能为空: '组合商品规格不能为空',
	选择一张图片作为组合商品主图: '选择一张图片作为组合商品主图',
	查看组合列表: '查看组合列表',
	当前购物车共X种商品: '当前购物车共X种商品',
	X件商品已添加至购物车: 'X件商品已添加至购物车',
	'库存过低不能下单，请调整发货量或及时补充库存':
		'库存过低不能下单，请调整发货量或及时补充库存',
	同步商品: '同步商品',
	fnsku: 'fnsku',
	asin: 'asin',
	'请输入Amazon sku': '请输入Amazon sku',
	'请搜索需要绑定的Amazon sku': '请搜索需要绑定的Amazon sku',
	附加项未配置箱唛: '附加项未配置箱唛',
	附加项未配置贴标签: '附加项未配置贴标签',
	'海外仓sku(fnsku)': '海外仓sku(fnsku)',
	补货数量: '补货数量',
	未提出: '未提出',
	已提出: '已提出',
	已作废: '已作废',
	'FNSKU/ASIN': 'FNSKU/ASIN',
	'商品名称/Amazon sku': '商品名称/Amazon sku',
	'品名/平台sku': '品名/平台sku',
	计划发货量: '计划发货量',
	是否确认提出: '是否确认提出',
	是否确认取消: '是否确认取消',
	作废成功: '作废成功',
	提交成功: '提交成功',
	国际发货单标题: '国际发货单标题',
	发货状态: '发货状态',
	发货类型: '发货类型',
	批量加购: '批量加购',
	'点击完结 单品可以转普通商品库存': '点击完结 单品可以转普通商品库存',
	'存在待组商品 请尽快加购组合。': '存在待组商品 请尽快加购组合。',
	物流信息: '物流信息',
	国际单号回传后不可修改: '国际单号回传后不可修改',
	收件人邮箱: '收件人邮箱',
	选择订单时间: '选择订单时间',
	订单付款日期区间: '订单付款日期区间',
	未下单: '未下单',
	已下单: '已下单',
	店铺订单号: '店铺订单号',
	订单时间: '订单时间',
	采购状态: '采购状态',
	订单金额: '订单金额',
	收货信息: '收货信息',
	商品图: '商品图',
	'商品名/sku': '商品名/sku',
	采购链接sku: '采购链接sku',
	同步订单数据: '同步订单数据',
	一键下单: '一键下单',
	'编辑物流/地址': '编辑物流/地址',
	是否删除: '是否删除',
	发货附加项编辑成功: '发货附加项编辑成功',
	留在此页: '留在此页',
	开始时间: '开始时间',
	质检中: '质检中',
	待发货: '待发货',
	'当前申请取消的商品，存在多个商品状态，确认取消后：':
		'当前申请取消的商品，存在多个商品状态，确认取消后：',
	'“X”状态商品，申请取消成功后，系统将自动取消商品的采购与发货，冻结金额在订单结算后返回至可用余额内。':
		'“X”状态商品，申请取消成功后，系统将自动取消商品的采购与发货，冻结金额在订单结算后返回至可用余额内。',
	'“X”状态，申请取消成功后，系统将自动取消商品的发货行为，并将商品保留库存。':
		'“X”状态，申请取消成功后，系统将自动取消商品的发货行为，并将商品保留库存。',
	'请确认是否申请取消？': '请确认是否申请取消？',
	'“X”状态商品，系统将自动取消商品的采购，冻结金额在订单结算后返回至可用余额内；':
		'“X”状态商品，系统将自动取消商品的采购，冻结金额在订单结算后返回至可用余额内；',
	'“X”状态商品，不可申请取消（系统将自动忽略）':
		'“X”状态商品，不可申请取消（系统将自动忽略）',
	'当前申请取消的商品，商品状态均为“X”状态,':
		'当前申请取消的商品，商品状态均为“X”状态,',
	'申请取消成功后，系统将自动取消商品的采购与发货，冻结金额在订单结算后返回至可用余额内。':
		'申请取消成功后，系统将自动取消商品的采购与发货，冻结金额在订单结算后返回至可用余额内。',
	'当前申请取消的商品，商品状态均为“X”状态，不可申请取消！':
		'当前申请取消的商品，商品状态均为“X”状态，不可申请取消！',
	'当前申请取消的商品，商品状态均为“X”状态，申请取消成功后，系统将自动取消商品的采购，冻结金额在订单结算后返回至可用余额内':
		'当前申请取消的商品，商品状态均为“X”状态，申请取消成功后，系统将自动取消商品的采购，冻结金额在订单结算后返回至可用余额内',
	再次下单: '再次下单',
	组合信息: '组合信息',
	结算中心查看明细: '结算中心查看明细',
	费用名称: '费用名称',
	'结算总价（元）': '结算总价（元）',
	问题联络: '问题联络',
	冻结: '冻结',
	解冻: '解冻',
	结算: '结算',
	重拍冻结: '重拍冻结',
	销售单价: '销售单价',
	结算数量: '结算数量',
	结算总价: '结算总价',
	点击上传凭证: '点击上传凭证',
	商品代金结算: '商品代金结算',
	国内运费: '国内运费',
	国际物流费用: '国际物流费用',
	国际运费重量差补扣: '国际运费重量差补扣',
	国际运费附加项补扣: '国际运费附加项补扣',
	销毁费补扣: '销毁费补扣',
	偏远地区费补扣: '偏远地区费补扣',
	仓储费补扣: '仓储费补扣',
	超长超重费补扣: '超长超重费补扣',
	关税补扣: '关税补扣',
	改派费补扣: '改派费补扣',
	特殊处理费补扣: '特殊处理费补扣',
	扣件费补扣: '扣件费补扣',
	退件费补扣: '退件费补扣',
	冻结总价: '冻结总价',
	解冻总价: '解冻总价',
	重拍冻结商品代金: '重拍冻结商品代金',
	冻结商品代金: '冻结商品代金',
	解冻商品代金: '解冻商品代金',
	冻结总数量: '冻结总数量',
	解冻总数量: '解冻总数量',
	手续费: '手续费',
	次品重买总次数: '次品重买总次数',
	纸箱总数量: '纸箱总数量',
	装箱总数量: '装箱总数量',
	气泡膜总数量: '气泡膜总数量',
	托盘总数量: '托盘总数量',
	箱子总数量: '箱子总数量',
	结算总数量: '结算总数量',
	我的默认附加项: '我的默认附加项',
	我的类目附加项: '我的类目附加项',
	商品总金额: '商品总金额',
	发货单: '发货单',
	箱: '箱',
	钱包: '钱包',
	记录: '记录',
	没有可优先发货的商品: '没有可优先发货的商品',
	是否确认优先发货: '是否确认优先发货',
	优先发货成功: '优先发货成功',
	没有可申请取消的商品: '没有可申请取消的商品',
	是否确认删除: '是否确认删除',
	搜索商品: '搜索商品',
	'您将要删除X条商品数据，删除后将无法恢复':
		'您将要删除X条商品数据，删除后将无法恢复',
	请输入商品名: '请输入商品名',
	选择收货地址: '选择收货地址',
	国际发货标题: '国际发货标题',
	是否确定加购大货: '是否确定加购大货',
	加购大货: '加购大货',
	确定加购样品: '确定加购样品',
	加购样品: '加购样品',
	库存还有X件商品或辅料: '库存还有X件商品或辅料',
	去处理: '去处理',
	'账号注销后我们将不再保存您的任何账号信息，是否注销？':
		'账号注销后我们将不再保存您的任何账号信息，是否注销？',
	直行台: '直行台',
	账户概览: '账户概览',
	同步订单: '同步订单',
	关联商品库: '关联商品库',
	我的组合商品: '我的组合商品',
	国际发货: '国际发货',
	国际发货列表: '国际发货列表',
	国际发货详情: '国际发货详情',
	补货计划: '补货计划',
	资金管理2: '资金管理',
	授权管理: '授权管理',
	再购入: '再购入',
	下单: '下单',
	同步商品信息: '同步商品信息',
	库存数: '库存数',
	可售: '可售',
	可用库存: '可用库存',
	已锁定: '已锁定',
	待处理次品数: '待处理次品数',
	导出成功: '导出成功',
	直行便发货单号: '直行便发货单号',
	复购: '复购',
	筛选条件: '筛选条件',
	'客户商品sku/海外仓sku': '客户商品sku/海外仓sku',
	'平台客户sku/商品名称': '平台客户sku/商品名称',
	更新: '更新',
	平台商品信息: '平台商品信息',
	'AMAZON库存(可售)': 'AMAZON库存(可售)',
	'AMAZON库存(入库)': 'AMAZON库存(入库)',
	'AMAZON库存(不可售)': 'AMAZON库存(不可售)',
	预计总库存: '预计总库存',
	绑定: '绑定',
	解绑: '解绑',
	确认签收: '确认签收',
	'是否确认签收？': '是否确认签收？',
	'是否确认取消国际发货？ 仓库已在作业中的发货单无法取消。':
		'是否确认取消国际发货？ 仓库已在作业中的发货单无法取消。',
	'小计（元）': '小计（元）',
	实际发货数量: '实际发货数量',
	提出: '提出',
	已确认: '已确认',
	尺寸: '尺寸',
	/**
	 *
	 * 补充翻译3
	 */
	寻源单号: '寻源单号',
	大货单: '大货单',
	预计可发货数: '预计可发货数',
	查看订单详情: '查看订单详情',
	购买数量不可为空: '购买数量不可为空',
	购买数量不可低于起订量: '购买数量不可低于起订量',
	子商品总数不能少于2件: '子商品总数不能少于2件',
	'存在不足起订量的商品，请修改数量': '存在不足起订量的商品，请修改数量',
	需要填写收货地址: '需要填写收货地址',
	添加收货地址: '添加收货地址',
	payoneer充值记录: 'payoneer充值记录',
	我知道了: '我知道了',
	'存入金额（USD）': '存入金额（USD）',
	待初审: '待初审',
	待终审: '待终审',
	已通过: '已通过',
	上传成功: '上传成功',
	参考单价: '参考单价',
	冻结数量: '冻结数量',
	扣费日: '扣费日',
	上次扣费日: '上次扣费日',
	上架日: '上架日',
	免费保管天数: '免费保管天数',
	计费天数: '计费天数',
	/**
	 *
	 * 补充翻译4
	 */
	普通发货: '普通发货',
	FBA发货: 'FBA发货',
	收货仓: '收货仓',
	'如果您当前存在部分订单未完成的话，您账户可用余额中我们将会自动为您留下当下账户冻结金额*1的金额，来确保账户有足够的金额来支付国际运费后顺利发货。所以您目前可转账的金额为：账户可用余额-冻结金额':
		'如果您当前存在部分订单未完成的话，您账户可用余额中我们将会自动为您留下当下账户冻结金额*1的金额，来确保账户有足够的金额来支付国际运费后顺利发货。所以您目前可转账的金额为：账户可用余额-冻结金额',
	本公司接受银行: '本公司接受银行',
	汇款人姓名: '汇款人姓名',
	汇款时间: '汇款时间',
	汇款凭证: '汇款凭证',
	点击上传: '点击上传',
	良品入库: '良品入库',
	付费重拍: '付费重拍',
	本次重拍费用X円: '本次重拍费用X円',
	'接受次品/缺货自动付费重拍（接受自动重拍，有助于我们更快的为您完成订单采购）':
		'接受次品/缺货自动付费重拍（接受自动重拍，有助于我们更快的为您完成订单采购）',
	更换尺码: '更换尺码',
	更换款式: '更换款式',
	'请输入链接(必填)': '请输入链接(必填)',
	同款链接: '同款链接',
	点击可查看: '点击可查看',
	类似款链接: '类似款链接',
	等待原链接: '等待原链接',
	下单原链接: '下单原链接',
	'下单原链接（多出数量保留库存）': '下单原链接（多出数量保留库存）',
	申请成功: '申请成功',
	请确认尺码: '请确认尺码',
	请确认颜色: '请确认颜色',
	'请确认设计（款式）': '请确认设计（款式）',
	'全网断货，建议取消': '全网断货，建议取消',
	// '原URL缺货，新的URL内商品是X元，请确认':
	//   '原URL缺货，新的URL内商品是X元，请确认',
	'原商品缺货，找到了类似商品，请确认': '原商品缺货，找到了类似商品，请确认',
	'预售商品。预计下单后X天后能发货，要等吗？':
		'预售商品。预计下单后X天后能发货，要等吗？',
	'找到新的现货链接，是否可以购买？': '找到新的现货链接，是否可以购买？',
	'此商品的最小起订量是X件。请确认是否购买。':
		'此商品的最小起订量是X件。请确认是否购买。',
	'找到符合下单量的新链接，是否可以购买。':
		'找到符合下单量的新链接，是否可以购买。',
	'原商品质量不佳，推荐在新链接采购。': '原商品质量不佳，推荐在新链接采购。',
	到错货: '到错货',
	少件: '少件',
	多件: '多件',
	快递扫不出: '快递扫不出',
	// 颜色差异: '颜色差异',
	// 商品有中文: '商品有中文',
	'配件差异（口袋，腰带，纽扣，拉链，拉绳等）':
		'配件差异（口袋，腰带，纽扣，拉链，拉绳等）',
	// logo差异: 'logo差异',
	// 尺码差异: '尺码差异',
	'设计差异（压线，领口，袖口，花纹，衣服设计等）':
		'设计差异（压线，领口，袖口，花纹，衣服设计等）',
	'材质/面料差异': '材质/面料差异',
	色差: '色差',
	// '污渍/斑点/水印': '污渍/斑点/水印',
	// 破损: '破损',
	// 线头: '线头',
	// 接缝不良: '接缝不良',
	// 抽丝: '抽丝',
	// 针眼: '针眼',
	// 霉菌: '霉菌',
	// 生锈: '生锈',
	// 纽扣破损: '纽扣破损',
	// 拉链破损: '拉链破损',
	// 附属品缺陷: '附属品缺陷',
	// 有气味: '有气味',
	// 其他不良: '其他不良',
	直行便档口: '直行便档口',
	'我们的 OEM 仅适用于商务舱和头等舱成员。如果您想使用OEM，请从[会员信息]更改会员等级。如果您有任何问题，请联系我们的在线客服。':
		'我们的 OEM 仅适用于商务舱和头等舱成员。如果您想使用OEM，请从[会员信息]更改会员等级。如果您有任何问题，请联系我们的在线客服。',
	充值会员: '充值会员',
	购买数量不可低于X件: '购买数量不可低于X件',
	/**
	 *
	 */
	'平台库存（处理中）': '平台库存（处理中）',
	'平台库存（已锁定）': '平台库存（已锁定）',
	'平台库存（可用库存）': '平台库存（可用库存）',
	'平台库存（待处理次品数）': '平台库存（待处理次品数）',
	转账金额不能为0: '转账金额不能为0',
	'同步中...': '同步中...',
	'同步完成-执行刷新': '同步完成-执行刷新',
	我的工作台: '我的工作台',
	API同步订单: 'API同步订单',
	库存管理: '库存管理',
	寻源列表: '寻源列表',
	OEM复购: 'OEM复购',
	'API/子账号配置': 'API/子账号配置',
	会员相关: '会员相关',
	国际配送管理: '国际配送管理',
	FBA补货计划: 'FBA补货计划',
	国际发货管理: '国际发货管理',
	国际发货售后: '国际发货售后',
	主账号资金明细: '主账号资金明细',
	店铺资金明细: '店铺资金明细',
	OEM寻源: 'OEM寻源',
	全部店铺数据: '全部店铺数据',
	这是您与直行便相处的第X天: '这是您与直行便相处的第X天',
	升级会员: '升级会员',
	钱包余额: '钱包余额',
	未关联的商品: '未关联的商品',
	API同步异常订单: 'API同步异常订单',
	商品在库: '商品在库',
	在库辅料: '在库辅料',
	昨日下单金额: '昨日下单金额',
	昨日API下单量: '昨日API下单量',
	昨日下单数量: '昨日下单数量',
	昨日采购金额: '昨日采购金额',
	昨日采购量: '昨日采购量',
	昨日发货量: '昨日发货量',
	待发库存数: '待发库存数',
	待确认补货计划: '待确认补货计划',
	未绑定商品: '未绑定商品',
	/**
	 *
	 *
	 */
	对折: '对折',
	不使用商品番号: '不使用商品番号',
	不使用客户商品sku: '不使用客户商品sku',
	关联商品库填写: '关联商品库填写',
	请填写会社名: '请填写会社名',
	请填写会社地址: '请填写会社地址',
	'图片格式支持jpg，png。': '图片格式支持jpg，png。',
	'LOGO图片比例为1:1，在水洗标打印时转变为黑白色':
		'LOGO图片比例为1:1，在水洗标打印时转变为黑白色',
	缝制样式: '缝制样式',
	请选择商品番号填写方式: '请选择商品番号填写方式',
	'更新中...': '更新中...',
	'更新完成-执行刷新': '更新完成-执行刷新',
	Amazon在庫数更新: 'Amazon在庫数更新',
	购买时间: '购买时间',
	收藏时间: '收藏时间',
	/**
	 *
	 */
	更新时间: '更新时间',
	请选择需要复购的商品: '请选择要复购的商品',
	请选择需要优先发货的商品: '请选择需要优先发货的商品',
	请选择需要取消的商品: '请选择需要取消的商品',
	请选择需要售后的商品: '请选择需要售后的商品',
	请选择需要导出的订单: '请选择需要导出的订单',
	'自動発注をOFFにしました。': '自動発注をOFFにしました。',
	店铺url和访问令牌不能为空: '店铺url和访问令牌不能为空',
	未关联sku数量: '未关联sku数量',
	星期一: '星期一',
	星期二: '星期二',
	星期三: '星期三',
	星期四: '星期四',
	星期五: '星期五',
	星期六: '星期六',
	星期日: '星期日',
	平台库存: '平台库存',
	AMAZON库存: 'AMAZON库存',
	不可售: '不可售',
	入库: '入库',
	/**
	 *
	 */
	'商品“已上架”之前的状态下的总数量': '商品“已上架”之前的状态下的总数量',
	已使用准备发给您的商品: '已使用准备发给您的商品',
	可使用的正品数量: '可使用的正品数量',
	'仓库发现商品次品且无法退款，进入您的次品库存':
		'仓库发现商品次品且无法退款，进入您的次品库存',
	弊社受取銀行: '弊社受取銀行',
	横浜駅前支店: '横浜駅前支店',
	口座番号: '口座番号',
	'普通預金  4014779': '普通預金  4014779',
	口座名: '口座名',
	'株式会社  SNIFF JAPAN': '株式会社  SNIFF JAPAN',
	料金表: '料金表',
	当前单价: '当前单价',
	国際配送依頼番号: '国際配送依頼番号',
	'Amazon sku绑定成功': 'Amazon sku绑定成功',
	收据: '收据',
	/**
	 *
	 */
	一键绑定: '一键绑定',
	导出: '导出',
	请确认售后信息: '请确认售后信息',
	解冻数量: '解冻数量',
	通过FBA补货计划发货: '通过FBA补货计划发货',
	海外仓sku不能为空: '海外仓sku不能为空',
	/**
	 *
	 */
	缺货: '缺货',
	传输商品信息到店铺: '传输商品信息到店铺',
	'创建成功，并已为您自动创建关联': '创建成功，并已为您自动创建关联',
	查看关联商品库: '查看关联商品库',
	领收书下载: '领收书下载',
	领收书: '领收书',
	流水: '流水',
	是否确定加购样品: '是否确定加购样品',
	查看报价信息: '查看报价信息',
	汇款金额必须大于0: '汇款金额必须大于0',
	确认取消: '确认取消',
	'请绑定 Amazon sku': '请绑定 Amazon sku',
	是否确认取消授权: '是否确认取消授权',
	残高からお支払い: '残高からお支払い',
	申込月数: '申込月数',
	转账成功: '转账成功',
	銀行振込: '銀行振込',
	/**
	 *
	 */
	换绑: '换绑',
	'绑定Amazon sku': '绑定Amazon sku',
	'申请取消成功后，系统将自动取消商品的发货行为，并将商品保留库存。':
		'申请取消成功后，系统将自动取消商品的发货行为，并将商品保留库存。',
	请确认本次重拍费用: '请确认本次重拍费用',
	'您的余额不足，请充值后再次支付': '您的余额不足，请充值后再次支付',
	'5s后跳转充值页面': '5s后跳转充值页面',
	'5s后跳转附加项配置': '5s后跳转附加项配置',
	下单失败: '下单失败',
	/**
	 *
	 */
	商品关联列表: '商品关联列表',
	修改关联: '修改关联',
	编辑附加项: '编辑附加项',
	'为您的商品编辑的附加项，会再API下单时直接使用。如需修改请点击此处。':
		'为您的商品编辑的附加项，会再API下单时直接使用。如需修改请点击此处。',
	请上传图片: '请上传图片',
	一键删除: '一键删除',
	店铺商品信息: '店铺商品信息',
	'如果是个人，请输入无': '如果是个人，请输入无',
	订单结算费用详情: '订单结算费用详情',
	物流详情: '物流详情',
	订单搜索: '订单搜索',
	发货详情: '发货详情',
	输入你的登陆密码: '输入你的登陆密码',
	是否确认复购: '是否确认复购',
	复购成功: '复购成功',
	不同意: '不同意',
	'未处理，请点击【提出】按钮': '未处理，请点击【提出】按钮',
	OEM报价: 'OEM报价',
	'处理中，需要1～2分钟': '处理中，需要1～2分钟',
	我的OEM商品: '我的OEM商品',
	转到运输公司设置: '转到运输公司设置',
	'点击上方按钮编辑你常用的<span>快捷功能</span>':
		'点击上方按钮编辑你常用的<span>快捷功能</span>',
	'拉动板块可<span>调整功能排序</span>哦！':
		'拉动板块可<span>调整功能排序</span>哦！',
	'编辑后的功能入口在上方显示，你需要的功能直接点击前往即可':
		'编辑后的功能入口在上方显示，你需要的功能直接点击前往即可',
	'<span>透明购</span>: 为首页，并为您推荐1688、淘宝、天猫的商品查找专区。<br/><span>市场购</span>: 直行便扩展的市场采购货品更全，价格更低。<br/><span>我的频道</span>: 你在本平台的喜欢的商品都在这里。 <br/>任何情况下，点击<span>logo</span>回到本页面':
		'<span>透明购</span>: 为首页，并为您推荐1688、淘宝、天猫的商品查找专区。<br/><span>市场购</span>: 直行便扩展的市场采购货品更全，价格更低。<br/><span>我的频道</span>: 你在本平台的喜欢的商品都在这里。 <br/>任何情况下，点击<span>logo</span>回到本页面',
	'选择你感兴趣的<span>平台</span>和<span>类目</span>，再输入关键词，搜索更精准':
		'选择你感兴趣的<span>平台</span>和<span>类目</span>，再输入关键词，搜索更精准',
	'点击“X”在直行便平台搜索': '点击“X”在直行便平台搜索',
	'点击“X”跳转你选择的平台搜索。': '点击“X”跳转你选择的平台搜索。',
	'直行便为你提供了多个选购平台。根据你的<span>采购类型迅速选品</span>。':
		'直行便为你提供了多个选购平台。根据你的<span>采购类型迅速选品</span>。',
	'点击上方切换你喜欢的品类，我们将根据该品类进行推荐。':
		'点击上方切换你喜欢的品类，我们将根据该品类进行推荐。',
	'如果您有多个店铺可点击这里切换店铺，为不同的店铺采购。直行便也会为不同的店铺推荐不同的商品。':
		'如果您有多个店铺可点击这里切换店铺，为不同的店铺采购。直行便也会为不同的店铺推荐不同的商品。',
	'你的专属担当根据你的采购习惯，为您甄选的店铺会展示在这里。':
		'你的专属担当根据你的采购习惯，为您甄选的店铺会展示在这里。',
	'此处由系统根据你的采购习惯，为你甄选的店铺会展示在这里。':
		'此处由系统根据你的采购习惯，为你甄选的店铺会展示在这里。',
	'下方为商品的推荐，会根据你的购买习惯更新商品哦！！！':
		'下方为商品的推荐，会根据你的购买习惯更新商品哦！！！',
	'你还没有店铺，快去创建一个你的店铺，用来采购你的商品。':
		'你还没有店铺，快去创建一个你的店铺，用来采购你的商品。',
	跳过: '跳过',
	'请输入正确的Amazon sku': '请输入正确的Amazon sku',
	国际发货创建成功: '国际发货创建成功',
	处理中的次品和异常: '处理中的次品和异常',
	不可退货次品: '不可退货次品',
	去查看采购订单: '去查看采购订单',
	修改商品附加项: '修改商品附加项',
	订单: '订单',
	申请取消成功: '申请取消成功',
	选择同款: '选择同款',
	'是否确认取消国际发货？': '是否确认取消国际发货？',
	'海外仓（fnsku）/asin': '海外仓（fnsku）/asin',
	'Amazon sku/AMAZON商品名称': 'Amazon sku/AMAZON商品名称',
	'搜索Amazon sku/FNSKU/ASIN/SKU': '搜索Amazon sku/FNSKU/ASIN/SKU',
	下单中: '下单中',
	无效的URL: '无效的URL',
	请选择打印模板: '请选择打印模板',
	商品更新: '商品更新',
	最新价格为X元: '最新价格为X元',
	请输入直行便订单编号: '请输入直行便订单编号',
	发货标题: '发货标题',
	请设置商品附加项: '请设置商品附加项',
	通过点击关联符合您要求的sku: '通过点击关联符合您要求的sku',
	商品附加项要保存吗: '商品附加项要保存吗',
	签收成功: '签收成功',
	已签收: '已签收',
	请上传箱唛: '请上传箱唛',
	箱唛文件: '箱唛文件',
	上传箱唛: '上传箱唛',
	修改箱唛: '修改箱唛',
	API状态: 'API状态',
	关联商品信息: '关联商品信息',
	出库手续费: '出库手续费',
	编辑组合商品: '编辑组合商品',
	请选择订单: '请选择订单',
	统一编辑发货附加项: '统一编辑发货附加项',
	我的账单: '我的账单',
	待支付总额: '待支付总额',
	'剩余XX天hh:mm:ss自动扣款': '剩余XX天hh:mm:ss自动扣款',
	账单名称: '账单名称',
	扣款说明: '扣款说明',
	应付总额: '应付总额',
	钱包金额: '钱包金额',
	主账户账单: '主账户账单',
	子账户账号: '子账户账号',
	'引き落としに成功した場合は、商品を発送いたします。': '',
	'残高不足の原因で引落できなかった場合は、出荷作業を停止して別途支払のご案内をお送りいたします。':
		'',
	'ご入金確認ができ次第、商品を発送いたします。': '',
	'発送の影響を与えるないように、商品発送前にはシステム口座に十分な残高を用意しておいて下さい。':
		'',
	'点击【确定】按钮，系统将自动从余额扣除当前账单<br />应付总额XX元，确定支付吗':
		'点击【确定】按钮，系统将自动从余额扣除当前账单<br/>应付总额XX元，确定支付吗',
	请输入拒绝原因: '请输入拒绝原因',
	请选择清关地址: '请选择清关地址',
	请选择收货地址: '请选择收货地址',
	取消类型: '取消类型',
	客户取消: '客户取消',
	系统取消: '系统取消',
	提交并下载: '提交并下载',
	请求对象姓名: '请求对象姓名',
	请求金额: '请求金额',
	点击下载: '点击下载',
	请求书下载: '请求书下载',
	请确认优先发货信息: '请确认优先发货信息',
	'余额扣费汇率全部以：XX円 = 1元，进行换算，请确认后再进行支付':
		'余额扣费汇率全部以：XX円 = 1元，进行换算，请确认后再进行支付',
	编辑裁剪图片: '编辑裁剪图片',
	名称: '名称',
	实际重量: '实际重量',
	体积重: '体积重',
	长: '长',
	宽: '宽',
	高: '高',
	箱子: '箱子',
	编辑剪切区域和移动图片: '编辑剪切区域和移动图片',
	适合正方形logo: '适合正方形logo',
	适合长方形logo: '适合长方形logo',
	请确认: '【ご確認下さい】',
	'在X系统中追加购买商品的购物车，正在选择中的系统是否符合顾客的意向，请再次确认':
		'Xシステム で買付商品のカート追加が実行されました \n 選択中のシステムがお客様のご意向に沿っているか、再度ご確認下さい',
	d2cb2b系统介绍: 'D2C / B2B システムの補足：',
	系统说明: `D2Cシステム：1点からエンドユーザーへ直送する業務をサポート
  B2Bシステム：在庫を保持し国内EC関連倉庫や自社倉庫へ納品する業務をサポート
  Topメニュー 右側 B2B / D2C アイコンをクリックでシステム切替可能`,
	'系统说明ps(X元Y円)':
		'D2Cシステムは小口配送前提です。1配送単位の商品代金がX元（約Y円）以上の場合、B2Bシステムをご利用下さい',
	// 会员条约: '会員規約',
	使用条约: '利用規約',
	处理结果: '处理结果',
	取消发货任务记录: '取消发货任务记录',
	仅限输入英文字符: '仅限输入英文字符',
	无效的邮编: '无效的邮编',
	'输入的支店名格式不正确，请设置1-20位字符':
		'输入的支店名格式不正确，请设置1-20位字符',
	销售额取值范围: '销售额取值范围',
	返现类型: '返现类型',
	返现: '返现',
	返现2: '返现',
	返点: '返点',
	销售额区间: '销售额区间',
	'销售额X%': '销售额X%',
	为期: '为期',
	X个月: 'X个月',
	返现发放时间: '返现发放时间',
	每月X日自动返: '每月X日自动返',
	每季度自动返: '每季度自动返',
	每半年自动返: '每半年自动返',
	订单是否结算: '订单是否结算',
	未结算: '未结算',
	结算中: '结算中',
	已结算: '已结算',
	结算失败: '结算失败',
	发货取消结果: '发货取消结果',
	查看异常联络处理记录: '查看异常联络处理记录',
	结算时应退款金额: '结算时应退款金额',
	'商品代金，订单结算后解冻至账户余额': '商品代金，订单结算后解冻至账户余额',
	仅限输入英文和数字字符: '仅限输入英文和数字字符',
	取消原因: '取消原因',
	'お客様申請(発送キャンセル済)': 'お客様申請(発送キャンセル済)',
	'お客様申請(発送キャンセル済・在庫保有)':
		'お客様申請(発送キャンセル済・在庫保有)',
	異常注文処理履歴: '異常注文処理履歴',
	お客様申請: 'お客様申請',
	取最小注文ロット数あり消原因: '最小注文ロット数あり',
	查看处理详情: '查看处理详情',
	主: '主',
	子: '子',
	月销售额区间: '月销售额区间',
	活动有效期: '活动有效期',
	次品退货退款并付费重拍: '次品退货退款并付费重拍',
	'以后的商品为次品时，请都默认处理为次品退货退款并付费重拍。':
		'以后的商品为次品时，请都默认处理为次品退货退款并付费重拍。',
	'因原商品质量不佳，我们为您重拍时自动寻找了以下新链接，但新链接价格不符合您的预期，请您确认':
		'因原商品质量不佳，我们为您重拍时自动寻找了以下新链接，但新链接价格不符合您的预期，请您确认',
	'因原商品质量不佳，我们为您自动寻找了以下新链接，请您确认是否需要再新链接下重拍':
		'因原商品质量不佳，我们为您自动寻找了以下新链接，请您确认是否需要再新链接下重拍',
	'以后的商品为次品时，请都默认处理为原商品退货退款并取消商品采购。':
		'以后的商品为次品时，请都默认处理为原商品退货退款并取消商品采购。',
	确认尺码: '确认尺码',
	确认颜色: '确认颜色',
	确认款式: '确认款式',
	全网断货: '全网断货',
	'原商品缺货-推荐同款-价格不符合客户预期':
		'原商品缺货-推荐同款-价格不符合客户预期',
	'原商品缺货-推荐类似款': '原商品缺货-推荐类似款',
	'预售商品-推荐同款商品': '预售商品-推荐同款商品',
	'预售商品-无同款商品推荐': '预售商品-无同款商品推荐',
	'不满足最小起量-推荐同款商品': '不满足最小起量-推荐同款商品',
	'不满足最小起量-无同款商品推荐': '不满足最小起量-无同款商品推荐',
	'更换同款链接-价格不符合客户预期': '更换同款链接-价格不符合客户预期',
	颜色差异: '颜色差异',
	logo差异: 'logo差异',
	'材质、面料差异': '材质、面料差异',
	配件差异: '配件差异',
	商品上有中文: '商品上有中文',
	尺码差异: '尺码差异',
	设计差异: '设计差异',
	其他不良: '其他不良',
	'污渍/斑点/水印': '污渍/斑点/水印',
	破损: '破损',
	线头: '线头',
	接缝不良: '接缝不良',
	抽丝: '抽丝',
	针眼: '针眼',
	霉菌: '霉菌',
	生锈: '生锈',
	商品有中文: '商品有中文',
	纽扣破损: '纽扣破损',
	拉链破损: '拉链破损',
	附属品缺陷: '附属品缺陷',
	有气味: '有气味',
	'原URL缺货，新的URL内商品是X元，请确认':
		'原URL缺货，新的URL内商品是X元，请确认',
	请在我指定的新链接购买: '请在我指定的新链接购买',
	取消商品采购: '取消商品采购',
	'取消商品采购+商品发送': '取消商品采购+商品发送',
	请在我指定的新链接下采购: '请在我指定的新链接下采购',
	请在直行便为我推荐的此链接下采购: '请在直行便为我推荐的此链接下采购',
	'可以等待，请继续在原链接采购': '可以等待，请继续在原链接采购',
	'我接受起订量，请继续在原链接采购':
		'我接受起订量，请继续在原链接采购<br/>(多采部分商品无异常上架后将作为库存在直行便仓库代为保管，并可匹配其他直发订单发出)',
	'我接受起订量，请继续在原链接采购（多采部分商品无异常上架后将作为库存在直行便仓库代为保管，并可匹配其他直发订单发出）':
		'我接受起订量，请继续在原链接采购（多采部分商品无异常上架后将作为库存在直行便仓库代为保管，并可匹配其他直发订单发出）',
	'问题不大，我能接受，请当成良品入库': '问题不大，我能接受，请当成良品入库',
	'此问题我不能接受，请依然当成不良品处理':
		'此问题我不能接受，请依然当成不良品处理',
	'我有可能有货的采购链接，请在这个链接再尝试购买':
		'我有可能有货的采购链接，请在这个链接再尝试购买',
	'我等不及，请在我指定的新链接下采购': '我等不及，请在我指定的新链接下采购',
	'我等不及，请在直行便为我推荐的此链接下采购':
		'我等不及，请在直行便为我推荐的此链接下采购',
	'我不接受起订量，请在我指定的新链接下采购':
		'我不接受起订量，请在我指定的新链接下采购',
	'我不接受起订量，请在直行便为我推荐的此链接下采购':
		'我不接受起订量，请在直行便为我推荐的此链接下采购',
	'直行便推荐的链接价格等不合适，请在我指定的新链接下采购':
		'直行便推荐的链接价格等不合适，请在我指定的新链接下采购',
	'此问题我不能接受，请依然当成不良品处理，并在我指定的新链接购买':
		'此问题我不能接受，请依然当成不良品处理，并在我指定的新链接购买',
	我还是要继续在原链接采购: '我还是要继续在原链接采购',
	取消商品重拍: '取消商品重拍',
	'取消商品重拍+商品发送': '取消商品重拍+商品发送',
	次品退货退款并取消商品采购: '次品退货退款并取消商品采购',
	'次品退货退款并取消商品采购+商品发送':
		'次品退货退款并取消商品采购+商品发送',
	批量搜索: '批量搜索',
	'每行一个（支持excel复制黏贴）': '每行一个（支持excel复制黏贴）',
	双击批量搜索: '双击批量搜索',
	确定后将按照新的货代进行发货: '确定后将按照新的货代进行发货',
	'确定后将取消您的国际发货单，您需重新创建':
		'确定后将取消您的国际发货单，您需重新创建',
	关闭后将不再向您店铺同步物流单号: '关闭后将不再向您店铺同步物流单号',
	关闭提醒: '关闭提醒',
	关闭后将不再向您店铺同步物流单号及订单状态:
		'关闭后将不再向您店铺同步物流单号及订单状态',
	选择类似款: '选择类似款',
	后台导入: '后台导入',
	取消发货: '取消发货',
	'同意一个包裹无法装下商品时，直行便将为您拆成多个包裹进行发货':
		'同意一个包裹无法装下商品时，直行便将为您拆成多个包裹进行发货',
	'由于该订单商品无法在一个包裹中装下，我们为您拆成了多个包裹进行发货':
		'由于该订单商品无法在一个包裹中装下，我们为您拆成了多个包裹进行发货',
	拆: '拆',
	优: '优',
	下单提醒: '下单提醒',
	下次不再提醒: '下次不再提醒',
	会员规则变更通知: '会员规则变更通知',
	'尊敬的用户，直行便团队经过谨慎考虑，为了更好的为用户提供服务，决定在经历了一段时间的免费运营后，于2023/1/11开始只对付费会员提供服务。':
		'尊敬的用户，直行便团队经过谨慎考虑，为了更好的为用户提供服务，决定在经历了一段时间的免费运营后，于2023/1/11开始只对付费会员提供服务。',
	'您也不用担心，您是陪伴我们成长的首批用户，我们已为您自动升级到新会员，并且免费送您会员到1月31日（原价9800日元/月)！赶快去试用体验吧！':
		'您也不用担心，您是陪伴我们成长的首批用户，我们已为您自动升级到新会员，并且免费送您会员到1月31日（原价9800日元/月)！赶快去试用体验吧！',
	'免费送您会员到1月31日（原价9800日元/月)':
		'免费送您会员到1月31日（原价9800日元/月)',
	'到期后会员将失效，将无法享受下单服务，欢迎随时充值新会员。':
		'到期后会员将失效，将无法享受下单服务，欢迎随时充值新会员。',
	新会员权益介绍: '新会员权益介绍',
	开通新会员: '开通新会员',
	查看新会员: '查看新会员',
	'开通会员，立享下单代买服务。请联系主账号开通。':
		'开通会员，立享下单代买服务。请联系主账号开通。',
	'开通会员，立享下单代买服务': '开通会员，立享下单代买服务',
	'开通会员，即可享受下单代买服务。': '开通会员，即可享受下单代买服务。',
	'请联系主账号开通。': '请联系主账号开通。',
	'即可享受下单代买服务。': '即可享受下单代买服务。',
	能够在中国找到丰富的供应商: '能够在中国找到丰富的供应商',
	'按照日本质量标准进行检验（隶属于日本公司）':
		'按照日本质量标准进行检验（隶属于日本公司）',
	'最低国际运费保证，可运往23个国家/地区':
		'最低国际运费保证，可运往23个国家/地区',
	可靠的支持和即时的客户服务: '可靠的支持和即时的客户服务',
	我的会员: '我的会员',
	'有效期：': '有效期：',
	已失去会員: '已失去会員',
	未登録: '未登録',
	未登录: '未登录',
	加入新会员: '加入新会员',
	'会员等级变更！': '会员等级变更！',
	您当前的会员等级已变更为: '您当前的会员等级已变更为',
	立即享用会员: '立即享用会员',
	'您的会员已到期！': '您的会员已到期！',
	'您当前的会员已到期，为了不影响正常下单和使用，请及时开通哦！（不影响历史订单集账户余额充值）':
		'您当前的会员已到期，为了不影响正常下单和使用，请及时开通哦！（不影响历史订单集账户余额充值）',
	查看会员: '查看会员',
	// 新会员
	'直行便会员，为您的买卖生活提供一切所需。':
		'直行便会员，为您的买卖生活提供一切所需。',
	入会优享超多服务: '入会优享超多服务',
	开通会员: '开通会员',
	有效期至: '有效期至',
	自动续费已开启: '自动续费已开启',
	成为: '成为', // xxxx 成为xxx会员
	去续费: '去续费',
	当前自动续费不可开启: '当前自动续费不可开启',
	'您在持有预充会员【XX】期间，暂不可开启自动续费。在该预充会员正式开启后，我们会自动为您开启会员的自动续费，敬请谅解。':
		'您在持有预充会员【XX】期间，暂不可开启自动续费。在该预充会员正式开启后，我们会自动为您开启会员的自动续费，敬请谅解。',
	知道了: '知道了',
	'请及时关闭PayPal账户内不需要的自动续费，如未关闭，我们会将这笔钱充值到您的余额，可能需要您承担部分手续费。':
		'请及时关闭PayPal账户内不需要的自动续费，如未关闭，我们会将这笔钱充值到您的余额，可能需要您承担部分手续费。',
	关闭PayPal自动续费教程: '关闭PayPal自动续费教程',
	续费提示: '续费提示',
	'续费后，会在XX开始自动延长YY时间': '续费后，会在XX开始自动延长YY时间',
	'检测到您当前有T1至T2的预充会员，续费成功后，会取消原来预充的会员，预充会员的<span style="color:#EB4545;">钱款不予退还</span>。':
		'检测到您当前有T1至T2的预充会员，续费成功后，会取消原来预充的会员，预充会员的<span style="color:#EB4545;">钱款不予退还</span>。',
	升舱提示: '升舱提示',
	'您当前为V1，想开通V2，您想选择何时生效？开通成功后，选择将不可更改':
		'您当前为V1，想开通V2，您想选择何时生效？开通成功后，选择将不可更改',
	'检测到您当前有T1至T2的预充会员，降仓成功后，会取消预充会员，预充会员的<span style="color:#EB4545;">钱款不予退还</span>。':
		'检测到您当前有T1至T2的预充会员，降仓成功后，会取消预充会员，预充会员的<span style="color:#EB4545;">钱款不予退还</span>。',
	降舱提示: '降舱提示',
	'您当前为V1，想开通V2，为保障您当前的会员权益，会等原会员套餐到期（TT）后开始实行降仓会员，是否要进行降仓？':
		'您当前为V1，想开通V2，为保障您当前的会员权益，会等原会员套餐到期（TT）后开始实行降仓会员，是否要进行降仓？',
	'续费后，会在T开始自动延长V时间': '续费后，会在T开始自动延长V时间',
	'您当前为V1，充值V2，您可以选择立即生效或者当前会员到期之后生效。开通成功后，选择将不可更改':
		'您当前为V1，充值V2，您可以选择立即生效或者当前会员到期之后生效。开通成功后，选择将不可更改',
	'您当前为V1，想开通V2，为保障您当前的会员权益，会等原会员套餐到期（T）后开始实行降仓会员，是否要进行降仓？':
		'您当前为V1，想开通V2，为保障您当前的会员权益，会等原会员套餐到期（T）后开始实行降仓会员，是否要进行降仓？',
	'检测到您当前有2段会员，升舱成功后，会取消当前会员和预充会员，<span style="color:#EB4545;">钱款不予退还</span>。':
		'检测到您当前有2段会员，升舱成功后，会取消当前会员和预充会员，<span style="color:#EB4545;">钱款不予退还</span>。',
	'检测到您当前有T1至T2的预充会员，升舱成功后，会取消预充会员，预充会员的<span style="color:#EB4545;">钱款不予退还</span>':
		'检测到您当前有T1至T2的预充会员，升舱成功后，会取消预充会员，预充会员的<span style="color:#EB4545;">钱款不予退还</span>',
	'您当前为V1，充值V2，您想选择立即生效还是老会员到期之后生效？开通成功后，选择将不可更改':
		'您当前为V1，充值V2，您想选择立即生效还是老会员到期之后生效？开通成功后，选择将不可更改',
	立即生效: '立即生效',
	开始生效: '开始生效',
	定时升舱: '定时升舱',
	利用期间: '利用期间',
	'%off': '%割引',
	'折合X円/月': '折合X円/月',
	已成功开通预充会员: '已成功开通预充会员',
	已成功开通会员: '已成功开通会员',
	'已为您开启预充会员V，有效期为Y': '已为您开启预充会员V，有效期为Y',
	'已为您开启V，有效期为Y': '已为您开启V，有效期为Y',
	'为了不影响您的后续使用，请保持PayPal内开启自动续费。':
		'为了不影响您的后续使用，请保持PayPal内开启自动续费。',
	已优惠: '已优惠:X',
	确认协议并立即支付: '确认协议并立即支付',
	请阅读: '请阅读',
	会员服务协议: '会员服务协议',
	及: '及',
	自动续费服务规则: '自动续费服务规则', // 整句话 请阅读“会员服务协议”及“自动续费服务规则”
	高级会员专享服务: '高级会员专享服务',
	'如需开通商务舱或头等舱会员，请先用line扫描右侧二维码联系我们，获取专属咨询服务。':
		'如需开通商务舱或头等舱会员，请先用line扫描右侧二维码联系我们，获取专属咨询服务。',
	恭喜成功开通: '恭喜成功开通',
	'为了更好地帮您履行下单履约，您需要提前填写以下信息。':
		'为了更好地帮您履行下单履约，您需要提前填写以下信息。',
	添加店铺: '添加店铺',
	采购设置: '采购设置',
	国际配送设置: '国际配送设置',
	暂不设置: '暂不设置',
	保存设置: '保存设置',
	请填写完成国际配送设置: '请填写完成国际配送设置',
	请填写完成采购设置: '请填写完成采购设置',
	请填写完成店铺信息: '请填写完成店铺信息',
	去开通: '去开通',
	是否要关闭自动续费: '是否要关闭自动续费',
	'关闭自动续费后，您的V将于T到期。关闭后会影响您后续的正常使用，请谨慎关闭。是否现在关闭？':
		'关闭自动续费后，您的V将于T到期。关闭后会影响您后续的正常使用，请谨慎关闭。是否现在关闭？',
	'为了能正常关闭自动续费，请务必前往paypal关闭自动续费。':
		'为了能正常关闭自动续费，请务必前往paypal关闭自动续费。',
	'已为您开启V自动续费，记得保持余额充足。':
		'已为您开启V自动续费，记得保持余额充足。',
	'为了能正常打开自动续费，请务必前往PayPal开启自动续费。':
		'为了能正常打开自动续费，请务必前往PayPal开启自动续费。',
	支付金额: '支付金额',
	'尊敬的用户，您需办理会员后，方可下单，请联系主账号办理':
		'尊敬的用户，您需办理会员后，方可下单，请联系主账号办理',
	'尊敬的用户，您需办理会员后，方可下单':
		'尊敬的用户，您需办理会员后，方可下单',
	办理会员: '办理会员',
	'尊敬的用户，您于T变更为非会员用户，我们已发站内信告知您：开启自动同步下单功能及按钮已关闭（变为非会员后，不影响历史订单及账户余额提现）':
		'尊敬的用户，您于T变更为非会员用户，我们已发站内信告知您：开启自动同步下单功能及按钮已关闭（变为非会员后，不影响历史订单及账户余额提现）',
	'尊敬的用户，您需办理会员成功后，方可同订单数据':
		'尊敬的用户，您需办理会员成功后，方可同订单数据',
	'尊敬的用户，您需办理会员成功后，方可开启自动同步下单，请联系主账号办理':
		'尊敬的用户，您需办理会员成功后，方可开启自动同步下单，请联系主账号办理',
	'尊敬的用户，您需办理会员成功后，方可开启自动同步下单':
		'尊敬的用户，您需办理会员成功后，方可开启自动同步下单',
	'尊敬的用户，您于2022.02.10 13:57变更为非会员用户，已授权的API被禁用，需办理会员成功后，方可同步订单数据，请联系主账号办理':
		'尊敬的用户，您于2022.02.10 13:57变更为非会员用户，已授权的API被禁用，需办理会员成功后，方可同步订单数据，请联系主账号办理',
	'尊敬的用户，您需办理会员后，方可授权绑定子店铺，请联系主账号办理':
		'尊敬的用户，您需办理会员后，方可授权绑定子店铺，，请联系主账号办理',
	'尊敬的用户，您需办理会员成功后，方可授权绑定子店铺':
		'尊敬的用户，您需办理会员成功后，方可授权绑定子店铺',
	授权店铺API绑定变更: '授权店铺API绑定变更',
	'授权店铺API绑定变更尊敬的用户，您于T变更为非会员用户，已授权店铺绑定的API变更为禁用状态（不影响历史订单及账户余额提现）':
		'授权店铺API绑定变更尊敬的用户，您于T变更为非会员用户，已授权店铺绑定的API变更为禁用状态（不影响历史订单及账户余额提现）',
	'已授权（禁用）': '已授权（禁用）',
	'円/月': '円/月',
	绑定信用卡支付: '绑定信用卡支付',
	你还没有绑定信用卡: '你还没有绑定信用卡',
	有効期限: '有効期限',
	请选择信用卡: '请选择信用卡',
	'是否删除信用卡？': '是否删除信用卡？',
	信用卡卡号: '信用卡卡号',
	信用卡安全码: '信用卡安全码',
	仅限数字: '仅限数字',
	仅限输入3位数字安全码: '仅限输入3位数字安全码',
	'仅限数字，例：05/2022': '仅限数字，例：05/2022',
	请检查卡信息是否有误: '请检查卡信息是否有误',
	'支付失败，请重新操作！': '支付失败，请重新操作！',
	paypal信用卡: 'paypal信用卡',
	会费: '会费',
	最低: '最低',
	请联系主账号更改: '请联系主账号更改',
	已为您开启自动续费: '已为您开启自动续费',
	已失去会员: '已失去会员',
	未入会: '未入会',
	设为默认支付方式: '设为默认支付方式',
	支付失败: '支付失败',
	'余额不足，请先充值余额后重试。': '余额不足，请先充值余额后重试。',
	每次询问我处理次品方式: '每次询问我处理次品方式',
	PayPal加载中: 'PayPal加载中',
	'余额不足，请先绑定店铺后充值，再重试':
		'余额不足，请先绑定店铺后充值，再重试',
	'信用卡-由PayPal提供，支持Visa、MasterCard、AMEX、JCB等类型':
		'信用卡-由PayPal提供，支持Visa、MasterCard、AMEX、JCB等类型',
	'您可以在顶部左侧切换/添加店铺，以及在右侧切换业务类型。':
		'您可以在顶部左侧切换/添加店铺，以及在右侧切换业务类型。',
	'B2B：向日本仓库囤货 (大宗囤货)': 'B2B：向日本仓库囤货 (大宗囤货)',
	'D2C：向用户直接送货 (小件送货)': 'D2C：向用户直接送货 (小件送货)',
	'您可以在这里搜索到优质低价的海量中国商品。':
		'商品キーワード/D2C：向用户直接送货 (小件送货)',
	'您可以在这里直接前往源头站点搜索低价好物，也可以在本地站点搜寻更低价同款好物。':
		'您可以在这里直接前往源头站点搜索低价好物，也可以在本地站点搜寻更低价同款好物。',
	'您可以在“工作台”查看和管理您的订单、商品、资金等等。':
		'您可以在“工作台”查看和管理您的订单、商品、资金等等。',
	'点击“去挖更多”浮窗，即可前往源头站点搜索更多低价好物。':
		'点击“去挖更多”浮窗，即可前往源头站点搜索更多低价好物。',
	'点击“加入购物车”，即可添加商品到本平台购物车。':
		'点击“加入购物车”，即可添加商品到本平台购物车。',
	'点击“日语搜索”浮窗，即可调起日文搜索框。':
		'点击“加入购物车”，即可添加商品到本平台购物车。',
	会员开通记录: '会员开通记录',
	活动赠送: '活动赠送',
	自动续费: '自动续费',
	自动续费2: '自动续费',
	开通失败: '开通失败',
	开通成功: '开通成功',
	待生效: '待生效',
	会员套餐: '会员套餐',
	'支付价格（日元）': '支付价格（日元）',
	会员有效期: '会员有效期',
	导出记录: '导出记录',
	会员订单编号: '会员订单编号',
	类型2: '类型',
	'如果您现在更改会员计划，您当前的会员资格将失效。请注意，会员费不可退还。':
		'如果您现在更改会员计划，您当前的会员资格将失效。请注意，<span style="color:#EB4545;">会员费不可退还。<span/>',
	开通会员2: '开通会员',
	状态2: '状态',
	不可代购: '不可代购',
	查看商品: '查看商品',
	收起: '收起',
	已开启: '已开启',
	'输入的密码格式不正确，请设置6-32个字符，字母和数字的组合，字母区分大小写':
		'输入的密码格式不正确，请设置6-32个字符，字母和数字的组合，字母区分大小写',
	'是否修改密码？': '是否修改密码？',
	'系统检测到您当前的密码过于简单，存在安全风险，建议您修改密码。':
		'系统检测到您当前的密码过于简单，存在安全风险，建议您修改密码。',
	'注册或登录后，将为您推荐更多个性化内容。':
		'注册或登录后，将为您推荐更多个性化内容。',
	'货物发出去之后不要在亚马逊后台再打开这个补货计划，避免调用api失败':
		'货物发出去之后不要在亚马逊后台再打开这个补货计划，避免调用api失败',
	'订单读取中，预计需要1-3分钟，请稍后刷新页面查看读取结果':
		'订单读取中，预计需要1-3分钟，请稍后刷新页面查看读取结果',
	文件大小不超过10M: '文件大小不超过10M',
	表格下单: '表格下单',
	直行便SKU: '直行便SKU',
	'元/发货单': '元/发货单',
	ヶ月: 'ヶ月',
	折合: '折合',
	'信用卡(PayPal提供)': '信用卡(PayPal提供)',
	PayPal支付: 'PayPal支付',
	添加信用卡: '添加信用卡',
	确认协议并支付: '确认协议并支付',
	优惠汇率: '优惠汇率',
	今日汇率: '今日汇率',
	'去登录(仅限内测用户)': '去登录(仅限内测用户)',
	'非常抱歉，本App仅对内侧用户开放。如有需要，请联系客服':
		'非常抱歉，本App仅对内侧用户开放。如有需要，请联系客服',
	系统升级中: '系统升级中',
	异常订单搜索: '异常订单搜索',
	一键搜索: '一键搜索',
	问题处理状态: '问题处理状态',
	点击选择: '点击选择',
	'问题处理(未处理)': '问题处理(未处理)',
	'问题处理(已处理)': '问题处理(已处理)',
	'商品取消履历(取消发货任务记录)': '商品取消履历(取消发货任务记录)',
	'商品取消履历(取消采购记录)': '商品取消履历(取消采购记录)',
	'支持输入直行便订单编号、客户订单编号、shopify订单编号':
		'支持输入直行便订单编号、客户订单编号、shopify订单编号',
	'支持输入直行便订单编号、客户订单编号、shopify订单编号、平台商品SKU':
		'支持输入直行便订单编号、客户订单编号、shopify订单编号、平台商品SKU',
	'点击输入平台商品SKU、商品名称': '点击输入平台商品SKU、商品名称',
	'支持输入直行便订单编号、订单标题': '支持输入直行便订单编号、订单标题',
	'点击输入平台商品SKU、客户商品SKU、商品名称':
		'点击输入平台商品SKU、客户商品SKU、商品名称',
	'支持输入平台商品SKU、直行便订单编号、客户订单编号':
		'支持输入平台商品SKU、直行便订单编号、客户订单编号',
	'支持输入订单标题、平台商品SKU、直行便订单编号':
		'支持输入订单标题、平台商品SKU、直行便订单编号',
	详细说明和上传图片: '详细说明和上传图片',
	'补充说明，有助于我们更好地帮您处理售后问题':
		'补充说明，有助于我们更好地帮您处理售后问题',
	不可申请取消: '不可申请取消',
	当前状态用户不可发起申请取消: '当前状态用户不可发起申请取消',
	请稍后重试: '请稍后重试',
	请输入金额: '请输入金额',
	可手动修改充值金额: '可手动修改充值金额',
	取消支付: '取消支付',
	售后数量大于下单数量: '售后数量大于下单数量',
	搜索内容不能为空: '搜索内容不能为空',
	已拆包: '已拆包',
	当前状态不可申请售后: '当前状态不可申请售后',
	'当前商品不是待发货状态，不允许优先发货':
		'分納発送不可  当前商品不是待发货状态，不允许优先发货',
	没有可取消商品: '没有可取消商品',
	'「Excel一括注文」相关功能请前往pc端操作。':
		'「Excel一括注文」相关功能请前往pc端操作。',
	'该页为第三方登录页。登录后，即可正常浏览第三方商品详情页，以及正常使用直行便的加购功能。':
		'该页为第三方登录页。登录后，即可正常浏览第三方商品详情页，以及正常使用直行便的加购功能。',
	不写区号: '不写区号',
	请输入有效的电话号码: '请输入有效的电话号码',
	语言: '语言',
	简体中文: '简体中文',
	日本語: '日本語',
	正在设置语言: '正在设置语言...',
	该商品不可代购: '该商品不可代购',
	'商品详情加载中，请稍后再试': '商品详情加载中，请稍后再试',
	当前等级: '当前等级',
	未开通: '未开通',
	钱包余额不足: '钱包余额不足',
	添加商品失败: '添加商品失败',
	邀请码: '邀请码',
	请输入邀请码: '请输入邀请码',
	'邀请码无效，请重新输入': '邀请码无效，请重新输入',
	'欢迎来到THE CKB！': '欢迎来到THE CKB！',
	恭喜您获得: '恭喜您获得',
	'会员免费试用！': 'X天【Y】会员免费试用！',
	关闭自动续费问卷: '关闭自动续费问卷',
	'取消自动缴纳会费的原因（可多选）': '取消自动缴纳会费的原因（可多选）',
	我想按照自己的节奏使用会员服务: '我想按照自己的节奏使用会员服务',
	我想更改会员费支付方式: '我想更改会员费支付方式',
	'我对会员费自动缴费服务功能不满意（焦虑）':
		'我对会员费自动缴费服务功能不满意（焦虑）',
	我想取消乘坐直飞航班: '我想取消乘坐直飞航班',
	其他的: '其他的',
	'请告诉我们您选择“我想取消直飞航班”的原因（可多选）':
		'请告诉我们您选择“我想取消直飞航班”的原因（可多选）',
	不常使用: '不常使用',
	昂贵的: '昂贵的',
	系统可操作性差: '系统可操作性差',
	对运送速度不满意: '对运送速度不满意',
	对产品质量不满意: '对产品质量不满意',
	我对支持和询问的答复不满意: '我对支持和询问的答复不满意',
	我没有得到我想要的服务: '我没有得到我想要的服务',
	'如果您想使用任何其他服务，请告知我们。':
		'如果您想使用任何其他服务，请告知我们。',
	'如果您还有其他意见或要求，请随时填写。':
		'如果您还有其他意见或要求，请随时填写。',
	请选择需要的项目: '请选择需要的项目',
	'新客优惠活动还剩：': '新客优惠活动还剩：',
	'新客限定·X折': '新客限定·X折',
	'新客限定·1円': '新客限定·1円',
	名额仅剩X名: '名额仅剩X名',
	新客特惠: '新客特惠',
	'※对于新用户专享活动，如果在会员到期前关闭自动续费，则不会产生使用费。只要没有办理关闭自动续费，就会从下个月开始以正常价格续费。':
		'※对于新用户专享活动，如果在会员到期前关闭自动续费，则不会产生使用费。只要没有办理关闭自动续费，就会从下个月开始以正常价格续费。',
	'次月续费X円/月': '次月续费X円/月',
	次月续费: '翌月から',
	'X円/月': '毎月X円',
	您已选择关闭自动付款: '您已选择关闭自动付款',
	'★请再次确认★': '★请再次确认★',
	'[推荐设置！ 超过90%的用户设定]': '[推荐设置！ 超过90%的用户设定]',
	'我们建议您自动支付支付每月的会员费。':
		'我们建议您自动支付支付每月的会员费。',
	'*如果自动付款设置为“关闭”，则需要采取以下措施。':
		'*如果自动付款设置为“关闭”，则需要采取以下措施。',
	'① 每月需要缴纳会费工作': '① 每月需要缴纳会费工作',
	'（2）如果会员有效期满而未采取第（1）项中的措施，则将无法使用所有会员服务。':
		'（2）如果会员有效期满而未采取第（1）项中的措施，则将无法使用所有会员服务。',
	'→需再次办理入会手续': '→需再次办理入会手续',
	'不可续费功能，超过有效期后无法使用': '不可续费功能，超过有效期后无法使用',
	'· 直接从中国EC网站购买购物车': '直接从中国EC网站购买购物车',
	日语和图片产品搜索: '日语和图片产品搜索',
	产品订单: '产品订单',
	'· 直接采购中国产品，最高品质检验': '· 直接采购中国产品，最高品质检验',
	'·行业最快的物流服务': '·行业最快的物流服务',
	'最快、最便宜的国际运输': '最快、最便宜的国际运输',
	'· 与其他网站（BASE、shopify、亚马逊等）的链接':
		'与其他网站（BASE、shopify、亚马逊等）的链接',
	'· 自动下单、批量产品注册、FBA发货等': '自动下单、批量产品注册、FBA发货等',
	'如果取消自动付款，过期后将不会自动续充会员':
		'如果取消自动付款，过期后将不会自动续充会员',
	'您的订单将自动停止处理，这可能会导致交货延迟。':
		'您的订单将自动停止处理，这可能会导致交货延迟。',
	'您确定要关闭会员费自动续费功能吗？': '您确定要关闭会员费自动续费功能吗？',
	発送: '発送',
	保留自动续费: '保留自动续费',
	关闭自动续费: '关闭自动续费',
	请填写投诉内容: '请填写投诉内容',
	关闭会员成功: '关闭会员成功',
	会员试用中: '会员试用中',
	必须: '必须',
	请解释原因: '请解释原因',
	请填写计入: '请填写',
	找同款: '找同款',
	'抱歉，当前邀请码无法注册app，app现阶段只支持日本站点用户使用':
		'抱歉，当前邀请码无法注册app，app现阶段只支持日本站点用户使用',
	首月: '首月',
	'1688国家货盘': '1688国家货盘',
	女装: '女装',
	男装: '男装',
	童装: '童装',
	家纺家饰: '家纺家饰',
	运动户外: '运动户外',
	宠物及园艺: '宠物及园艺',
	日用餐厨饮具: '日用餐厨饮具',
	箱包皮具: '箱包皮具',
	收纳防尘: '收纳防尘',
	'服饰配件、饰品': '服饰配件、饰品',
	'数码、电脑': '数码、电脑',
	汽车用品: '汽车用品',
	拍摄照片: '拍摄照片',
	从相册选取: '从相册选取',
	提交并关闭自动续费: '提交并关闭自动续费',
	取消并关闭自动续费: '取消并关闭自动续费',
	手机号登录: '手机号登录',
	手机验证码: '手机验证码',
	请输入手机验证码: '请输入手机验证码',
	展开: '展开',
	复购率: '复购率',
	由1688提供部分技术服务支持: '由1688提供部分技术服务支持',
	'1688隐私政策': '1688隐私政策',
	'1688用户协议': '1688用户协议',
	同时注册1688账户和直行便账户: '同时注册1688账户和直行便账户',
	应用程序相机权限: '应用程序相机权限',
	应用程序需要访问您的相机: '应用程序需要访问您的相机',
	直行便用户协议: '直行便用户协议',
	和: '和',
	履约质量: '履约质量',
	综合体验: '综合体验',
	认证工厂: '认证工厂',
	'24小时揽收率': '24小时揽收率',
	'48小时揽收率': '48小时揽收率',
	'5星': '5星',
	'4.5星ー5星': '4.5星ー5星',
	'4星ー4.5星': '4星ー4.5星',
	'4星以下': '4星以下',
	服务保障: '服务保障',
	当日发货: '当日发货',
	'24小时发货': '24小时发货',
	'48小时发货': '48小时发货',
	'7天无理由': '7天无理由',
	了解国家专属搜索框和普通搜索框的区别:
		'了解国家专属搜索框和普通搜索框的区别',
	请同意: '请同意',
	'该品牌店铺已下架，不可下单': '该品牌店铺已下架，不可下单',
	'该店铺未授权，不可下单，请前往PC端授权':
		'该店铺未授权，不可下单，请前往PC端授权',
	我确认并同意以上内容: '我确认并同意以上内容',
	拖动选择您需要搜索的商品: '拖动选择您需要搜索的商品',
	'抱歉，该商品暂不支持购买': '抱歉，该商品暂不支持购买',
	亚马逊: '亚马逊',
	乐天: '乐天',
	速卖通: '速卖通',
	阿里巴巴国际站: '阿里巴巴国际站',
	ZOZO: 'ZOZO',
	Mercari: 'Mercari',
	YAHOO: 'YAHOO',
	NETSEA: 'NETSEA',
	日本热门贩卖平台一键找同款: '日本热门贩卖平台一键找同款',
	一键在中国采购平台找同款: '一键在中国采购平台找同款',
	'拉动上方高亮区域边框，以确定您需要搜索的商品图片':
		'拉动上方高亮区域边框，以确定您需要搜索的商品图片',
	去挑选: '去挑选',
	通过修剪调整搜索图像的大小: '通过修剪调整搜索图像的大小',
	'目前app仅限日本用户使用，请去电脑端使用系统':
		'目前app仅限日本用户使用，请去电脑端使用系统',
	注册完成: '注册完成',
	直营商城用户协议: '直营商城用户协议',
	使用手机号注册: '使用手机号注册',
	使用邮箱注册: '使用邮箱注册',
	'国家/地区': '国家/地区',
	'都道府县/州/地区': '都道府县/\n州/地区',
	'都道府县/州/地区2': '都道府县/州/地区',
	'支持日/英文搜索': '支持日/英文搜索',
	选择国家: '选择国家',
	'选择都道府县/州/地域': '选择都道府县/州/地域',
	支持英文搜索: '支持英文搜索',
	支持日文搜索: '支持日文搜索',
	暂无相关搜索结果: '暂无相关搜索结果',
	同意直行便用户协议: '同意直行便用户协议',
	注册并绑定您的CKB账户: '注册并绑定您的CKB账户',
	'只需绑定一次，后续可用x直接登录':
		'只需绑定一次，后续可用{platform}直接登录',
	'已有CKB账户，去': '已有CKB账户，去',
	登录并绑定: '登录并绑定',
	绑定您的CKB账户: '绑定您的CKB账户',
	'我还没有CKB账户，现在去': '我还没有CKB账户，现在去',
	注册并绑定: '注册并绑定',
	邮箱验证码找回: '邮箱验证码找回',
	手机验证码找回: '手机验证码找回',
	绑定失败: '绑定失败',
	'您的直行便账户已绑定了{thirdName}账户，无法再绑定，请先解绑原先的后再进行绑定。':
		'您的直行便账户已绑定了{thirdName}账户，无法再绑定，请先解绑原先的后再进行绑定。',
	'(约{{x}}円)': '(約{{x}}円)',
	售罄1: '售罄',
	代采专属商品: '代采专属商品',
	// 直采首页
	资金钱包: '资金钱包',
	请输入商品名称: '请输入商品名称',
	免费注册查看价格: '免费注册查看价格',
	代采: '代采',
	'开通会员，即可享受': '开通会员，即可享受',
	'采购更透明，更便捷': '采购更透明，更便捷',
	'会员权益超有料，快来开通吧！': '会员权益超有料，快来开通吧！',
	直行便会员卡: '直行便会员卡',
	'· 超低商品价格，更透明！': '超低商品价格，更透明！',
	'· 大量采购更省钱！': '· 大量采购更省钱！',
	'· 专属客服服务，更快响应！': '· 专属客服服务，更快响应！',
	立即开通: '立即开通',
	// 侧边栏
	在线客服: '在线客服',
	注册成为1688官方认证账号: '注册成为1688官方认证账号',
	返回上一步: '返回上一步',
	'手机号不存在，请重新输入': '手机号不存在，请重新输入',
	'1688日本官方合作伙伴': '1688日本官方合作伙伴',
	'请确认要退出登录吗？': '请确认要退出登录吗？',
	'~没有啦，我也是有底线的~': '～没有啦，我也是有底线的～',
	'下列商品被取消，取消原因：{{reason}}':
		'下列商品被取消，取消原因：{{reason}}',
	退款事宜可与客服进行联系确认: '退款事宜可与客服进行联系确认',
	稍后查看: '稍后查看',
	账户信息: '会員情報',
	使用指南: '使用指南',
	取消收藏成功: '取消收藏成功',
	'新人专享！满{{money}}円免配送费': '新人专享！满{{money}}円免配送费',
	'活动适用于{{cityName}}，具体运费以您的收货地区为准，详见下方运费详情表':
		'活动适用于{{cityName}}，具体运费以您的收货地区为准，详见下方运费详情表',
	'订单满{{num}}円以上包邮': '订单满{{num}}円以上包邮',
	立即订购: '立即订购',
	商品已售罄: '商品已售罄',
	运费活动: '运费活动',
	'{{num}}件': '{{num}}件',
	商品SKU不能超过200: '商品SKU不能超过200',
	活动商品不能超过1个: '活动商品不能超过1个',
	加入购物车成功: '加入购物车成功',
	'很遗憾，该商品已售罄': '很遗憾，该商品已售罄',
	'加入社群，及时获取1日元秒杀活动消息！':
		'加入社群，及时获取1日元秒杀活动消息！',
	"快人一步获得秒杀活动预告，还有<span className='special-text-style'>优质商品上新提醒、生动的商品测评分享、日本讲师教学课程，</span>并配有专属人员答疑解惑。":
		"快人一步获得秒杀活动预告，还有<span className='special-text-style'>优质商品上新提醒、生动的商品测评分享、日本讲师教学课程，</span>并配有专属人员答疑解惑。",
	'快人一步获得秒杀活动预告，还有': '快人一步获得秒杀活动预告，还有',
	'优质商品上新提醒、生动的商品测评分享、日本讲师教学课程，':
		'优质商品上新提醒、生动的商品测评分享、日本讲师教学课程，',
	'并配有专属人员答疑解惑。': '并配有专属人员答疑解惑。',
	该商品已停止售卖: '该商品已停止售卖',
	该商品还未开放售卖: '该商品还未开放售卖',
	该商品已删除: '该商品已删除',
	'很遗憾，这个商品不符合日本代购条件，可以看看其他商品':
		'很遗憾，这个商品不符合日本代购条件，可以看看其他商品',
	返回首页: '返回首页',
	'抱歉，此功能暂只支持日本站， 请重新确认账号':
		'抱歉，此功能暂只支持日本站， 请重新确认账号',
	限时活动: '限时活动',
	人气搜索: '人气搜索',
	$最后一行谢谢$: ''
};
