/*
 * @Author: tianzhitong laotianwy@163.com
 * @Date: 2023-08-12 14:25:00
 * @LastEditors: shiguang
 * @LastEditTime: 2023-08-12 17:50:02
 * @FilePath: \theckbsniffmobile\App\components\Goods\useEvent.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// (!) Approximate behavior
import { useRef, useLayoutEffect, useCallback } from 'react';

function useEvent<Handler extends (...params: any[]) => any>(handler: Handler) {
  const handlerRef = useRef<null | Handler>(null);
  // In a real implementation, this would run before layout effects
  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args: Parameters<Handler>) => {
    // In a real implementation, this would throw if called during render
    const fn = handlerRef.current!;
    return fn(...args);
  }, []);
}

export default useEvent;