import { useCallback, useState } from 'react';
import { useBoolean } from './useBoolean';
import { useInterval } from './useInterval';

export interface CountdownControllers {
  startCountdown: () => void;
  stopCountdown: () => void;
  isTimeout: boolean;
}
export interface TimeRemaining {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  timeDifference: number;
}
// 格式化数据，这里就是补零
export const formate = (time: number): string => {
  return `${time < 10 ? '0' : ''}${time}`;
};
export const useCountdown = (
endTime?: number)
: [TimeRemaining, CountdownControllers] => {
  const targetTime = endTime;

  const [isTimeout, setIsTimeOut] = useState(false);
  const [count, setCount] = useState({
    days: '0',
    hours: '0',
    minutes: '0',
    seconds: '0',
    timeDifference: 1
  });

  const {
    value: isCountdownRunning,
    setTrue: startCountdown,
    setFalse: stopCountdown
  } = useBoolean(false);

  const calculateTimeRemaining = useCallback(() => {
    {
      const currentTime = new Date().getTime();

      const timeDifference = targetTime! - currentTime;

      if (timeDifference <= 0) {
        setCount({
          days: '0',
          hours: '0',
          minutes: '0',
          seconds: '0',
          timeDifference: 1
        });
        setIsTimeOut(!!targetTime);
        return;
      }

      const oneDay = 24 * 60 * 60 * 1000;
      const oneHour = 60 * 60 * 1000;
      const oneMinute = 60 * 1000;

      const days = Math.floor(timeDifference / oneDay);
      const hours = Math.floor(timeDifference % oneDay / oneHour);
      const minutes = Math.floor(timeDifference % oneHour / oneMinute);
      const seconds = Math.floor(timeDifference % oneMinute / 1000);
      setCount({
        days: formate(days),
        hours: formate(hours),
        minutes: formate(minutes),
        seconds: formate(seconds),
        timeDifference
      });
    }
  }, [targetTime]);

  // function updateCountdown() {
  // 	return timeRemaining;
  // }

  const updateCountdown = useCallback(() => {
    calculateTimeRemaining();

    // 在这里你可以根据需要更新你的倒计时组件的显示
    if (count.timeDifference === 0 && targetTime) {
      if (isCountdownRunning) {
        setIsTimeOut(true);
        // 倒计时结束
        // clearInterval(countdownInterval as NodeJS.Timeout);
      }
    }
  }, [
  calculateTimeRemaining,
  count.timeDifference,
  isCountdownRunning,
  targetTime]
  );

  // 每秒钟更新一次倒计时
  // const countdownInterval = isCountdownRunning
  // 	? setInterval(() => {
  // 			const val = updateCountdown();
  // 			setCount(val);
  // 	  }, 1000)
  // 	: null;
  useInterval(updateCountdown, isCountdownRunning ? 1000 : null);
  // if (!targetTime) {
  // 	return [
  // 		{
  // 			days: '0',
  // 			hours: '0',
  // 			minutes: '0',
  // 			seconds: '0',
  // 			timeDifference: 0
  // 		},
  // 		{
  // 			startCountdown,
  // 			stopCountdown,
  // 			isTimeout
  // 		}
  // 	];
  // }
  return [
  count,
  {
    startCountdown,
    stopCountdown,
    isTimeout
  }];

};

// // 使用示例
// const endTime = '2024-01-31T00:00:00'; // 替换为你的倒计时结束时间
// countdown(endTime);