/*
 * @Author: yusha
 * @Date: 2024-02-29 10:40:22
 * @LastEditors: yusha
 * @LastEditTime: 2024-05-17 16:04:09
 * @Description: 新的商品详情
 */

import { memo } from 'react';
import { useAtomValue } from 'jotai';
import { Toast } from 'antd-mobile';
import copy from 'copy-to-clipboard';
import GoodsDetail from '@/outs/container/GoodsDetail';
import { Order, User } from '@/Atoms';
import ShareH5 from '@/component/Share';
import { goToLogin } from '@/config/request/interceptors';
import { jsBridge } from '@/utils/jsBridge';
import { CartAddSkuReq } from '@/service/easyOrder';
import OpenApp from './components/OpenApp';
import './index.scss';

interface NewGoodsDetailProps {
	backClick: (val?: CartAddSkuReq[]) => void;
	/** 目前场景只有来自顺手捎活动列表 */
	fromPage?: 'onHandList';
}
const NewGoodsDetail = (props: NewGoodsDetailProps) => {
	const { backClick, fromPage } = props;
	/** 联系客服 */
	const showService = () => {
		const w: any = window;
		const ChannelIO = w.ChannelIO;
		// 根据站点判断展示哪个语言
		// {language: 'ko' | 'ja' | 'en'}
		const language = 'ja';
		// eslint-disable-next-line new-cap
		ChannelIO('updateUser', {
			language
		});
		// eslint-disable-next-line new-cap
		ChannelIO('showMessenger');
	};
	const handleCopy = (value: string) => {
		if (copy(value!)) {
			Toast.show(window._$m.t('复制成功'));
		}
	};
	return (
		<div className="new-goods-detail">
			<GoodsDetail
				h5GoHomeJump={() => {
					jsBridge.postMessage({
						type: 'DIRCT_goHome',
						payload: {}
					});
				}}
				showService={showService}
				atomUserDetail={User.userDetail as any}
				handleCopy={handleCopy}
				goToLogin={goToLogin}
				OpenApp={OpenApp}
				toast={(content: string) => {
					return Toast.show({
						content,
						maskClassName: 'zIndexImportant-9999'
					});
				}}
				ShareH5={ShareH5}
				atomCartCount={Order.atomCartCount}
				backClick={backClick}
				fromPage={fromPage}
			/>
		</div>
	);
};
export default memo(NewGoodsDetail);
