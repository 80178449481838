/*
 * @Author: yusha
 * @Date: 2024-01-12 10:09:52
 * @LastEditors: yusha
 * @LastEditTime: 2024-03-01 16:52:45
 * @Description: 一元售罄弹窗
 */

import { Image, Text, YStack } from '@snifftest/tamagui';
import { memo } from 'react';
import { Modal } from 'react-native';
import * as SDK from '@snifftest/sdk/lib/rn';
import { tool } from '@/outs/utils/tool';

const fitSize = SDK.fitSize;

interface ModalSoldOutProps {
  visible: boolean;
  onClose: () => void;
}
const ModalSoldOut = (props: ModalSoldOutProps) => {
  const { visible, onClose } = props;
  return (
    <Modal
      transparent={true}
      visible={visible}
      presentationStyle={'overFullScreen'}
      onRequestClose={() => {
        onClose();
      }}
      statusBarTranslucent={true}>

			<YStack
        style={{
          backgroundColor: 'rgba(0,0,0,0.4)',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>

				<YStack
          width={'85%'}
          height={fitSize(380)}
          backgroundColor={'#fff'}
          position="relative"
          borderRadius={fitSize(8)}
          zIndex={6}
          right={0}>

					<Image
            width={fitSize(150)}
            height={fitSize(157)}
            position="absolute"
            left={fitSize(84)}
            top={fitSize(-90)}
            source={{
              uri: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/soldout.png'
            }}
            // zIndex={1}
          />
					<Text
            fontWeight={'400'}
            position="absolute"
            top={fitSize(20)}
            fontSize={fitSize(14)}
            color={'#999'}
            lineHeight={fitSize(29)}
            textAlign="center"
            left={0}
            right={0}>

						{tool.strings('很遗憾，该商品已售罄')}
					</Text>
					<Image
            width={'100%'}
            height={fitSize(104)}
            position="absolute"
            top={0}
            source={{
              uri: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/soldoutBackground.png'
            }}
            zIndex={-1} />


					<Text
            paddingLeft={fitSize(16)}
            paddingTop={fitSize(56)}
            paddingRight={fitSize(16)}
            paddingBottom={fitSize(3)}
            fontSize={fitSize(14)}
            color={'#333'}
            fontWeight={'bold'}
            textAlign="center">

						{tool.strings('加入社群，及时获取1日元秒杀活动消息！')}
					</Text>
					<YStack
            padding={fitSize(16)}
            // todo 渐变
            backgroundColor={'#F2F8FF'}
            flex={1}
            borderBottomLeftRadius={fitSize(8)}
            borderBottomRightRadius={fitSize(8)}>

						{/* <Text>{tool.strings('快人一步获得秒杀活动预告，还有<Text>优质商品上新提醒、生动的商品测评分享、日本讲师教学课程，</Text>并配有专属人员答疑解惑。')}</Text> */}
						<Text
              fontSize={fitSize(14)}
              fontWeight={'400'}
              color={'#646464'}
              lineHeight={fitSize(24)}>

							{tool.strings('快人一步获得秒杀活动预告，还有')}
							<Text
                color={'#008060'}
                fontSize={fitSize(14)}
                fontWeight={'400'}
                lineHeight={fitSize(24)}>

								{tool.strings(
                  '优质商品上新提醒、生动的商品测评分享、日本讲师教学课程，'
                )}
								<Text
                  fontSize={fitSize(14)}
                  fontWeight={'400'}
                  color={'#646464'}
                  lineHeight={fitSize(24)}>

									{tool.strings('并配有专属人员答疑解惑。')}
								</Text>
							</Text>
						</Text>
						<YStack
              position="relative"
              justifyContent="center"
              alignItems="center">

							<Image
                width={fitSize(164)}
                height={fitSize(164)}
                source={{
                  uri: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/code.png'
                }} />


							<Image
                position="absolute"
                width={fitSize(100)}
                bottom={fitSize(32)}
                right={fitSize(94)}
                height={fitSize(100)}
                source={{
                  uri: 'https://static-s.theckb.com/BusinessMarket/Easy/PC/QRCode.png'
                }} />

						</YStack>
					</YStack>

					<Image
            width={fitSize(36)}
            height={fitSize(36)}
            position="absolute"
            bottom={fitSize(-50)}
            left={fitSize(142)}
            source={{
              uri: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/close4.png'
            }}
            onPress={onClose} />

				</YStack>
			</YStack>
		</Modal>);

};
export default memo(ModalSoldOut);