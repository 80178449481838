/*
 * @Author: tianzhitong laotianwy@163.com
 * @Date: 2023-08-16 11:12:47
 * @LastEditors: yusha
 * @LastEditTime: 2024-05-15 15:31:51
 * @FilePath: /ckb-mall-app/src/container/goods/ProductDetail/components/Stepper/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { View, TouchableOpacity, TextInput } from 'react-native';
import React, { useState } from 'react';
import { Image } from '@snifftest/tamagui';
import * as SDK from '@snifftest/sdk/lib/rn';
import useEvent from '@/outs/hooks/base/useEvent';
import { px2dp } from '@/outs/utils';

interface StepperProps {
	/** 输入框改变触发 */
	onChange?: (num: number) => void;
	/** 默认的数量 */
	defaultValue: number;
	/** 最小的加入数量 */
	min: number;
	/** 最大的加入数量 */
	max: number;
	/** 当前输入框输入的值 */
	value: number;
	/** 是否禁用 */
	disable?: boolean;
}
// const REDUCE_NORMAL_IMG =
// 	'https://static-s.theckb.com/BusinessMarket/App/Icon/new-detail-can-reduce.png';
// const REDUCE_DISABLE_IMG =
// 	'https://static-s.theckb.com/BusinessMarket/App/Icon/new-detail-no-can-reduce.png';
// const ADD_NORMAL_IMG =
// 	'https://static-s.theckb.com/BusinessMarket/App/Icon/new-detail-can-add.png';
// const ADD_DISABLE_IMG =
// 	'https://static-s.theckb.com/BusinessMarket/App/Icon/new-detail-no-can-add.png';
const REDUCE_NORMAL_IMG = require('./img/new-detail-can-reduce.png');
const REDUCE_DISABLE_IMG = require('./img/new-detail-no-can-reduce.png');
const ADD_NORMAL_IMG = require('./img/new-detail-can-add.png');
const ADD_DISABLE_IMG = require('./img/new-detail-no-can-add.png');
const Stepper = (props: StepperProps) => {
	const { value: inputValue, disable } = props;
	const [defaultValue] = useState(String(props.defaultValue));

	/** 如果小于0， 那么返回0，否则返回当前值 */
	const lastZeroGetValue = (currentNum: number): number => {
		return currentNum <= 0 ? 0 : currentNum;
	};

	/** 当输入框的值改变时 */
	const onInputChange = useEvent((text: string) => {
		if (disable) {
			return;
		}
		const reg = /^\d+$/;
		if (!reg.test(text)) {
			props.onChange?.(0);
			return;
		}

		if (Number(text) >= props.max) {
			props.onChange?.(lastZeroGetValue(props.max));
			return;
		}
		if (Number(text) <= props.min) {
			props.onChange?.(lastZeroGetValue(props.min));
			return;
		}

		props.onChange?.(Number(text));
	});

	const reduceCart = () => {
		if (disable) {
			return;
		}
		let newValue = Number(props.value);
		newValue--;
		if (newValue <= props.min) {
			props.onChange?.(lastZeroGetValue(props.min));
			return;
		}
		props.onChange?.(newValue);
	};

	const addCart = () => {
		if (disable) {
			return;
		}
		let newValue = Number(props.value);
		newValue++;
		if (newValue >= props.max) {
			props.onChange?.(lastZeroGetValue(props.max));
			return;
		}
		props.onChange?.(newValue);
	};
	/** 获取减号图片 */
	const getLeftReduceImg = () => {
		if (disable) {
			return REDUCE_DISABLE_IMG;
		}
		if (props.max > 0 && inputValue !== props.min) {
			return REDUCE_NORMAL_IMG;
		}
		return REDUCE_DISABLE_IMG;
	};
	/** 获取加号图片 */
	const getRightAddImg = () => {
		if (disable) {
			return ADD_DISABLE_IMG;
		}
		if (props.max > 0 && inputValue !== props.max) {
			return ADD_NORMAL_IMG;
		}
		return ADD_DISABLE_IMG;
	};

	return (
		<View
			style={{
				width: px2dp(90),
				flexDirection: 'row',
				height: px2dp(24)
			}}
		>
			<TouchableOpacity
				onPress={() => reduceCart()}
				activeOpacity={0.8}
				style={{
					width: px2dp(24),
					height: px2dp(24),
					borderRadius: px2dp(24),
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<Image
					source={getLeftReduceImg()}
					style={{
						width: px2dp(24),
						height: px2dp(24),
						borderRadius: px2dp(12)
					}}
				/>
			</TouchableOpacity>
			<TextInput
				keyboardType="numeric"
				style={[
					{
						flex: 1,
						// 在h5中必须指定宽度
						width: SDK.isH5() ? SDK.fitSize(42) : 'auto',
						fontSize: px2dp(12),
						color: '#333',
						textAlign: 'center',
						paddingVertical: 0
					},
					disable ? { color: '#B0B7C2' } : {}
				]}
				editable={!disable}
				defaultValue={defaultValue}
				value={String(inputValue)}
				onChangeText={(text: string) => onInputChange(text)}
			/>

			<TouchableOpacity
				onPress={() => addCart()}
				activeOpacity={0.8}
				style={{
					width: px2dp(24),
					height: px2dp(24),
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: px2dp(24)
				}}
			>
				<Image
					source={getRightAddImg()}
					style={{
						width: px2dp(24),
						height: px2dp(24),
						borderRadius: px2dp(12)
					}}
				/>
			</TouchableOpacity>
		</View>
	);
};

export default Stepper;
