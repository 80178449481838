/*
 * @Author: yusha
 * @Date: 2024-01-11 15:26:22
 * @LastEditors: yusha
 * @LastEditTime: 2024-05-15 14:45:15
 * @Description:
 */
import * as SDK from '@snifftest/sdk/lib/rn';
import { Text, XStack } from '@snifftest/tamagui';
import { memo, useContext, useMemo } from 'react';
import { createSearchParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { uniqueId } from 'lodash';
import { useSetAtom } from 'jotai';
import { request } from '@/config/request/interceptors';
import { TaEvent } from '@/outs/utils/TDAnalytics';
import {
	CartAddReq,
	CartAddSkuReq,
	ProductReq
} from '@/outs/service/easyOrder';
import { tool } from '@/outs/utils/tool';
import { px2dp } from '@/outs/utils';
import { useNavigationWebViewH5 } from '@/outs/hooks/common/useNavigationWebViewH5';
import { AmountProps } from '..';
import { ContextPreView } from '../../../context';

const fitSize = SDK.fitSize;

interface ConfirmBtnsProps {
	setSkuAmount: (val: AmountProps) => void;
	skuAmount: AmountProps;
	setSpuAmount: (val: AmountProps) => void;
	/** 三种类型，加购、立即购买、两者都有 */
	type?: 'both' | 'addCart' | 'buyItNow';
	onClose: () => void;
	getSelectedSkuInfo?: (val: CartAddSkuReq[]) => void;
}
const ConfirmBtns = (props: ConfirmBtnsProps) => {
	const {
		skuAmount,
		setSkuAmount,
		setSpuAmount,
		type,
		onClose,
		getSelectedSkuInfo
	} = props;
	const {
		product = {},
		allSoldout,
		setSoldOutModalVisible,
		setConfirmMessage,
		toast,
		atomCartCount,
		fromPage
	} = useContext(ContextPreView);
	/** 是否来自顺手捎页面 */
	const isFromOnHandList = fromPage === 'onHandList';
	const { skuList = [] } = product;
	const setCartOriginalData = useSetAtom(atomCartCount!);
	const navigationWebViewH5 = useNavigationWebViewH5();
	const navigate = SDK.useNavigate() as any;
	const { runAsync: buyNowApi, loading: buyNowApiLoading } = useRequest(
		(params) =>
			request.easyOrder.order.calculatePrice(params, {
				isNotShowToast: true
			}),
		{
			manual: true
		}
	);
	// 添加购物车
	const { runAsync: postCart, loading: addCartLoading } = useRequest(
		(params) =>
			request.easyOrder.cart.postCart(params, { isNotShowToast: true }),
		{
			manual: true
		}
	);
	/** 获取购物选择商品列表 */
	const getShopList = () => {
		const list: CartAddReq = {
			skuList: []
		};
		if (Object.keys(skuAmount).length) {
			Object.keys(skuAmount).forEach((i) => {
				const productSku = skuList.find(
					(sku) => sku.productProperties === i
				)?.productSku;
				skuAmount[i] &&
					list.skuList?.push({
						productSku,
						quantity: skuAmount[i]
					});
			});
		}
		return list;
	};
	const getOrderDataAndNum = async () => {
		const resData = await request.easyOrder.cart.list();
		const { data = [] } = resData || {};
		setCartOriginalData(data.length ?? 0);
	};
	/** 提示信息 */
	const toastMessage = (text: string) => {
		if (SDK.isH5()) {
			toast(text);
			return;
		}
		onClose();
		tool.Toast.stop(text);
	};
	/** 点击加入购物车 */
	const clickAddCart = async () => {
		if (addCartLoading) {
			return;
		}
		const list = getShopList();
		if (!list.skuList?.length) {
			toastMessage(tool.strings('购买数量不可为空'));
			return;
		}

		try {
			const res = await postCart(list);
			SDK.TA.track({
				event: TaEvent.ADD_SHOPPING_CART,
				properties: {
					product_code: product.productCode,
					product_title_ja: product.productTitle,
					product_num: list.skuList?.reduce((pre, cur) => {
						return pre + (cur?.quantity || 0);
					}, 0)
				}
			});
			if (!res.success) {
				if (SDK.isH5() && res?.msg) {
					toast(res?.msg);
					return;
				}
				onClose();
				res?.msg && tool.Toast.message(res?.msg);
				return;
			}
			setSkuAmount({});
			setSpuAmount({});

			// todo刷新购物车列表接口
			await getOrderDataAndNum();
			onClose();

			toastMessage(tool.strings('加入购物车成功'));
		} catch (e: any) {
			if (SDK.isH5() && e?.msg) {
				toast(e?.msg);
				return;
			}
			onClose();
			e?.msg && tool.Toast.message(e?.msg);
		}
	};
	const allAccount = useMemo(() => {
		const allAccount = Object.keys(skuAmount).reduce((pre, cur) => {
			return pre + skuAmount[cur];
		}, 0);
		return allAccount;
	}, [skuAmount]);

	/** 立即购买 */
	const buyNow = async () => {
		const local_order = `h5_${uniqueId()}`;
		const list = getShopList().skuList;

		// 计算所有商品数量
		const allAccount = Object.keys(skuAmount).reduce((pre, cur) => {
			return pre + skuAmount[cur];
		}, 0);
		if (buyNowApiLoading) {
			return;
		}
		if (!list?.length) {
			toastMessage(tool.strings('购买数量不可为空'));
			return;
		}
		if (list.length > 200) {
			toastMessage(tool.strings('商品SKU不能超过200'));
			return;
		}
		if (product.oneBuyFlag && allAccount > 1) {
			toastMessage(tool.strings('活动商品不能超过1个'));
			return;
		}
		const orderShopList: ProductReq[] = list.map((i) => {
			return {
				...i,
				productCode: product.productCode
			};
		});
		try {
			await buyNowApi({
				productList: orderShopList
			});
		} catch (e: any) {
			onClose();
			if (e?.code === '10000000') {
				return;
			}
			e?.msg && setConfirmMessage?.(e?.msg);
			return;
		}
		onClose();

		if (SDK.isH5()) {
			sessionStorage.setItem(local_order, JSON.stringify(orderShopList));
			navigate({
				pathname: '/order/create',
				search: `?${createSearchParams({
					fromPage: 'goodsDetail',
					local_order
				})}`
			});
			return;
		}
		// TODO
		navigationWebViewH5(
			`/order/create?fromPage=goodsDetail&local_order=${local_order}&orderShopList=${JSON.stringify(
				orderShopList
			)}`
		);
	};
	const buyShopp = (addCart: boolean) => {
		if (!addCart) {
			buyNow();
			return;
		}
		clickAddCart();
	};
	const bottomBtnView = () => {
		const isAddCart = type === 'addCart';
		if (allSoldout) {
			return (
				<Text
					onPress={() => {
						// 只有一日元活动售罄才有点击事件
						if (product.oneBuyFlag === 1) {
							onClose();
							setSoldOutModalVisible?.(true);
						}
					}}
					paddingLeft={px2dp(16)}
					paddingTop={px2dp(12)}
					paddingRight={px2dp(16)}
					paddingBottom={px2dp(12)}
					borderRadius={px2dp(24)}
					backgroundColor={'#b0b7c2'}
					color={'#fff'}
					overflow="hidden"
					fontSize={px2dp(14)}
					marginLeft={px2dp(23)}
					lineHeight={px2dp(22)}
					style={{
						// ios必须这种
						borderRadius: px2dp(24)
					}}
				>
					{tool.strings('商品已售罄')}
				</Text>
			);
		}
		if (type === 'both') {
			return (
				<XStack display="flex" flexDirection="row">
					<XStack
						style={{
							paddingTop: px2dp(12),
							paddingRight: px2dp(16),
							paddingBottom: px2dp(11),
							paddingLeft: px2dp(16),
							borderWidth: px2dp(2),
							borderColor: '#CDD2DA',
							borderRadius: px2dp(24),
							backgroundColor: '#fff',
							marginRight: px2dp(12),
							overflow: 'hidden'
						}}
						onPress={() => buyShopp(true)}
					>
						<Text
							style={{
								fontSize: px2dp(14),
								fontWeight: 'bold'
							}}
						>
							{tool.strings('加入购物车')}
						</Text>
					</XStack>
					<XStack
						style={{
							paddingTop: px2dp(12),
							paddingRight: px2dp(16),
							paddingBottom: px2dp(11),
							paddingLeft: px2dp(16),
							borderRadius: px2dp(24),
							backgroundColor: '#1C2026'
						}}
						onPress={() => buyShopp(false)}
					>
						<Text
							style={{
								fontSize: px2dp(14),
								fontWeight: 'bold',
								color: '#fff'
							}}
						>
							{tool.strings('立即订购')}
						</Text>
					</XStack>
				</XStack>
			);
		}
		return (
			<Text
				height={px2dp(40)}
				width={px2dp(200)}
				onPress={() => buyShopp(isAddCart)}
				backgroundColor={'#1c2026'}
				borderRadius={px2dp(20)}
				lineHeight={px2dp(40)}
				textAlign="center"
				fontSize={px2dp(14)}
				fontWeight={'500'}
				color={'#fff'}
				overflow="hidden"
				style={{
					// ios必须这种
					borderRadius: px2dp(20)
				}}
			>
				{isAddCart
					? tool.strings('加入购物车')
					: tool.strings('立即订购')}
			</Text>
		);
	};
	/** 确认选择sku（用于顺手捎列表进来选择） */
	const confirmSelectSku = async () => {
		const list = getShopList().skuList;
		if (!list?.length) {
			toastMessage(tool.strings('购买数量不可为空'));
			return;
		}
		if (list.length > 200) {
			toastMessage(tool.strings('商品SKU不能超过200'));
			return;
		}
		const orderShopList: ProductReq[] = list.map((i) => {
			return {
				...i,
				productCode: product.productCode,
				// 商品类型，1：顺手捎，不传：商详页下单
				productType: 1
			};
		});
		try {
			// 需要做校验，有些商品售罄
			const res = await buyNowApi({
				productList: orderShopList
			});
			// 成功才需要回填数据
			if (res.success) {
				getSelectedSkuInfo?.(orderShopList);
				onClose();
				return;
			}
			onClose();
			res?.msg && setConfirmMessage?.(res?.msg);
		} catch (e: any) {
			onClose();
			if (e?.code === '10000000') {
				return;
			}
			e?.msg && setConfirmMessage?.(e?.msg);
		}
	};
	return (
		<>
			{isFromOnHandList && (
				<XStack
					padding={px2dp(12)}
					backgroundColor={'#fff'}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					borderTopWidth={2}
					borderTopColor={'#F0F2F5'}
				>
					<Text
						style={{
							fontSize: fitSize(14),
							color: '#1C2026',
							fontWeight: 'bold'
						}}
					>
						{tool.strings('已选{{num}}件', {
							num: getShopList()?.skuList?.length ?? 0
						})}
					</Text>
					<XStack
						style={{
							paddingTop: fitSize(12),
							paddingRight: fitSize(16),
							paddingBottom: fitSize(11),
							paddingLeft: fitSize(16),
							borderRadius: fitSize(24),
							backgroundColor: '#1C2026'
						}}
						onPress={confirmSelectSku}
					>
						<Text
							style={{
								fontSize: fitSize(14),
								fontWeight: 'bold',
								color: '#fff'
							}}
						>
							{tool.strings('确认选择')}
						</Text>
					</XStack>
				</XStack>
			)}

			{!isFromOnHandList && (
				<XStack
					padding={px2dp(12)}
					backgroundColor={'#fff'}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					borderTopWidth={2}
					borderTopColor={'#F0F2F5'}
				>
					<XStack flexDirection="column">
						<Text color={'#1c2026'} fontWeight={'500'}>
							{tool.strings('{{num}}件', {
								num: allAccount
							})}
						</Text>
						<Text color={'#1c2026'} fontWeight={'500'}>
							{tool.strings('已选')}
						</Text>
					</XStack>
					{bottomBtnView()}
				</XStack>
			)}
		</>
	);
};
export default memo(ConfirmBtns);
