/*
 * @Author: shiguang
 * @Date: 2023-04-25 10:46:45
 * @LastEditors: yusha
 * @LastEditTime: 2024-05-16 18:18:33
 * @Description: App
 */
import React, {
	Suspense,
	createContext,
	useContext,
	useEffect,
	useState
} from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { ConfigProvider, FloatingBubble } from 'antd-mobile';
import { TamaguiProvider, Theme } from '@snifftest/tamagui';
import {
	NavigationType,
	RouterProvider,
	RouterProviderProps,
	createBrowserRouter,
	Location,
	useNavigate,
	useLocation,
	useNavigation
} from 'react-router-dom';
import jaJp from 'antd-mobile/es/locales/ja-JP';
import { useAsyncEffect, useMount } from 'ahooks';
import queryString from 'query-string';
import {
	initThinkingData,
	taSetUser,
	thinkingdata
} from '@/config/buryingPoint';
import routers from './config/router/index';
import { Lang, LocalContext, getQuerylang } from './i18n/i18n';
import KeepAlive from './component/KeepAlive';
import '@/styles/theme/index.scss';
import './App.css';
import BottomnavigationHomepageDefault from './common/icons/BottomnavigationHomepageDefault';
import { checkLogin, loginEnv } from './config/request/login';
import { request } from './config/request';
import { Order, User } from './Atoms';
import { UserDetailType } from './Atoms/User';
import { EnumStationListValue, mapStationList } from './common/Maps';
import { EnumStationList, Enum_SellStatus } from './common/Enums';
import { ROUTER_INDEX_NAME } from './config/router/goods';
import { commonGoBack } from './common/Layout/CustomNavbar';
import { isMobile, toPCTheCkb } from './utils';
import { jsBridge } from './utils/jsBridge';
import { useJsBridgeLogin } from './utils/hooks/JsBridge/useJsBridgeLogin';
import { useJsBridgeLogout } from './utils/hooks/JsBridge/useJsBridgeLogout';
import { useJsBridgeJumpUrl } from './utils/hooks/JsBridge/useJsBridgeJumpUrl';
import { useAppMount } from './utils/hooks/useAppMount';
import { useJsBridgeGoBack } from './utils/hooks/JsBridge/useJsBridgeGoBack';
import { useInitChannel } from './utils/hooks/useInitChannel';
import { useInitThinkingData } from './utils/hooks/useInitThinkingData';
import tamaguiConfig from './tamagui.config';
import '@tamagui/core/reset.css';
import { useSetChannel } from './utils/hooks/useSetChannel';
import {
	ON_HAND_GOODS_SELECT,
	ON_HAND_GOODS_SELECT_SESSION_KEY
} from './const/config';

global._sniff_app_ = {
	/** 是否是嵌入app中 */
	isApp: false,
	/** 是否app审核版本 */
	isAppAuditVersion: false
};
/** 路由白名单 */
const curRouteWhitelist = [
	'/goods/list',
	'/goods/collection',
	'/goods/collectionGoodsList',
	'/goods/hot',
	'/goods/priceCollectionList',
	// '/goods/detail',
	'/order/shopcart'
	// '/order/create',
	// '/goods/home'
];
/** 路由白名单 */
const preRouteWhitelist = ['/order/create'];
/** 需要清空存储选择的地址和下单人信息的页面 */
const isNotNeedClearAddressAndPersonRouter = [
	'/order/create',
	'/user/address/edit',
	'/user/person/edit'
];
/** 不需要清空选择顺手捎商品的存储 */
const isNotNeedClearSelectHanderRouter = [
	'/order/create',
	'/user/address/edit',
	'/user/person/edit',
	'/goods/onHandList'
];

interface RouterHistoryItem extends Location {
	navigationType: NavigationType;
}
export const RouterHistoryContext = createContext<RouterHistoryItem[]>([]);

interface PageContentProps {
	children?: React.ReactNode;
}

const isLocal = window.location.hostname === 'localhost';

const { channel, forcePC, fromSniffApp } = queryString.parse(
	window.location.search
);
export const platRedirect = async () => {
	if (!isMobile() && !forcePC) {
		window.location.href = toPCTheCkb(channel);
	}
};

/** 每个页面渲染的包裹组件 */
const PageContent = (props: PageContentProps) => {
	const { children } = props;
	const navigate = useNavigate();
	const location = useLocation();
	/** 获取页面路径 */
	const { pathname } = location;
	const routerHistory = useContext(RouterHistoryContext);
	// 初始化购物车数据
	const setCartOriginalData = useSetAtom(Order.atomCartOriginalData);
	const setIsLogin = useSetAtom(User.isLogin);
	const [currentUserDetail, setUserinfo] = useAtom(User.userDetail);
	/** 首页是否启用 */
	const setEnableIndex = useSetAtom(User.enableIndex);
	const [curPage, prepage] = routerHistory;

	/** 获取购物车数据 */
	const getCartList = async () => {
		const token = await checkLogin();
		// 是否需要重新请求下购物车列表
		const isNeedRerequestCartList =
			curRouteWhitelist.includes(curPage?.pathname) ||
			preRouteWhitelist.includes(prepage?.pathname) ||
			curRouteWhitelist.includes(pathname);

		// 登录之后路由变化则请求购物车列表
		if (token) {
			// 登录之后清除信息
			sessionStorage.removeItem('isFromOtherDomain');
			const userRes =
				await request.customer.getCustomerDetails.getCustomerDetails();
			if (userRes.success) {
				const currentUserStationCode = (userRes.data?.stationCode ||
					EnumStationList.JAPAN) as EnumStationListValue;
				setIsLogin(true);
				const indexShow = mapStationList.get(
					currentUserStationCode
				) as boolean;
				if (!indexShow) {
					setEnableIndex(indexShow);
					navigate(ROUTER_INDEX_NAME, { replace: true });
					return;
				}
				setEnableIndex(indexShow);
				if (currentUserDetail.customerId !== userRes.data?.customerId) {
					setUserinfo(userRes.data as UserDetailType);
				}
			}
			// 是否需要重新请求购物车列表
			if (isNeedRerequestCartList) {
				const resData = await request.easyOrder.cart.list();
				const { data = [] } = resData || {};
				const cartData = {
					cartNum: data.length ?? 0,
					cartList: data
				};
				setCartOriginalData(cartData);
			}
		} else {
			setIsLogin(false);
		}
	};

	useAsyncEffect(async () => {
		await getCartList();
		// 除了这三个页面，其余的需要清空保存的信息
		if (!isNotNeedClearAddressAndPersonRouter.includes(pathname)) {
			window.sessionStorage.removeItem('selectedAddressId');
			window.sessionStorage.removeItem('selectedPersonInfoId');
		}
		// 除了这几个页面全部要清空
		if (!isNotNeedClearSelectHanderRouter.includes(pathname)) {
			window.sessionStorage.removeItem(ON_HAND_GOODS_SELECT_SESSION_KEY);
		}
	}, [pathname]);

	useJsBridgeGoBack(curPage, navigate);

	return (
		<KeepAlive>
			<Suspense fallback={<div />}>
				<>
					{children}
					{isLocal && (
						<FloatingBubble
							axis="xy"
							magnetic="x"
							style={{
								'--initial-position-top': '2rem',
								'--initial-position-right': '24px',
								'--edge-distance': '24px'
							}}
							onClick={() => {
								navigate('/goods/home', {
									replace: true
								});
							}}
						>
							{/* <AppstoreOutline fontSize={32} /> */}
							<BottomnavigationHomepageDefault fontSize={22} />
						</FloatingBubble>
					)}
				</>
			</Suspense>
		</KeepAlive>
	);
};

const createRouter = (): RouterProviderProps['router'] => {
	const newrouters = [...routers];
	return createBrowserRouter(
		newrouters.map((item) => {
			const Comp = item.component;
			return {
				element: <PageContent>{Comp && <Comp />}</PageContent>,
				path: item.path,
				errorElement: <div>router err</div>
			};
		})
	);
};

export const router = createRouter();

router.subscribe((data) => {
	const { location } = data;
	if (!(channel || fromSniffApp)) return;
	// 因为在创建订单页面选择顺手捎商品之后会将fromSniffApp搞上去，所以现在要区分下
	const searchString = location.search;
	const searchObj = queryString.parse(searchString);
	// 是否选中了顺手捎商品
	const isSelectHandingGoods =
		Boolean(searchObj[ON_HAND_GOODS_SELECT] ?? false) ?? false;
	// app中没有下面的字段
	if (
		data.historyAction === 'REPLACE' &&
		fromSniffApp &&
		!isSelectHandingGoods
	) {
		// 渠道，若是该字段存在，则路由上一直带着
		const _search = queryString.stringify({
			...queryString.parse(location.search),
			channel,
			fromSniffApp
		});
		window.history.replaceState(
			null,
			'',
			location.pathname + '?' + _search
		);
		return;
	}
	// 渠道，若是该字段存在，则路由上一直带着
	if (channel) {
		const _search = queryString.stringify({
			...queryString.parse(location.search),
			channel
		});
		setTimeout(() => {
			window.history.replaceState(
				null,
				'',
				location.pathname + '?' + _search
			);
		}, 1);
	}
});

function App() {
	/** 是否显示app的页面，默认为false */
	const [showAppPage] = useAppMount();
	/** 强更的key，重新render */
	const forceUpdateApp = useAtomValue(User.foreceUpdateApp);
	/** 获取历史访问的路由 */
	const [routerHistoryContextValue, setRouterHistoryContextValue] = useState<
		RouterHistoryItem[]
	>([]);

	/** app初始化。监听app内是否登陆。如果登陆，那么会向h5发送消息，并让h111115登陆 */
	useJsBridgeLogin();
	/** app初始化。监听app内是退出。如果退出，那么会向h5发送消息，并让h5退出 */
	useJsBridgeLogout();
	/** app向h5跳转页面。触发h5的路由跳转 */
	useJsBridgeJumpUrl();

	/** 初始化客服 */
	useInitChannel();

	/** 初始化数数科技 */
	useInitThinkingData();

	useSetChannel();

	router.subscribe((routerData) => {
		setRouterHistoryContextValue((preState) => {
			const [preRouterData] = preState;
			const isEqual = routerData.location.key === preRouterData?.key;
			if (isEqual) return preState;
			return [
				{
					...routerData.location,
					navigationType: routerData.historyAction
				},
				...preState
			];
		});
	});

	if (!showAppPage) return null;

	return (
		<div id="J_B2B" className="App" key={forceUpdateApp}>
			<RouterHistoryContext.Provider value={routerHistoryContextValue}>
				<LocalContext.Provider value={getQuerylang()}>
					<ConfigProvider locale={jaJp}>
						<TamaguiProvider config={tamaguiConfig}>
							<RouterProvider
								router={router}
								fallbackElement={<div>fallbackElement</div>}
							/>
						</TamaguiProvider>
					</ConfigProvider>
				</LocalContext.Provider>
			</RouterHistoryContext.Provider>
		</div>
	);
}

export default App;
