/*
 * @Author: yusha
 * @Date: 2024-01-08 15:30:09
 * @LastEditors: yusha
 * @LastEditTime: 2024-04-10 17:48:05
 * @Description: 商品详情
 */

import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useMount, useRequest } from 'ahooks';
import { ScrollView, TamaguiElement, Text, YStack } from '@snifftest/tamagui';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import * as SDK from '@snifftest/sdk/lib/rn';
import { View } from 'react-native';
import { PrimitiveAtom, useAtomValue } from 'jotai';
import { ProductDetailRespDTO } from '@/outs/service/easyGoods';
import { request } from '@/config/request/interceptors';
import { TaEvent } from '@/outs/utils/TDAnalytics';
import { tool } from '@/outs/utils/tool';
import { PRODUCT_STATUS_TYPE } from '@/outs/const';
import BackToTop from '@/outs/components/BackToTop';
import { GlobalLoading } from '@/outs/utils/tool/loading/globalLoading';
import GoodsDetailSwipper from '@/component/GoodsDetailSwiper';
import { CustomerDetailRespDTO } from '@/outs/service/customer';
import { CouponNotice } from '@/outs/components/CouponNotice';
import { usePageFocuEffect } from '@/outs/hooks/common/usePageFocuEffect';
import { CartAddSkuReq } from '@/outs/service/easyOrder';
import CustomModal from '../GoodsDetail/component/CustomModal';
import Header from './modules/Header';
import Banner from './modules/Banner';
import Footer from './modules/Footer';
import ActiveHeader from './modules/ActiveHeader';
import DetailContent from './modules/DetailContent';
import { ContextPreView, SpuProps } from './context';
import SelectSkuPopup from './component/SelectSkuPopup';
import DetailEmptyPage from './modules/DetailEmptyPage';
import ModalSoldOut from './component/ModalSoldOut';
// 商品兜底图
export const BACK_UP_IMG =
	'https://static-s.theckb.com/BusinessMarket/Easy/H5/empty_img.png';
const LISTEN_VIEW_ID = 'goods-detail';
export interface GoodDetailPageProps {
	atomUserDetail: PrimitiveAtom<
		| (CustomerDetailRespDTO & {
				membership: {
					templateLevel: number;
				};
		  })
		| undefined
	> | null;
	/** 联系客服 */
	showService?: () => void;
	/** h5copy方法 */
	handleCopy?: (val: string) => void;
	toast?: any;
	goToLogin?: () => void;
	ShareH5?: React.FC<{ product: any }> | null;
	OpenApp?: React.FC<{ productCode: string }> | null;
	atomCartCount: PrimitiveAtom<number> | null;
	/** 用于商品列表进入商详的返回 */
	backClick?: (val?: CartAddSkuReq[]) => void;
	/** app所需要的环境 */
	env?: string;
	h5GoHomeJump?: () => void;
	/** 来自于哪些页面，目前只有顺手捎页面来才需要做处理 */
	fromPage?: 'onHandList';
}

const GoodDetail = (props: GoodDetailPageProps) => {
	const { OpenApp, env, h5GoHomeJump } = props;
	const navigation = SDK.useNavigate() as any;
	const router = SDK.useParams();
	const { productCode } = router || {};
	const insets = useSafeAreaInsets();
	const scrollViewRef = useRef(null);
	const useDetail = useAtomValue(props.atomUserDetail!);
	const isLogin = !!useDetail?.customerId;
	/** 选中的sku信息（只有从顺手捎活动列表进来才需要） */
	const [selectedSkuInfo, setSelectedSkuInfo] = useState<CartAddSkuReq[]>([]);
	// 是否全部售罄
	const [allSoldout, setAllSoldout] = useState(false);
	/** 一级类目售罄数量状态 */
	const [firstSpuSoldOut, setFirstSpuSoldOut] = useState<SpuProps>({});
	/** 商品详情数据 */
	const [product, setProduct] = useState<ProductDetailRespDTO>({});
	/** 错误信息, 空代表请求成功 */
	const [pageErrMsg, setPageErrMsg] = useState('');
	/** 商品是否收藏 */
	const [favoriteFlag, setFavoriteFlag] = useState(false);
	/** 是否滚动，滚动距离 */
	const [scrollHeight, setScrollHeight] = useState<number>(0);
	/** 判断sku浮层类型，以及判断是否打开该浮层 */
	const [skuPopupType, setSkuPopupType] = useState<
		'both' | 'addCart' | 'buyItNow' | undefined
	>(undefined);
	/** confirm弹窗信息 */
	const [confirmMessage, setConfirmMessage] = useState<string | undefined>(
		undefined
	);
	/** 一元售罄弹窗 */
	const [soldOutModalVisible, setSoldOutModalVisible] = useState(false);

	const { runAsync: apiGetProduct } = useRequest(
		(params) =>
			request.easyGoods.product.detail(params, { isNotShowToast: true }),
		{
			manual: true
		}
	);

	const goToLoginApp = () => {
		navigation.navigate('Login', {
			redirectKey: 'GoodDetail'
		});
	};

	/** 初始化页面 */
	const initPage = useCallback(() => {
		(async () => {
			// if (skuPopupType) {
			// 	return;
			// }
			if (!productCode) {
				return;
			}
			GlobalLoading.showLoading();
			try {
				const res =
					(await apiGetProduct({
						productCode
					})) || {};
				GlobalLoading.dismissLoading();
				// 埋点
				SDK.TA.track({
					event: TaEvent.PRODUCT_DETAIL_PAGEVIEW,
					properties: {
						product_code: productCode,
						product_title_ja: res.data?.productTitle
					}
				});
				setProduct({
					...res?.data,
					skuList: res?.data?.skuList ?? []
				});
				if (res?.data?.status === PRODUCT_STATUS_TYPE.STOP_SALE) {
					setPageErrMsg(tool.strings('该商品已停止售卖'));
					return;
				}
				if (res.data?.status === PRODUCT_STATUS_TYPE.PENDING_SALE) {
					setPageErrMsg(tool.strings('该商品还未开放售卖'));
					return;
				}
				if (res.data?.status === PRODUCT_STATUS_TYPE.DELETE) {
					setPageErrMsg(tool.strings('该商品已删除'));
					return;
				}
				const firstSpu: SpuProps = {};
				if ((res.data?.productPropGroupJaList ?? []).length > 1) {
					res.data?.productPropGroupJaList![0].props?.forEach(
						(item) => {
							firstSpu[item.propKey ?? ''] = 0;
						}
					);
				}
				for (const key in firstSpu) {
					if (Object.prototype.hasOwnProperty.call(firstSpu, key)) {
						res.data?.skuList?.forEach((item) => {
							if (item.productProperties?.includes(key)) {
								firstSpu[key] += item.availableQty || 0;
							}
						});
					}
				}
				setAllSoldout(
					!(res.data?.skuList ?? []).some(
						(item) => (item.availableQty ?? 0) > 0
					)
				);
				setFirstSpuSoldOut(firstSpu);
				// 是否收藏
				setFavoriteFlag(res.data?.favoriteFlag === 1);
				setPageErrMsg('');
			} catch (e: any) {
				GlobalLoading.dismissLoading();
				const { code, msg } = e;
				// 请求失败

				// 商品详情不存在
				if (code === '24000000') {
					setPageErrMsg(tool.strings('该商品已删除'));
					return;
				}
				// apicode： 24100008
				setPageErrMsg(
					msg ||
						tool.strings(
							'很遗憾，这个商品不符合日本代购条件，可以看看其他商品'
						)
				);
			}
		})();
	}, [apiGetProduct, productCode]);

	usePageFocuEffect(initPage);

	useMount(() => {
		// 只有一日元活动售罄才有点击事件,页面初始化也弹
		if (product.oneBuyFlag === 1 && allSoldout) {
			setSoldOutModalVisible(true);
		}
	});

	const getImgSkuPrewCallBack = () => {
		setSkuPopupType(skuPopupType);
	};
	const getHeight = () => {
		return SDK.fitSize(SDK.getDeviceSize().height - 38);
	};
	// insets.top
	return (
		<YStack
			flex={1}
			paddingTop={scrollHeight > 0 || pageErrMsg ? insets.top : 0}
		>
			{!!pageErrMsg && <DetailEmptyPage pageErrMsg={pageErrMsg} />}
			{!pageErrMsg && (
				<ContextPreView.Provider
					value={{
						product,
						skuPopupType,
						setSkuPopupType,
						allSoldout,
						setSoldOutModalVisible,
						firstSpuSoldOut,
						setConfirmMessage,
						confirmMessage,
						getImgSkuPrewCallBack,
						...props
					}}
				>
					<Header
						scrollHeight={scrollHeight}
						env={env}
						product={product}
						h5GoHomeJump={h5GoHomeJump}
					/>
					{SDK.isH5() ? <OpenApp productCode={productCode} /> : null}
					<View
						style={{
							flex: SDK.isH5() ? undefined : 1,
							height: SDK.isH5() ? getHeight() : undefined
						}}
					>
						<ScrollView
							style={{
								flex: 1
							}}
							scrollEventThrottle={16}
							ref={scrollViewRef}
							showsHorizontalScrollIndicator={false}
							showsVerticalScrollIndicator={false}
							onScroll={(event) => {
								const { contentOffset } = event.nativeEvent;
								// 大于0代表滚动了
								// const value = contentOffset.y > 0 ? true : false;
								setScrollHeight(contentOffset.y);
							}}
						>
							{/* banner部分 */}
							{!SDK.isH5() ? (
								<Banner />
							) : (
								<GoodsDetailSwipper product={product} />
							)}

							{/* 活动头部 */}
							{product.activityInfo && <ActiveHeader />}
							<DetailContent />
						</ScrollView>
					</View>
					{/* 底部 */}
					<Footer
						favoriteFlag={favoriteFlag}
						setFavoriteFlag={setFavoriteFlag}
						selectedSkuInfo={selectedSkuInfo}
					/>

					{/* 选择sku弹窗 */}
					<SelectSkuPopup
						visible={Boolean(skuPopupType)}
						onClose={() => setSkuPopupType(undefined)}
						type={skuPopupType}
						getSelectedSkuInfo={(val) => {
							setSelectedSkuInfo(val);
						}}
					/>

					{/* 一元售罄弹窗 */}
					<ModalSoldOut
						visible={soldOutModalVisible}
						onClose={() => setSoldOutModalVisible(false)}
					/>

					<CustomModal
						showConfirmBtn={false}
						cancelText={tool.strings('确认')}
						visible={Boolean(confirmMessage)}
						onClose={() => setConfirmMessage(undefined)}
					>
						<Text textAlign="center">{confirmMessage}</Text>
					</CustomModal>
					<BackToTop
						visible={scrollHeight > SDK.getDeviceSize().height}
						scrollView={scrollViewRef}
					/>

					<CouponNotice
						open={true}
						isLogin={isLogin}
						goLogin={() => {
							if (SDK.isH5()) {
								props.goToLogin?.();
								return;
							}
							goToLoginApp();
						}}
						burialPoint={{
							position: 'product_new_user_coupon',
							iKnowposition: 'product_register_success'
						}}
						type={'detail'}
					/>
				</ContextPreView.Provider>
			)}
		</YStack>
	);
};
export default memo(GoodDetail);
