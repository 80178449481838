import { memo, useState, useRef, useMemo } from 'react';
import { Grid, List, InfiniteScroll, Toast, Popup } from 'antd-mobile';
import {
	List as VirtualizedList,
	AutoSizer,
	WindowScroller
} from 'react-virtualized';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';
import { useAsyncEffect, useMount, useRequest } from 'ahooks';
import { flushSync } from 'react-dom';
import queryString from 'query-string';
import CustomNavbar from '@/common/Layout/CustomNavbar';
import ShoppingCartLarge from '@/common/icons/ShoppingCartLarge';
import ScrollPage from '@/common/ScrollPage';
import BackToTop from '@/component/BackToTop';
import { Order, User } from '@/Atoms';
import ShoppItem, { ShoppInfoProps } from '@/component/ShoppItem';
import { request } from '@/config/request';
import { ActivityAreaMoreQuery, ProductVO } from '@/service/easyMarket';
import {
	AddProductFavoriteReqDTO,
	ProductKeywordRespDTO
} from '@/service/easyGoods';
import { changeImageCdn, groupArray } from '@/utils';
import { useEvent } from '@/utils/hooks/useEvent';
import Loading from '@/component/Loading';
import { EnumProductPurchaseType } from '@/common/Enums';
import Detail from '@/container/goods/NewDetail';
import Filter from './modules/Filter';
import { LEFT_SORT_LIST, VirtualizedListProps } from './config/index';
import styles from './index.module.scss';
import EmptyPage from './modules/EmptyPage';
import HeaderCoupon from './modules/HeaderCoupon';
const SCROLL_TO_VIEW_ID = 'goods-index-scroll-view';
const LISTEN_VIEW_ID = 'goods-list';
const GoodList = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [cartOriginalData] = useAtom(Order.cartOriginalData);
	const [rootFontSize, setRootFontSize] = useState<number>(0);
	const [total, setTotal] = useState(0);
	const [detailVisible, setDetailVisible] = useState(
		Boolean(searchParams.get('productCode'))
	);
	const isLogin = useAtomValue(User.isLogin);
	const navigate = useNavigate();
	const [couponId] = useState(() => searchParams.get('couponId'));
	const { runAsync: apiGetGoodsList, loading: apiGetGoodsListLoading } =
		useRequest(request.market.coupon.productSearchPage, {
			manual: true
		});
	/** 收藏取消 */
	const { runAsync: postCancelCollect } = useRequest(
		request.easyGoods.favoriteProduct.cancel,
		{
			manual: true
		}
	);
	/** 收藏 */
	const { runAsync: postAddCollect } = useRequest(
		request.easyGoods.favoriteProduct.postFavoriteProduct,
		{
			manual: true
		}
	);
	const [leftFilterShow, setLeftFilterShow] = useState(false);
	const [data, setData] = useState<ShoppInfoProps[]>([]);
	const [hasMore, setHasMore] = useState(false);
	const scrollRef = useRef<HTMLDivElement>(null);
	const [pageNum, setPageNum] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	// 收藏商品ID
	const [collectCode, setCollectCode] = useState<string>('');
	const httpGetListRef = useRef(false);
	/** 是否是第一页请求完毕 */
	const httpFirstRequstRef = useRef(false);

	/** 左侧综合排序 */
	const [comprehensiveSort, setComprehensiveSort] = useState(
		LEFT_SORT_LIST[0].id as number
	);

	/** 左侧排序文字 */
	const leftFilterText = useMemo(() => {
		const leftFilterText = LEFT_SORT_LIST?.find(
			(sortItem) => sortItem.id === comprehensiveSort
		)!['text']();
		return leftFilterText;
	}, [comprehensiveSort]);
	/** 是否展示模态框 */
	const showMask = leftFilterShow;
	const setLeftShow = () => {
		setLeftFilterShow(!leftFilterShow);
	};
	const getRootFontSize = () => {
		const rootPX = Number(
			document.documentElement.style.fontSize.split('px')[0]
		);
		setRootFontSize(rootPX);
	};
	useMount(() => {
		getRootFontSize();
	});
	useAsyncEffect(async () => {
		httpGetListRef.current = true;
		const params = {
			pageNum,
			pageSize,
			couponId: couponId || '',
			descendOrder: comprehensiveSort === 3,
			/** 排序字段:0 综合 1 价格(默认) 2 时间 */
			sortType: comprehensiveSort === 1 ? 0 : 1
		};
		const res = await apiGetGoodsList({
			...params
		});
		if (res.success) {
			httpGetListRef.current = false;
			let newList: ShoppInfoProps[] = [];
			if ('countId' in (res?.data ?? {})) {
				if (res.data?.records) {
					res.data.records = (res.data?.records ?? []).filter(
						(item) => item.status === 1 || item.status === 2
					);
				}
				((res.data?.records ?? []) as ProductVO[]).forEach((item) => {
					newList.push({
						...item,
						/** 商品最低价 */
						productSellPriceJa: item.productLowestPrice,
						/** 商品标题 */
						title: item.productTitle,
						/** 商品id	 */
						productCode: item.productCode,
						/** 商品主图 */
						productMainImg: item.productMainImg,
						/** 商品售罄状态 */
						status: item.availableQuantity,
						/** 是否展示及时返金 */
						showGoBackPrice: false
					});
				});
			} else {
				const resonseList = (
					(res?.data?.records ??
						([] as ProductKeywordRespDTO[])) as any
				).filter((item: ProductKeywordRespDTO) => {
					// 如果是代采商品，直接返回
					if (
						item.productPurchaseType ===
						EnumProductPurchaseType.PROXY
					) {
						return true;
					}
					return item.status === 1 || item.status === 2;
				});
				(resonseList as ProductKeywordRespDTO[]).forEach((item) => {
					newList.push({
						...item,
						/** 商品最低价 */
						productSellPriceJa: item.productSellPriceJa,
						sellPrice: item.sellPrice,
						/** 商品标题 */
						title: item.productTitle,
						/** 商品id	 */
						productCode: item.productCode,
						/** 商品主图 */
						productMainImg: item.productMainImg,
						/** 商品售罄状态 */
						status: item.availableQuantity,
						/** 是否展示及时返金 */
						showGoBackPrice: false
					});
				});
			}
			newList = newList.map((item) => {
				return {
					...item
				};
			});
			if (pageNum === 1) {
				httpFirstRequstRef.current = true;
				setData(newList);
			} else {
				httpFirstRequstRef.current = false;
				setData((data) => [...data, ...newList]);
			}
			setTotal(res.data?.total ?? 0);
			setHasMore(newList.length > 0);
		}
	}, [pageNum, pageSize, apiGetGoodsList, comprehensiveSort]);
	const loadMore = async () => {
		if (httpGetListRef.current) return;
		setPageNum((num) => num + 1);
	};
	const filterList = useMemo(() => {
		return groupArray(data, 2);
	}, [data]);
	const handleShoppItem = useEvent(async ({ productCode }) => {
		// TODO 如果是app内的h5页面。并且版本是1.1.2 那么走app的商详
		const currentPageParams =
			queryString.parse(window.location.search) ?? {};
		setSearchParams({
			...searchParams,
			...currentPageParams,
			productCode
		});
		setDetailVisible(true);
	});
	// 选择收藏商品
	const changeCollectItem = (code: string | undefined) => {
		if (code === collectCode) {
			setCollectCode('');
		} else {
			setCollectCode(code || '');
		}
	};

	/** 收藏切换 */
	const changeCollect = async (code?: string) => {
		const collectItem = data.find(
			(shoppItem) => shoppItem.productCode === code
		);
		if (!collectItem) return;
		const favoriteFlagBoolean = Boolean(collectItem['favoriteFlag']);
		// 取消收藏
		if (favoriteFlagBoolean) {
			const res = await postCancelCollect({
				productCode: code
			});
			if (!res.success) return;
			const newList = data.map((shoppItem) => {
				if (shoppItem.productCode !== code) return shoppItem;
				return {
					...shoppItem,
					favoriteFlag: 0
				};
			});
			flushSync(() => {
				setData(newList);
			});
			Toast.show({
				content: window._$m.t('取消收藏成功')
			});
			return;
		}
		// 收藏
		const { productCode, productMainImg, productSellPriceJa, title } =
			collectItem;
		const payload: AddProductFavoriteReqDTO = {
			productCode,
			productMainImg,
			productSellPrice: productSellPriceJa,
			productTitle: title
		};
		const res = await postAddCollect({
			...payload,
			favoriteProductType: 0
		});
		if (!res.success) return;
		const newList = data.map((shoppItem) => {
			if (shoppItem.productCode !== code) return shoppItem;
			return {
				...shoppItem,
				favoriteFlag: 1,
				openFavoriteFlagModal: true
			};
		});
		flushSync(() => {
			setData(newList);
		});
		Toast.show({
			content: window._$m.t('收藏成功')
		});
	};
	const rowRenderer = ({ index, key, style }: VirtualizedListProps) => {
		const item = filterList[index];
		if (!item) return;
		return (
			<div key={key} className={styles['shopp-view']} style={style}>
				<Grid columns={2} gap={rootFontSize * 0.08}>
					{item.map((shoppItem) => (
						<Grid.Item key={shoppItem.productCode}>
							<ShoppItem
								favoriteFlag={shoppItem.favoriteFlag}
								activityFlag={shoppItem.activityFlag}
								onCollect={changeCollect}
								onClick={handleShoppItem}
								onSelectCollect={changeCollectItem}
								sellPrice={shoppItem.sellPrice}
								maskStatus={
									collectCode === shoppItem.productCode
								}
								productSellPriceJa={
									shoppItem.activityPriceJa ||
									shoppItem.activitySellPriceJpy ||
									shoppItem.productLowestPrice ||
									0
								}
								productPurchaseType={
									shoppItem.productPurchaseType
								}
								title={shoppItem.title}
								productCode={shoppItem.productCode}
								productMainImg={shoppItem.productMainImg}
								status={shoppItem.status}
								showGoBackPrice={shoppItem.showGoBackPrice}
							/>
						</Grid.Item>
					))}
				</Grid>
			</div>
		);
	};

	/** 如果展示模态框 不让content滚动 */
	const dynamicClassName = 'no-scroll';
	const fullClassName = `${styles.goods} ${showMask ? styles[dynamicClassName] : ''}`;
	const goCartList = () => {
		navigate('/order/shopcart?fromPage=goodslist');
	};
	const changeLeftPriceFilter = (id: number) => {
		setPageNum(1);
		setComprehensiveSort(id);
		setLeftFilterShow(false);
	};
	const getHeight = (): string => {
		if (isLogin) {
			return `1.68rem`;
		}
		return '1.68rem';
	};
	/** 计算layout-content高度 */
	const getLayoutContentHeight = () => {
		if (isLogin) {
			return `calc(100% - 1.68rem)`;
		}
		return 'calc(100% - 1.49rem)';
	};
	return (
		<ScrollPage
			id={LISTEN_VIEW_ID}
			title={
				<>
					<CustomNavbar
						title={window._$m.t('商品列表')}
						navBarRight={
							isLogin ? (
								<div
									className={styles['header-right-cart']}
									onClick={goCartList}
								>
									<div className={styles['cart-num-area']}>
										<ShoppingCartLarge
											width={'0.16rem'}
											height={'0.16rem'}
										/>

										{cartOriginalData.cartNum > 0 ? (
											<div className={styles['cart-num']}>
												{cartOriginalData.cartNum > 99
													? '99+'
													: cartOriginalData.cartNum}
											</div>
										) : null}
									</div>
								</div>
							) : null
						}
						isShowBackIcon={true}
						showBackHome={true}
					/>

					<div
						className={`${styles.header} ${showMask ? styles['border-radio'] : ''}`}
					>
						<HeaderCoupon />
						<Filter
							isLogin={isLogin}
							leftFilterShow={leftFilterShow}
							setLeftShow={setLeftShow}
							leftFilterText={leftFilterText}
							changeLeftPriceFilter={changeLeftPriceFilter}
							comprehensiveSort={comprehensiveSort}
						/>
					</div>
					{showMask && (
						<div
							className={styles.mask}
							onClick={() => {
								setLeftFilterShow(false);
							}}
						/>
					)}
				</>
			}
		>
			<BackToTop
				scrollViewIdName={SCROLL_TO_VIEW_ID}
				listenScrollId={LISTEN_VIEW_ID}
			/>

			<div
				className={`layout-content `}
				style={{
					width: '100%',
					height: getLayoutContentHeight(),
					marginTop: getHeight()
				}}
			>
				{apiGetGoodsListLoading && <Loading />}
				<div className={fullClassName}>
					<div className={styles['shopp-list']}>
						<div
							id={SCROLL_TO_VIEW_ID}
							style={{
								height: '100%'
							}}
						>
							{httpFirstRequstRef.current && data.length === 0 ? (
								// 只有词搜接口为空才展示该模块
								<>
									{
										<EmptyPage
											goodsListLength={data.length ?? 0}
											rootFontSize={rootFontSize}
										/>
									}
								</>
							) : (
								<div
									ref={scrollRef}
									className={styles['cate-shopp-list']}
									style={
										{
											'--adm-color-background':
												'transparent'
										} as any
									}
								>
									<WindowScroller
										scrollElement={
											scrollRef.current || undefined
										}
									>
										{({ height }) => (
											<List>
												<AutoSizer disableHeight>
													{({ width }) => (
														<VirtualizedList
															autoHeight
															rowCount={
																filterList.length
															}
															rowRenderer={
																rowRenderer
															}
															width={width}
															height={height}
															rowHeight={
																rootFontSize *
																2.58
															}
															overscanRowCount={
																20
															}
														/>
													)}
												</AutoSizer>
											</List>
										)}
									</WindowScroller>
									<InfiniteScroll
										loadMore={loadMore}
										hasMore={hasMore}
										// eslint-disable-next-line react/no-children-prop
										children={() => {
											if (!data?.length) {
												return null;
											}
											if (total === data.length) {
												return '';
											}
											return (
												<div>
													{window._$m.t(
														'~没有啦，我也是有底线的~'
													)}
												</div>
											);
										}}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{detailVisible && (
				<Popup
					destroyOnClose={true}
					visible={detailVisible}
					mask={false}
					style={{
						'--z-index': '10'
					}}
					position="right"
					bodyStyle={{
						width: '100vw'
					}}
					getContainer={() => document.getElementById('root')!}
				>
					<Detail
						backClick={() => {
							setDetailVisible(false);
							window.history.back();
						}}
					/>
				</Popup>
			)}
		</ScrollPage>
	);
};
export default memo(GoodList);
