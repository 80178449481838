/*
 * @Author: yusha
 * @Date: 2024-02-28 11:52:07
 * @LastEditors: yusha
 * @LastEditTime: 2024-03-19 21:55:27
 * @Description: 优惠券活动
 */

import {
	Button,
	Image,
	ScrollView,
	Text,
	XStack,
	YStack
} from '@snifftest/tamagui';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import * as SDK from '@snifftest/sdk/lib/rn';
import { ImageBackground, Modal } from 'react-native';
import { useAsyncEffect, useRequest, useToggle } from 'ahooks';
import { useAtomValue } from 'jotai';
import Coupon from '@snifftest/coupon';
import { useFocusEffect } from '@react-navigation/native';
import { tool } from '@/outs/utils/tool';
import { request } from '@/config/request/interceptors';
import { CouponRespDTO } from '@/outs/service/easyMarket';
import { usePageFocuEffect } from '@/outs/hooks/common/usePageFocuEffect';
import { ContextPreView } from '../../../context';

const fitSize = SDK.fitSize;

const CouponActivity = () => {
	const [state, { toggle }] = useToggle();
	const [couponList, setCouponList] = useState<CouponRespDTO[]>([]);
	const { product = {}, atomUserDetail } = useContext(ContextPreView);
	/** 用户信息 */
	const userDetail = useAtomValue(atomUserDetail!);
	const isLogin = userDetail?.customerId;
	const productCode = product.productCode;
	/** 初始化优惠券列表 */
	const initCouponList = useCallback(() => {
		(async () => {
			if (!isLogin || !product.productCode) {
				setCouponList([]);
				return;
			}

			const cateCode = product.cateCode;
			const customerId = userDetail?.customerId;
			const params = {
				cateCode,
				customerId,
				pageNum: 1,
				pageSize: 200,
				productCode
			};
			const data = await request.easyMarket.coupon.couponProductPage(
				params,
				{
					useMock: false
				}
			);
			setCouponList(data.data ?? []);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogin, product.productCode]);
	usePageFocuEffect(initCouponList);

	const getHeight = () => {
		if (SDK.isH5()) {
			return 'auto';
		}
		if (couponList.length <= 0) {
			return '70%';
		}
		return '100%';
	};

	return (
		<>
			{!!couponList?.length && (
				<ImageBackground
					style={{
						height: fitSize(40),
						marginTop: fitSize(16)

						// justifyContent: 'space-between',
						// display: 'flex'
					}}
					source={{
						uri: 'https://static-jp.theckb.com/static-asset/easy-app/goodsdetailCouponlist.png'
					}}
				>
					<XStack
						onPress={() => toggle()}
						// marginTop={fitSize(16)}
						// height={fitSize(40)}
						width={'auto'}
						// backgroundColor={'#FAFAFA'}
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
						paddingLeft={fitSize(8)}
						paddingRight={fitSize(8)}
					>
						<XStack
							overflow="hidden"
							flex={1}
							height={fitSize(40)}
							alignItems="center"
							flexWrap="wrap"
							paddingBottom={SDK.isH5() ? fitSize(8) : 0}
							paddingTop={SDK.isH5() ? fitSize(8) : fitSize(4)}
						>
							{couponList.map((item, index) => {
								return (
									<XStack
										overflow="hidden"
										key={`${item.couponId}` + `${index}`}
										marginRight={fitSize(6)}
										zIndex={5}
										marginBottom={fitSize(10)}
									>
										<XStack
											position="relative"
											height={fitSize(24)}
											width={'auto'}
											borderWidth={fitSize(1)}
											borderColor={'#FF5010'}
											alignItems="center"
											justifyContent="center"
											borderRadius={fitSize(2)}
										>
											<YStack
												position="absolute"
												width={fitSize(10)}
												height={fitSize(10)}
												borderRadius={8}
												top={fitSize(7)}
												left={fitSize(0)}
												transform={[{ translateX: -5 }]}
												borderWidth={fitSize(1)}
												borderColor={'#FF5010'}
												backgroundColor={'#F5F5F5'}
											/>

											<Text
												paddingLeft={fitSize(8)}
												paddingRight={fitSize(8)}
												color={'#FF5010'}
												fontSize={fitSize(10)}
												fontWeight={'500'}
												lineHeight={fitSize(16)}
											>
												{item.title}
											</Text>
											<YStack
												position="absolute"
												width={fitSize(10)}
												height={fitSize(10)}
												borderRadius={8}
												top={fitSize(7)}
												right={fitSize(0)}
												transform={[{ translateX: 5 }]}
												borderWidth={fitSize(1)}
												borderColor={'#FF5010'}
												backgroundColor={'#F5F5F5'}
											/>
										</XStack>
									</XStack>
								);
							})}
						</XStack>

						<XStack
							// justifyContent="flex-end"
							marginLeft="auto"
							width={'auto'}
						>
							<XStack alignItems="center">
								<Text
									color={'#000'}
									fontSize={fitSize(10)}
									fontWeight={'500'}
								>
									{tool.strings('共计')}
								</Text>
								<Text
									color={'#FF5010'}
									fontSize={fitSize(10)}
									fontWeight={'500'}
								>
									{couponList.length}
								</Text>
								<Text
									color={'#000'}
									fontSize={fitSize(10)}
									fontWeight={'500'}
								>
									{tool.strings('张')}
								</Text>
							</XStack>
							<Image
								source={{
									uri: 'https://static-jp.theckb.com/static-asset/easy-app/rightarrow.png'
								}}
								width={fitSize(16)}
								height={fitSize(16)}
							/>
						</XStack>
					</XStack>
				</ImageBackground>
			)}

			<Modal
				transparent={true}
				visible={state}
				presentationStyle={'overFullScreen'}
				onRequestClose={() => {
					toggle();
				}}
				statusBarTranslucent={true}
			>
				<YStack
					onPress={() => {
						toggle();
					}}
					style={{
						backgroundColor: 'rgba(0,0,0,0.4)',
						position: 'absolute',
						left: 0,
						top: 0,
						bottom: 0,
						right: 0,
						zIndex: 2
					}}
				/>

				<YStack
					position="absolute"
					zIndex={3}
					maxHeight={'51%'}
					height={getHeight()}
					width={'100%'}
					backgroundColor={'#fff'}
					bottom={0}
					left={0}
					borderTopLeftRadius={fitSize(12)}
					borderTopRightRadius={fitSize(12)}
					paddingTop={fitSize(18)}
					paddingBottom={fitSize(20)}
					paddingLeft={fitSize(20)}
					paddingRight={fitSize(20)}
				>
					<YStack
						style={{
							flex: SDK.isH5() ? undefined : 1,
							maxHeight: SDK.isH5()
								? SDK.getDeviceSize().height * 0.5 - 85
								: undefined
						}}
					>
						<ScrollView
							flex={1}
							showsVerticalScrollIndicator={false}
						>
							{couponList.map((item, index) => {
								return (
									<YStack
										key={index}
										marginBottom={
											index === couponList.length - 1
												? 0
												: fitSize(16)
										}
									>
										<Coupon
											detail={item}
											isCanUse={false}
										/>
									</YStack>
								);
							})}
						</ScrollView>
					</YStack>
					<XStack
						width={'100%'}
						height={fitSize(52)}
						borderWidth={fitSize(2)}
						borderColor={'#CDD2DA'}
						borderRadius={fitSize(24)}
						backgroundColor={'#fff'}
						justifyContent="center"
						alignItems="center"
						paddingTop={fitSize(10)}
						paddingBottom={fitSize(10)}
						marginTop={fitSize(16)}
						onPress={() => toggle()}
					>
						<Text
							color={'#1C2026'}
							fontWeight={'500'}
							lineHeight={fitSize(24)}
							fontSize={fitSize(14)}
						>
							{tool.strings('关闭')}
						</Text>
					</XStack>
				</YStack>
			</Modal>
		</>
	);
};
export default memo(CouponActivity);
