/*
 * @Author: tianzhitong laotianwy@163.com
 * @Date: 2023-11-07 14:20:59
 * @LastEditors: tianzhitong laotianwy@163.com
 * @LastEditTime: 2023-11-07 16:54:05
 * @FilePath: \ckb-easy-h5-fed\src\common\Maps.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { EnumStationList } from './Enums';
type ValueOf<T> = T[keyof T];
export type EnumStationListValue = ValueOf<typeof EnumStationList>;
const mapStationList = new Map<EnumStationListValue, boolean>();
mapStationList.set('JapanStation', true);
mapStationList.set('KoreaStation', false);
export { mapStationList };
