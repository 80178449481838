/*
 * @Author: tianzhitong laotianwy@163.com
 * @Date: 2023-10-18 13:54:06
 * @LastEditors: yusha
 * @LastEditTime: 2023-11-01 14:23:51
 * @FilePath: \ckb-easy-h5-fed\src\config\router\goods.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { lazy } from 'react';

export const ROUTER_SETTLEMENT = [
	{
		path: '/settlement/payPalCardPay',
		title: 'paypal 信用卡支付',
		component: lazy(
			() => import('../../container/settlement/PayPalCardPay')
		)
	},
	{
		path: '/settlement/paySuccess',
		title: '支付成功',
		component: lazy(() => import('../../container/settlement/PaySuccess'))
	},
	{
		path: '/settlement/payFail',
		title: '支付失败',
		component: lazy(() => import('../../container/settlement/PayFail'))
	}
];
