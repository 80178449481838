/*
 * @Author: shiguang
 * @Date: 2023-09-14 10:38:48
 * @LastEditors: shiguang
 * @LastEditTime: 2023-09-14 10:38:50
 * @Description: useLayoutEffect 在非浏览器端不生效
 */
import { useEffect, useLayoutEffect } from 'react';

export const useIsomorphicLayoutEffect =
typeof window !== 'undefined' ? useLayoutEffect : useEffect;