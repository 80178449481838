/*
 * @Author: huajian
 * @Date: 2023-11-16 14:51:54
 * @LastEditors: huajian
 * @LastEditTime: 2024-03-13 17:16:14
 * @Description: styles
 */
import { StyleSheet } from 'react-native';
import * as SDK from '@snifftest/sdk/lib/rn';

export const styles = StyleSheet.create({
	container: {
		position: 'relative'
	},
	textWrap: {
		backgroundColor: 'rgba(255, 80, 16, 1)',
		borderRadius: SDK.fitSize(12),
		paddingHorizontal: SDK.fitSize(4),
		color: '#fff',
		position: 'absolute',
		top: 0,
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: SDK.fitSize(10)
	},
	text: {
		color: '#fff',
		fontSize: SDK.fitSize(10),
		fontFamily: 'PingFang SC'
	},
	topLeft: {
		left: 0
	},

	topRight: {
		right: 0
	},

	temp: {
		opacity: 0,
		position: 'absolute',
		top: 0,
		right: 0
	},

	dotContainer: {
		width: SDK.fitSize(9),
		height: SDK.fitSize(9),
		backgroundColor: 'rgba(255, 80, 16, 1)',
		borderBlockColor: '#fff',
		borderBlockWidth: !SDK.isH5() ? SDK.fitSize(1) : 0,
		borderRadius: SDK.fitSize(4),
		position: 'absolute',
		top: 0,
		right: 0
	}
});
