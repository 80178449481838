/*
 * @Author: yusha
 * @Date: 2024-01-11 21:16:05
 * @LastEditors: huajian
 * @LastEditTime: 2024-03-05 16:56:18
 * @Description: 商品详情空白页面
 */

import { Image, Text, XStack, YStack } from '@snifftest/tamagui';
import { memo } from 'react';
import { CommonActions } from '@react-navigation/native';
import * as SDK from '@snifftest/sdk/lib/rn';
import { tool } from '@/outs/utils/tool';
import { BORDER_WIDTH } from '@/outs/utils/tool/utils';
import { EMPTY_PAGE_IMAGE_BG_WHITE } from '@/outs/const/url/image';
import Header from '../Header';

const fitSize = SDK.fitSize;

// const EMPTY_PAGE_IMAGE =
// 	'https://static-jp.theckb.com/static-asset/easy-app/emptyGroup@3.x.png';
interface DetailEmptyPageProps {
  pageErrMsg?: string;
}
const DetailEmptyPage = (props: DetailEmptyPageProps) => {
  const { pageErrMsg } = props;
  const navigation = (SDK.useNavigate() as any);
  return (
    <YStack height={SDK.getDeviceSize().height}>
			<Header
        style={{ backgroundColor: 'rgb(240, 242, 245)' }}
        isShowTitle={true} />


			<YStack
      // paddingTop={fitSize(185)}
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="column"
      backgroundColor={'#fff'}
      height={'100%'}>

				<YStack
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={fitSize(216)}>

					<Image
            source={{
              uri: EMPTY_PAGE_IMAGE_BG_WHITE
            }}
            width={fitSize(100)}
            height={fitSize(100)} />


					<Text
            fontSize={fitSize(12)}
            fontWeight={'500'}
            color={'#999'}
            lineHeight={fitSize(17)}
            textAlign="center"
            marginTop={fitSize(20)}>

						{pageErrMsg}
					</Text>
					<Text
            width={fitSize(110)}
            height={fitSize(30)}
            lineHeight={fitSize(30)}
            borderRadius={fitSize(15)}
            overflow="hidden"
            fontSize={fitSize(12)}
            fontWeight={'500'}
            color={'#fff'}
            backgroundColor={'#1C2026'}
            textAlign="center"
            marginTop={fitSize(30)}
            style={{
              // ios必须这种
              borderRadius: fitSize(15)
            }}
            onPress={() => {
              if (!SDK.isH5()) {
                // 返回首页，直接重置路由
                // todo
                navigation.dispatch(
                  CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'DirectIndex' }]
                  })
                );
                return;
              }
              navigation('/goods/home', {
                replace: true
              });
            }}>

						{tool.strings('返回首页')}
					</Text>
				</YStack>
			</YStack>
		</YStack>);

};
export default memo(DetailEmptyPage);