/*
 * @Author: shiguang
 * @Date: 2024-01-25 13:41:08
 * @LastEditors: shiguang
 * @LastEditTime: 2024-01-25 13:47:06
 * @Description:
 */
/** 列表为空展示的图片 白色背景 */
export const EMPTY_PAGE_IMAGE_BG_WHITE =
'https://static-jp.theckb.com/static-asset/easy-app/emptyGroup@3.x.png';
/** 列表为空展示的图片 透明背景 */
export const EMPTY_PAGE_IMAGE_BG_TRANSPARENT =
'https://static-jp.theckb.com/static-asset/easy-app/empty_default_transparent_bg.png';