/*
 * @Date: 2023-12-07 22:02:32
 * @LastEditors: lihwang_wf@126.com
 * @LastEditTime: 2023-12-08 13:32:38
 * @FilePath: /ckb-easy-h5-fed/src/common/ScrollPage/Index.tsx
 * @Description:
 */
import { ReactNode, useRef, useState } from 'react';
interface Props {
	children: ReactNode;
	className?: string;
	title?: ReactNode | string;
	ref?: any;
	id?: string;
}
const ScrollPage = (props: Props) => {
	const containerRef = useRef(null);
	/** 是否滚动到顶部 */
	const [scrollOpcity, setScrollOpcity] = useState(0);
	return (
		<div
			id={props.id ?? ''}
			className={`layout-style ${props.className ?? ''}`}
			ref={containerRef}
			onScroll={(e) => {
				const container: any = containerRef.current;
				const scrollTop = container.scrollTop;
				// 顶部
				const opcity = scrollTop / 50 > 1 ? 1 : scrollTop / 50;
				setScrollOpcity(opcity);
			}}
		>
			<div
				style={{
					position: 'fixed',
					left: 0,
					right: 0,
					backgroundColor: `rgba(240, 242, 245,${scrollOpcity})`,
					top: 0,
					paddingTop: '0.12rem',
					zIndex: 200
				}}
			>
				{props.title}
			</div>
			{props.children}
		</div>
	);
};
export default ScrollPage;
