import {
	StatusBar,
	Dimensions,
	Platform,
	Image,
	Text,
	PixelRatio
} from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import layout from './layout';
import { tool } from './index';

export const STATUS_BAR =
	Platform.OS == 'android' ? StatusBar.statusBarHeight || 24 : 24;

// 主题颜色
export const defColor = () => {
	return global.SYSTEMCOLOR || '#008060';
};
// 按钮黑色颜色
export const defColor_black = () => {
	return '#1C2026';
};
// 按钮灰色颜色
export const defColor_default = () => {
	return '#CDD2DA';
};
// 屏幕宽度
export const defWidth = () => {
	return Dimensions.get('window').width;
};
// 屏幕高度
export const defHeight = () => {
	return Dimensions.get('window').height;
};
// 防抖
let isCalled = false;
let timer;
export const preventDoubleClick = (functionTobeCalled, interval = 1000) => {
	if (!isCalled) {
		isCalled = true;
		clearTimeout(timer);
		timer = setTimeout(() => {
			isCalled = false;
		}, interval);
		return functionTobeCalled();
	}
};
// url正则
export const isUrl = (url) =>
	/(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/.test(
		url
	);
// 动态获取图片尺寸
export const setImgUrlsAndHeight = (imgUrls) => {
	if (!imgUrls || imgUrls.length === 0) {
		return;
	}
	const promises = imgUrls.map((img, index) => {
		return new Promise((resolve, reject) => {
			Image.getSize(
				img,
				(width, height) => {
					let imgW = layout.ScaleX(width);
					let imgH = Math.floor(
						(layout.ScreenWidth / width) * height
					);
					let ImgObj = {
						img,
						imgW,
						imgH
					};
					return resolve(ImgObj);
				},
				(error) => {
					let ImgObj = {
						img,
						imgW: 0,
						imgH: 0
					};
					return resolve(ImgObj);
				}
			);
		});
	});
	return promises;
};

export const skuImg = (img) => {
	if (!img) {
		return null;
	}
	return img.slice(0, 4) == 'http' ? img : 'https:' + img;
};

export const ClipboardText = (text) => {
	if (text) {
		Clipboard.setString(text);
		tool.Toast.success(tool.strings('コピー済み'));
	}
};

export const getNowTimeFull = () => {
	const myDate = new Date();
	const year = myDate.getFullYear();
	const mon =
		myDate.getMonth() + 1 < 10
			? '0' + (myDate.getMonth() + 1)
			: myDate.getMonth() + 1;
	const date =
		myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
	const hour =
		myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours();
	const minute =
		myDate.getMinutes() < 10
			? '0' + myDate.getMinutes()
			: myDate.getMinutes();
	return year + '-' + mon + '-' + date + ' ' + hour + ':' + minute + ':00';
};

export const updateTime = (time) => {
	if (!time) {
		return '';
	}
	const dates = new Date(time.replace(/-/g, '/'));
	const dataTime = dates.getTime() + 3600000;
	const date = new Date(dataTime);
	const Y = date.getFullYear() + '-';
	const M =
		date.getMonth() + 1 < 10
			? '0' + (date.getMonth() + 1) + '-'
			: date.getMonth() + 1 + '-';
	const D =
		date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
	const h =
		date.getHours() < 10
			? '0' + date.getHours() + ':'
			: date.getHours() + ':';
	const m =
		date.getMinutes() < 10
			? '0' + date.getMinutes() + ':'
			: date.getMinutes() + ':';
	const s =
		date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	return Y + M + D + h + m + s;
};

export const formatPrice = (price) => {
	if (price && !isNaN(parseFloat(price))) {
		return String(Number(price).toFixed(2)).replace(
			/\B(?=(\d{3})+(?!\d))/g,
			','
		);
	}
	// return '-'
};

export const formatPriceJp = (price) => {
	if (price && !isNaN(parseFloat(price))) {
		return String(Number(price)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return '-';
};

export const isChinese = (temp) => {
	var re = /[^\u4e00-\u9fa5]/;
	if (re.test(temp)) {
		return false;
	}
	return true;
};

export const isJapanese = (temp) => {
	var re = /[^\u0800-\u4e00]/;
	if (re.test(temp)) {
		return false;
	}
	return true;
};

export const isKoera = (chr) => {
	if ((chr > 0x3130 && chr < 0x318f) || (chr >= 0xac00 && chr <= 0xd7a3)) {
		return true;
	}
	return false;
};

/** 是否是安卓设备 */
export const isAndroid = () => {
	return Platform.OS === 'android';
};

/** 1px 像素 */
export const BORDER_WIDTH = 1 / PixelRatio.get();

/** 数组分组显示 */
export const groupArray = (array, subGroupLength) => {
	let index = 0;
	let newArray = [];
	while (index < array.length) {
		newArray.push(array.slice(index, (index += subGroupLength)));
	}
	return newArray;
};
