/*
 * @Author: yusha
 * @Date: 2024-01-08 15:56:42
 * @LastEditors: tianzhitong laotianwy@163.com
 * @LastEditTime: 2024-03-15 16:14:19
 * @Description:
 */
import { createContext, Dispatch, SetStateAction } from 'react';
import { ProductDetailRespDTO } from '@/outs/service/easyGoods';
import { GoodDetailPageProps } from '.';
export type SpuProps = Record<string, number>;
interface ContextParams extends GoodDetailPageProps {
	/** 商品详情数据 */
	product: ProductDetailRespDTO;
	/** 选择sku弹窗类型 */
	skuPopupType?: 'both' | 'addCart' | 'buyItNow';
	/** 选择sku弹窗方法 */
	setSkuPopupType: null | Dispatch<
		SetStateAction<'both' | 'addCart' | 'buyItNow' | undefined>
	>;

	/** 是否商品售罄 */
	allSoldout: boolean;
	/** 一元售罄弹窗 */
	setSoldOutModalVisible: null | Dispatch<SetStateAction<boolean>>;
	firstSpuSoldOut: SpuProps;
	setConfirmMessage: null | Dispatch<SetStateAction<string | undefined>>;
	confirmMessage: string | undefined;
	getImgSkuPrewCallBack: null | Dispatch<
		SetStateAction<'both' | 'addCart' | 'buyItNow' | undefined>
	>;
	/** app所需要的环境 */
	env?: string;
}
export const ContextPreView = createContext<ContextParams>({
	product: {},
	skuPopupType: undefined,
	setSkuPopupType: null,
	allSoldout: false,
	setSoldOutModalVisible: null,
	firstSpuSoldOut: {},
	setConfirmMessage: null,
	confirmMessage: undefined,
	getImgSkuPrewCallBack: null,
	atomUserDetail: null,
	showService: undefined,
	handleCopy: undefined,
	toast: undefined,
	goToLogin: undefined,
	ShareH5: null,
	OpenApp: null,
	atomCartCount: null,
	backClick: null,
	env: ''
});
