/*
 * @Author: yusha
 * @Date: 2024-01-10 11:20:23
 * @LastEditors: yusha
 * @LastEditTime: 2024-04-03 10:57:21
 * @Description: 商品标题
 */
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { Image, XStack, YStack, Text, styled, Stack } from '@snifftest/tamagui';
import * as SDK from '@snifftest/sdk/lib/rn';
import { ProductPriceLadderDTO } from '@/outs/service/easyGoods';
import { tool } from '@/outs/utils/tool';
import { formatMoney } from '@/outs/utils';
import { ACTIVITY_TYPE } from '@/outs/const';
import { ContextPreView } from '../../../context';

const fitSize = SDK.fitSize;

export interface LadderItemProps extends ProductPriceLadderDTO {
	// 显示数据
	text: string;
}

const GoodsTitle = () => {
	const { product = {}, setSkuPopupType } = useContext(ContextPreView);
	/** 阶梯价列表 */
	const [ladderList, setLadderList] = useState<LadderItemProps[]>();
	/** 显示阶梯价 */
	const [showLadder, setShowLadder] = useState(false);

	/** 商品售价计算 */
	const sellprice = useMemo<string>(() => {
		const min = formatMoney(product.productSellLowestPriceJa);
		const max = formatMoney(product.productSellHighestPriceJa);
		if (min === max) {
			return `${min}`;
		}
		return `${min} - ${max}`;
	}, [product.productSellHighestPriceJa, product.productSellLowestPriceJa]);
	/** 商品阶梯价 */
	useEffect(() => {
		if (product?.productPriceLadderList?.length) {
			/** 返回的阶梯价列表 */
			const ladderList = product.productPriceLadderList;

			// 保证阶梯价格不能全部相同 1个时仍展示 && 阶梯价的一口价，不再展示阶梯价，只展示1个一口价
			const showLladder =
				((ladderList.length >= 2 &&
					new Set(ladderList.map((i: any) => i.price)).size > 2) ||
					ladderList.length === 2) &&
				product.activityInfo?.activityType !== ACTIVITY_TYPE.PRICE;
			setShowLadder(showLladder);
			const ladder = ladderList.map((ladderItem, index) => {
				return {
					text:
						index === ladderList.length - 1
							? `≥${ladderItem.count}`
							: `${ladderItem.count}~${
									(ladderList[index + 1]?.count ?? 0) - 1
							  }`,
					...ladderItem
				};
			});
			setLadderList(ladder);
		}
	}, [product.activityInfo?.activityType, product.productPriceLadderList]);

	/** spuString-多个spu /分开显示。cateChooseNum-第一个spu分类有多少个。cateImageList-第一个spu前四个商品 */
	const { spuString } = useMemo(() => {
		const spuList: string[] = [];
		const cateImageList: string[] = [];
		let cateChooseNum = 0;
		if (product.productPropGroupJaList?.length) {
			cateChooseNum =
				product.productPropGroupJaList[0].props?.length || 0;
			(product.productPropGroupJaList[0].props ?? []).forEach(
				(item, index) => {
					if (index < 4) {
						cateImageList.push(item.url ?? '');
					}
				}
			);
			product.productPropGroupJaList.forEach((item) => {
				spuList.push(item.type || '');
			});
		}
		return {
			spuString: spuList.join('/'),
			cateChooseNum,
			cateImageList
		};
	}, [product.productPropGroupJaList]);

	return (
		<YStack
			style={{
				backgroundColor: '#fff',
				borderRadius: fitSize(4),
				overflow: 'hidden',
				marginTop: fitSize(8)
			}}
		>
			<Text
				numberOfLines={2}
				style={{
					fontSize: fitSize(18),
					fontWeight: 'bold',
					color: '#1c2026',
					lineHeight: fitSize(24),
					overflow: 'hidden'
				}}
			>
				{product.productTitle}
			</Text>
			{/* 一口价不显示价格 */}
			{!product.activityInfo && (
				<Text
					style={{
						color: '#1c2026',
						fontSize: fitSize(16),
						fontWeight: '600',
						lineHeight: fitSize(24),
						marginTop: fitSize(8)
					}}
				>
					{sellprice}
					{tool.strings('円')}
				</Text>
			)}

			{/* 显示阶梯价 */}
			{showLadder && (
				<XStack
					style={{
						marginTop: fitSize(8),
						display: 'flex',
						paddingTop: fitSize(12),
						paddingBottom: fitSize(12),
						justifyContent: 'space-between',
						alignItems: 'flex-start',
						borderRadius: fitSize(8),
						borderWidth: fitSize(1),
						borderColor: '#f0f2f5'
					}}
				>
					{ladderList?.map((item, index) => (
						<YStack
							style={{
								display: 'flex',
								flexDirection: 'column',
								position: 'relative',
								justifyContent: 'center',
								alignItems: 'center',
								flex: 1,
								lineHeight: fitSize(20),
								backgroundColor:
									ladderList.length === 1 ? '#fff' : '',
								borderRightWidth: fitSize(1),
								borderRightColor:
									index === ladderList.length - 1
										? 'transparent'
										: '#f5f5f5'
							}}
							key={item.text}
						>
							<Text
								style={{
									color: '#7e8694',
									fontSize: fitSize(12),
									lineHeight: fitSize(20)
								}}
							>
								{item.text}
							</Text>
							{product.activityInfo?.activityType ===
							ACTIVITY_TYPE.DISCOUNT_RATE ? (
								<>
									<Text
										style={{
											color: '#1c2026',
											fontSize: fitSize(13),
											fontWeight: 'bold'
										}}
									>
										{formatMoney(item.activityPriceJa)}
										{tool.strings('円')}
									</Text>
									<Text
										style={{
											textDecoration: 'line-through',
											color: '#7e8694',
											fontSize: fitSize(12),
											lineHeight: fitSize(20)
										}}
									>
										{formatMoney(item.sellPriceJa)}
									</Text>
								</>
							) : (
								<Text
									style={{
										color: '#1c2026',
										fontSize: fitSize(13),
										fontWeight: 'bold'
									}}
								>
									{formatMoney(item.sellPriceJa)}
									{tool.strings('円')}
								</Text>
							)}
						</YStack>
					))}
				</XStack>
			)}

			{/* 分类可选省略 */}
			<ChooseSpecStyle
				onPress={() => {
					setSkuPopupType?.('both');
				}}
			>
				<Text
					style={{
						color: '#000',
						fontSize: fitSize(14),
						fontWeight: '500',
						lineHeight: fitSize(20)
					}}
				>
					{spuString}
				</Text>
				<Image
					source={{
						width: fitSize(16),
						height: fitSize(16),
						uri: 'https://static-jp.theckb.com/static-asset/easy-app/add@3.x.png'
					}}
				/>
			</ChooseSpecStyle>
		</YStack>
	);
};
const ChooseSpecStyle = styled(Stack, {
	width: '100%',
	marginTop: fitSize(8),
	paddingTop: fitSize(14),
	paddingBottom: fitSize(14),
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'row',
	borderBottomColor: '#f0f2f5',
	borderBottomWidth: fitSize(1)
});
export default memo(GoodsTitle);
