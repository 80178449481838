/*
 * @Author: shiguang
 * @Date: 2023-04-26 10:48:59
 * @LastEditors: huajian
 * @LastEditTime: 2024-01-10 17:43:03
 * @Description: 静态地址
 */

import { Config } from '@/config';

export const STATIC_URL = {
	login: `https://${Config.envUrl}login-m.3fbox.com/login/home`,
	SelectIdentity: '/smc/promotion/SelectIdentity'
};

if (Config.env === 'prod' || !Config.env) {
	STATIC_URL.login = 'https://login-m.3fbox.com/login/home';
}

if (Config.env === 'dev') {
	STATIC_URL.login = 'http://localhost:3666/smc/dev/login';
}

/** 缓存当前用户的站点。如果用户退出刷新获取上次的值 */
export const CACHE_STATION_NAME = 'smc-stationCode';
