/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * ActivityAreaMoreQuery
 * 活动专区更多查询
 */
export interface ActivityAreaMoreQuery {
	/**
	 * 活动专区id
	 * @format int64
	 */
	areaId?: number;
	/** 排序 是否降序 */
	descendOrder?: boolean;
	/** 结束价格 */
	endPrice?: number;
	/** 是否需要置顶商品(默认不需要) */
	needTopProduct?: boolean;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 排序字段:0 综合 1 价格(默认) 2 时间
	 * @format int32
	 */
	sortType?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/** 开始价格 */
	startPrice?: number;
}

/**
 * ActivityAreaQuery
 * 活动专区查询请求
 */
export interface ActivityAreaQuery {
	/** 显示频道 */
	barCode?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 合集分类Id
	 * @format int64
	 */
	collectionClassifyId?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** 模块名称 */
	moduleName?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 0 商品合集，1单个商品
	 * @format int32
	 */
	productCollection?: number;
	sorts?: ISortFieldEnum[];
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/** @format int32 */
	startIndex?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/**
	 * 类型 0 活动小专区 1 合集  默认0 活动小专区
	 * @format int32
	 */
	type?: number;
}

/**
 * ActivityAreaRespDTO
 * 活动专区信息
 */
export interface ActivityAreaRespDTO {
	/** 显示频道code */
	barCode?: string;
	/** 显示频道name */
	barName?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 合集分类id
	 * @format int64
	 */
	collectionClassifyId?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/**
	 * 客户操作不喜欢时间
	 * @format date-time
	 */
	disLikeTime?: string;
	/**
	 * 客户是否喜欢特集活动，0默认，1不喜欢
	 * @format int32
	 */
	dislikeFlag?: number;
	/** 修改人id */
	editorId?: string;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** 合集列表页头图 */
	headUrl?: string;
	/**
	 * 首页封面展示 0:商品 1:图片
	 * @format int32
	 */
	homeCoverShowType?: number;
	/** 图片url */
	homeImgUrl?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/** 模块说明 */
	moduleDesc?: string;
	/** 模块名称 */
	moduleName?: string;
	/** 更多活动列表 */
	moreActivityInfoList?: ActivityInfo[];
	/** 更多类目列表 */
	moreCategoryList?: FrontCategoryInfo[];
	/** 更多商品列表 */
	moreProductList?: ProductInfo[];
	/**
	 * 更多类型 1:商品 2:类目
	 * @format int32
	 */
	moreType?: number;
	/**
	 * 位置
	 * @format int32
	 */
	position?: number;
	/** 价格说明 */
	priceDesc?: string;
	/**
	 * 合集配置：0 商品合集，1单个商品
	 * @format int32
	 */
	productCollection?: number;
	/**
	 * 是否展示价格 0:不展示 1:展示
	 * @format int32
	 */
	showPrice?: number;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 置顶商品列表 */
	topProductList?: ProductInfo[];
	/**
	 * 类型 0 活动小专区 1 合集
	 * @format int32
	 */
	type?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/**
 * ActivityAreaVO
 * 活动专区vo
 */
export interface ActivityAreaVO {
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 首页封面展示 0:商品 1:图片
	 * @format int32
	 */
	homeCoverShowType?: number;
	/** 图片url */
	homeImgUrl?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/** 模块说明 */
	moduleDesc?: string;
	/** 模块名称 */
	moduleName?: string;
	/**
	 * 更多类型 1:商品 2:类目
	 * @format int32
	 */
	moreType?: number;
	/**
	 * 位置
	 * @format int32
	 */
	position?: number;
	/** 价格说明 */
	priceDesc?: string;
	/**
	 * 是否展示价格 0:不展示 1:展示
	 * @format int32
	 */
	showPrice?: number;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/** 站点code */
	stationCode?: string;
	/** 置顶商品列表 */
	topProductList?: ProductVO[];
}

/**
 * ActivityBarVO
 * 活动频道信息
 */
export interface ActivityBarVO {
	/** 关联的活动id */
	activityCodeList?: string;
	/** 活动价code */
	barCode?: string;
	/** 活动价名称 */
	barName?: string;
}

/**
 * ActivityInfo
 * 活动信息
 */
export interface ActivityInfo {
	/** 活动编码 */
	activityCode?: string;
	/** 活动名称 */
	activityName?: string;
	/**
	 * 活动类型 0:限时折扣 1:一口价
	 * @format int32
	 */
	activityType?: number;
	/**
	 * 优惠券叠加标记 0:不可叠加 1:可叠加
	 * @format int32
	 */
	addCoupon?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * 价格类型 0:固定价 1:溢价率 2:折扣率
	 * @format int32
	 */
	priceType?: number;
	/** 价格值 */
	priceValue?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/**
	 * 活动状态 0:草稿 1:未开始 2:活动中 3:已结束
	 * @format int32
	 */
	status?: number;
}

/**
 * ActivityPriceDetailQuery
 * 活动价明细查询请求
 */
export interface ActivityPriceDetailQuery {
	/** 活动编号 */
	activityCode?: string;
	/** 适用对象code */
	applyObjCode?: string;
	/** 适用对象名称 */
	applyObjName?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 价格维度 0:类目 1:商品 2:sku 3:商品标签
	 * @format int32
	 */
	priceDimension?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/** 站点code */
	stationCode?: string;
}

/**
 * ActivityPriceDetailRespDTO
 * 活动价明细信息
 */
export interface ActivityPriceDetailRespDTO {
	/**
	 * 活动id
	 * @format int64
	 */
	activityId?: number;
	/** 活动名称 */
	activityName?: string;
	/**
	 * 活动类型 0:限时折扣 1:一口价
	 * @format int32
	 */
	activityType?: number;
	/** 适用类目 */
	applyCategory?: BackendCategoryInfo;
	/** 适用信息 */
	applyInfo?: string;
	/** 适用商品 */
	applyProduct?: ProductInfo;
	/** 适用商品标签 */
	applyProductTag?: ProductTagInfo;
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** 类目code */
	categoryCode?: string;
	/** 类目名称 */
	categoryName?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 修改人id */
	editorId?: string;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * 活动价明细id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/**
	 * 价格维度 0:类目 1:商品 2:sku 3:商品标签
	 * @format int32
	 */
	priceDimension?: number;
	/** 价格标签code */
	priceTagCode?: string;
	/** 价格标签名称 */
	priceTagName?: string;
	/**
	 * 价格类型 0:固定价 1:溢价率 2:折扣率
	 * @format int32
	 */
	priceType?: number;
	/** 价格值 */
	priceValue?: number;
	/** 商品spu */
	productCode?: string;
	/** 商品名称 */
	productName?: string;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点 */
	stationCode?: string;
	/**
	 * 活动状态 0:草稿 1:未开始 2:活动中 3:已结束
	 * @format int32
	 */
	status?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/**
 * ActivityQuery
 * 活动查询请求
 */
export interface ActivityQuery {
	/** 活动编码 */
	activityCodes?: string[];
	/** 活动名称 */
	activityName?: string;
	/**
	 * 活动类型 0:限时折扣 1:一口价
	 * @format int32
	 */
	activityType?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 活动状态 0:草稿 1:未开始 2:活动中 3:已结束
	 * @format int32
	 */
	status?: number;
}

/**
 * ActivityRespDTO
 * 活动信息
 */
export interface ActivityRespDTO {
	/** 活动编码 */
	activityCode?: string;
	/** 活动说明 */
	activityDesc?: string;
	/** 活动名称 */
	activityName?: string;
	/**
	 * 活动类型 0:限时折扣 1:一口价
	 * @format int32
	 */
	activityType?: number;
	/**
	 * 是否叠加优惠券 0:否 1:是
	 * @format int32
	 */
	addCoupon?: number;
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 修改人id */
	editorId?: string;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * 活动id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/**
	 * 价格维度 0:类目 1:商品 2:sku 3:商品标签
	 * @format int32
	 */
	priceDimension?: number;
	/**
	 * 价格类型 0:固定价 1:溢价率 2:折扣率
	 * @format int32
	 */
	priceType?: number;
	/** 价格值 */
	priceValue?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点 */
	stationCode?: string;
	/**
	 * 活动状态 0:草稿 1:未开始 2:活动中 3:已结束
	 * @format int32
	 */
	status?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/**
 * ActivitySceneConfigQuery
 * 活动奖励查询请求
 */
export interface ActivitySceneConfigQuery {
	/** id */
	id?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 发放场景 1:新人注册 2:首次下单 */
	sceneTypes?: number[];
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/** 生效状态0:待生效 1:生效中 2:已失效 3:已取消 */
	status?: number[];
}

/**
 * ActivitySceneConfigRespDTO
 * 活动奖励信息
 */
export interface ActivitySceneConfigRespDTO {
	/** 优惠券信息 */
	activitySceneCouponInfos?: ActivitySceneCouponInfo[];
	/**
	 * 业务类型 1:直营2:代采
	 * @format int32
	 */
	buType?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 修改人id */
	editorId?: string;
	/** 操作人 */
	editorName?: string;
	/**
	 * 结束有效时间
	 * @format date-time
	 */
	endDate?: string;
	/**
	 * 活动奖励id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/**
	 * 发放场景 1:新人注册 2:首次下单
	 * @format int32
	 */
	sceneType?: number;
	/**
	 * 开始有效时间
	 * @format date-time
	 */
	startDate?: string;
	/**
	 * 活动状态 0:待生效 1:生效中 2:已失效 3:已取消
	 * @format int32
	 */
	status?: number;
	/** 配置标题 */
	title?: string;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/** ActivitySceneCouponInfo */
export interface ActivitySceneCouponInfo {
	/**
	 * 活动奖励配置id
	 * @format int64
	 */
	actSceneConfigId?: number;
	/** 优惠券内容 */
	couponContent?: string;
	/**
	 * 优惠券id
	 * @format int64
	 */
	couponId?: number;
	/** 优惠劵名称 */
	title?: string;
}

/**
 * AddActivityAreaReqDTO
 * 新建活动专区请求
 */
export interface AddActivityAreaReqDTO {
	/** 所在频道 */
	barCode?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 合集分类Id
	 * @format int64
	 */
	collectionClassifyId?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** 合集列表页头图 */
	headUrl?: string;
	/**
	 * 首页封面展示 0:商品 1:图片 (配置H5的时候需要)
	 * @format int32
	 */
	homeCoverShowType?: number;
	/** 图片url (配置H5的时候需要 */
	homeImgUrl?: string;
	/** 模块说明 */
	moduleDesc?: string;
	/** 模块名称 */
	moduleName?: string;
	/** 更多活动列表 */
	moreActivityInfoList?: ActivityInfo[];
	/** 更多类目列表 */
	moreCategoryList?: FrontCategoryInfo[];
	/** 更多商品列表 */
	moreProductList?: ProductInfo[];
	/**
	 * 更多类型 1:商品 2:类目 3:活动
	 * @format int32
	 */
	moreType?: number;
	/**
	 * 位置
	 * @format int32
	 */
	position?: number;
	/** 价格说明 */
	priceDesc?: string;
	/**
	 * 0 商品合集，1单个商品
	 * @format int32
	 */
	productCollection?: number;
	/**
	 * 是否展示价格 0:不展示 1:展示 (配置H5的时候需要
	 * @format int32
	 */
	showPrice?: number;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 置顶商品列表 */
	topProductList?: ProductInfo[];
	/**
	 * 类型 0 活动小专区 1 合集  默认0 活动小专区
	 * @format int32
	 */
	type?: number;
}

/**
 * AddActivityReqDTO
 * 新建活动请求
 */
export interface AddActivityReqDTO {
	/** 活动说明 */
	activityDesc?: string;
	/** 活动名称 */
	activityName?: string;
	/**
	 * 活动类型 0:限时折扣 1:一口价
	 * @format int32
	 */
	activityType?: number;
	/**
	 * 是否叠加优惠券 0:否 1:是
	 * @format int32
	 */
	addCoupon?: number;
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** 是否草稿 */
	draft?: boolean;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** 导入文件url */
	importFileUrl?: string;
	/**
	 * 价格维度 0:类目 1:商品 2:sku 3:商品标签
	 * @format int32
	 */
	priceDimension?: number;
	/**
	 * 价格类型 0:固定价 1:溢价率 2:折扣率
	 * @format int32
	 */
	priceType?: number;
	/** 价格值 */
	priceValue?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点 */
	stationCode?: string;
}

/**
 * AddActivitySceneConfigReqDTO
 * 新建活动奖励请求
 */
export interface AddActivitySceneConfigReqDTO {
	/** 优惠券id */
	couponIds?: number[];
	/**
	 * 发放场景 1:新人注册 2:首次下单
	 * @format int32
	 */
	sceneType?: number;
	/**
	 * 开始有效时间
	 * @format date-time
	 */
	startDate?: string;
}

/**
 * AddBannerReqDTO
 * 新增banner请求
 */
export interface AddBannerReqDTO {
	/** 显示频道 */
	barCode?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** 跳转url */
	jumpUrl?: string;
	/** 图片url */
	picUrl?: string;
	/**
	 * 位置类型
	 * @format int32
	 */
	placeType?: number;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 标题 */
	title?: string;
}

/**
 * AddCouponCountReqDTO
 * 增加优惠券数量
 */
export interface AddCouponCountReqDTO {
	/**
	 * 添加的数量
	 * @format int32
	 */
	addCount?: number;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
}

/**
 * AddCouponReqDTO
 * 新建优惠券请求
 */
export interface AddCouponReqDTO {
	/**
	 * 业务类型 1:直营2:代采
	 * @format int32
	 */
	buType?: number;
	/** 包含类目列表 */
	containCategoryList?: CouponCategoryInfo[];
	/** 包含商品列表 */
	containProductList?: string[];
	/**
	 * 优惠劵业务类型1:新用户注册 2:新人首单 3:人工发放
	 * @format int32
	 */
	couponBizType?: number;
	/**
	 * 优惠券发放总数量
	 * @format int32
	 */
	couponCount?: number;
	/**
	 * 优惠劵时间类型 1:按领取时间 2:按固定时间
	 * @format int32
	 */
	couponTimeType?: number;
	/**
	 * 优惠劵类型 1:满减劵 2:满折劵
	 * @format int32
	 */
	couponType?: number;
	/** 优惠劵文案 */
	description?: string;
	/** 满减劵满金额 打折额度 */
	discountValue?: number;
	/**
	 * 优惠券结束有效时间
	 * @format date-time
	 */
	endDate?: string;
	/** 满金额条件 */
	feeToCut?: number;
	/**
	 * 满件数条件
	 * @format int32
	 */
	numToCut?: number;
	/**
	 * 发放类型 1:人工发放 2:系统发放
	 * @format int32
	 */
	sendType?: number;
	/**
	 * 优惠券开始有效时间
	 * @format date-time
	 */
	startDate?: string;
	/** 优惠劵标题 */
	title?: string;
	/** 不包含类目列表 */
	unContainCategoryList?: CouponCategoryInfo[];
	/** 不包含商品列表 */
	unContainProductList?: string[];
	/**
	 * 优惠券有效时间段，精确到天
	 * @format int32
	 */
	validTime?: number;
}

/**
 * AddFreightTemplateReqDTO
 * 新建运费模板请求
 */
export interface AddFreightTemplateReqDTO {
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** 平台类目范围值 多个值用，分开 */
	cateCodeRange?: string;
	/** 平台类目范围值存整个list前端使用 */
	cateCodeRangeList?: string[][];
	/** 说明 */
	description?: string;
	/** 模板明细列表 */
	detailList?: FreightTemplateDetailInfo[];
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * 运费类型 1:普通运费规则，2：顺手捎商品规则
	 * @format int32
	 */
	freightType?: number;
	/** 价格标签范围 多个值用，分开 */
	goodsRange?: string;
	/**
	 * 商品范围 0:全部 1:按照价格标签，2：按照平台三级类目
	 * @format int32
	 */
	goodsScope?: number;
	/** 模板名称 */
	name?: string;
	/**
	 * 排序
	 * @format int32
	 */
	sort?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
}

/**
 * AddHotKeyParam
 * 新增热词
 */
export interface AddHotKeyParam {
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** 热词底图 */
	imgUrl?: string;
	/** 热词name */
	name?: string;
	/** 关联id */
	relationId?: string;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点 */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 关联类型 1:标签 2:活动 */
	type?: string;
}

/**
 * AddPopupReqDTO
 * 新建弹窗请求
 */
export interface AddPopupReqDTO {
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** 跳转url */
	jumpUrl?: string;
	/** 图片url */
	picUrl?: string;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
}

/**
 * AddRecommendReqDTO
 * 新建推荐请求
 */
export interface AddRecommendReqDTO {
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** 推荐商品列表 */
	productList?: ProductInfo[];
	/**
	 * 推荐类型 1:商品
	 * @format int32
	 */
	recommendType?: number;
	/** 来源渠道 1:PC 2:H5 */
	sourceChannels?: number[];
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 策略名称 */
	strategyName?: string;
}

/**
 * AddSellingPriceReqDTO
 * 新建销售价请求
 */
export interface AddSellingPriceReqDTO {
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** 说明 */
	description?: string;
	/** 导入文件url */
	importFileUrl?: string;
	/** 名称 */
	name?: string;
	/**
	 * 价格维度 0:类目 1:商品 2:sku
	 * @format int32
	 */
	priceDimension?: number;
	/**
	 * 适用范围为全部时使用!! 价格类型 0:固定价 1:溢价率
	 * @format int32
	 */
	priceType?: number;
	/** 适用范围为全部时使用!! 价格值 */
	priceValue?: number;
	/** 站点code */
	stationCode?: string;
}

/**
 * BackendBarVO
 * 后台频道列表信息
 */
export interface BackendBarVO {
	/** 活动ID列表 */
	activityCodeList?: string[];
	/** 活动ID列表(文本) */
	activityCodeListStr?: string;
	/** 活动价code */
	barCode?: string;
	/** 活动价名称 */
	barName?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** tab图片(选中) */
	choseImgUrl?: string;
	/**
	 * 序号
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否固定 0:不固定 1:固定
	 * @format int32
	 */
	isFix?: number;
	/**
	 * 是否展示价格 0:不展示 1:展示
	 * @format int32
	 */
	showPrice?: number;
	/** 站点 */
	stationCode?: string;
	/** tab图片(未选中) */
	unChoseImgUrl?: string;
}

/**
 * BackendCategoryInfo
 * 后台类目信息
 */
export interface BackendCategoryInfo {
	/** 一级类目code */
	firstCategoryCode?: string;
	/** 一级类目名称 */
	firstCategoryName?: string;
	/** 二级类目code */
	secondCategoryCode?: string;
	/** 二级类目名称 */
	secondCategoryName?: string;
	/** 三级类目code */
	thirdCategoryCode?: string;
	/** 三级类目名称 */
	thirdCategoryName?: string;
}

/**
 * BannerQuery
 * banner查询
 */
export interface BannerQuery {
	/** 显示频道 */
	barCode?: string;
	/**
	 * 业务场景
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 位置类型
	 * @format int32
	 */
	placeType?: number;
	sorts?: ISortFieldEnum[];
	/**
	 * 来源渠道
	 * @format int32
	 */
	sourceChannel?: number;
	/** @format int32 */
	startIndex?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态
	 * @format int32
	 */
	status?: number;
	/** 标题 */
	title?: string;
}

/**
 * BannerRespDTO
 * banner信息
 */
export interface BannerRespDTO {
	/** 显示频道code */
	barCode?: string;
	/** 显示频道name */
	barName?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 修改人id */
	editorId?: string;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/** 跳转url */
	jumpUrl?: string;
	/** 图片url */
	picUrl?: string;
	/**
	 * 位置类型
	 * @format int32
	 */
	placeType?: number;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 标题 */
	title?: string;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/**
 * BarMoreQuery
 * 活动频道更多查询参数
 */
export interface BarMoreQuery {
	/** 频道code */
	barCode?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
}

/**
 * BarSimpleVO
 * 频道信息
 */
export interface BarSimpleVO {
	/** 活动价code */
	barCode?: string;
	/** 活动价名称 */
	barName?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** tab图片(选中) */
	choseImgUrl?: string;
	/**
	 * 序号
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否固定 0:不固定 1:固定
	 * @format int32
	 */
	isFix?: number;
	/**
	 * 是否展示价格 0:不展示 1:展示
	 * @format int32
	 */
	showPrice?: number;
	/** 站点 */
	stationCode?: string;
	/** tab图片(未选中) */
	unChoseImgUrl?: string;
}

/**
 * BarVO
 * 频道扩展信息
 */
export interface BarVO {
	/** 活动价code */
	barCode?: string;
	/** 活动价名称 */
	barName?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** tab图片(选中) */
	choseImgUrl?: string;
	/**
	 * 序号
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否固定 0:不固定 1:固定
	 * @format int32
	 */
	isFix?: number;
	/** 更多活动列表 */
	moreActivityList?: ActivityInfo[];
	/** 更多类目列表 */
	moreCategoryList?: FrontCategoryInfo[];
	/** 更多商品列表 */
	moreProductList?: ProductInfo[];
	/**
	 * 更多类型 1:商品 2:类目 3:活动
	 * @format int32
	 */
	moreType?: number;
	/**
	 * 是否展示价格 0:不展示 1:展示
	 * @format int32
	 */
	showPrice?: number;
	/** 站点 */
	stationCode?: string;
	/** 置顶商品列表 */
	topProductList?: ProductInfo[];
	/** tab图片(未选中) */
	unChoseImgUrl?: string;
}

/** ChooseCouponInfo */
export interface ChooseCouponInfo {
	auto?: boolean;
	/** @format int64 */
	couponCustomerId?: number;
}

/** CollectionActivityRespDTO */
export interface CollectionActivityRespDTO {
	/** 显示频道code */
	barCode?: string;
	/** 显示频道name */
	barName?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 客户是否收藏该特集活动，默认收藏，1收藏
	 * @format int32
	 */
	collectedFlag?: number;
	/**
	 * 合集分类id
	 * @format int64
	 */
	collectionClassifyId?: number;
	/** 合集分类名字 */
	collectionClassifyName?: string;
	/** 是否收藏标记,true已经收藏，false没有收藏 */
	collectionFlag?: boolean;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 客户操作不喜欢时间
	 * @format date-time
	 */
	disLikeTime?: string;
	/**
	 * 客户是否喜欢特集活动，0默认，1不喜欢
	 * @format int32
	 */
	dislikeFlag?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** 合集列表页头图 */
	headUrl?: string;
	/**
	 * 首页封面展示 0:商品 1:图片
	 * @format int32
	 */
	homeCoverShowType?: number;
	/** 图片url */
	homeImgUrl?: string;
	/** 活动特选商品列表 */
	homePageProductsInfoList?: ProductVO[];
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/** 模块说明 */
	moduleDesc?: string;
	/** 模块名称 */
	moduleName?: string;
	/** 更多活动列表 */
	moreActivityInfoList?: ActivityInfo[];
	/** 更多类目列表 */
	moreCategoryList?: FrontCategoryInfo[];
	/** 更多商品列表 */
	moreProductList?: ProductInfo[];
	/**
	 * 更多类型 1:商品 2:类目
	 * @format int32
	 */
	moreType?: number;
	/**
	 * 位置
	 * @format int32
	 */
	position?: number;
	/** 价格说明 */
	priceDesc?: string;
	/**
	 * 0 商品合集，1单个商品
	 * @format int32
	 */
	productCollection?: number;
	/**
	 * 是否展示价格 0:不展示 1:展示
	 * @format int32
	 */
	showPrice?: number;
	/** 单个商品的code */
	singleProductCode?: string;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 置顶商品列表 */
	topProductList?: ProductInfo[];
	/**
	 * 类型 0 活动小专区 1 合集
	 * @format int32
	 */
	type?: number;
	/**
	 * 更新时间
	 * @format date-time
	 */
	updateTime?: string;
}

/** CollectionClassifylMergeRespDTO */
export interface CollectionClassifylMergeRespDTO {
	/** 按特集分类的活动列表 */
	activityList?: CollectionActivityRespDTO[];
	/** 合集分类名称 */
	collectionClassifyName?: string;
	/** 所有活动列表 */
	collectionList?: CollectionActivityRespDTO[];
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 排序
	 * @format int32
	 */
	sortNum?: number;
}

/**
 * CouponCategoryInfo
 * 优惠券类目信息
 */
export interface CouponCategoryInfo {
	/** 类目id */
	cateCode?: string;
	/** 类目名称 */
	cateName?: string;
}

/**
 * CouponCustomerDetailQuery
 * 用户优惠券查询请求
 */
export interface CouponCustomerDetailQuery {
	/** 订单编号 */
	bizCode?: string;
	/**
	 * 优惠券id
	 * @format int64
	 */
	couponId?: number;
	/**
	 * 用户id
	 * @format int64
	 */
	customerId?: number;
	dataSourceName?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/**
	 * 0 可使用 1 已使用 2 失效 3 待生效
	 * @format int32
	 */
	usedStatus?: number;
}

/**
 * CouponCustomerDetailRespDTO
 * 优惠券明细
 */
export interface CouponCustomerDetailRespDTO {
	/** 订单编号 */
	bizCode?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 用户ID
	 * @format int64
	 */
	customerId?: number;
	/** 用户名 */
	customerName?: string;
	/**
	 * 用户券ID
	 * @format int64
	 */
	id?: number;
	/**
	 * 使用时间
	 * @format date-time
	 */
	useDate?: string;
}

/**
 * CouponCustomerDistributeReq
 * 用户优惠券发放
 */
export interface CouponCustomerDistributeReq {
	/**
	 * 发放场景 1:后台手动发放 2:首单活动发放 3:新用户注册发放
	 * @format int32
	 */
	couponCause?: number;
	/**
	 * 优惠券id
	 * @format int64
	 */
	couponId?: number;
	/** 优惠券idList */
	couponIdList?: number[];
	/**
	 * 用户id
	 * @format int64
	 */
	customerId?: number;
	/** 业务源头code */
	sourceBizCode?: string;
}

/**
 * CouponCustomerImportDistributeReq
 * 用户优惠券发放
 */
export interface CouponCustomerImportDistributeReq {
	/**
	 * 优惠券id
	 * @format int64
	 */
	couponId?: number;
	/** 用户id文件 */
	url?: string;
}

/**
 * CouponCustomerQuery
 * 用户优惠券查询请求
 */
export interface CouponCustomerQuery {
	/**
	 * 优惠劵业务类型 1:新用户注册 2:新人首单 3:人工发放
	 * @format int32
	 */
	bizType?: number;
	/**
	 * 用户id
	 * @format int64
	 */
	customerId?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
}

/**
 * CouponProductQuery
 * 商品优惠券查询请求
 */
export interface CouponProductQuery {
	/** 商品平台类目编码 */
	cateCode?: string;
	/**
	 * 用户id
	 * @format int64
	 */
	customerId?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 商品编码 */
	productCode?: string;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
}

/**
 * CouponQuery
 * 优惠券查询请求
 */
export interface CouponQuery {
	/**
	 * 发放场景 1:新用户注册 2:新人首单 3:人工发放
	 * @format int32
	 */
	couponBizType?: number;
	/** 优惠券名称 */
	couponTitle?: string;
	/**
	 * 优惠券类型 1:满减券 2:打折券
	 * @format int32
	 */
	couponType?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 发放类型 1:人工发放 2:系统发放
	 * @format int32
	 */
	sendType?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/**
	 * 状态 0:未发布 1:已发布 2:已停止
	 * @format int32
	 */
	status?: number;
}

/**
 * CouponRespDTO
 * 优惠券信息
 */
export interface CouponRespDTO {
	/**
	 * 业务类型 1:直营2:代采
	 * @format int32
	 */
	buType?: number;
	/** 包含类目列表 */
	containCategoryList?: CouponCategoryInfo[];
	/** 包含商品列表 */
	containProductList?: ProductInfo[];
	/**
	 * 优惠劵业务类型1:新用户注册 2:新人首单 3:人工发放
	 * @format int32
	 */
	couponBizType?: number;
	/** 优惠券内容 */
	couponContent?: string;
	/**
	 * 优惠券发放总数量
	 * @format int32
	 */
	couponCount?: number;
	/**
	 * 优惠劵具体优惠类型 1:满多少钱打多少折 2:满多少件减多少钱 3:满多少件打多少折 4:满多少钱减多少钱
	 * @format int32
	 */
	couponDetailType?: number;
	/**
	 * 优惠券已获得总数量
	 * @format int32
	 */
	couponGetCount?: number;
	/**
	 * 优惠券模板id
	 * @format int64
	 */
	couponId?: number;
	/**
	 * 优惠劵时间类型 1:按领取时间 2:按固定时间
	 * @format int32
	 */
	couponTimeType?: number;
	/**
	 * 优惠劵类型 1:满减劵 2:满折劵
	 * @format int32
	 */
	couponType?: number;
	/**
	 * 优惠券已使用总数量
	 * @format int32
	 */
	couponUseCount?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 优惠劵文案 */
	description?: string;
	/** 满减劵满金额 打折额度 */
	discountValue?: number;
	/** 修改人id */
	editorId?: string;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 优惠券结束有效时间
	 * @format date-time
	 */
	endDate?: string;
	/** 满金额条件 */
	feeToCut?: number;
	/**
	 * 优惠券id(用户券id)
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/**
	 * 满件数条件
	 * @format int32
	 */
	numToCut?: number;
	/**
	 * 发放方式 1:人工发放 2:系统发放
	 * @format int32
	 */
	sendType?: number;
	/**
	 * 优惠券开始有效时间
	 * @format date-time
	 */
	startDate?: string;
	/**
	 * 0未发布 1已发布，2.已停止
	 * @format int32
	 */
	status?: number;
	/** 优惠券标题 */
	title?: string;
	/** 不包含类目列表 */
	unContainCategoryList?: CouponCategoryInfo[];
	/** 不包含商品列表 */
	unContainProductList?: ProductInfo[];
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
	/**
	 * 0 可使用 1 已使用 2 失效
	 * @format int32
	 */
	usedStatus?: number;
	/**
	 * 优惠券有效时间段，精确到天
	 * @format int32
	 */
	validTime?: number;
	/** 有效期 */
	validTimeString?: string;
}

/**
 * DeleteActivityPriceDetailReqDTO
 * 删除活动价明细请求
 */
export interface DeleteActivityPriceDetailReqDTO {
	/** 活动编号 */
	activityCode?: string;
	/** 活动价明细id列表 */
	detailIdList?: number[];
}

/**
 * DeleteSellingPriceDetailReqDTO
 * 删除销售价明细请求
 */
export interface DeleteSellingPriceDetailReqDTO {
	/** 销售价明细id列表 */
	detailIdList?: number[];
	/**
	 * 销售价id
	 * @format int64
	 */
	sellingPriceId?: number;
}

/**
 * EditActivityAreaReqDTO
 * 编辑活动专区请求
 */
export interface EditActivityAreaReqDTO {
	/** 所在频道 */
	barCode?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 合集分类Id
	 * @format int64
	 */
	collectionClassifyId?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** 合集列表页头图 */
	headUrl?: string;
	/**
	 * 首页封面展示 0:商品 1:图片 (配置H5的时候需要)
	 * @format int32
	 */
	homeCoverShowType?: number;
	/** 图片url (配置H5的时候需要 */
	homeImgUrl?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/** 模块说明 */
	moduleDesc?: string;
	/** 模块名称 */
	moduleName?: string;
	/** 更多活动列表 */
	moreActivityInfoList?: ActivityInfo[];
	/** 更多类目列表 */
	moreCategoryList?: FrontCategoryInfo[];
	/** 更多商品列表 */
	moreProductList?: ProductInfo[];
	/**
	 * 更多类型 1:商品 2:类目 3:活动
	 * @format int32
	 */
	moreType?: number;
	/**
	 * 位置
	 * @format int32
	 */
	position?: number;
	/** 价格说明 */
	priceDesc?: string;
	/**
	 * 0 商品合集，1单个商品
	 * @format int32
	 */
	productCollection?: number;
	/**
	 * 是否展示价格 0:不展示 1:展示 (配置H5的时候需要
	 * @format int32
	 */
	showPrice?: number;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 置顶商品列表 */
	topProductList?: ProductInfo[];
	/**
	 * 类型 0 活动小专区 1 合集  默认0 活动小专区
	 * @format int32
	 */
	type?: number;
}

/**
 * EditActivityPriceDetailReqDTO
 * 编辑活动价明细请求
 */
export interface EditActivityPriceDetailReqDTO {
	/** 活动编号 */
	activityCode?: string;
	/** 活动价明细列表 */
	editDetailList?: EditDetail[];
}

/**
 * EditActivityReqDTO
 * 编辑活动请求
 */
export interface EditActivityReqDTO {
	/**
	 * 业务类型 1:直营2:代采
	 * @format int32
	 */
	buType?: number;
	/** 包含类目列表 */
	containCategoryList?: CouponCategoryInfo[];
	/** 包含商品列表 */
	containProductList?: string[];
	/**
	 * 优惠劵业务类型1:新用户注册 2:新人首单 3:人工发放
	 * @format int32
	 */
	couponBizType?: number;
	/**
	 * 优惠券发放总数量
	 * @format int32
	 */
	couponCount?: number;
	/**
	 * 优惠劵时间类型 1:按领取时间 2:按固定时间
	 * @format int32
	 */
	couponTimeType?: number;
	/**
	 * 优惠劵类型 1:满减劵 2:满折劵
	 * @format int32
	 */
	couponType?: number;
	/** 优惠劵文案 */
	description?: string;
	/** 满减劵满金额 打折额度 */
	discountValue?: number;
	/**
	 * 优惠券结束有效时间
	 * @format date-time
	 */
	endDate?: string;
	/** 满金额条件 */
	feeToCut?: number;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 满件数条件
	 * @format int32
	 */
	numToCut?: number;
	/**
	 * 发放类型 1:人工发放 2:系统发放
	 * @format int32
	 */
	sendType?: number;
	/**
	 * 优惠券开始有效时间
	 * @format date-time
	 */
	startDate?: string;
	/** 优惠劵标题 */
	title?: string;
	/** 不包含类目列表 */
	unContainCategoryList?: CouponCategoryInfo[];
	/** 不包含商品列表 */
	unContainProductList?: string[];
	/**
	 * 优惠券有效时间段，精确到天
	 * @format int32
	 */
	validTime?: number;
}

/**
 * EditActivitySceneConfigReqDTO
 * 编辑活动奖励请求
 */
export interface EditActivitySceneConfigReqDTO {
	/** 优惠券id */
	couponIds?: number[];
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 发放场景 1:新人注册 2:首次下单
	 * @format int32
	 */
	sceneType?: number;
	/**
	 * 开始有效时间
	 * @format date-time
	 */
	startDate?: string;
}

/**
 * EditBannerReqDTO
 * 编辑banner请求
 */
export interface EditBannerReqDTO {
	/** 显示频道 */
	barCode?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/** 跳转url */
	jumpUrl?: string;
	/** 图片url */
	picUrl?: string;
	/**
	 * 位置类型
	 * @format int32
	 */
	placeType?: number;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 标题 */
	title?: string;
}

/**
 * EditBarReqDTO
 * 修改活动价频道参数
 */
export interface EditBarReqDTO {
	/** 活动价code */
	barCode?: string;
	/** 活动价名称 */
	barName?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** tab图片(选中) */
	choseImgUrl?: string;
	/** @format int64 */
	editorId?: number;
	/**
	 * 序号
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否固定 0:不固定 1:固定
	 * @format int32
	 */
	isFix?: number;
	/** 更多活动列表 */
	moreActivityList?: ActivityInfo[];
	/** 更多类目列表 */
	moreCategoryList?: FrontCategoryInfo[];
	/** 更多商品列表 */
	moreProductList?: ProductInfo[];
	/**
	 * 更多类型 1:商品 2:类目 3:活动
	 * @format int32
	 */
	moreType?: number;
	/**
	 * 是否展示价格 0:不展示 1:展示
	 * @format int32
	 */
	showPrice?: number;
	/** 站点 */
	stationCode?: string;
	/** 置顶商品列表 */
	topProductList?: ProductInfo[];
	/** tab图片(未选中) */
	unChoseImgUrl?: string;
}

/** EditDetail */
export interface EditDetail {
	/**
	 * 销售价明细id
	 * @format int64
	 */
	detailId?: number;
	/** 价格值 */
	priceValue?: number;
}

/**
 * EditFreightTemplateReqDTO
 * 编辑运费模板请求
 */
export interface EditFreightTemplateReqDTO {
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** 平台类目范围值 多个值用，分开 */
	cateCodeRange?: string;
	/** 平台类目范围值存整个list前端使用 */
	cateCodeRangeList?: string[][];
	/** 说明 */
	description?: string;
	/** 模板明细列表 */
	detailList?: FreightTemplateDetailInfo[];
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * 运费类型 1:普通运费规则，2：顺手捎商品规则
	 * @format int32
	 */
	freightType?: number;
	/** 价格标签范围 多个值用，分开 */
	goodsRange?: string;
	/**
	 * 商品范围 0:全部 1:按照价格标签，2：按照平台三级类目
	 * @format int32
	 */
	goodsScope?: number;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/** 模板名称 */
	name?: string;
	/**
	 * 排序
	 * @format int32
	 */
	sort?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
}

/**
 * EditHandyActivityReqDTO
 * 编辑顺手捎请求
 */
export interface EditHandyActivityReqDTO {
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** 可使用商品列表 */
	handyActivityProductList?: ProductInfo[];
	/**
	 * id
	 * @format int64
	 */
	handyBuyActivityId?: number;
	/**
	 * 允许顺手捎的数量
	 * @format int32
	 */
	handyNum?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 可使用商品列表 */
	unavailableProductList?: ProductInfo[];
}

/**
 * EditHotKeyParam
 * 新增热词
 */
export interface EditHotKeyParam {
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * 序号
	 * @format int64
	 */
	id?: number;
	/** 热词底图 */
	imgUrl?: string;
	/** 热词name */
	name?: string;
	/** 关联id */
	relationId?: string;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点 */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 关联类型 1:标签 2:活动 */
	type?: string;
}

/**
 * EditPopupReqDTO
 * 编辑弹窗请求
 */
export interface EditPopupReqDTO {
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/** 跳转url */
	jumpUrl?: string;
	/** 图片url */
	picUrl?: string;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
}

/**
 * EditRecommendReqDTO
 * 编辑推荐请求
 */
export interface EditRecommendReqDTO {
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/** 推荐商品列表 */
	productList?: ProductInfo[];
	/**
	 * 推荐类型 1:商品
	 * @format int32
	 */
	recommendType?: number;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/** 来源渠道 1:PC 2:H5 */
	sourceChannels?: number[];
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 策略名称 */
	strategyName?: string;
}

/**
 * EditSellingPriceDetailReqDTO
 * 编辑销售价明细请求
 */
export interface EditSellingPriceDetailReqDTO {
	/** 销售价明细列表 */
	editDetailList?: EditDetail[];
	/**
	 * 销售价id
	 * @format int64
	 */
	sellPriceId?: number;
}

/**
 * EditSellingPriceReqDTO
 * 编辑销售价请求
 */
export interface EditSellingPriceReqDTO {
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** 说明 */
	description?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/** 导入文件url */
	importFileUrl?: string;
	/** 名称 */
	name?: string;
	/**
	 * 价格维度 0:类目 1:商品 2:sku
	 * @format int32
	 */
	priceDimension?: number;
	/**
	 * 适用范围为全部时使用!! 价格类型 0:固定价 1:溢价率
	 * @format int32
	 */
	priceType?: number;
	/** 适用范围为全部时使用!! 价格值 */
	priceValue?: number;
	/** 站点code */
	stationCode?: string;
}

/**
 * FreightAreaVO
 * 运费地区vo
 */
export interface FreightAreaVO {
	/**
	 * 收货人城市id
	 * @format int64
	 */
	receiverCityId?: number;
	/** 收货人城市名称 */
	receiverCityName?: string;
	/**
	 * 收货人国家id
	 * @format int64
	 */
	receiverCountryId?: number;
	/** 收货人国家名称 */
	receiverCountryName?: string;
	/**
	 * 收货人省份id
	 * @format int64
	 */
	receiverProvinceId?: number;
	/** 收货人省份名称 */
	receiverProvinceName?: string;
	/**
	 * 收货人区域名称
	 * @example "沖縄, 九州"
	 */
	receiverRegionName?: string;
	/**
	 * 发货人城市id
	 * @format int64
	 */
	senderCityId?: number;
	/** 发货人城市名称 */
	senderCityName?: string;
	/**
	 * 发货人国家id
	 * @format int64
	 */
	senderCountryId?: number;
	/** 发货人国家名称 */
	senderCountryName?: string;
	/**
	 * 发货人省份id
	 * @format int64
	 */
	senderProvinceId?: number;
	/** 发货人省份名称 */
	senderProvinceName?: string;
}

/**
 * FreightFixStageInfo
 * 计费方式固定模板
 */
export interface FreightFixStageInfo {
	/** 开始区间(包含) */
	from?: number;
	/** 续重数量 */
	renewAmount?: number;
	/** 续重费用 */
	renewUnitFee?: number;
	/** 首重数量 */
	startAmount?: number;
	/** 首重费用 */
	startFee?: number;
	/** 结束区间(不包含) */
	to?: number;
}

/** FreightInfo */
export interface FreightInfo {
	freeShipThreshold?: number;
	freightAmt?: number;
	handyFreightAmt?: number;
	handyNum?: number;
	handyProductNum?: number;
	internationalDiscountAmount?: number;
}

/**
 * FreightStageInfo
 * 运费阶梯信息
 */
export interface FreightStageInfo {
	/** 开始区间(包含) */
	from?: number;
	/** 定价 */
	price?: number;
	/** 结束区间(不包含) */
	to?: number;
}

/**
 * FreightTemplateDetailInfo
 * 运费模板明细信息
 */
export interface FreightTemplateDetailInfo {
	/** 数量范围公式 */
	amountRangeList?: FreightStageInfo[];
	/**
	 * 计费依据 0:订单金额,1：商品件数
	 * @format int32
	 */
	billingBasic?: number;
	/**
	 * 计费方式0:数量范围，1：固定公式
	 * @format int32
	 */
	feeType?: number;
	/** 固定公式 */
	fixRangeList?: FreightFixStageInfo[];
	/**
	 * 价格类型 0:固定价
	 * @format int32
	 */
	priceType?: number;
	/**
	 * 收货人城市id
	 * @format int64
	 */
	receiverCityId?: number;
	/** 收货人城市名称 */
	receiverCityName?: string;
	/**
	 * 收货人国家id
	 * @format int64
	 */
	receiverCountryId?: number;
	/** 收货人国家名称 */
	receiverCountryName?: string;
	/**
	 * 收货人省份id
	 * @format int64
	 */
	receiverProvinceId?: number;
	/** 收货人省份名称 */
	receiverProvinceName?: string;
	/** 收货人区域名称 */
	receiverRegionName?: string;
	/**
	 * 发货人城市id
	 * @format int64
	 */
	senderCityId?: number;
	/** 发货人城市名称 */
	senderCityName?: string;
	/**
	 * 发货人国家id
	 * @format int64
	 */
	senderCountryId?: number;
	/** 发货人国家名称 */
	senderCountryName?: string;
	/**
	 * 发货人省份id
	 * @format int64
	 */
	senderProvinceId?: number;
	/** 发货人省份名称 */
	senderProvinceName?: string;
	/** 运费阶梯列表 */
	stageList?: FreightStageInfo[];
}

/**
 * FreightTemplateDetailRespDTO
 * 运费模板明细信息
 */
export interface FreightTemplateDetailRespDTO {
	/** 数量范围公式 */
	amountRangeFormula?: string;
	/** 数量范围公式 */
	amountRangeList?: FreightStageInfo[];
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/**
	 * 计费依据 0:订单金额,1：商品件数
	 * @format int32
	 */
	billingBasic?: number;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 修改人id */
	editorId?: string;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * 计费方式0:数量范围，1：固定公式
	 * @format int32
	 */
	feeType?: number;
	/** 固定公式 */
	fixRangeFormula?: string;
	/** 固定公式 */
	fixRangeList?: FreightFixStageInfo[];
	/**
	 * 商品范围 0:全部 1:指定
	 * @format int32
	 */
	goodsScope?: number;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/**
	 * 价格类型 0:固定价
	 * @format int32
	 */
	priceType?: number;
	/**
	 * 收货人城市id
	 * @format int64
	 */
	receiverCityId?: number;
	/** 收货人城市名称 */
	receiverCityName?: string;
	/**
	 * 收货人国家id
	 * @format int64
	 */
	receiverCountryId?: number;
	/** 收货人国家名称 */
	receiverCountryName?: string;
	/**
	 * 收货人省份id
	 * @format int64
	 */
	receiverProvinceId?: number;
	/** 收货人省份名称 */
	receiverProvinceName?: string;
	/** 收货人区域名称 */
	receiverRegionName?: string;
	/**
	 * 发货人城市id
	 * @format int64
	 */
	senderCityId?: number;
	/** 发货人城市名称 */
	senderCityName?: string;
	/**
	 * 发货人国家id
	 * @format int64
	 */
	senderCountryId?: number;
	/** 发货人国家名称 */
	senderCountryName?: string;
	/**
	 * 发货人省份id
	 * @format int64
	 */
	senderProvinceId?: number;
	/** 发货人省份名称 */
	senderProvinceName?: string;
	/** 阶梯信息 */
	stageInfo?: string;
	/** 运费阶梯列表 */
	stageList?: FreightStageInfo[];
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:未生效 1:生效中 2:已失效
	 * @format int32
	 */
	status?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/**
 * FreightTemplateQuery
 * 运费模板查询
 */
export interface FreightTemplateQuery {
	/**
	 * 业务场景
	 * @format int32
	 */
	bizScene?: number;
	/** 是否校验有效 */
	checkValid?: boolean;
	/**
	 * 运费类型 1:普通运费规则，2：顺手捎商品规则
	 * @format int32
	 */
	freightType?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:未生效 1:生效中 2:已失效
	 * @format int32
	 */
	status?: number;
}

/**
 * FreightTemplateReqDTO
 * 运费模板查询
 */
export interface FreightTemplateReqDTO {
	/**
	 * 业务场景
	 * @format int32
	 */
	bizScene?: number;
	/** 是否校验有效 */
	checkValid?: boolean;
	/**
	 * 运费类型 1:普通运费规则，2：顺手捎商品规则
	 * @format int32
	 */
	freightType?: number;
	/**
	 * 收货人国家id
	 * @format int64
	 */
	receiverCountryId?: number;
	/**
	 * 收货人省份id
	 * @format int64
	 */
	receiverProvinceId?: number;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:未生效 1:生效中 2:已失效
	 * @format int32
	 */
	status?: number;
}

/**
 * FreightTemplateRespDTO
 * 运费模板信息
 */
export interface FreightTemplateRespDTO {
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** 平台类目范围值 多个值用，分开 */
	cateCodeRange?: string;
	/** 平台类目范围值存整个list前端使用 */
	cateCodeRangeInfo?: string;
	/** 平台类目范围值存整个list前端使用 */
	cateCodeRangeList?: string[][];
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 说明 */
	description?: string;
	/** 模板明细列表 */
	detailList?: FreightTemplateDetailRespDTO[];
	/** 修改人id */
	editorId?: string;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * 运费类型 1:普通运费规则，2：顺手捎商品规则
	 * @format int32
	 */
	freightType?: number;
	/** 价格标签范围 多个值用，分开 */
	goodsRange?: string;
	/**
	 * 商品范围 0:全部 1:指定
	 * @format int32
	 */
	goodsScope?: number;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/** 模板名称 */
	name?: string;
	/**
	 * 排序
	 * @format int32
	 */
	sort?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:未生效 1:生效中 2:已失效
	 * @format int32
	 */
	status?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/**
 * FrontBarVO
 * 前台频道信息
 */
export interface FrontBarVO {
	/** 活动专区信息 */
	activityAreaList?: ActivityAreaVO[];
	/** 活动价code */
	barCode?: string;
	/** 活动价名称 */
	barName?: string;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** tab图片(选中) */
	choseImgUrl?: string;
	/**
	 * 序号
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否固定 0:不固定 1:固定
	 * @format int32
	 */
	isFix?: number;
	/**
	 * 是否展示价格 0:不展示 1:展示
	 * @format int32
	 */
	showPrice?: number;
	/** 站点 */
	stationCode?: string;
	/** 置顶商品id列表 */
	topProductList?: ProductVO[];
	/** tab图片(未选中) */
	unChoseImgUrl?: string;
}

/**
 * FrontCategoryInfo
 * 前台类目信息
 */
export interface FrontCategoryInfo {
	/**
	 * 类目id
	 * @format int64
	 */
	categoryId?: number;
	/** 类目名称 */
	categoryName?: string;
}

/**
 * HandyActivityProductReqDTO
 * 顺手捎商品查询参数
 */
export interface HandyActivityProductReqDTO {
	/** 排序 是否降序 */
	descendOrder?: boolean;
	/** 结束价格 */
	endPrice?: number;
	/**
	 * 顺手捎活动id
	 * @format int64
	 */
	handyBuyActivityId?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 类目编码(三级)
	 * @format int64
	 */
	productCategoryFrontendId?: number;
	/** 已加购顺手捎商品列表 */
	productList?: ProductVO[];
	/**
	 * 排序字段:0 综合 1 价格(默认) 2 时间
	 * @format int32
	 */
	sortType?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/** 开始价格 */
	startPrice?: number;
}

/**
 * HandyActivityRespDTO
 * 顺手捎活动响应信息
 */
export interface HandyActivityRespDTO {
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 修改人id */
	editorId?: string;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * 顺手捎活动id
	 * @format int64
	 */
	handyBuyActivityId?: number;
	/** 可使用顺手捎商品列表 */
	handyBuyActivityProductList?: ProductInfo[];
	/**
	 * 允许顺手捎的数量
	 * @format int32
	 */
	handyNum?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 不可用商品列表 */
	unavailableProductList?: ProductInfo[];
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/**
 * HotKeyQuery
 * 热词搜索参数
 */
export interface HotKeyQuery {
	/**
	 * 业务 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束日期
	 * @format date-time
	 */
	endTime?: string;
	/** 热词名称 */
	name?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/**
	 * 开始日期
	 * @format date-time
	 */
	startTime?: string;
	/**
	 * 状态
	 * @format int32
	 */
	status?: number;
}

/**
 * HotKeyRespDTO
 * 热词搜索返回结果
 */
export interface HotKeyRespDTO {
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * 序号
	 * @format int64
	 */
	id?: number;
	/** 热词底图 */
	imgUrl?: string;
	/** 热词name */
	name?: string;
	/** 关联id */
	relationId?: string;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点 */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 关联类型 1:标签 2:活动 */
	type?: string;
}

/**
 * HotKeyVO
 * 热词前台列表
 */
export interface HotKeyVO {
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** 热词底图 */
	imgUrl?: string;
	/** 热词name */
	name?: string;
	/** 关联id */
	relationId?: string;
	/** 站点 */
	stationCode?: string;
	/** 关联类型 1:标签 2:活动 */
	type?: string;
}

/** ISortFieldEnum */
export interface ISortFieldEnum {
	asc?: boolean;
	field?: string;
}

/**
 * MktCollectionClassifyDTO
 * 专集分类
 */
export interface MktCollectionClassifyDTO {
	/** 活动是否生效 */
	activetyAlive?: boolean;
	/**
	 * 活动ID
	 * @format int64
	 */
	activityAreaId?: number;
	/** 频道code */
	barCode?: string;
	/** 频道名字 */
	barName?: string;
	/**
	 * 合集ID
	 * @format int64
	 */
	collectionId?: number;
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人ID
	 * @format int64
	 */
	creatorId?: number;
	/** 创建人名称 */
	creatorName?: string;
	/**
	 * 用户id
	 * @format int64
	 */
	customerId?: number;
	/**
	 * 修改人ID
	 * @format int64
	 */
	editorId?: number;
	/** 修改人名称 */
	editorName?: string;
	/** 合集列表页头图 */
	headUrl?: string;
	/** 图片url */
	homeImgUrl?: string;
	/**
	 * 主键id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除
	 * @format int32
	 */
	isDelete?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/** 模块说明 */
	moduleDesc?: string;
	/** 模块名称 */
	moduleName?: string;
	/** 合集分类名称 */
	name?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 合集配置：0 商品合集，1单个商品
	 * @format int32
	 */
	productCollection?: number;
	/** 单个商品的code */
	singleProductCode?: string;
	/** @format int32 */
	sortNum?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/**
	 * 分类 0系统 1自定义
	 * @format int32
	 */
	type?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/**
 * MktCustomerCollectionDTO
 * 客户收藏的专集
 */
export interface MktCustomerCollectionDTO {
	/**
	 * 活动ID
	 * @format int64
	 */
	activityAreaId?: number;
	/**
	 * 合集ID
	 * @format int64
	 */
	collectionId?: number;
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 用户id
	 * @format int64
	 */
	customerId?: number;
	/**
	 * 是否删除
	 * @format int32
	 */
	isDelete?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/**
 * MktHomePageCollectionRespDTO
 * 首页专集列表
 */
export interface MktHomePageCollectionRespDTO {
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 用户id
	 * @format int64
	 */
	customerId?: number;
	/** 生活用品专集列表 */
	dailyLifeProductList?: CollectionActivityRespDTO[];
	/** 一站式购物专集列表 */
	oneStopProductList?: CollectionActivityRespDTO[];
	/** 自定义专集列表 */
	selfDefinedProductList?: CollectionActivityRespDTO[];
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/** OrderItem */
export interface OrderItem {
	asc?: boolean;
	column?: string;
}

/** OrderPriceCalculateReqDTO */
export interface OrderPriceCalculateReqDTO {
	couponInfo?: ChooseCouponInfo;
	/** @format int64 */
	customerId?: number;
	/** @format int32 */
	freightType?: number;
	newUser?: boolean;
	newUserFreeShippingAmount?: number;
	orderAmt?: number;
	/** @format int64 */
	receiverCityId?: number;
	/** @format int64 */
	receiverCountryId?: number;
	/** @format int64 */
	receiverProvinceId?: number;
	/** @format int64 */
	senderCityId?: number;
	/** @format int64 */
	senderCountryId?: number;
	/** @format int64 */
	senderProvinceId?: number;
	skuList?: OrderSkuInfo[];
	stationCode?: string;
}

/** OrderSkuInfo */
export interface OrderSkuInfo {
	activityCode?: string;
	freeShipping?: boolean;
	productCode?: string;
	productSku?: string;
	/** @format int32 */
	quantity?: number;
}

/**
 * PopupQuery
 * 弹窗查询请求
 */
export interface PopupQuery {
	/**
	 * 业务场景
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/**
	 * 来源渠道
	 * @format int32
	 */
	sourceChannel?: number;
	/** @format int32 */
	startIndex?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态
	 * @format int32
	 */
	status?: number;
}

/**
 * PopupRespDTO
 * 弹窗信息
 */
export interface PopupRespDTO {
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 修改人id */
	editorId?: string;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/** 跳转url */
	jumpUrl?: string;
	/** 图片url */
	picUrl?: string;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/**
 * ProductByCouponQuery
 * 优惠券可使用商品查询参数
 */
export interface ProductByCouponQuery {
	/** 优惠券模板id */
	couponId?: string;
	/** 排序 是否降序 */
	descendOrder?: boolean;
	/** 结束价格 */
	endPrice?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 排序字段:0 综合 1 价格(默认) 2 时间
	 * @format int32
	 */
	sortType?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/** 开始价格 */
	startPrice?: number;
}

/**
 * ProductInfo
 * 商品信息
 */
export interface ProductInfo {
	/** 商品最高基准价格 */
	baseHighestPrice?: number;
	/** 商品最低基准价格 */
	baseLowestPrice?: number;
	/** 商品编码 */
	productCode?: string;
	/** 直采商详链接 */
	productDetailUrl?: string;
	/** 主图 */
	productMainImg?: string;
	/** 阶梯价格 */
	productPriceLadderList?: ProductPriceLadderInfo[];
	/** 商品最高销售价格 */
	productSellHighestPrice?: number;
	/** 商品最低销售价格 */
	productSellLowestPrice?: number;
	/** 商品标题 */
	productTitle?: string;
	/**
	 * 商品状态：0-待售卖、1-售卖中、2-待下架、3-停止售卖、4-删除
	 * @format int32
	 */
	status?: number;
}

/**
 * ProductPriceLadderInfo
 * 商品阶梯价信息
 */
export interface ProductPriceLadderInfo {
	/** app价格 */
	appPrice?: number;
	/** 基准价 */
	basePrice?: number;
	/**
	 * 阶梯数量
	 * @format int32
	 */
	count?: number;
	/** 国际运费 */
	internationalFreight?: number;
	/** 原始价格 */
	originPrice?: number;
	/** 溢价率 */
	premiumRate?: number;
	/** pc价格 */
	price?: number;
	/** 销售单价(参考价) */
	productSellPrice?: number;
	/** 销售价格 */
	sellPrice?: number;
}

/**
 * ProductTagInfo
 * 商品标签信息
 */
export interface ProductTagInfo {
	/** 标签code */
	tagCode?: string;
	/** 标签名称 */
	tagName?: string;
	/**
	 * 标签类型
	 * @format int32
	 */
	tagType?: number;
}

/**
 * ProductVO
 * 商品vo
 */
export interface ProductVO {
	/**
	 * 活动标识, 0-无活动, 1-有活动
	 * @format int32
	 */
	activityFlag?: number;
	/** 活动价(日元) */
	activitySellPriceJpy?: number;
	/**
	 * 活动类型 0-一口价  1-折扣价
	 * @format int32
	 */
	activityType?: number;
	/**
	 * 可用库存
	 * @format int32
	 */
	availableQuantity?: number;
	/**
	 * 是否收藏 0 否 1 是
	 * @format int32
	 */
	favoriteFlag?: number;
	/**
	 * 允许顺手捎的数量
	 * @format int32
	 */
	handyAmount?: number;
	/** 是否已加购 */
	hasBought?: boolean;
	/** 是否展示活动价格 */
	highPriceShow?: boolean;
	/** 平台类型 TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园 */
	platformType?: string;
	/** 商品SPU */
	productCode?: string;
	/** 商品最低价 */
	productLowestPrice?: number;
	/** 商品主图 */
	productMainImg?: string;
	/**
	 * 采购类型, 代采：1, 直采：0
	 * @format int32
	 */
	productPurchaseType?: number;
	/** 商品名称 */
	productTitle?: string;
	/**
	 * 商品type
	 * @format int32
	 */
	productType?: number;
	/**
	 * 商品状态：0-待售卖、1-售卖中、2-待下架、3-停止售卖、4-删除
	 * @format int32
	 */
	status?: number;
}

/**
 * RecommendQuery
 * 推荐查询
 */
export interface RecommendQuery {
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 推荐类型 1:商品
	 * @format int32
	 */
	recommendType?: number;
	sorts?: ISortFieldEnum[];
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/** @format int32 */
	startIndex?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 策略名称 */
	strategyName?: string;
}

/**
 * RecommendRespDTO
 * 推荐信息
 */
export interface RecommendRespDTO {
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 修改人id */
	editorId?: string;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/** 推荐商品列表 */
	productList?: ProductInfo[];
	/**
	 * 推荐类型 1:商品
	 * @format int32
	 */
	recommendType?: number;
	/**
	 * 来源渠道 1:PC 2:H5
	 * @format int32
	 */
	sourceChannel?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/** 策略名称 */
	strategyName?: string;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/**
 * SellingPriceDetailQuery
 * 销售价明细查询
 */
export interface SellingPriceDetailQuery {
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/** 是否包含已删除数据 */
	containDeleted?: boolean;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 价格维度 0:类目 1:商品 2:sku
	 * @format int32
	 */
	priceDimension?: number;
	/** 商品spu */
	productCode?: string;
	/** 商品名称 */
	productName?: string;
	/**
	 * 销售价id
	 * @format int64
	 */
	sellingPriceId?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/** 站点code */
	stationCode?: string;
	/** 三级类目code */
	thirdCategoryCode?: string;
	/** 三级类目名称 */
	thirdCategoryName?: string;
	/**
	 * 更新时间开始
	 * @format date-time
	 */
	updateTimeBegin?: string;
	/**
	 * 更新时间结束
	 * @format date-time
	 */
	updateTimeEnd?: string;
}

/**
 * SellingPriceDetailRespDTO
 * 销售价明细信息
 */
export interface SellingPriceDetailRespDTO {
	/** 适用类目 */
	applyCategory?: BackendCategoryInfo;
	/** 适用信息 */
	applyInfo?: string;
	/** 适用商品 */
	applyProduct?: ProductInfo;
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** 类目code */
	categoryCode?: string;
	/** 类目名称 */
	categoryName?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 修改人id */
	editorId?: string;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/**
	 * 价格维度 0:类目 1:商品 2:sku
	 * @format int32
	 */
	priceDimension?: number;
	/**
	 * 价格类型 0:固定价 1:溢价率
	 * @format int32
	 */
	priceType?: number;
	/** 价格值 */
	priceValue?: number;
	/** 商品spu */
	productCode?: string;
	/** 商品名称 */
	productName?: string;
	/**
	 * 销售价id
	 * @format int64
	 */
	sellingPriceId?: number;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/**
 * SellingPriceQuery
 * 销售价查询
 */
export interface SellingPriceQuery {
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/** 名称 */
	name?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 价格维度 0:类目 1:商品 2:sku
	 * @format int32
	 */
	priceDimension?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	startIndex?: number;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
}

/**
 * SellingPriceRespDTO
 * 销售价信息
 */
export interface SellingPriceRespDTO {
	/**
	 * 适用范围 0:全部 1:指定
	 * @format int32
	 */
	applyScope?: number;
	/**
	 * 业务场景 1:直营商城
	 * @format int32
	 */
	bizScene?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人id */
	creatorId?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 说明 */
	description?: string;
	/** 修改人id */
	editorId?: string;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否删除 0:未删除 1:已删除
	 * @format int32
	 */
	isDeleted?: number;
	/** 名称 */
	name?: string;
	/**
	 * 价格维度 0:类目 1:商品 2:sku
	 * @format int32
	 */
	priceDimension?: number;
	/**
	 * 适用范围为全部时使用!! 价格类型 0:固定价 1:溢价率
	 * @format int32
	 */
	priceType?: number;
	/** 适用范围为全部时使用!! 价格值 */
	priceValue?: number;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态 0:关闭 1:开启
	 * @format int32
	 */
	status?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
}

/** BizResponse«ActivityAreaRespDTO» */
export interface BizResponseActivityAreaRespDTO {
	code?: string;
	/** 活动专区信息 */
	data?: ActivityAreaRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«ActivityRespDTO» */
export interface BizResponseActivityRespDTO {
	code?: string;
	/** 活动信息 */
	data?: ActivityRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«ActivitySceneConfigRespDTO» */
export interface BizResponseActivitySceneConfigRespDTO {
	code?: string;
	/** 活动奖励信息 */
	data?: ActivitySceneConfigRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«BarVO» */
export interface BizResponseBarVO {
	code?: string;
	/** 频道扩展信息 */
	data?: BarVO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«CollectionActivityRespDTO» */
export interface BizResponseCollectionActivityRespDTO {
	code?: string;
	data?: CollectionActivityRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«CouponRespDTO» */
export interface BizResponseCouponRespDTO {
	code?: string;
	/** 优惠券信息 */
	data?: CouponRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«FreightInfo» */
export interface BizResponseFreightInfo {
	code?: string;
	data?: FreightInfo;
	msg?: string;
	success?: boolean;
}

/** BizResponse«FreightTemplateDetailRespDTO» */
export interface BizResponseFreightTemplateDetailRespDTO {
	code?: string;
	/** 运费模板明细信息 */
	data?: FreightTemplateDetailRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«FrontBarVO» */
export interface BizResponseFrontBarVO {
	code?: string;
	/** 前台频道信息 */
	data?: FrontBarVO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«HandyActivityRespDTO» */
export interface BizResponseHandyActivityRespDTO {
	code?: string;
	/** 顺手捎活动响应信息 */
	data?: HandyActivityRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ActivityAreaVO»» */
export interface BizResponseListActivityAreaVO {
	code?: string;
	data?: ActivityAreaVO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ActivityBarVO»» */
export interface BizResponseListActivityBarVO {
	code?: string;
	data?: ActivityBarVO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«BackendBarVO»» */
export interface BizResponseListBackendBarVO {
	code?: string;
	data?: BackendBarVO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«BannerRespDTO»» */
export interface BizResponseListBannerRespDTO {
	code?: string;
	data?: BannerRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«BarSimpleVO»» */
export interface BizResponseListBarSimpleVO {
	code?: string;
	data?: BarSimpleVO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«CollectionActivityRespDTO»» */
export interface BizResponseListCollectionActivityRespDTO {
	code?: string;
	data?: CollectionActivityRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«CollectionClassifylMergeRespDTO»» */
export interface BizResponseListCollectionClassifylMergeRespDTO {
	code?: string;
	data?: CollectionClassifylMergeRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«CouponRespDTO»» */
export interface BizResponseListCouponRespDTO {
	code?: string;
	data?: CouponRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«FreightAreaVO»» */
export interface BizResponseListFreightAreaVO {
	code?: string;
	data?: FreightAreaVO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«HandyActivityRespDTO»» */
export interface BizResponseListHandyActivityRespDTO {
	code?: string;
	data?: HandyActivityRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«HotKeyVO»» */
export interface BizResponseListHotKeyVO {
	code?: string;
	data?: HotKeyVO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«MktCollectionClassifyDTO»» */
export interface BizResponseListMktCollectionClassifyDTO {
	code?: string;
	data?: MktCollectionClassifyDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ProductVO»» */
export interface BizResponseListProductVO {
	code?: string;
	data?: ProductVO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«MktHomePageCollectionRespDTO» */
export interface BizResponseMktHomePageCollectionRespDTO {
	code?: string;
	/** 首页专集列表 */
	data?: MktHomePageCollectionRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«ActivityAreaRespDTO»» */
export interface BizResponsePageActivityAreaRespDTO {
	code?: string;
	data?: PageActivityAreaRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«ActivityPriceDetailRespDTO»» */
export interface BizResponsePageActivityPriceDetailRespDTO {
	code?: string;
	data?: PageActivityPriceDetailRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«ActivityRespDTO»» */
export interface BizResponsePageActivityRespDTO {
	code?: string;
	data?: PageActivityRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«ActivitySceneConfigRespDTO»» */
export interface BizResponsePageActivitySceneConfigRespDTO {
	code?: string;
	data?: PageActivitySceneConfigRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«BannerRespDTO»» */
export interface BizResponsePageBannerRespDTO {
	code?: string;
	data?: PageBannerRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«CouponCustomerDetailRespDTO»» */
export interface BizResponsePageCouponCustomerDetailRespDTO {
	code?: string;
	data?: PageCouponCustomerDetailRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«CouponRespDTO»» */
export interface BizResponsePageCouponRespDTO {
	code?: string;
	data?: PageCouponRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«FreightTemplateRespDTO»» */
export interface BizResponsePageFreightTemplateRespDTO {
	code?: string;
	data?: PageFreightTemplateRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«HotKeyRespDTO»» */
export interface BizResponsePageHotKeyRespDTO {
	code?: string;
	data?: PageHotKeyRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«MktCollectionClassifyDTO»» */
export interface BizResponsePageMktCollectionClassifyDTO {
	code?: string;
	data?: PageMktCollectionClassifyDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«PopupRespDTO»» */
export interface BizResponsePagePopupRespDTO {
	code?: string;
	data?: PagePopupRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«ProductVO»» */
export interface BizResponsePageProductVO {
	code?: string;
	data?: PageProductVO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«RecommendRespDTO»» */
export interface BizResponsePageRecommendRespDTO {
	code?: string;
	data?: PageRecommendRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«SellingPriceDetailRespDTO»» */
export interface BizResponsePageSellingPriceDetailRespDTO {
	code?: string;
	data?: PageSellingPriceDetailRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«SellingPriceRespDTO»» */
export interface BizResponsePageSellingPriceRespDTO {
	code?: string;
	data?: PageSellingPriceRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PopupRespDTO» */
export interface BizResponsePopupRespDTO {
	code?: string;
	/** 弹窗信息 */
	data?: PopupRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«RecommendRespDTO» */
export interface BizResponseRecommendRespDTO {
	code?: string;
	/** 推荐信息 */
	data?: RecommendRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«boolean» */
export interface BizResponseBoolean {
	code?: string;
	data?: boolean;
	msg?: string;
	success?: boolean;
}

/** BizResponse«object» */
export interface BizResponseObject {
	code?: string;
	data?: object;
	msg?: string;
	success?: boolean;
}

/** BizResponse«string» */
export interface BizResponseString {
	code?: string;
	data?: string;
	msg?: string;
	success?: boolean;
}

/** Page«ActivityAreaRespDTO» */
export interface PageActivityAreaRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: ActivityAreaRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«ActivityPriceDetailRespDTO» */
export interface PageActivityPriceDetailRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: ActivityPriceDetailRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«ActivityRespDTO» */
export interface PageActivityRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: ActivityRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«ActivitySceneConfigRespDTO» */
export interface PageActivitySceneConfigRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: ActivitySceneConfigRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«BannerRespDTO» */
export interface PageBannerRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: BannerRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«CouponCustomerDetailRespDTO» */
export interface PageCouponCustomerDetailRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: CouponCustomerDetailRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«CouponRespDTO» */
export interface PageCouponRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: CouponRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«FreightTemplateRespDTO» */
export interface PageFreightTemplateRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: FreightTemplateRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«HotKeyRespDTO» */
export interface PageHotKeyRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: HotKeyRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«MktCollectionClassifyDTO» */
export interface PageMktCollectionClassifyDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: MktCollectionClassifyDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«PopupRespDTO» */
export interface PagePopupRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: PopupRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«ProductVO» */
export interface PageProductVO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: ProductVO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«RecommendRespDTO» */
export interface PageRecommendRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: RecommendRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«SellingPriceDetailRespDTO» */
export interface PageSellingPriceDetailRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: SellingPriceDetailRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«SellingPriceRespDTO» */
export interface PageSellingPriceRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: SellingPriceRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
	securityWorker?: (
		securityData: SecurityDataType | null
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({
			...axiosConfig,
			baseURL: axiosConfig.baseURL || '//master-gateway.theckb.com:443/market'
		});
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {})
			}
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === 'object' && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<T> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
			body = JSON.stringify(body);
		}

		return this.instance
			.request({
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
				},
				params: query,
				responseType: responseFormat,
				data: body,
				url: path
			})
			.then((response) => response.data);
	};
}

/**
 * @title API文档
 * @version v1.0.0
 * @baseUrl //master-gateway.theckb.com:443/market
 * @contact 史尼芙 (https://www.taobaockb.com/)
 *
 * 史尼芙API文档
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	coupon = {
		/**
		 * No description
		 *
		 * @tags 优惠券
		 * @name CouponCustomerPage
		 * @summary 客户端：当前用户的优惠卷
		 * @request POST:/coupon/couponCustomerPage
		 */
		couponCustomerPage: (couponCustomerQuery: CouponCustomerDetailQuery, params: RequestParams = {}) =>
			this.request<BizResponsePageCouponRespDTO, any>({
				path: `/coupon/couponCustomerPage`,
				method: 'POST',
				body: couponCustomerQuery,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 优惠券
		 * @name CouponProductPage
		 * @summary 客户端：当前商品符合使用条件的的优惠券
		 * @request POST:/coupon/couponProductPage
		 */
		couponProductPage: (couponProductQuery: CouponProductQuery, params: RequestParams = {}) =>
			this.request<BizResponseListCouponRespDTO, any>({
				path: `/coupon/couponProductPage`,
				method: 'POST',
				body: couponProductQuery,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 优惠券
		 * @name CustomerDetail
		 * @summary 客户端: 单张用户优惠券详情
		 * @request GET:/coupon/customer/detail
		 */
		customerDetail: (
			query: {
				/** id */
				id: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseCouponRespDTO, any>({
				path: `/coupon/customer/detail`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 优惠券
		 * @name ProductSearchPage
		 * @summary 客户端: 查询适用该优惠券的商品(分页)
		 * @request POST:/coupon/product/search/page
		 */
		productSearchPage: (param: ProductByCouponQuery, params: RequestParams = {}) =>
			this.request<BizResponsePageProductVO, any>({
				path: `/coupon/product/search/page`,
				method: 'POST',
				body: param,
				type: ContentType.Json,
				...params
			})
	};
	handyActivity = {
		/**
		 * No description
		 *
		 * @tags 顺手捎活动
		 * @name ActivityProductList
		 * @summary 顺手捎商品列表(前台)
		 * @request POST:/handyActivity/activityProductList
		 */
		activityProductList: (reqDTO: HandyActivityProductReqDTO, params: RequestParams = {}) =>
			this.request<BizResponsePageProductVO, any>({
				path: `/handyActivity/activityProductList`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			})
	};
}
