/*
 * @Author: tianzhitong laotianwy@163.com
 * @Date: 2023-09-04 18:01:08
 * @LastEditors: yusha
 * @LastEditTime: 2024-04-10 17:27:09
 * @FilePath: \ckb-smc-client-fed\src\const\config\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/** 韩国站周一到周日的日期。注意index：0=周日。index：1=周一 */
export const KoWeeks = [
	'일요일',
	'월요일',
	'화요일',
	'수요일',
	'목요일',
	'금요일',
	'토요일'
];

export const LOCAL_SESSION_GOODS_DETAIL_BUY_NOW = 'local_order';
/** 顺手捎标志 */
export const ON_HAND_GOODS_SELECT = 'onhand_order';
/** 顺手捎加购之后存在session中的可以 */
export const ON_HAND_GOODS_SELECT_SESSION_KEY = 'onHandList_1';
