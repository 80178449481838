/*
 * @Author: tianzhitong laotianwy@163.com
 * @Date: 2023-10-18 13:54:06
 * @LastEditors: yusha
 * @LastEditTime: 2024-04-07 11:51:41
 * @FilePath: /ckb-easy-h5-fed/src/config/router/goods.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { lazy } from 'react';
import CouponGoodsList from '@/container/goods/CouponGoodsList';
/** 首页路由名字 */
export const ROUTER_INDEX_NAME = '/goods/home';

export const ROUTER_GOODS = [
	{
		path: ROUTER_INDEX_NAME,
		title: 'CKB | 1688直营商城',
		component: lazy(() => import('../../container/goods/Home'))
	},
	// {
	// 	path: '/goods/home',
	// 	title: '新首页',
	// 	component: lazy(() => import('../../container/goods/Home'))
	// },
	{
		path: '/goods/list',
		title: '商品列表',
		component: lazy(() => import('../../container/goods/List'))
	},
	{
		path: '/goods/list/coupon',
		title: '商品列表',
		component: CouponGoodsList
	},
	{
		path: '/goods/collectionGoodsList',
		title: '特辑商品列表',
		component: lazy(
			() => import('../../container/goods/CollectionGoodsList')
		)
	},
	{
		path: '/goods/priceCollectionList',
		title: '百元特辑',
		component: lazy(
			() => import('../../container/goods/PriceCollectionList')
		)
	},
	{
		path: '/goods/collection',
		title: '一站式购物',
		component: lazy(() => import('../../container/goods/CollectionList'))
	},
	{
		path: '/goods/imageSearchList',
		title: '图搜列表',
		component: lazy(() => import('../../container/goods/ImageSearchList'))
	},
	{
		path: '/goods/detail',
		title: '商品详情',
		component: lazy(() => import('../../container/goods/NewDetail'))
	},
	{
		path: '/goods/search',
		title: '搜索',
		component: lazy(() => import('../../container/goods/Search'))
	},
	{
		path: '/goods/classify',
		title: 'CKB X 1688直营商城',
		component: lazy(() => import('../../container/goods/Classify'))
	},
	{
		path: '/goods/hot',
		title: '热门单品',
		component: lazy(() => import('../../container/goods/HotSingle/Index'))
	},
	{
		path: '/goods/onHandList',
		title: '顺手捎列表',
		component: lazy(() => import('../../container/goods/OnHandList'))
	}
];
