/*
 * @Author: tianzhitong laotianwy@163.com
 * @Date: 2023-08-30 10:09:32
 * @LastEditors: huajian
 * @LastEditTime: 2024-03-04 15:38:49
 * @FilePath: /ckb-easy-h5-fed/src/Atoms/User.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { atom } from 'jotai';
// import { Site } from '@/const/enum';
// import { CustomerRes } from '@/common/Layout/Header';
import { CACHE_STATION_NAME } from '@/const/staticURL';
import { lang } from '@/i18n/i18n';
import { CustomerDetailRespDTO } from '@/service/customer';
import { mapStationList } from '../common/Maps';

export type UserDetailType = CustomerDetailRespDTO & {
	membership: {
		templateLevel: number;
	};
};
/** 是否登录 */
const isLogin = atom<boolean>(false);
/** 用户信息 */
const userDetail = atom<UserDetailType>({} as UserDetailType);
/** 客服信息数量 */
const serviceNum = atom<number>(0);
/** 首页是否启用 */
const enableIndex = atom(mapStationList.get('JapanStation'));

// const getDefaultStation = () => {
//     if (lang === undefined) {
//         return (
//             (window.localStorage.getItem(CACHE_STATION_NAME) as Site) || Site.JP
//         );
//     }
//     if (lang === 'ja') {
//         return Site.JP;
//     } else if (lang === 'ko') {
//         return Site.KR;
//     } else if (lang === 'en') {
//         return Site.UK;
//     }
// };
// /** 站点 */
// const stationCode = atom<Site>(getDefaultStation() ?? Site.JP);

/** 汇率 */
const exchangeRate = atom<number>(0);
/** 强制更新app */
const foreceUpdateApp = atom<number>(0);

export default {
	exchangeRate,
	isLogin,
	userDetail,
	serviceNum,
	enableIndex,
	foreceUpdateApp
};
