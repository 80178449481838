/*
 * @Author: shiguang
 * @Date: 2023-04-26 10:50:23
 * @LastEditors: huajian
 * @LastEditTime: 2024-02-26 14:35:18
 * @Description: 路由页面
 */
import { ComponentType, LazyExoticComponent, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ROUTER_ORDER } from './order';
import { ROUTER_GOODS, ROUTER_INDEX_NAME } from './goods';
import { ROUTER_USER } from './user';
import { ROUTER_SETTLEMENT } from './settlement';

export type RouteItem = RouteObject & {
	/** 是否需要右侧自定义按钮 */
	isNeedNavBarRight?: boolean;
	/** 是否展示tabbar */
	showTabBar?: boolean;
	/**
	 * 路由对应的页面
	 */
	component?: LazyExoticComponent<ComponentType<any>> | ComponentType<any>;
	/**
	 * 路由地址
	 */
	path: string;
	/**
	 * 父级路由，用于menu的高亮判断，例如 detail 页面 要定位到 列表页面 菜单上
	 */
	activePath?: string;
	/**
	 * 默认false，如果为true时，需要和路由相同时才能匹配，但是如果有斜杠也是可以匹配上的。
	 */
	exact?: boolean;
	/**
	 * 是否严格匹配斜杠结尾
	 */
	strict?: boolean;
	/**
	 * 页面标题
	 */
	title?: string;
	/**
	 * 图标
	 */
	icon?: React.ReactNode;
	layout?: React.FC<any>;
	/**
	 * 显示左侧菜单，默认为true
	 */
	showMenu?: boolean;
	/**
	 * 显示左侧菜单，默认为true
	 */
	showSlider?: boolean;
	/**
	 * 显示头部，默认为true，如果设为false，则无头部组件
	 */
	showHeader?: boolean;
	/** 页面权限code */
	pagePermissionCode?: string;
	/** 操作权限code */
	actionPermissionCode?: string[];
	// 嵌套路由
	children?: RouteItem[];
};

const router: RouteItem[] = [
	{
		path: '/',
		title: '',
		component: () => {
			return <Navigate to={`${ROUTER_INDEX_NAME}`} />;
		}
	},
	/** 订单相关 */
	...ROUTER_ORDER,
	/** 商品相关 */
	...ROUTER_GOODS,
	/** 用户相关 */
	...ROUTER_USER,
	/** 结算相关 */
	...ROUTER_SETTLEMENT,
	{
		path: '/*',
		title: '迷失方向',
		component: function () {
			return <div>{window._$m.t('您似乎迷失了方向')}~</div>;
		}
	},
	{
		path: '/test/paypal',
		title: '测试paypal支付',
		component: lazy(() => import('../../container/test/PayPal'))
	},
	{
		path: '/order/search',
		component: lazy(() => import('../../container/order/OrderSearch'))
	}
];

const newrouters = [...router];
if (process.env.REACT_APP_ENV !== 'prod') {
	newrouters.unshift({
		path: '/dev/login',
		title: 'dev-login',
		component: lazy(() => import('../../container/dev/Login'))
	});
}
export default newrouters;
