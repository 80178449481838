/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2023-12-28 13:46:12
 * @LastEditors: huajian
 * @LastEditTime: 2024-03-04 11:50:29
 * @FilePath: /ckb-directlogin-h5-fed/src/component/Button/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import {
  GetProps,
  Stack,
  styled,
  Button,
  Text,
  ButtonProps } from
'@snifftest/tamagui';
import * as SDK from '@snifftest/sdk/lib/rn';
const ButtonPrimary = styled(Button, {
  name: 'ButtonPrimary',
  backgroundColor: '#1C2026',
  hoverStyle: {
    backgroundColor: '#1C2026'
  },
  pressStyle: {
    backgroundColor: '#1C2026',
    opacity: 0.8
  },
  width: '100%',
  borderRadius: SDK.fitSize(26),
  height: SDK.fitSize(52),
  focusStyle: {
    outlineWidth: 0
  }
});
const ButtonDefault = styled(Button, {
  name: 'ButtonDefault',
  backgroundColor: '#fff',
  hoverStyle: {
    backgroundColor: '#fff',
    borderColor: '#CDD2DA'
  },
  pressStyle: {
    backgroundColor: '#fff',
    borderColor: '#CDD2DA',
    opacity: 0.8
  },
  width: '100%',
  borderRadius: SDK.fitSize(26),
  height: SDK.fitSize(52),
  borderWidth: 2,
  borderColor: '#CDD2DA',
  focusStyle: {
    outlineWidth: 0
  }
});
const TextStyled = styled(Text, {
  name: 'TextStyled',
  color: '#fff',
  fontWeight: '500',
  fontSize: SDK.fitSize(16)
});
interface ButtonProps1 extends ButtonProps {
  children?: React.ReactNode;
  type?: 'default' | 'primary';
}
const Buttons = (props: ButtonProps1) => {
  const { children, type = 'default' } = props;
  return (
    <>
			{type === 'default' ?
      <ButtonDefault {...props}>
					{typeof children === 'string' ?
        <Text color="#1C2026" fontWeight="500">
							{children}
						</Text> :

        children}

				</ButtonDefault> :

      <ButtonPrimary {...props}>
					{typeof children === 'string' ?
        <Text color="#fff" fontWeight="500">
							{children}
						</Text> :

        children}

				</ButtonPrimary>}

		</>);

};
export default Buttons;