/*
 * @Date: 2023-12-05 17:55:17
 * @LastEditors: yusha
 * @LastEditTime: 2024-05-15 14:48:17
 * @FilePath: /ckb-easy-h5-fed/src/component/ActiveTag/index.tsx
 * @Description:
 */
// import { Image, Text } from 'react-native';
import { XStack, Text, Image } from '@snifftest/tamagui';
import { ReactNode, useContext } from 'react';
import * as SDK from '@snifftest/sdk/lib/rn';
import { TextStyle } from 'react-native';
import { ContextPreView } from '../../context';
interface ActiveTagProps {
	/** 内容 */
	text: string;
	/** 提示 */
	tips?: string;
	setConfirmMessageNode?: (ReactNode) => void;
	tipsNode?: ReactNode;
	textStyle?: TextStyle;
}
const fitSize = SDK.fitSize;
const ActiveTag = (props: ActiveTagProps) => {
	const { setConfirmMessageNode, tipsNode, textStyle = {} } = props;
	const { setConfirmMessage } = useContext(ContextPreView);
	const clickShowTips = () => {
		// 当不传自定义的tipNode则走以前的展示逻辑
		!tipsNode && setConfirmMessage?.(props.tips);
		// 当传自定义的tipNode则走传进来的方法
		tipsNode && setConfirmMessageNode?.(props.tipsNode);
	};
	return (
		<XStack
			style={{
				display: 'flex',
				alignItems: 'center',
				marginBottom: fitSize(4)
			}}
		>
			{props.text && (
				<Text
					style={{
						paddingLeft: fitSize(8),
						paddingRight: fitSize(8),
						paddingTop: fitSize(4),
						paddingBottom: fitSize(4),
						borderRadius: fitSize(4),
						borderWidth: 1,
						borderColor: '#e6eaf0',
						backgroundColor: '#fff',
						color: '#ff5010',
						fontSize: fitSize(10),
						fontWeight: '500',
						lineHeight: fitSize(16),
						...textStyle
					}}
				>
					{props.text}
				</Text>
			)}

			{(props.tips || props.tipsNode) && (
				<Image
					onPress={clickShowTips}
					style={{
						width: fitSize(16),
						height: fitSize(16),
						marginLeft: fitSize(4)
					}}
					source={{
						uri: 'https://static-jp.theckb.com/static-asset/easy-app/question@3.x.png'
					}}
				/>
			)}
		</XStack>
	);
};
export default ActiveTag;
