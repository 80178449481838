/*
 * @Author: shiguang
 * @Date: 2023-04-28 11:51:00
 * @LastEditors: yusha
 * @LastEditTime: 2024-05-17 16:03:49
 * @Description: interceptors
 */
// import { message } from 'antd';
// import gray from '@sniff/gray/dist/index.esm';
import { useLocation } from 'react-router-dom';
import { Toast } from 'antd-mobile';
import queryString from 'query-string';
import { debounce } from 'lodash';
import axios from 'axios';
import Cookie from 'js-cookie';
import { api, apiInstanceList } from '@/service';
import { getLang, getStationCode } from '@/i18n/i18n';
import apiCode from '@/i18n/locales/apiCode.json';
import { jsBridge } from '@/utils/jsBridge';
import { thinkingdata } from '../buryingPoint';
import { loginEnv, logout, TokenSignCookie } from './login';

// const graify = gray(serviceConfig.baseURL);
/** 请求接口时请求头需要加的东西 */
export const apiTag = '20240401-hunderd-fee';
// 项目文件根目录
const baseURL = process.env.REACT_APP_PUBLIC_PATH;
const isProd = process.env.NODE_ENV === 'production';
const axiosV = axios.create({
	timeout: 3 * 60 * 1000,
	baseURL
});

// 请求是否刷新资源
const validVersion = debounce(() => {
	const curVersion: any = window.curVersion ?? { time: 0 };
	let request = false;
	if (Date.now() > curVersion.time + 10 * 60 * 1000 && !request) {
		request = true;
		axiosV.get(`/compilerInfo.json?t=${Date.now()}`).then((res) => {
			request = false;
			if (
				curVersion.version &&
				curVersion.version !== res?.data?.compilterTime
			) {
				requestAnimationFrame(() => {
					window.location.reload();
				});
			}
			curVersion.version = res?.data?.compilterTime;
			curVersion.time = Date.now();
			window.curVersion = curVersion;
		});
	}
}, 1000);
declare module 'axios' {
	interface AxiosRequestConfig {
		/** 使用mock */
		useMock?: boolean;
		isNotShowToast?: boolean;
	}
}
export interface User {
	loginInfo: {
		userId: string;
		username: string;
	};
	userInfo: {};
	auth: never[];
	token: string;
}

export const getToken = async (config) => {
	config.headers = config.headers ?? {};
	const cookieToken = await Cookie.get(TokenSignCookie);
	const localToken = window.localStorage.getItem(`production_route/token`);
	if (cookieToken || localToken) {
		if (config.url?.includes('/changeLoginByType')) {
			return cookieToken;
		}
		return localToken || cookieToken;
	}
};

export const getShopId = () => {
	const shopStr = window.localStorage.getItem('production_route/curShop');
	if (!shopStr) return;
	try {
		const token = shopStr;
		return token;
	} catch (e) {
		return undefined;
	}
};

/**
 * admin 端
 */
export const getUserInLocal = () => {
	const usrStr = window.localStorage.getItem('user');
	if (!usrStr) return;
	try {
		const user = JSON.parse(usrStr).user as User;
		return user;
	} catch (e) {
		return undefined;
	}
};
const stationCode = getStationCode();

const getUrl = (token) => {
	// token存在代表token失效的情况
	const path = window.location.href;
	if (window.location.port && token) {
		return encodeURIComponent(
			`http:${window.location.hostname}:${window.location.port}/goods/home`
		);
	}
	if (!window.location.port && token) {
		return encodeURIComponent(
			`https:${window.location.hostname}/goods/home`
		);
	}
	return encodeURIComponent(path);
};
export const goToLogin = async () => {
	const token = window.localStorage.getItem('production_route/token');
	logout();
	let { channel, channel_group } = queryString.parse(window.location.search);

	const sessionStorageChannelData = JSON.parse(
		window.sessionStorage.getItem('h5ChannelFromOther') || '{}'
	);
	if (sessionStorageChannelData.channel_group) {
		channel = sessionStorageChannelData.channel;
		channel_group = sessionStorageChannelData.channel_group;
	}
	const _env = process.env.REACT_APP_ENV as loginEnv;
	const url = getUrl(token);
	// 检查 sessionStorage 或 localStorage 中的标识,从登录页面回退
	const isFromOtherDomain = sessionStorage.getItem('isFromOtherDomain');

	let _searchObj = { _tasdk: 'd' + thinkingdata.getDistinctId() } as Record<
		string,
		any
	>;
	if (channel) {
		_searchObj['channel'] = channel;
	}
	if (channel_group) {
		_searchObj['channel_group'] = channel_group;
	}
	if (isFromOtherDomain === 'true' && !window.location.port && token) {
		// todo
		sessionStorage.removeItem('isFromOtherDomain');
		const _search = queryString.stringify(_searchObj);
		window.location.replace(`/goods/home?` + _search);
		return;
	}

	// 如果是app，直接跳转登录
	if (window?.ReactNativeWebView) {
		await jsBridge.postMessage({
			type: 'DIRCT_goNativeLogin',
			payload: {}
		});
		return;
	}
	if (window.location.port || _env === 'prod1') {
		_searchObj = {
			..._searchObj,
			from: url
		};
		const _search = queryString.stringify(_searchObj);
		// 跳转登陆页面（目前先写本地的登录）
		window.location.href = `/dev/login?` + _search;
		sessionStorage.setItem('isFromOtherDomain', 'true');
		return;
	}
	_searchObj = {
		..._searchObj,
		from: url
	};
	// if (!_searchObj._tasdk) {
	// 	_searchObj._tasdk = 'd' + thinkingdata.getDistinctId();
	// }

	const _search = queryString.stringify(_searchObj);
	sessionStorage.setItem('isFromOtherDomain', 'true');
	if (_env === 'test') {
		// 跳转登陆页面（目前先写本地的登录）
		window.location.href =
			`https://test-login-m.3fbox.com/login/home?` + _search;
	}
	if (_env === 'pre') {
		// 跳转登陆页面（目前先写本地的登录）
		window.location.href =
			`https://pre-login-m.3fbox.com/login/home?` + _search;
	}
	if (_env === 'prod') {
		// 跳转登陆页面（目前先写本地的登录）
		window.location.href =
			`https://login-m.3fbox.com/login/home?` + _search;
	}
};

const whiteApicode = ['33000000'];

apiInstanceList.forEach((item) => {
	// 请求拦截
	item.instance.instance.interceptors.request.use(async function (config) {
		isProd && validVersion();
		config.headers = config.headers ?? ({} as any);
		const isPost = ['POST', 'post'].includes(config.method!);
		const token = await getToken(config);
		const shopId = getShopId();

		if (token) {
			config.headers['X-Authtoken'] = token;
		}
		if (shopId && !config.url?.includes('/changeLoginByType')) {
			config.headers['X-Authshopid'] = shopId;
		}
		if (isPost) {
			if (!config.data && config.params) {
				config.data = config.params;
			}
			config.params = {};
			config.headers['Content-Type'] = 'application/json';
		}
		if (stationCode) {
			config.headers['X-stationCode'] = stationCode;
		}
		config.headers['X-GRAY-TAG'] = apiTag;
		// config.headers['X-GRAY-TAG'] = apiConfig.requestEnv;
		if (config['useMock']) {
			const queryPath = (config.baseURL! + config.url!).replace(
				// eslint-disable-next-line no-useless-escape
				/https{0,1}:\/\/[^\/]+/,
				''
			);
			config.headers['Mock-Query-Path'] = queryPath;
			config.baseURL =
				'http://192.168.201.56:8093/functions/v1/mockapi' + queryPath;
			config.url = '';
		}
		// await graify(config);
		return config;
	});

	// 返回拦截
	item.instance.instance.interceptors.response.use(
		function (response) {
			if (response.data.code === '10000000') {
				goToLogin();
				return Promise.reject(response.data.msg);
			}

			if (response.config.responseType === 'blob') {
				return response;
			}
			// 特殊 code 处理
			if (
				[
					'31011004',
					'31011001',
					'31011002',
					'31011003',
					'31030000',
					'31030002'
				].includes(response.data.code)
			) {
				return Promise.resolve(response);
			}

			if (!response.data.success) {
				// 获取语言
				const lang = getLang();
				/** 翻译后的错误信息, 若是不存在，则展示中文 */
				const errMessaage =
					apiCode[lang][response.data?.code] ??
					apiCode['zh_CN'][response.data?.code];
				const apiCodeArr = Object.keys(apiCode[lang]) ?? [];
				if (apiCodeArr.length <= 0) {
					return Promise.reject(response.data.msg);
				}
				// 白名单apicode
				if (whiteApicode.includes(response.data?.code)) {
					Toast.show(errMessaage);
					return Promise.reject(errMessaage);
				}
				// 如果有apicode，且该错误码包含在apicode中，则透出msg字段
				if (
					apiCodeArr.length > 0 &&
					apiCodeArr.includes(response.data?.code)
				) {
					const _response = {
						...response,
						data: {
							...response.data,
							msg: errMessaage ?? response.data.msg
						}
					};
					return Promise.resolve(_response);
				}
				if (response.data.msg) {
					Toast.show(apiCode.noMatch);
				}
				return Promise.reject(response.data.msg);
			}
			if (response.data?.code === '0' && response.data.success === true) {
				return Promise.resolve(response);
			}
			// return response;
			// message.error(String(response.data.msg));
			return Promise.reject(response.data.msg);
		},
		function (error) {
			// console.log(444444, error);
			// message.error(window._$m.t('请求错误：') + String(error));
			return Promise.reject(error);
		}
	);
});

export const request = api;
