/*
 * @Author: yusha
 * @Date: 2024-01-08 15:42:23
 * @LastEditors: yusha
 * @LastEditTime: 2024-03-28 22:12:40
 * @Description: 商详头部
 */

import { Image, XStack, Text } from '@snifftest/tamagui';
import { memo, useContext, useEffect } from 'react';
import { CommonActions } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import * as SDK from '@snifftest/sdk/lib/rn';
import { tool } from '@/outs/utils/tool';
import ShareApp from '@/outs/components/Share';
import { ProductDetailRespDTO } from '@/outs/service/easyGoods';
import { ContextPreView } from '../../context';

const fitSize = SDK.fitSize;

interface HeaderProps {
	/** 是否页面滚动 */
	scrollHeight?: number;
	style?: any;
	/** 是否展示标题 */
	isShowTitle?: boolean;
	product?: any;
	env?: string;
	h5GoHomeJump?: () => void;
}
const Header = (props: HeaderProps) => {
	const {
		style,
		isShowTitle,
		scrollHeight = 0,
		product,
		env,
		h5GoHomeJump
	} = props;
	const customNavbarStatus = scrollHeight > 0;
	const navigation = SDK.useNavigate() as any;
	const insets = useSafeAreaInsets();
	const { ShareH5, backClick } = useContext(ContextPreView);

	// SDK.isH5判断不严谨，如果是app的webView判断也是h5，为了不影响别的模块，在这边重写了h5方法
	const isRealH5 = () => {
		return !window?.ReactNativeWebView;
	};

	return (
		<XStack
			style={{
				// height: fitSize(36),
				backgroundColor: !customNavbarStatus ? 'rgba(0,0,0,0)' : '#FFF',
				width: '100%',
				position: 'absolute',
				top: isShowTitle ? 0 : insets.top,
				left: 0,
				zIndex: 9,
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				paddingTop: fitSize(12),
				paddingLeft: fitSize(10),
				paddingRight: fitSize(10),
				paddingBottom: fitSize(10),
				...style
			}}
		>
			<XStack>
				{/* 返回上页 */}
				<XStack
					style={{
						height: fitSize(32),
						width: fitSize(32),
						backgroundColor: '#fff',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: fitSize(16),
						marginRight: fitSize(12)
					}}
					onPress={() => {
						// 如果是
						if (backClick) {
							backClick();
							return;
						}
						// navigation.goBack();
						if (SDK.isH5()) {
							return navigation(-1);
						}
						navigation.goBack();
					}}
				>
					<Image
						style={{
							height: fitSize(16),
							width: fitSize(16)
						}}
						resizeMode={'contain'}
						source={{
							uri: 'https://static-jp.theckb.com/static-asset/easy-app/left-arrow@3.x.png'
						}}
					/>
				</XStack>
				{/* 返回首页 */}
				<XStack
					style={{
						width: fitSize(32),
						height: fitSize(32),
						backgroundColor: '#fff',
						borderRadius: fitSize(16),
						alignItems: 'center',
						justifyContent: 'center'
					}}
					onPress={() => {
						if (!SDK.isH5()) {
							// 返回首页，直接重置路由
							navigation.dispatch(
								CommonActions.reset({
									index: 1,
									routes: [{ name: 'DirectIndex' }]
								})
							);
						} else {
							if (isRealH5()) {
								navigation('/goods/home', {
									replaceAll: true
								});
							} else {
								navigation('/user/globalReplaceView', {
									replace: true
								});
								h5GoHomeJump?.();
							}
						}
					}}
				>
					<Image
						style={{
							width: fitSize(16),
							height: fitSize(16)
						}}
						source={{
							uri: 'https://static-jp.theckb.com/static-asset/easy-app/house-home@3.x.png'
						}}
					/>
				</XStack>
			</XStack>

			<Text
				style={{
					flex: 1,
					textAlign: 'center',
					fontSize: fitSize(16),
					fontWeight: 'bold',
					color: '#333'
				}}
			>
				{(customNavbarStatus || isShowTitle) &&
					tool.strings('商品详情')}
			</Text>
			<XStack
				width={fitSize(70)}
				height={fitSize(32)}
				justifyContent="flex-end"
			>
				{SDK.isH5() ? (
					ShareH5 && <ShareH5 product={product} />
				) : (
					<ShareApp product={product} env={env} />
				)}
			</XStack>
		</XStack>
	);
};
export default memo(Header);
