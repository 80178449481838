/*
 * @Author: shiguang
 * @Date: 2023-08-22 17:43:43
 * @LastEditors: shiguang
 * @LastEditTime: 2023-09-11 18:21:55
 * @Description:
 */
import React from 'react';
import { PixelRatio, Platform, Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');

let pixelRatio = PixelRatio.get();
let screenPxW = PixelRatio.getPixelSizeForLayoutSize(width);
let basePx = Platform.OS === 'ios' ? 812 : 812;

export const px2dp = (px) => {
	var scaleWidth = (px * screenPxW * 2) / basePx;
	var size = Math.round(scaleWidth / pixelRatio + 0.5);
	return size;
};
