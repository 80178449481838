import React, { useEffect, useRef } from 'react';
import styles from './style.module.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  spin?: boolean;
  rtl?: boolean;
  color?: string;
  fill?: string;
  stroke?: string;
}

export default function Xianshimiaosha(props: IconProps) {
  const root = useRef<SVGSVGElement>(null);
  const { size = '1em', width, height, spin, rtl, color, fill, stroke, className, ...rest } = props;
  const _width = width || size;
  const _height = height || size;
  const _stroke = stroke || color;
  const _fill = fill || color;
  useEffect(() => {
    if (!_fill) {
      (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-fill]').forEach((item) => {
        item.setAttribute('fill', item.getAttribute('data-follow-fill') || '');
      });
    }
    if (!_stroke) {
      (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-stroke]').forEach((item) => {
        item.setAttribute('stroke', item.getAttribute('data-follow-stroke') || '');
      });
    }
  }, [stroke, color, fill]);
  return (
    <svg
      ref={root}
      width={_width}
      height={_height}
      viewBox="0 0 17 16"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
      {...rest}>

          <g><path d="M8.5 4a5 5 0 1 0 0 10 5 5 0 0 0 0-10ZM2.167 9a6.333 6.333 0 1 1 12.667 0A6.333 6.333 0 1 1 2.167 9Zm3-7.667c0-.368.298-.666.667-.666h5.333a.667.667 0 1 1 0 1.333H5.834a.667.667 0 0 1-.667-.667Z" clipRule="evenodd" fillRule="evenodd" data-follow-fill="#FF5010" fill={_fill} /><path d="M14.972 3.529c.26.26.26.682 0 .942l-1.334 1.334a.667.667 0 1 1-.942-.943l1.333-1.333c.26-.26.682-.26.943 0ZM8.5 5.333c.369 0 .667.299.667.667v2.667a.667.667 0 1 1-1.334 0V6c0-.368.3-.667.667-.667Z" clipRule="evenodd" fillRule="evenodd" data-follow-fill="#FF5010" fill={_fill} /></g>
        </svg>);

}