/*
 * @Author: yusha
 * @Date: 2024-01-08 15:49:36
 * @LastEditors: huajian
 * @LastEditTime: 2024-03-05 17:13:23
 * @Description: 商品banner
 */

import { memo, useContext, useMemo, useRef, useState } from 'react';
import Swiper from '@nutui/nutui-react-native/components/swiper';
import SwiperItem from '@nutui/nutui-react-native/components/swiperitem';
import { XStack, YStack, Text, Image } from '@snifftest/tamagui';
import LinearGradient from 'react-native-linear-gradient';
import * as SDK from '@snifftest/sdk/lib/rn';
import FastImage from '@/outs/components/FastImage';
import { defWidth } from '@/outs/utils/tool/utils';
import { changeImageCdn } from '@/outs/utils';
import { ContextPreView } from '../../context';

const px2dp = SDK.fitSize;

const Banner = () => {
	const { product = {} } = useContext(ContextPreView);
	const swiperRef = useRef<any>(null);
	const navigation = SDK.useNavigate() as any;
	/** 当前页码 */
	const [current, setCurrent] = useState(0);

	const swiperList = useMemo(() => {
		const swiperList: string[] = [];
		if (product.productItemImgs?.length || product.productMainImg) {
			product.productItemImgs?.forEach((item) => {
				swiperList.push(item.url ?? '');
			});
		}
		return swiperList.slice(0, 5);
	}, [product.productItemImgs, product.productMainImg]);

	return (
		<YStack width={defWidth()} height={defWidth()}>
			<Swiper
				ref={swiperRef}
				width={defWidth()}
				height={defWidth()}
				initPage={0}
				loop={true}
				autoPlay={0}
				pageContent={
					<XStack
						style={{
							position: 'absolute',
							bottom: px2dp(24),
							right: px2dp(20),
							height: px2dp(40),
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'row'
						}}
					>
						<Text
							style={{
								fontSize: px2dp(16),
								color: '#fff',
								lineHeight: px2dp(16)
							}}
						>
							{current + 1}
						</Text>
						<Text
							style={{
								fontSize: px2dp(10),
								color: '#fff'
							}}
						>
							/{swiperList.length}
						</Text>
					</XStack>
				}
				onChange={(index) => setCurrent(index)}
			>
				{swiperList?.map((banner, index) => {
					return (
						<SwiperItem key={index}>
							<YStack
								width={defWidth()}
								height={defWidth()}
								position="relative"
								onPress={() => {
									if (!SDK.isH5()) {
										navigation.navigate('ImageView', {
											list: swiperList,
											index
										});
									}
								}}
							>
								<LinearGradient
									style={{
										width: defWidth(),
										height: defWidth(),
										position: 'absolute',
										left: 0,
										right: 0,
										top: 0,
										zIndex: 1
									}}
									colors={[
										'transparent',
										'rgba(0, 0, 0, 0.4)'
									]}
									start={{ x: 0, y: 0 }}
									end={{ x: 0, y: 1 }}
								/>

								<FastImage
									style={{
										width: defWidth(),
										height: defWidth()
									}}
									// source={{ uri: changeImageCdn(banner) }}
									source={{ uri: banner }}
								/>
							</YStack>
						</SwiperItem>
					);
				})}
			</Swiper>
			<XStack
				style={{
					position: 'absolute',
					bottom: px2dp(34),
					alignItems: 'center',
					flexDirection: 'row',
					left: 0,
					right: 0,
					justifyContent: 'center'
				}}
			>
				{swiperList.map((img, index) => (
					<XStack
						key={index}
						onPress={() => {
							if (current === index) {
								return;
							}
							setCurrent(index);
							swiperRef.current.to(index);
						}}
						style={{
							width: px2dp(44),
							height: px2dp(44),
							marginRight: px2dp(12),
							borderRadius: px2dp(10),
							borderWidth: index === current ? px2dp(2) : 0,
							borderColor: index === current ? '#b0b7c2' : ''
						}}
					>
						<FastImage
							source={{
								uri: SDK.isH5() ? changeImageCdn(img) : img
							}}
							style={{
								borderRadius: px2dp(8),
								width: px2dp(40),
								height: px2dp(40)
							}}
						/>
					</XStack>
				))}
			</XStack>
		</YStack>
	);
};
export default memo(Banner);
