/*
 * @Author: huajian
 * @Date: 2024-01-25 16:09:24
 * @LastEditors: tianzhitong laotianwy@163.com
 * @LastEditTime: 2024-03-21 15:03:40
 * @Description:
 */
import { Mask } from '@snifftest/mask/lib/index.mjs';
import { ScrollView, Stack, Text, View, XStack } from '@snifftest/tamagui';
import * as SDK from '@snifftest/sdk/lib/rn';
import React, { useEffect, useState } from 'react';
import { Img } from '@snifftest/img';
import Coupon from '@snifftest/coupon';
import { useAsyncEffect } from 'ahooks';
import { tool } from '@/outs/utils/tool';
import Buttons from '@/outs/components/Buttons';
import { request } from '@/config/request/interceptors';
import { CouponRespDTO } from '@/outs/service/easyMarket';
import { getItem, setItem } from '@/outs/utils/tool/AsyncStorage';
import { BUTYPE } from '@/outs/const';
import { useNavigationWebViewH5 } from '@/outs/hooks/common/useNavigationWebViewH5';
import { TaEvent } from '@/outs/utils/TDAnalytics';

interface Props {
	open: boolean;
	isLogin: boolean;
	goLogin: () => void;
	customerId?: number;
	burialPoint: {
		// product_new_user_coupon商详弹窗，new_user_coupon首页弹窗
		position: 'product_new_user_coupon' | 'new_user_coupon';
		iKnowposition?: 'product_register_success';
	};
	type: 'detail' | 'home';
}
const couponNoticeKey = 'CouponNotice';
const couponNoticeLoginKey = 'CouponNoticeLogin';
export const CouponNotice: React.FC<Props> = (props) => {
	let noticeKey = couponNoticeKey;
	const params = SDK.useParams();

	if (params.needShowCouponNotice) {
		noticeKey =
			couponNoticeLoginKey +
			(props.customerId || params.needShowCouponNotice);
	}

	const isLogin = props.isLogin;
	const nav = SDK.useNav();
	const [visible, setVisible] = useState(false);
	const [couponList, setCouponList] = useState<CouponRespDTO[]>();
	const navigationWebViewH5 = useNavigationWebViewH5();

	useAsyncEffect(async () => {
		const haveShow = SDK.isH5()
			? window.localStorage.getItem(noticeKey)
			: await getItem(noticeKey);

		// 如果已经显示过 就不用再请求了数据
		if (haveShow) {
			return;
		}
		if (isLogin) {
			request.easyMarket.coupon
				.getCouponCustomerBySenceType(
					{
						bizType: 1,
						customerId:
							props.customerId ?? params.needShowCouponNotice
					},
					{ useMock: false }
				)
				.then((res) => {
					setCouponList(res.data || []);
				});
			return;
		}

		if (params.needShowCouponNotice && !isLogin) {
			return;
		}

		request.easyMarket.coupon
			.scene({ buType: BUTYPE.DIRECT, sceneCode: 1 }, { useMock: false })
			.then((res) => {
				setCouponList(res.data || []);
			});
	}, [isLogin, params.needShowCouponNotice]);

	useAsyncEffect(async () => {
		const isOpen =
			props.open &&
			!!couponList?.length &&
			(SDK.isH5()
				? !window.localStorage.getItem(noticeKey)
				: !(await getItem(noticeKey)));
		setVisible(isOpen);
		if (!isOpen) return;

		// 商详未登录或者已登录都有埋点
		if (props.type === 'detail') {
			// 埋点
			SDK.TA.track({
				event: TaEvent.POPUP_WINDOW,
				properties: {
					position: !isLogin
						? props.burialPoint.position
						: props.burialPoint.iKnowposition
				}
			});
		}
		// 首页只有未登录有埋点
		if (props.type === 'home' && !isLogin) {
			// 埋点
			SDK.TA.track({
				event: TaEvent.POPUP_WINDOW,
				properties: {
					position: props.burialPoint.position
				}
			});
		}

		if (SDK.isH5()) {
			window.localStorage.setItem(noticeKey, 'true');
		} else {
			setItem(noticeKey, true);
		}
	}, [props.open, couponList]);

	useEffect(() => {
		// 表明为空
		if (!couponList) {
			return;
		}
		// 如果请求了数据 但是数据为空 并且不是h5 没有登录的情况下
		if (!isLogin && props.open && !couponList.length && !SDK.isH5()) {
			props.goLogin();
		}
	}, [couponList, props, isLogin, props.open]);

	const onClose = () => {
		// 商详未登录或者已登录都有埋点
		if (props.type === 'detail') {
			// 埋点
			SDK.TA.track({
				event: TaEvent.CLOSE_WINDOW,
				properties: {
					position: isLogin
						? props.burialPoint.iKnowposition
						: props.burialPoint.position
				}
			});
		}
		// 首页未登录有埋点
		if (props.type === 'home' && !isLogin) {
			// 埋点
			SDK.TA.track({
				event: TaEvent.CLOSE_WINDOW,
				properties: {
					position: props.burialPoint.position
				}
			});
		}

		setVisible(false);
		if (SDK.isH5()) {
			window.localStorage.setItem(noticeKey, 'true');
		} else {
			setItem(noticeKey, true);
		}
	};

	return (
		<Mask open={visible} onPress={onClose}>
			<Stack
				position="absolute"
				left={SDK.fitSize(8)}
				right={SDK.fitSize(8)}
				top="50%"
				backgroundColor="#fff"
				borderRadius={SDK.fitSize(10)}
				marginTop={SDK.fitSize(-200)}
			>
				<XStack justifyContent="center" marginTop={SDK.fitSize(16)}>
					<Text fontWeight={'bold'} fontSize={SDK.fitSize(16)}>
						{isLogin
							? tool.strings('新人好礼')
							: tool.strings('新人登录即领专享券包')}
					</Text>
				</XStack>
				{isLogin && (
					<XStack justifyContent="center" marginTop={SDK.fitSize(8)}>
						<Text fontSize={SDK.fitSize(12)}>
							{tool.strings('您已获得新人专享券包，快去使用吧～')}
						</Text>
					</XStack>
				)}

				<ScrollView
					maxHeight={SDK.fitSize(220)}
					marginTop={SDK.fitSize(12)}
				>
					{couponList?.map((coupon, index) => {
						return (
							<XStack
								justifyContent="center"
								key={index}
								marginTop={index === 0 ? 0 : SDK.fitSize(16)}
							>
								<Coupon
									detail={coupon}
									isCanUse={isLogin ?? false}
									goToUse={() => {
										// 只有商详才需要埋去使用的埋点
										if (props.type === 'detail') {
											// 埋点
											SDK.TA.track({
												event: TaEvent.BTN_CLICK,
												properties: {
													position: 'go_use',
													source: props.burialPoint
														.iKnowposition
												}
											});
										}

										onClose();
										const url = `/goods/list/coupon?couponId=${coupon.couponId}&couponCustomId=${coupon.id}`;
										if (SDK.isH5()) {
											nav({ path: url });
											return;
										}
										navigationWebViewH5(url);
									}}
								/>
							</XStack>
						);
					})}
				</ScrollView>

				<XStack
					height={SDK.fitSize(1)}
					backgroundColor="#F0F2F5"
					marginTop={SDK.fitSize(12)}
				/>

				<XStack justifyContent="center" marginTop={SDK.fitSize(12)}>
					<Buttons
						width={SDK.fitSize(273)}
						type="primary"
						borderColor="rgba(240, 242, 245, 1)"
						borderWidth={SDK.fitSize(1)}
						onPress={() => {
							// 商详，我知道了和前往注册都有埋点
							if (props.type === 'detail') {
								// 埋点
								SDK.TA.track({
									event: TaEvent.BTN_CLICK,
									properties: {
										position: isLogin
											? 'i_known'
											: 'go_register',
										source: !isLogin
											? props.burialPoint.position
											: props.burialPoint.iKnowposition
									}
								});
							}
							// 首页只有前往注册才有埋点
							if (props.type === 'home' && !isLogin) {
								// 埋点
								SDK.TA.track({
									event: TaEvent.BTN_CLICK,
									properties: {
										position: 'go_register',
										source: props.burialPoint.position
									}
								});
							}

							onClose();
							if (isLogin) {
								return;
							}
							props.goLogin();
						}}
					>
						{isLogin
							? tool.strings('我知道了')
							: tool.strings('前往注册')}
					</Buttons>
				</XStack>

				<Img
					src="https://static-jp.theckb.com/static-asset/easy-app/Close-one@3.x.png"
					position="absolute"
					bottom={SDK.fitSize(-36)}
					left={SDK.getDeviceSize().width / 2 - 12}
					width={SDK.fitSize(24)}
					height={SDK.fitSize(24)}
					onPress={onClose}
					marginLeft={SDK.fitSize(-24 / 2)}
				/>
			</Stack>
		</Mask>
	);
};
