/*
 * @Author: yusha
 * @Date: 2023-10-19 16:23:17
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-04-11 11:44:53
 * @Description:
 */
import { lazy } from 'react';

export const ROUTER_USER = [
	{
		path: '/user/offlineRecharge',
		title: '充值',
		showHeader: true,
		showTabBar: false,
		component: lazy(() => import('../../container/user/OfflineRecharge'))
	},
	{
		path: '/user/rechargeResut',
		title: '充值结果页',
		showHeader: true,
		showTabBar: false,
		component: lazy(() => import('../../container/user/RechargeResut'))
	},
	{
		path: '/user/wallet',
		title: '资金钱包',
		showHeader: true,
		showTabBar: false,
		component: lazy(() => import('../../container/user/Wallet'))
	},
	{
		path: '/user/rechargeRecord',
		title: '充值记录',
		showHeader: true,
		showTabBar: false,
		component: lazy(() => import('../../container/user/RechargeRecord'))
	},

	{
		path: '/user/mine',
		title: '我的',
		component: lazy(() => import('../../container/user/Mine'))
	},
	{
		path: '/user/collection',
		title: '收藏',
		showHeader: true,
		component: lazy(() => import('../../container/user/Collection'))
	},
	{
		path: '/user/refund',
		title: '退款记录',
		showHeader: true,
		component: lazy(() => import('../../container/user/RefundList'))
	},
	{
		path: '/user/address/edit',
		title: '新增/编辑地址',
		component: lazy(() => import('../../container/user/AddOrEditAddress'))
	},
	{
		path: '/user/person/edit',
		title: '新增/编辑下单人信息',
		component: lazy(
			() => import('../../container/user/AddOrEditPersonInfo')
		)
	},
	{
		path: '/user/message/center',
		title: '消息中心',
		component: lazy(() => import('../../container/user/MessageCenter'))
	},
	{
		path: '/user/message/usageGuide',
		title: '利用規約页',
		component: lazy(() => import('../../container/user/UsageGuide'))
	},
	{
		path: '/user/message/usageGuide/detail',
		title: '利用規約详情',
		component: lazy(() => import('../../container/user/UsageGuideDetail'))
	},
	{
		path: '/user/CouponList',
		title: '优惠券',
		component: lazy(() => import('../../container/user/CouponList'))
	},
	{
		path: '/user/message/detail',
		title: '消息详情',
		component: lazy(() => import('../../container/user/MessageDetail'))
	},
	{
		path: '/user/globalReplaceView',
		title: 'app后台替代页面',
		component: lazy(() => import('../../container/user/GlobalReplaceView'))
	},
	{
		path: '/user/addressList',
		title: '地址列表',
		component: lazy(() => import('../../container/user/AddressList'))
	}
];
