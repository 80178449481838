import { Image, ImageProps, ImageViewer } from 'antd-mobile';
import { useState } from 'react';
import ZhanweituCkb from '@/common/icons/ZhanweituCkb';
import { changeImageCdn } from '@/utils';
import './index.scss';
/*
 * @Author: huajian
 * @Date: 2023-11-06 19:55:42
 * @LastEditors: yusha
 * @LastEditTime: 2024-03-18 10:28:12
 * @Description:
 */

interface Props extends ImageProps {
	/** 照片尺寸 */
	imgSize?: number | undefined;
	/** 是否可以预览 */
	isPreView?: boolean | undefined;
	/** 蒙层类名 */
	customizeMaskClass?: string;
}
export function CustomRMImg(props: Props) {
	const [visible, setVisible] = useState(false);
	const url = changeImageCdn(props.src, props.imgSize);
	return (
		<>
			<ImageViewer
				classNames={{
					mask: `customize-mask ${props.customizeMaskClass}`
				}}
				getContainer={document.querySelector('body')}
				image={changeImageCdn(props.src)}
				visible={visible}
				onClose={() => {
					setVisible(false);
				}}
			/>

			<Image
				onClick={() => {
					props.isPreView && setVisible(true);
				}}
				alt=""
				{...props}
				src={url}
				lazy={true}
				fallback={
					<div
						style={{
							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<ZhanweituCkb
							style={{
								width: '90%',
								height: '90%'
							}}
						/>
					</div>
				}
			/>
		</>
	);
}
