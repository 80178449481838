import React, { useEffect, useRef } from 'react';
import styles from './style.module.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  spin?: boolean;
  rtl?: boolean;
  color?: string;
  fill?: string;
  stroke?: string;
}

export default function ZhanweituCkb(props: IconProps) {
  const root = useRef<SVGSVGElement>(null);
  const { size = '1em', width, height, spin, rtl, color, fill, stroke, className, ...rest } = props;
  const _width = width || size;
  const _height = height || size;
  const _stroke = stroke || color;
  const _fill = fill || color;
  useEffect(() => {
    if (!_fill) {
      (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-fill]').forEach((item) => {
        item.setAttribute('fill', item.getAttribute('data-follow-fill') || '');
      });
    }
    if (!_stroke) {
      (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-stroke]').forEach((item) => {
        item.setAttribute('stroke', item.getAttribute('data-follow-stroke') || '');
      });
    }
  }, [stroke, color, fill]);
  return (
    <svg
      ref={root}
      width={_width}
      height={_height}
      viewBox="0 0 200 51"
      preserveAspectRatio="xMidYMid meet"
      fill=""
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
      {...rest}>

          <g><g data-follow-fill="#E7E7E7" fill={_fill}><path d="M76.923 0v50.962h-7.692V0zm50.972.816a59.664 59.664 0 0 1 2.702 7.202L88.832 23.68a1.923 1.923 0 0 0 0 3.601l41.765 15.662a59.664 59.664 0 0 1-2.702 7.202L86.132 34.484a9.615 9.615 0 0 1 0-18.006L127.895.816ZM61.538 0v7.692H25.481c-9.825 0-17.789 7.964-17.789 17.789 0 9.824 7.964 17.788 17.789 17.788h36.057v7.693H25.481C11.408 50.962 0 39.553 0 25.48 0 11.408 11.408 0 25.48 0h36.058Zm123.799 0C193.435 0 200 6.565 200 14.663c0 8.099-6.565 14.664-14.663 14.664h-46.875V0h46.875Zm-39.183 21.635h39.183a6.971 6.971 0 1 0 0-13.943h-39.183v13.943Z" /><path d="M185.337 21.635c8.098 0 14.663 6.565 14.663 14.663 0 8.098-6.565 14.664-14.663 14.664h-46.875V21.635h46.875Zm-39.183 21.634h39.183a6.971 6.971 0 1 0 0-13.942h-39.183v13.942Z" /></g></g>
        </svg>);

}