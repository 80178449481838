/*
 * @Author: yusha
 * @Date: 2023-12-14 10:16:05
 * @LastEditors: yusha
 * @LastEditTime: 2024-05-16 18:23:42
 * @Description:
 */

import { tool } from '@/outs/utils/tool';

/** 业务线 TODO */
export enum ENUM_SYSTEMSOURCE {
	D2C = 1,
	B2B,
	D2D
}
/** 国际料金pdf */
export const MAP_COST_PDF = new Map([
	[
		ENUM_SYSTEMSOURCE.D2C,
		{
			link: 'https://theckbstest-oss.theckbs.com/pdf/%E5%9B%BD%E9%9A%9B%E9%80%81%E6%96%99_D2C_JapanStation.pdf?v=1698744631496'
		}
	],

	[
		ENUM_SYSTEMSOURCE.B2B,
		{
			link: 'https://theckbstest-oss.theckbs.com/pdf/%E5%9B%BD%E9%9A%9B%E9%80%81%E6%96%99_B2B_JapanStation.pdf?v=1698744631496'
		}
	]
]);

export enum ENMU_PLATFORM_TYPE {
	/** 1688 */
	Alibaba = '1688',
	/** 淘宝 */
	TB = 'taobao',
	/** 天猫 */
	Tmall = 'tmall',
	/** 拼多多 */
	PDD = 'pdd'
}

export const zhPlatformToEn = {
	淘宝: ENMU_PLATFORM_TYPE.TB,
	天猫: ENMU_PLATFORM_TYPE.Tmall,
	拼多多: ENMU_PLATFORM_TYPE.PDD,
	'1688': ENMU_PLATFORM_TYPE.Alibaba
};

export const MAP_PLATFORM_TYPE = new Map([
	[
		ENMU_PLATFORM_TYPE.Alibaba,
		{ searchUrl: 'https://m.1688.com/offer_search/-6D7033.html?keywords=' }
	],

	[
		ENMU_PLATFORM_TYPE.TB,
		{ searchUrl: 'https://main.m.taobao.com/search/index.html?q=' }
	],

	[
		ENMU_PLATFORM_TYPE.Tmall,
		{ searchUrl: 'https://list.tmall.com/search_product.htm?q=' }
	],

	[
		ENMU_PLATFORM_TYPE.PDD,
		{
			searchUrl:
				'https://mobile.pinduoduo.com/search_result.html?search_key='
		}
	]
]);

/** 市场tag */
export enum FAVORITEFLAG {
	unfavorite,
	isfavorite
}

export enum BEHAVIORFLAG {
	/** 不喜欢 */
	unBehavior,
	/** 喜欢 */
	isBehavior,
	/** 不推荐 也就是不展示 */
	noRecommended
}

/** 用户协议条款 */
export enum ENUM_TERM_LIST {
	/** 直行便用户协议 */
	USER_TERM_CKB_JP = 'https://static-jp.theckb.com/static-asset/agreement/dirctApp/user-agreement2.html',
	/** 隐私政策 */
	USER_PRIVACY = 'https://static-jp.theckb.com/static-asset/agreement/dirctApp/privacy-policy1.html'
} /** 1688用户协议 english */ // USER_TERM_1688_EN = 'https://terms.alicdn.com/legal-agreement/terms/common_platform_service/20230920180838377/20230920180838377.html',
/** 1688隐私政策 english */ // USER_PRIVACY_1688_EN = 'https://terms.alicdn.com/legal-agreement/terms/privacy_policy_full/20230919144510933/20230919144510933.html',
/** 账号绑定及用户授权协议 english */ // USER_TERM_ACCOUNT_USER = 'https://terms.alicdn.com/legal-agreement/terms/common_platform_service/20230925140931267/20230925140931267.html',
/** 直营商城用户协议 */ // USER_TERM_DIRECTLY_SHOP = 'https://theckbstest-oss.theckbs.com/term/content/111.txt'
/** 登录人是被推广者，则表示推广者的赠送会员  */ export enum ENUM_PROMO_VIP_LEVEL /** 一级会员 */ {
	LEVEL1 = 'LEVEL1',
	/** 二级会员 */
	LEVEL2 = 'LEVEL2',
	/** 三级会员 */
	LEVEL3 = 'LEVEL3',
	/** 超级会员 */
	SUPER = 'SUPER'
}

/** 会员等级对应中文 */
export enum ENUM_MEMBER_NAEMS {
	LEVEL1 = tool.strings('经济舱'),
	LEVEL2 = tool.strings('标准舱'),
	LEVEL3 = tool.strings('商务舱'),
	SUPER = tool.strings('头等舱'),
	SPECIAL = tool.strings('专属会员')
}

/** 会员活动类型 */
export enum ENUM_VIP_TRIAL_TYPE {
	/** 普通会员活动 */
	COMMON = 'COMMON',
	/** 会员试用活动-价格版 */
	PRICE = 'PRICE',
	/** 会员试用活动-折扣版 */
	DISCOUNT = 'DISCOUNT'
}

/**
 * 国家及货币单位
 */
export enum ENUM_SITE {
	/**
	 * 日本站
	 */
	JA = 'JapanStation',
	/**
	 * 韩国站
	 */
	KO = 'KoreaStation',
	/**
	 * 英国站
	 */
	EN = 'UkStation'
}

/** 条款类型 */
export enum ENUM_TREM_TYPE {
	/** 利用规约 */
	UTILIZE = 'utilize',
	/** 隐私权限 */
	PRIVACY = 'privacy',
	/** 次转正提醒 */
	REGULAR = 'regular',
	/** OEM条款 */
	OEM = 'oem',
	/** 特定商取引法 */
	DSPECIFIC = 'dspecific',
	/** 利用规约 */
	DUTILIZE = 'dutilize'
}

/** 次转正接受类型 */
export enum ENUM_RISK_FLAG {
	/** 下单 */
	PLACE_ORDER = 1,
	API_ORDER = 2,
	EMAIL = 3
}

/** 三方登陆平台 */
export enum Plat {
	/** facebook */
	FB = 'facebook',
	/** google  */
	GG = 'google',
	/** Line */
	LE = 'line',
	/** apple */
	AP = 'apple',
	/** yahoo */
	YH = 'yahoo',
	/** tiktok */
	TK = 'tiktok'
}

/**
 * 国家枚举 同 PC
 */
export enum CountriesEnum {
	/**
	 * 日本
	 */
	Japan = 'JP',
	/**
	 * 澳大利亚
	 */
	Australia = 'AU',
	/**
	 * 加拿大
	 */
	Canada = 'CA',
	/**
	 * 爱尔兰
	 */
	Ireland = 'IE',

	/**
	 * 韩国
	 */
	Korea = 'KR',
	/**
	 * 马来西亚
	 */
	Malaysia = 'MY',
	/**
	 * 新西兰
	 */
	NewZealand = 'NZ',
	/**
	 * 新加坡
	 */
	Singapore = 'SG',
	/**
	 * 英国
	 */
	UK = 'GB',
	/**
	 * 其他国家
	 */
	other = 'OTHER'
}

export const EnumOrderStatus = {
	waitPay: {
		code: 0,
		cn: tool.strings('待支付'),
		icon: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/待支付.png'
	},
	waitShip: {
		code: 1,
		cn: tool.strings('待发货'),
		icon: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/待发货.png'
	},
	shipped: {
		code: 2,
		cn: tool.strings('已发货'),
		icon: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/已发货.png'
	},
	cancelled: {
		code: 3,
		cn: tool.strings('已取消'),
		icon: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/取消@2x.png'
	},
	closed: {
		code: 4,
		cn: tool.strings('已关闭'),
		icon: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/关闭@2x.png'
	}
};

// 活动类型枚举
export enum ACTIVITY_TYPE {
	/** 一口价格活动 */
	PRICE = 1,
	/** 折扣率活动 */
	DISCOUNT_RATE = 0
}
export enum PRODUCT_STATUS_TYPE {
	/** 0-待售卖 */
	PENDING_SALE = 0,
	/** 1-售卖中 */
	SELLING = 1,
	/** 2-待下架 */
	PENDING_OFFLINE = 2,
	/** 停止售卖 */
	STOP_SALE = 3,
	/** 4-删除 */
	DELETE = 4
}
// 商品类型
export enum PRODUCT_TYPE {
	// 0-线上，1-线下
	ON_LINE = 0,
	OFF_LINE = 1
}

/** 业务类型 */
export enum BUTYPE {
	DIRECT = 1
}

export const BARCODE = {
	BAR001: 'BAR001',
	BAR002: 'BAR002',
	BAR003: 'BAR003',
	BAR004: 'BAR004'
};

export const BarCodePrice = {
	[BARCODE.BAR001]: 100,
	[BARCODE.BAR002]: 500,
	[BARCODE.BAR003]: 1000
};

export enum HOME_ENUM {
	/** 一站式 */
	ONE_PRODUCT = 1,
	/** 生活用品 */
	LIVE_PRODUCT = 2
}
