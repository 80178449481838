/*
 * @Author: tianzhitong laotianwy@163.com
 * @Date: 2023-10-19 14:34:32
 * @LastEditors: yusha
 * @LastEditTime: 2024-02-29 15:51:48
 * @FilePath: /ckb-easy-h5-fed/src/container/goods/Detail/component/ShoppSwiper/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { ImageViewer, Swiper, SwiperRef } from 'antd-mobile';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { ProductDetailRespDTO } from '@/service/easyGoods';
import { CustomRMImg } from '@/component/CustomRMImg';
import { changeImageCdn } from '@/utils';
import './index.scss';
interface ShoppSwiperProps {
	product?: ProductDetailRespDTO;
}
const ShoppSwiper = (props: ShoppSwiperProps) => {
	const { product = {} } = props;
	const swiper = useRef<SwiperRef>(null);
	// const [showViewer, setShowViewer] = useState(false);
	const swiperList = useMemo(() => {
		const swiperList: string[] = [];
		if (product.productItemImgs?.length || product.productMainImg) {
			product.productItemImgs?.forEach((item) => {
				swiperList.push(item.url ?? '');
			});
		}
		return swiperList.slice(0, 5);
	}, [product.productItemImgs, product.productMainImg]);
	const [imgIndex, setImgIndex] = useState(0);
	return (
		<div className="goods-detail-swiper">
			<Swiper
				loop
				ref={swiper}
				defaultIndex={imgIndex}
				onIndexChange={(i) => {
					setImgIndex(i);
				}}
				indicator={(total: number, current: number) => {
					return (
						<div className="indicator">
							<span>{current + 1}</span>
							<span>/{total}</span>
						</div>
					);
				}}
			>
				{swiperList.map((item, index) => {
					console.log(item, 'item');
					return (
						<Swiper.Item
							key={item + index}
							onClick={() => {
								setImgIndex(index);
								ImageViewer.Multi.show({
									getContainer:
										document.querySelector('body'),
									images: swiperList.map((i) =>
										changeImageCdn(i)
									),
									defaultIndex: index
								});
							}}
						>
							<div className="swiper-image">
								<div className="wrap" />
								<CustomRMImg
									imgSize={750}
									className="img"
									isPreView={true}
									src={item}
									alt=""
								/>
							</div>
						</Swiper.Item>
					);
				})}
			</Swiper>
			<div className="img-list">
				{swiperList.map((img, index) => (
					<div
						key={img + index}
						onClick={() => {
							setImgIndex(index);
							swiper.current?.swipeTo(index);
						}}
						className={`imgBox ${index === imgIndex ? 'active' : ''}`}
					>
						<CustomRMImg
							key={img + index}
							className="img"
							src={img}
							imgSize={40}
							alt=""
						/>
					</div>
				))}
			</div>
		</div>
	);
};
export default memo(ShoppSwiper);
