/*
 * @Author: huajian
 * @Date: 2023-12-28 11:29:33
 * @LastEditors: huajian
 * @LastEditTime: 2024-01-03 17:28:17
 * @Description:
 */
import { config } from '@tamagui/config/v2';

import { createTamagui } from '@snifftest/tamagui';

export const themes = {
	light: {
		height: '.44rem',
		borderRadius: '.22rem',
		background: '#fff',
		borderStyle: 'solid',
		borderColor: 'rgba(240, 242, 245, 1)',
		borderWidth: '.01rem',
		color: 'rgba(28, 32, 38, 1)',
		warnColor: 'rgba(255, 80, 16, 1)'
	}
};

const tamaguiConfig = createTamagui({ ...config });
// this makes typescript properly type everything based on the config

type Conf = typeof tamaguiConfig;

declare module '@snifftest/tamagui' {
	interface TamaguiCustomConfig extends Conf {}
}
export default tamaguiConfig;
