import { useNavigate } from 'react-router-dom';
import { jsBridge } from '@/utils/jsBridge';
import Hoursehome from '@/common/icons/BottomnavigationHomepageDefault';
import styles from './index.module.scss';
function BackToHome() {
	const navigate = useNavigate();
	const goHome = async () => {
		// 如果是嵌入app
		if (window?.ReactNativeWebView) {
			navigate('/user/globalReplaceView', {
				replace: true
			});
			await jsBridge.postMessage({
				type: 'DIRCT_goHome',
				payload: {}
			});
			return;
		}
		navigate('/goods/home', {
			replace: true
		});
	};
	return (
		<div className={styles.backToHome}>
			<img
				style={{
					width: '.16rem',
					height: '.16rem'
				}}
				src="https://static-jp.theckb.com/static-asset/easy-h5/hourseHome.svg"
				onClick={goHome}
				alt=""
			/>
		</div>
	);
}
export default BackToHome;
