/*
 * @Author: yusha
 * @Date: 2024-04-03 10:42:45
 * @LastEditors: yusha
 * @LastEditTime: 2024-05-15 15:17:01
 * @Description: 商品运费
 */

import { useAsyncEffect } from 'ahooks';
import { memo, useContext, useState } from 'react';
import { Image, ScrollView, Text, YStack, styled } from '@snifftest/tamagui';
import { Stack } from '@tamagui/web';
import * as SDK from '@snifftest/sdk/lib/rn';
import { SafeAreaView } from 'react-native';
import {
	FreightFixStageInfo,
	FreightStageInfo,
	FreightTemplateDetailRespDTO
} from '@/outs/service/easyMarket';
import { tool } from '@/outs/utils/tool';
import CustomPopup from '@/outs/components/CustomPopup';
import FastImage from '@/outs/components/FastImage';
import {
	ResolveImageInfo,
	createPoolRequest,
	returnGetSizePromiseFunc
} from '@/outs/utils';
import { UserDefaultFreeShippingResp } from '@/outs/service/easyOrder';
import { ContextPreView } from '../../../context';

const fitSize = SDK.fitSize;

interface GoodsFreightProps {
	/** 运费模版弹窗是否展示 */
	freightModalVisible: boolean;
	setFreightModalVisible: (val: boolean) => void;
	/** 包邮信息 */
	freeShipInfo: UserDefaultFreeShippingResp;
	freightTemplate: FreightTemplateDetailRespDTO;
}
/** 计费方式0:数量范围，1：固定公式 */
enum FeeType {
	/** 数量范围 */
	RANG = 0,
	/** 固定公式 */
	FIXED = 1
}

const GoodsFreight = (props: GoodsFreightProps) => {
	const { product = {} } = useContext(ContextPreView);
	const { freeShipping } = product;
	const {
		freightModalVisible,
		setFreightModalVisible,
		freeShipInfo,
		freightTemplate
	} = props;

	/** 运费图片 */
	const [imgUrlArr, setImgUrlArr] = useState<ResolveImageInfo[]>([]);

	useAsyncEffect(async () => {
		const tasks: Array<() => Promise<ResolveImageInfo>> = [];
		tasks.push(
			returnGetSizePromiseFunc(
				'https://static-s.theckb.com/BusinessMarket/Easy/H5/freight1.png'
			)
		);
		const res = await createPoolRequest(tasks, 1);
		setImgUrlArr(res);
	}, []);

	const getText = (item, to) => {
		if (freightTemplate.feeType === FeeType.RANG) {
			return `${item.price}${tool.strings('元')}`;
		}
		if (Number(item.to) === 99999999) {
			return tool.strings(
				'{{x}}件以上运费{{y}}元，每增加{{z}}件，加收运费{{m}}元',
				{
					x: item.from,
					y: item.startFee,
					z: item.renewAmount,
					m: item.renewUnitFee
				}
			);
		}
		return tool.strings(
			'{{x}}件以下运费{{y}}元，每增加{{z}}件，加收运费{{m}}元',
			{
				x: to,
				y: item.startFee,
				z: item.renewAmount,
				m: item.renewUnitFee
			}
		);
	};
	/** table渲染 */
	const renderTableList = () => {
		// 计费方式0:数量范围，1：固定公式
		const data =
			(freightTemplate.feeType === FeeType.RANG
				? freightTemplate.amountRangeList
				: freightTemplate.fixRangeList) ?? [];
		const length = data.length;
		return (
			<>
				{!length && (
					<Text
						style={{
							textAlign: 'center',
							marginTop: fitSize(100)
						}}
					>
						{tool.strings('暂无数据')}
					</Text>
				)}
				{Boolean(length) &&
					data.map((item, index) => {
						// app会报错
						const _item: any = item;
						// 除去最后元素，其他的都要减1
						const _to =
							index < data.length - 1
								? Number(_item.to) - 1
								: Number(_item.to);
						// 计费方式0:数量范围，1：固定公式
						const text = getText(_item, _to);
						/** 获取件数 */
						const getNumberOfPackages = () => {
							if (_item.from && Number(_item.to) === 99999999) {
								return tool.strings('{{x}}件以上', {
									x: _item.from
								});
							}
							return `${_item.from}-${_to}`;
						};
						return (
							<TableRowStyle key={index}>
								<ColumnFirstStyle>
									<ColumnFirstTextStyle>
										{getNumberOfPackages()}
									</ColumnFirstTextStyle>
								</ColumnFirstStyle>
								<ColumnOtherStyle>
									<ColumnOtherTextStyle>
										{text}
									</ColumnOtherTextStyle>
								</ColumnOtherStyle>
							</TableRowStyle>
						);
					})}
			</>
		);
	};
	return (
		<>
			{/* 运费弹窗 */}
			<ChooseSpecStyle
				onPress={() => {
					setFreightModalVisible(true);
				}}
			>
				<Text
					style={{
						color: '#000',
						fontSize: fitSize(14),
						fontWeight: '500',
						lineHeight: fitSize(20)
					}}
				>
					{tool.strings('运费活动')}
				</Text>
				<Image
					source={{
						width: fitSize(16),
						height: fitSize(16),
						uri: 'https://static-jp.theckb.com/static-asset/easy-app/add@3.x.png'
					}}
				/>
			</ChooseSpecStyle>

			{/* 运费弹窗 */}
			<CustomPopup
				contentStyle={{
					height: '70%'
				}}
				visible={freightModalVisible}
				onClose={() => setFreightModalVisible(false)}
			>
				<YStack
					style={{
						flex: 1,
						flexDirection: 'column',
						padding: fitSize(12),
						height: '100%'
					}}
				>
					{/* 包邮 */}
					{freeShipping !== false && (
						<>
							<Text
								style={{
									fontSize: fitSize(14),
									fontWeight: 'bold',
									lineHeight: fitSize(22),
									textAlign: 'left',
									marginBottom: fitSize(12)
								}}
							>
								{tool.strings('运费活动')}
							</Text>
							<YStack style={{ flex: 1 }}>
								<SafeAreaView style={{ flex: 1 }}>
									<ScrollView style={{ flex: 1 }}>
										{imgUrlArr.map((item, index) => {
											return (
												<FastImage
													key={index}
													resizeMode="stretch"
													style={{
														width: '100%',
														height: item.imgH,
														zIndex: 226
													}}
													source={{
														uri: item.url
													}}
												/>
											);
										})}
									</ScrollView>
								</SafeAreaView>
							</YStack>
						</>
					)}
					{/* 不包邮 */}
					{freeShipping === false && (
						<>
							<Text
								style={{
									fontSize: fitSize(14),
									fontWeight: 'bold',
									lineHeight: fitSize(22),
									textAlign: 'left',
									marginBottom: fitSize(2)
								}}
							>
								{tool.strings('运费规则')}
							</Text>
							<Text
								style={{
									color: '#505762',
									fontSize: fitSize(12),
									fontWeight: 'bold',
									textAlign: 'left',
									marginBottom: fitSize(20)
								}}
							>
								{tool.strings(
									'以下运费规则针对部分商品（适用于{{cityName}}）',
									{
										cityName:
											// 当未登录的情况，给个兜底的
											freeShipInfo.address ?? '东京都'
									}
								)}
							</Text>
							<YStack
								style={{
									flex: 1,
									paddingLeft: fitSize(12),
									paddingRight: fitSize(12),
									borderRadius: fitSize(4)
								}}
							>
								<SafeAreaView style={{ flex: 1 }}>
									<ScrollView style={{ flex: 1 }}>
										<YStack
											style={{
												borderRadius: fitSize(8),
												overflow: 'hidden'
											}}
										>
											<TableRowStyle>
												<ColumnFirstStyle>
													<ColumnFirstTextStyle>
														{tool.strings('件数')}
													</ColumnFirstTextStyle>
												</ColumnFirstStyle>
												<ColumnOtherStyle>
													<ColumnOtherTextStyle>
														{tool.strings('运费')}
													</ColumnOtherTextStyle>
												</ColumnOtherStyle>
											</TableRowStyle>
											{renderTableList()}
										</YStack>
									</ScrollView>
								</SafeAreaView>
							</YStack>
						</>
					)}
				</YStack>
			</CustomPopup>
		</>
	);
};
const ChooseSpecStyle = styled(Stack, {
	width: '100%',
	marginTop: fitSize(8),
	paddingTop: fitSize(14),
	paddingBottom: fitSize(14),
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'row',
	borderBottomColor: '#f0f2f5',
	borderBottomWidth: fitSize(1)
});
const TableRowStyle = styled(Stack, {
	display: 'flex',
	flexDirection: 'row',
	marginBottom: fitSize(1)
});
const ColumnFirstStyle = styled(Stack, {
	width: fitSize(70),
	backgroundColor: '#F0F2F5',
	paddingVertical: fitSize(16),
	alignItems: 'center',
	justifyContent: 'center'
});
const ColumnFirstTextStyle = styled(Text, {
	textAlign: 'center',
	color: '#505762',
	fontSize: fitSize(12),
	fontWeight: 'bold'
});
const ColumnOtherTextStyle = styled(Text, {
	textAlign: 'left',
	color: '#505762',
	fontSize: fitSize(12),
	fontWeight: 'bold'
});
const ColumnOtherStyle = styled(Stack, {
	flex: 1,
	backgroundColor: '#FCFCFC',
	paddingVertical: fitSize(16),
	justifyContent: 'center',
	alignItems: 'center',
	paddingHorizontal: fitSize(8)
});
export default memo(GoodsFreight);
