/*
 * @Author: yusha
 * @Date: 2023-12-15 13:18:46
 * @LastEditors: huajian
 * @LastEditTime: 2024-03-05 17:10:19
 * @Description:
 */
import dayjs from 'dayjs';
import axios from 'axios';
import { Image } from '@snifftest/tamagui';
import { px2dp } from './pxtodp';
import { convertParamsToObj } from './convertParamsToObj';
import layout from './tool/layout';
/**
 * 格式化日期 cn->jp
 * @param formatStr
 * @returns
 */
export const formatDateCN2JP = (
	date?: dayjs.Dayjs | string,
	formatStr?: string
): string => {
	const format = formatStr || 'YYYY-MM-DD HH:mm:ss';
	if (!date) {
		return '';
	}
	if (typeof date === 'string') {
		return dayjs(date).add(1, 'hour').format(format);
	}
	return date.add(1, 'hour').format(format);
};

const aliRex = /(cbu01|img|cbu02|cbu03|cbu04).alicdn.com/;

/**
 * 通过cdn地址替换图片地址
 * @param imgUrl 图片地址
 * @returns
 */
export const changeImageCdn = (
	imgUrl: string | undefined = '',
	size: number | undefined = 0
) => {
	const imgPart = imgUrl?.split('.') || [];
	const reg = /(png|jpg|jpeg|bmp|webp)/;
	if (!reg.test(imgPart[imgPart.length - 1])) {
		return imgUrl;
	}
	if (
		(imgUrl.includes('.theckbs.com') || imgUrl.includes('.theckb.com')) &&
		!imgUrl.includes('x-oss-process')
	) {
		let url =
			imgUrl +
			(size
				? `?x-oss-process=image/resize,w_${size},limit_0,/format,webp`
				: '?x-oss-process=image/format,webp');
		if (url.includes('jpg')) {
			url += '/quality,Q_80';
		}
		return url;
	}

	if (aliRex.test(imgUrl)) {
		let newImgUrl = imgUrl.replace(aliRex, 'global-img-cdn.1688.com');
		const urlList = newImgUrl.split('.');
		// 如果商品详情有size参数，则不替换
		if (size && !/\dx\d/.test(urlList[urlList.length - 2])) {
			const maxSize = size > 375 ? 375 : size;
			urlList.splice(imgPart.length - 1, 0, `${maxSize}x${maxSize}`);
			newImgUrl = urlList.join('.');
		}
		return newImgUrl;
	}

	return imgUrl;
};
/** 请求文本 */
export const fetchText = (url: string) => axios.get(url);

/** 渲染html标签 */
export const renderHTML = (content: any, scriptStr = '') => {
	return `
        <html>
            <head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0;">
            <style>
                body,
                html {
                    height: 100%;
                    width: 100%;
                    overflow-x: hidden;
                }
                *{
                    padding:0;
                    margin:0;
                }
                p {
                    margin-bottom: 10px;
                    line-height: 24px;
                }
            </style>
            </head>
            <script>${scriptStr}</script>
            <body>
            <div style="overflow-x:hidden;" id="content">${content}</div>
            </body>
        </html>
    `;
};

export const toThousands = (x?: number | string) => {
	if (!x) {
		return x;
	}
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
// 添加金额分隔符
export function formatMoney(
	num: number | string | void,
	returnVal = true
): string {
	// return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	if (num !== 0 && (!num || isNaN(Number(num)))) {
		return '';
	}
	return returnVal
		? (Math.round(Number(num) * 100) / 100)
				.toLocaleString('en-us')
				.replace(/(\.\d\d)\d$/, '$1')
		: num.toLocaleString('en-us');
}
/** 防抖函数 */
export function debounce(fn: () => void, delay: number) {
	let timerId: any;

	return function (...args) {
		clearTimeout(timerId);
		timerId = setTimeout(() => {
			(fn as any)(...args);
		}, delay);
	};
}
export interface ResolveImageInfo {
	url: string;
	imgW: number;
	imgH: number;
}
/** 使用递归实现限制并发数量的方法 */
export const createPoolRequest = (
	tasks: Array<() => Promise<ResolveImageInfo>> = [],
	pool: number = 3
): Promise<ResolveImageInfo[]> => {
	/** 完成的数量 */
	const results: ResolveImageInfo[] = [];
	let running = 0; // 当前运行个数
	const resultsLength = tasks.length; // 用来判断最后的是否全部成功

	return new Promise((resolve) => {
		next();
		function next() {
			while (running < pool && tasks.length) {
				// 这个wile循环保证 一直有pool个请求在进行
				running++;

				// 删除第一个元素并返回当前删除的元素
				const task = tasks.shift();

				task?.()
					.then((result: ResolveImageInfo) => {
						results.push(result);
					})
					.finally(() => {
						running--;
						next();
					});
			}
			if (results.length === resultsLength) {
				// 全部执行结束
				resolve(results);
			}
		}
	});
};
/** 获取图片的宽，纯工具，不要把业务逻辑做绑定。TODO */
export const returnGetSizePromiseFunc = (
	url: string
): (() => Promise<ResolveImageInfo>) => {
	return () => {
		return new Promise((resolve) => {
			Image.getSize(
				url,
				(width, height) => {
					const imgW = layout.ScaleX(width);
					const imgH = Math.floor(
						(layout.ScreenWidth / width) * height
					);
					const ImgObj = {
						url,
						imgW,
						imgH
					};
					return resolve(ImgObj);
				},
				(error) => {
					const ImgObj = {
						url,
						imgW: 0,
						imgH: 0
					};
					return resolve(ImgObj);
				}
			);
		});
	};
};

export { px2dp, convertParamsToObj };
