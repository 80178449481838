/*
 * @Author: yusha
 * @Date: 2024-01-10 17:17:25
 * @LastEditors: yusha
 * @LastEditTime: 2024-04-03 11:09:37
 * @Description:
 */

import { ReactNode, memo } from 'react';
import { Modal, ViewStyle } from 'react-native';
import { Image, YStack } from '@snifftest/tamagui';
import React from 'react';
import { px2dp } from '@/outs/utils';

interface CustomPopupProps {
	/** 显示隐藏 */
	visible: boolean;
	/** 关闭 */
	onClose: () => void;
	/** 外部容器类名 */
	className?: string;
	/** 自定义关闭按钮 */
	customCloseIcon?: ReactNode;
	id?: string;
	children: ReactNode;
	contentStyle?: ViewStyle;
}
const CustomPopup = (props: CustomPopupProps) => {
	const { visible, onClose, contentStyle = {} } = props;
	return (
		<Modal
			transparent={true}
			visible={visible}
			presentationStyle={'overFullScreen'}
			onRequestClose={() => {
				onClose();
			}}
			statusBarTranslucent={true}
		>
			<YStack
				style={{
					backgroundColor: 'rgba(0,0,0,0.4)',
					position: 'absolute',
					left: 0,
					top: 0,
					bottom: 0,
					right: 0,
					zIndex: 222
				}}
			/>

			<YStack
				style={{
					backgroundColor: '#fff',
					height: '50%',
					width: '100%',
					position: 'absolute',
					bottom: 0,
					zIndex: 223,
					borderTopLeftRadius: px2dp(12),
					borderTopRightRadius: px2dp(12),
					...contentStyle
				}}
			>
				{props.children}
				<Image
					onPress={onClose}
					style={{
						width: px2dp(26),
						height: px2dp(26),
						position: 'absolute',
						top: px2dp(-40),
						right: px2dp(6),
						zIndex: 9999
						// backgroundColor: 'red'
					}}
					source={{
						uri: 'https://static-jp.theckb.com/static-asset/easy-app/Close-one@3.x.png'
					}}
				/>
			</YStack>
		</Modal>
	);
};
export default memo(CustomPopup);
