/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AddProductFavoriteReqDTO */
export interface AddProductFavoriteReqDTO {
	/**
	 * 商品类型，0-直采商品，1-代采商品
	 * @format int32
	 */
	favoriteProductType?: number;
	/** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园;YW:义乌小商品城; */
	platformType?: string;
	/** 商品编码 */
	productCode?: string;
	/** 商品主图 */
	productMainImg?: string;
	/** 商品价格 */
	productSellPrice?: number;
	/** 商品标题 */
	productTitle?: string;
}

/** AddProductTagReqDTO */
export interface AddProductTagReqDTO {
	/** 标签名称 */
	name?: string;
	/** 标签说明 */
	remark?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 标签类型， 0-价格标签， 1-运营标签
	 * @format int32
	 */
	type?: number;
}

/** BatchDeleteProductTagReqDTO */
export interface BatchDeleteProductTagReqDTO {
	/** 商品编码 */
	productCodeList?: string[];
	/**
	 * 选中的标签 1-价格标签 2-运营标签 3-全选
	 * @format int32
	 */
	selectedTag?: number;
}

/** BatchSaveProductTagReqDTO */
export interface BatchSaveProductTagReqDTO {
	/** 商品编码 */
	productCodeList?: string[];
	/** 运营标签 */
	productMarketTagList?: string[];
	/** 价格标签 */
	productPriceTagList?: string[];
}

/** BizResponse */
export interface BizResponse {
	code?: string;
	data?: object;
	msg?: string;
	success?: boolean;
}

/** CancelProductFavoriteReqDTO */
export interface CancelProductFavoriteReqDTO {
	/** 商品编码 */
	productCode?: string;
}

/** CheckSyncReqDTO */
export interface CheckSyncReqDTO {
	/** 商品编码信息 */
	productCode?: string;
}

/** EasyProductManageResp */
export interface EasyProductManageResp {
	/**
	 * 是否活动商品  0-否，1-是
	 * @format int32
	 */
	activityFlag?: number;
	basePrice?: number;
	/** @format int64 */
	calculateWeight?: number;
	/**
	 * 类目重量,单位g
	 * @format int64
	 */
	cateWeight?: number;
	/** 类目国际运费 */
	categoryInternationalFreight?: number;
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/** 出货条件 */
	deliveryCondition?: string;
	/** @format double */
	height?: number;
	/** 完整层级类目代码 */
	intactCateCode?: string;
	/** 完整层级类目名称(后台类目) */
	intactCateName?: string;
	/** 国际运费 */
	internationalFreight?: number;
	/**
	 * 是否校验销售库存,1是,0否
	 * @format int32
	 */
	isVerifyStockQuantity?: number;
	/** @format double */
	length?: number;
	/** 运营标签 */
	marketTag?: string;
	/** 运营标签名称 */
	marketTagName?: string;
	/** 材质信息日文 */
	materialInfoJa?: string;
	/** 材质信息中文 */
	materialInfoZh?: string;
	/** 注意事项 */
	notes?: string;
	/**
	 * 上架时间
	 * @format date-time
	 */
	onShelfTime?: string;
	/** 原标题 */
	originalProductTitle?: string;
	/** 原日文标题 */
	originalProductTitleJa?: string;
	platformType?: string;
	/** 溢价率 */
	premiumRate?: number;
	/** 价格标签 */
	priceTag?: string;
	/** 价格标签名称 */
	priceTagName?: string;
	/** @format int32 */
	productBuyMininum?: number;
	productCateCode?: string;
	/** 商品编码 */
	productCode?: string;
	/** 描述图片（商品详情） */
	productDescImgs?: string;
	productDescription?: string;
	productDescriptionJa?: string;
	productDetailSniffUrl?: string;
	/** 直采商详链接 */
	productDetailUrl?: string;
	/** 中文品名 */
	productItemCategoryZh?: string;
	/** item缩略图（大） */
	productItemImgs?: string;
	/** item缩略图（小） */
	productItemImgsS?: string;
	/** 商品主图 */
	productMainImg?: string;
	productNo?: string;
	productPriceLadder?: string;
	productPropImgs?: string;
	productProps?: string;
	productPropsJa?: string;
	productPropsJaList?: string;
	productPropsList?: string;
	productSellHighestPrice?: number;
	productSellLowestPrice?: number;
	/** 采购价 */
	productSellPrice?: number;
	/** 商品价格范围 */
	productSellPriceRange?: string;
	/**
	 * 商品销量
	 * @format int32
	 */
	productSellQuantity?: number;
	/** @format int32 */
	productStockQuantity?: number;
	/** 商品标题 */
	productTitle?: string;
	/** @format int32 */
	productType?: number;
	productVideo?: string;
	productVideoImg?: string;
	/** 销售价 */
	sellPrice?: number;
	/** 销售价范围 */
	sellPriceRange?: number;
	/**
	 * 0-售卖中、1-待售卖、2-停止售卖、3-待下架、4-删除
	 * @format int32
	 */
	status?: number;
	/** 系统商品标题 */
	systemProductTitle?: string;
	thirdProductCateCode?: string;
	/** 三方平台商详链接 */
	thirdProductDetailUrl?: string;
	thirdSize?: string;
	/** @format int64 */
	thirdWeight?: number;
	/** 类目运输属性 */
	transportAttr?: string;
	transportAttrEmpty?: boolean;
	/**
	 * 更新日期
	 * @format date-time
	 */
	updateTime?: string;
	/**
	 * 重，单位kg
	 * @format int64
	 */
	weight?: number;
	weightEmpty?: boolean;
	/** @format double */
	width?: number;
}

/** EasyProductResp */
export interface EasyProductResp {
	/** 基准价 */
	basePrice?: number;
	/** 商品阶梯价 */
	base_price_ladder?: string;
	/**
	 * 计算重量
	 * @format int64
	 */
	calculateWeight?: number;
	/**
	 * 类目重量
	 * @format int64
	 */
	cateWeight?: number;
	/** 类目国际运费 */
	categoryInternationalFreight?: number;
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/** 出货条件 */
	deliveryCondition?: string;
	/**
	 * 高，单位cm
	 * @format double
	 */
	height?: number;
	/** 完整层级类目代码 */
	intactCateCode?: string;
	/** 完整层级类目名称 */
	intactCateName?: string;
	/** 国际运费 */
	internationalFreight?: number;
	/**
	 * 是否校验销售库存
	 * @format int32
	 */
	isVerifyStockQuantity?: number;
	/**
	 * 长，单位cm
	 * @format double
	 */
	length?: number;
	/** 运营标签 */
	marketTag?: string;
	/** 运营标签名称 */
	marketTagName?: string;
	/** 材质信息日文 */
	materialInfoJa?: string;
	/** 材质信息中文 */
	materialInfoZh?: string;
	/** 注意事项 */
	notes?: string;
	/**
	 * 上架时间
	 * @format date-time
	 */
	onShelfTime?: string;
	/**
	 * 操作类型  0-保存，1-提交，2-上架
	 * @format int32
	 */
	operationType?: number;
	/** 原材质信息日文 */
	originalMaterialInfoJa?: string;
	/** 原中文品名 */
	originalProductItemCategoryZh?: string;
	/** 原标题 */
	originalProductTitle?: string;
	/** 原日文标题 */
	originalProductTitleJa?: string;
	/** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园 */
	platformType?: string;
	/** 溢价率 */
	premiumRate?: number;
	/** 价格标签 */
	priceTag?: string;
	/** 价格标签名称 */
	priceTagName?: string;
	/**
	 * 最小购买数
	 * @format int32
	 */
	productBuyMininum?: number;
	/** 类目编码 */
	productCateCode?: string;
	/** 平台商品SPU */
	productCode?: string;
	/**
	 * 商品报关ID
	 * @format int64
	 */
	productCustomsId?: number;
	/** 描述图片（商品详情） */
	productDescImgs?: string;
	/** 描述图片（商品详情） */
	productDescImgsList?: string[];
	/** 商品描述 */
	productDescription?: string;
	/** 日文商品描述 */
	productDescriptionJa?: string;
	/** sniff商品详情链接 */
	productDetailSniffUrl?: string;
	/** 直采商详链接 */
	productDetailUrl?: string;
	/** 商品国际运费 */
	productInternationalFreight?: number;
	/** 中文品名 */
	productItemCategoryZh?: string;
	/** item缩略图（大） */
	productItemImgs?: string;
	/** item缩略图（大） */
	productItemImgsList?: ProductImageUrlDTO[];
	/** item缩略图（小） */
	productItemImgsS?: string;
	productKeywords?: string;
	/** 主图 */
	productMainImg?: string;
	/** 运营标签集合 */
	productMarketTagCodeList?: string[];
	/** 商品番号 */
	productNo?: string;
	/** 商品阶梯价 */
	productPriceLadder?: string;
	/** 价格方案 */
	productPriceLadderList?: ProductPriceLadderResp[];
	/** 价格标签集合 */
	productPriceTagCodeList?: string[];
	/** 属性图片 */
	productPropImgs?: string;
	/** 商品详情属性 */
	productProps?: string;
	/** 商品详情属性（日文） */
	productPropsJa?: string;
	/** 商品sku属性日文列表 */
	productPropsJaList?: string;
	/** 商品sku属性列表 */
	productPropsList?: string;
	/** 商品价格 */
	productSellPrice?: number;
	/** 商品价格范围 */
	productSellPriceRange?: string;
	/**
	 * 销量
	 * @format int32
	 */
	productSellQuantity?: number;
	/** 商品名称 */
	productTitle?: string;
	/**
	 * 商品类型: 0-线上，1-线下
	 * @format int32
	 */
	productType?: number;
	/** 视频 */
	productVideo?: string;
	/** 视频海报图 */
	productVideoImg?: string;
	/** 商品sku属性日文 */
	propsJaList?: ProductPropGroupDTO[];
	/** 商品sku属性中文 */
	propsList?: ProductPropGroupDTO[];
	/** 备注:质检辅材成本,手续费用,商品国际运费JSON */
	remark?: string;
	/** 备注:质检辅材成本,手续费用,商品国际运费JSON */
	remarkRespVO?: ProductRemarkRespVO;
	/** 销售价 */
	sellPrice?: number;
	/** 商品阶梯价 */
	sell_price_ladder?: string;
	/** sku管理 */
	skuList?: EasySkuResp[];
	/**
	 * 商品状态：0-待售卖、1-售卖中、2-待下架、3-停止售卖、4-删除
	 * @format int32
	 */
	status?: number;
	/** 系统商品名称 */
	systemProductTitle?: string;
	/** 第三方商品类目编码 */
	thirdProductCateCode?: string;
	/** 三方平台商详链接 */
	thirdProductDetailUrl?: string;
	/** 三方尺寸 */
	thirdSize?: string;
	/**
	 * 三方重量
	 * @format int64
	 */
	thirdWeight?: number;
	/** 类目运输属性 */
	transportAttr?: string;
	/**
	 * 更新日期
	 * @format date-time
	 */
	updateTime?: string;
	/**
	 * 重，单位kg
	 * @format int64
	 */
	weight?: number;
	/**
	 * 宽，单位cm
	 * @format double
	 */
	width?: number;
}

/** EasySkuResp */
export interface EasySkuResp {
	/** 基准价 */
	basePrice?: number;
	/**
	 * 计算重量
	 * @format int64
	 */
	calculateWeight?: number;
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 可售库存
	 * @format int32
	 */
	enbleStockQuantity?: number;
	/** 交易手续费 */
	handlingPrice?: number;
	/**
	 * 高，单位cm
	 * @format double
	 */
	height?: number;
	/** 质检成本费 */
	inspectPrice?: number;
	/** 国际运费 */
	internationalFreight?: number;
	/**
	 * 长，单位cm
	 * @format double
	 */
	length?: number;
	/** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园 */
	platformType?: string;
	/** 溢价率 */
	premiumRate?: number;
	/**
	 * 最小购买数
	 * @format int32
	 */
	productBuyMininum?: number;
	/** 商品编码 */
	productCode?: string;
	/** 属性标识 */
	productProperties?: string;
	/** 属性名称 */
	productPropertiesName?: string;
	/** 属性名称(日文) */
	productPropertiesNameJa?: string;
	productPropertiesNameParse?: string;
	/** 销售单价(参考价) */
	productSellPrice?: number;
	/**
	 * 销售库存
	 * @format int32
	 */
	productSellQuantity?: number;
	/** 商品sku */
	productSku?: string;
	/** 商品sku图 */
	productSkuImg?: string;
	/**
	 * 已售库存
	 * @format int32
	 */
	productSoldQuantity?: number;
	/**
	 * 1688库存
	 * @format int32
	 */
	productStockQuantity?: number;
	/** 销售价 */
	sellPrice?: number;
	/**
	 * 商品状态:1-上架;0-下架
	 * @format int32
	 */
	status?: number;
	/** 三方尺寸 */
	thirdSize?: string;
	/**
	 * 三方重量
	 * @format int64
	 */
	thirdWeight?: number;
	/** 运输属性 */
	transportAttr?: string;
	/** 运输属性code */
	transportAttrCode?: string;
	/**
	 * 更新日期
	 * @format date-time
	 */
	updateTime?: string;
	/**
	 * 仓库库存
	 * @format int32
	 */
	warehouseQuantity?: number;
	/**
	 * 重，单位kg
	 * @format int64
	 */
	weight?: number;
	/**
	 * 宽，单位cm
	 * @format double
	 */
	width?: number;
}

/** EsProductEditReqDTO */
export interface EsProductEditReqDTO {
	marketTag?: string[];
	/** @format date-time */
	onShelfTime?: string;
	priceTag?: string[];
	productCode?: string;
	productMainImg?: string;
	productSellHighestPrice?: number;
	productSellLowestPrice?: number;
	productTitle?: string;
	/** @format int32 */
	status?: number;
	systemProductTitle?: string;
}

/** ExportProductReqDTO */
export interface ExportProductReqDTO {
	/** 商品原中文标题 */
	originalProductTitle?: string;
	/** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园 */
	platformType?: string;
	/** 价格标签 */
	priceTag?: string;
	/** 类目编码(三级) */
	productCateCodeList?: string[];
	/** 商品code */
	productCode?: string;
	/** 商品code */
	productCodeList?: string[];
	/** 运营标签集合 */
	productMarketTagCodeList?: string[];
	/** 商品标题 */
	productTitle?: string;
	/**
	 * 0-线上，1-线下
	 * @format int32
	 */
	productType?: number;
	/** SKU列表 */
	skuList?: string[];
	/** 站点 */
	stationCode?: string;
	/**
	 * 0-售卖中、1-待售卖、2-停止售卖、3-待下架、4-删除
	 * @format int32
	 */
	status?: number;
	/** 三方平台商详链接 */
	thirdProductDetailUrl?: string;
	/** 重量是否为空 */
	weightIsNull?: boolean;
}

/** ImgData */
export interface ImgData {
	/**
	 * 任务状态
	 * @format int32
	 */
	code?: number;
	/** 图片id */
	id?: string;
	/** 翻译后的图片地址，有效时间1小时，请及时保存 */
	imgPath?: string;
	/** 原图片地址 */
	originalPath?: string;
}

/** ImportProductReqDTO */
export interface ImportProductReqDTO {
	/**
	 * 商品类型 0-线上，1-线下
	 * @format int32
	 */
	productType?: number;
	/**
	 * 导入类型- 1：商品 2：sku
	 * @format int32
	 */
	type?: number;
	/** url */
	url?: string;
}

/** OrderItem */
export interface OrderItem {
	asc?: boolean;
	column?: string;
}

/** ProductActivityInfoDTO */
export interface ProductActivityInfoDTO {
	/** 活动编码 */
	activityCode?: string;
	/**
	 * 活动结束时间(时间戳)
	 * @format int64
	 */
	activityEndTime?: number;
	/** 商品活动最高价格 */
	activityHighestPrice?: number;
	/** 商品活动最高价格-日元 */
	activityHighestPriceJa?: number;
	/** 商品活动最低价格 */
	activityLowestPrice?: number;
	/** 商品活动最低价格-日元 */
	activityLowestPriceJa?: number;
	/**
	 * 活动类型，0-折扣率 1-一口价
	 * @format int32
	 */
	activityType?: number;
	/**
	 * 活动是否可以叠加券 0:不可叠加 1:可叠加
	 * @format int32
	 */
	addCoupon?: number;
	/** 折扣率 */
	discountRate?: number;
}

/** ProductCategoryFrontendAllParentNodeDTO */
export interface ProductCategoryFrontendAllParentNodeDTO {
	/** 类目图标 */
	cateIcon?: string;
	/** 分类名称-英文 */
	cateNameEn?: string;
	/** 分类名称-日文 */
	cateNameJp?: string;
	/** 分类名称-韩文 */
	cateNameKr?: string;
	/** 分类名称（中文） */
	cateNameZh?: string;
	/** 客户端频道tag */
	channelTag?: string;
	/**
	 * 默认;1-默认;0-非默认;
	 * @format int32
	 */
	defaultFlag?: number;
	/**
	 * 分类等级
	 * @format int32
	 */
	level?: number;
	/**
	 * 前台类目ID
	 * @format int64
	 */
	parentId?: number;
	/** @format int64 */
	productCategoryFrontendId?: number;
	stationCode?: string;
	/**
	 * 启用状态;1-启用;0-关闭;
	 * @format int32
	 */
	status?: number;
}

/** ProductCategoryFrontendInsertReqDTO */
export interface ProductCategoryFrontendInsertReqDTO {
	cateCodeList?: string[];
	cateIcon?: string;
	/** 类目名称（英文） */
	cateNameEn?: string;
	/** 类目名称（日文） */
	cateNameJp?: string;
	/** 类目名称（韩文） */
	cateNameKr?: string;
	cateNameZh?: string;
	/** @format int32 */
	defaultFlag?: number;
	/** @format int32 */
	level?: number;
	/** @format int64 */
	parentId?: number;
	/** 站点code */
	stationCode?: string;
}

/** ProductCategoryFrontendMapGroupRespDTO */
export interface ProductCategoryFrontendMapGroupRespDTO {
	cateCodeList?: ProductCategorySingleRespDTO[];
	/** @format int64 */
	productCategoryFrontendId?: number;
	/**
	 * 前台类目ID
	 * @format int64
	 */
	productCategoryFrontendReqId?: number;
}

/** ProductCategoryFrontendMapReqDTO */
export interface ProductCategoryFrontendMapReqDTO {
	cateCode?: string;
	cateCodeList?: string[];
	/**
	 * 前台商品类目ID
	 * @format int64
	 */
	productCategoryFrontendId?: number;
	/** 前台商品类目ID集合 */
	productCategoryFrontendIds?: number[];
	/** @format int32 */
	productCategoryFrontendLevel?: number;
	stationCode?: string;
}

/** ProductCategoryFrontendRespDTO */
export interface ProductCategoryFrontendRespDTO {
	cateIcon?: string;
	cateNameEn?: string;
	cateNameJp?: string;
	cateNameKr?: string;
	cateNameZh?: string;
	channels?: number[];
	children?: ProductCategoryFrontendRespDTO[];
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	defaultFlag?: number;
	/** @format int32 */
	fastCategoryFlag?: number;
	/** @format int32 */
	level?: number;
	/** @format int64 */
	parentId?: number;
	/** @format int64 */
	productCategoryFrontendId?: number;
	/** @format int32 */
	sort?: number;
	/** @format int32 */
	status?: number;
	/** @format date-time */
	updateTime?: string;
}

/** ProductCategoryFrontendShortRespDTO */
export interface ProductCategoryFrontendShortRespDTO {
	cateIcon?: string;
	cateNameEn?: string;
	cateNameJp?: string;
	cateNameKr?: string;
	cateNameZh?: string;
	children?: ProductCategoryFrontendShortRespDTO[];
	/** @format int32 */
	level?: number;
	/** @format int64 */
	parentId?: number;
	/** @format int64 */
	productCategoryFrontendId?: number;
	/**
	 * 排序;
	 * @format int32
	 */
	sort?: number;
	stationCode?: string;
}

/** ProductCategoryFrontendSortReqDTO */
export interface ProductCategoryFrontendSortReqDTO {
	/**
	 * 客户端类目主键ID
	 * @format int64
	 */
	categoryId?: number;
	/**
	 * 分类等级
	 * @format int32
	 */
	level?: number;
	/**
	 * 排序
	 * @format int32
	 */
	sort?: number;
	/** 客户端子类目列表 */
	subCategoryList?: ProductCategoryFrontendSortReqDTO[];
}

/** ProductCategoryFrontendUpdateReqDTO */
export interface ProductCategoryFrontendUpdateReqDTO {
	cateCodeList?: string[];
	cateIcon?: string;
	/** 类目名称（英文） */
	cateNameEn?: string;
	/** 类目名称（日文） */
	cateNameJp?: string;
	/** 类目名称（韩文） */
	cateNameKr?: string;
	cateNameZh?: string;
	/** @format int32 */
	defaultFlag?: number;
	/** @format int64 */
	productCategoryFrontendId?: number;
	/** 站点code */
	stationCode?: string;
}

/** ProductCategorySingleRespDTO */
export interface ProductCategorySingleRespDTO {
	cateCodeList?: string[];
	/** @format int32 */
	level?: number;
}

/** ProductDetailReqDTO */
export interface ProductDetailReqDTO {
	productCode: string;
	stationCode?: string;
}

/** ProductDetailRespDTO */
export interface ProductDetailRespDTO {
	/** 活动信息 */
	activityInfo?: ProductActivityInfoDTO;
	cateCode?: string;
	/**
	 * 是否收藏;1-已收藏;0-未收藏
	 * @format int32
	 */
	favoriteFlag?: number;
	/** 是否展示包邮tag */
	freeShipping?: boolean;
	/** 运营标签 */
	marketTag?: string[];
	/**
	 * 一元购标识， 0 否 1 是
	 * @format int32
	 */
	oneBuyFlag?: number;
	/** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园 */
	platformType?: string;
	/** 价格标签 */
	priceTag?: string;
	/**
	 * 最小购买数/起拍数
	 * @format int32
	 */
	productBuyMininum?: number;
	/** 商品编码 */
	productCode?: string;
	/** 描述图片 */
	productDescImgs?: string[];
	/** 日文商品描述 */
	productDescriptionJa?: string;
	/** sniff商品详情链接url */
	productDetailSniffUrl?: string;
	/** 直采商详链接url */
	productDetailUrl?: string;
	productExchangeRate?: number;
	/** item缩略图（大） */
	productItemImgs?: ProductImageUrlDTO[];
	/** item缩略图（小） */
	productItemImgsS?: ProductImageUrlDTO[];
	/** 主图 */
	productMainImg?: string;
	/** 商品番号 */
	productNo?: string;
	/** 商品阶梯价 */
	productPriceLadderList?: ProductPriceLadderDTO[];
	/** 商品属性组-日文 */
	productPropGroupJaList?: ProductPropGroupDTO[];
	/** 商品属性组 */
	productPropGroupList?: ProductPropGroupDTO[];
	/** 商品详情属性 */
	productProps?: string;
	/** 商品sku属性列表 */
	productPropsList?: string;
	/** 商品最高价格 */
	productSellHighestPrice?: number;
	/** 商品最高价格-日元 */
	productSellHighestPriceJa?: number;
	/** 商品最低价格 */
	productSellLowestPrice?: number;
	/** 商品最低价格-日元 */
	productSellLowestPriceJa?: number;
	/**
	 * 销量
	 * @format int32
	 */
	productSellQuantity?: number;
	/**
	 * 库存
	 * @format int32
	 */
	productStockQuantity?: number;
	/** 商品名称 */
	productTitle?: string;
	/**
	 * 商品类型， 0 线上 1 线下
	 * @format int32
	 */
	productType?: number;
	/** 商品视频介绍 */
	productVideo?: string;
	/** sku列表 */
	skuList?: SkuDetailRespDTO[];
	/** 站点 */
	stationCode?: string;
	/**
	 * 商品状态：0-待售卖、1-售卖中、2-待下架、3停止售卖、4-删除
	 * @format int32
	 */
	status?: number;
	/** 三方平台商详链接url */
	thirdProductDetailUrl?: string;
}

/** ProductErrorRespDTO */
export interface ProductErrorRespDTO {
	/** 错误码 */
	errorCode?: string;
	/** 失败原因 */
	msg?: string;
	/** 商品编码 */
	productCode?: string;
}

/** ProductFavoritePageReqDTO */
export interface ProductFavoritePageReqDTO {
	/**
	 * 商品类型，0-直采商品，1-代采商品
	 * @format int32
	 */
	favoriteProductType?: number;
	/** 按照时间排序 asc 由小到大，desc 由大到小 */
	orderByType?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** @format int32 */
	startIndex?: number;
	stationCode?: string;
}

/** ProductFavoriteRespDTO */
export interface ProductFavoriteRespDTO {
	/**
	 * 活动标识, 0-无活动, 1-有活动
	 * @format int32
	 */
	activityFlag?: number;
	/** 活动价 */
	activityPrice?: number;
	/** 日币活动价格 */
	activityPriceJa?: number;
	/**
	 * 活动类型 0-折扣价 1-一口价
	 * @format int32
	 */
	activityType?: number;
	/**
	 * 可用库存
	 * @format int32
	 */
	availableQuantity?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 客户ID
	 * @format int64
	 */
	customerId?: number;
	/**
	 * 商品类型，0-直采商品，1-代采商品
	 * @format int32
	 */
	favoriteProductType?: number;
	/** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园;YW:义乌小商品城; */
	platformType?: string;
	/** 商品类目编码 */
	productCateCode?: string;
	/** 商品编码 */
	productCode?: string;
	/** 商品主图 */
	productMainImg?: string;
	/** 商品价格 */
	productSellPrice?: number;
	/** 商品价格-日币 */
	productSellPriceJpy?: number;
	/** 商品标题 */
	productTitle?: string;
	/**
	 * 商品状态：0-待售卖、1-售卖中、2-待下架、3停止售卖、4-删除
	 * @format int32
	 */
	status?: number;
}

/** ProductImageUrlDTO */
export interface ProductImageUrlDTO {
	url?: string;
}

/** ProductKeyWordSearchReqDTO */
export interface ProductKeyWordSearchReqDTO {
	/** 营销活动标签列表 */
	activityTagList?: string[];
	/** 后端类目 */
	categoryCodeList?: string[];
	/** 营销频道标签列表 */
	channelTagList?: string[];
	/** 特集分类 */
	collectionTag?: string[];
	/** @format int64 */
	customerId?: number;
	/** 降序 */
	descendOrder?: boolean;
	/** 结束价格 */
	endPrice?: number;
	/** 不包含后端类目 */
	excludeCategoryCodeList?: string[];
	/** 需要排除的营销频道标签列表 */
	excludeChannelTagList?: string[];
	/** 不包含SPU编码列表 */
	excludeProductCodeList?: string[];
	/** 前台类目 */
	frontCategoryCodeList?: number[];
	/** 搜索关键字 */
	keyword?: string;
	/** 商品运营标签列表 */
	marketTagList?: string[];
	/**
	 * 一周上新商品
	 * @format int32
	 */
	newProductSort?: number;
	/** 只看直采商品, 是：true， 否：false */
	onlyDirctSale?: boolean;
	/** 只看限时折扣商品 是：true， 否：false */
	onlyDiscountSale?: boolean;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 前端类目编码
	 * @format int64
	 */
	productCategoryFrontendId?: number;
	/** SPU编码列表 */
	productCodeList?: string[];
	/**
	 * 排序字段:0 综合 1 价格(默认) 2 时间
	 * @format int32
	 */
	sortType?: number;
	/** @format int32 */
	startIndex?: number;
	/** 开始价格 */
	startPrice?: number;
	stationCode?: string;
	/** 商品状态：0-待售卖、1-售卖中、2-待下架、3-停止售卖、4-删除、5-待上架、6-上架中 */
	statusList?: number[];
	useCouponFlag?: boolean;
}

/** ProductKeywordRespDTO */
export interface ProductKeywordRespDTO {
	/**
	 * 活动标识, 0-无活动, 1-有活动
	 * @format int32
	 */
	activityFlag?: number;
	/** 活动价 */
	activityPrice?: number;
	/** 日币活动价格 */
	activityPriceJa?: number;
	activityTag?: string[];
	/**
	 * 活动类型 0-折扣价 1-一口价
	 * @format int32
	 */
	activityType?: number;
	agentData?: boolean;
	/**
	 * 可用库存
	 * @format int32
	 */
	availableQuantity?: number;
	channelTag?: string[];
	/** 商品特集分类 */
	collectionTag?: string[];
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 是否收藏, 0-否, 1-是
	 * @format int32
	 */
	favoriteFlag?: number;
	highPriceShow?: boolean;
	/** 完整层级类目代码 */
	intactCateCode?: string;
	/** 完整层级类目名称 */
	intactCateName?: string;
	marketTag?: string[];
	/** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园;YW:义乌小商品城; XX:线下 */
	platformType?: string;
	priceTag?: string[];
	/** 类目 */
	productCateCode?: string;
	/** 商品编码 */
	productCode?: string;
	/** 产品主图 */
	productMainImg?: string;
	/** 商品番号 */
	productNo?: string;
	/**
	 * 采购类型大写, 0-直采, 1-代采
	 * @format int32
	 */
	productPurchaseType?: number;
	/** 日币销售价格 */
	productSellPriceJa?: number;
	/** 商品价格范围 */
	productSellPriceRange?: string;
	/** 商品标题 */
	productTitle?: string;
	/**
	 * 三方平台类型：1688、淘宝、sniff
	 * @format int32
	 */
	productType?: number;
	/** 销售价 */
	sellPrice?: number;
	/**
	 * 商品状态：0-待售卖、1-售卖中、2-待下架、3-停止售卖、4-删除
	 * @format int32
	 */
	status?: number;
	/** 系统商品标题 */
	systemProductTitle?: string;
}

/** ProductManageReqDTO */
export interface ProductManageReqDTO {
	/** 活动id集合 */
	activitycodeList?: string[];
	/** 是否包邮 */
	freeShipping?: boolean;
	/** 运营标签集合 */
	marketTagList?: string[];
	/** 商品编码信息 */
	productCodes?: string[];
	/** 站点国家 */
	stationCode?: string;
}

/** ProductManageRespDTO */
export interface ProductManageRespDTO {
	/** 商品最高基准价格 */
	baseHighestPrice?: number;
	/** 商品最低基准价格 */
	baseLowestPrice?: number;
	basePrice?: number;
	/** @format int64 */
	calculateWeight?: number;
	/**
	 * 类目重量,单位g
	 * @format int64
	 */
	cateWeight?: number;
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/** 出货条件 */
	deliveryCondition?: string;
	/** @format double */
	height?: number;
	/** 完整层级类目代码 */
	intactCateCode?: string;
	/** 完整层级类目名称(后台类目) */
	intactCateName?: string;
	internationalFreight?: number;
	/** @format double */
	length?: number;
	/** 材质信息日文 */
	materialInfoJa?: string;
	/** 材质信息中文 */
	materialInfoZh?: string;
	/** 注意事项 */
	notes?: string;
	/** 原日文标题 */
	originalProductTitleJa?: string;
	platformType?: string;
	/** 溢价率 */
	premiumRate?: number;
	/** @format int32 */
	productBuyMininum?: number;
	productCateCode?: string;
	/** 商品编码 */
	productCode?: string;
	/** 描述图片（商品详情） */
	productDescImgs?: string;
	productDescription?: string;
	productDescriptionJa?: string;
	productDetailSniffUrl?: string;
	/** 直采商详链接 */
	productDetailUrl?: string;
	/** 中文品名 */
	productItemCategoryZh?: string;
	/** item缩略图（大） */
	productItemImgs?: string;
	/** item缩略图（小） */
	productItemImgsS?: string;
	/** 商品主图 */
	productMainImg?: string;
	productNo?: string;
	/** 商品阶梯价格列表 */
	productPriceLadder?: string;
	/** 商品阶梯价格 */
	productPriceLadderList?: ProductPriceLadderDTO[];
	productPropImgs?: string;
	productProps?: string;
	productPropsJa?: string;
	productPropsJaList?: string;
	productPropsList?: string;
	/** 商品最低销售价格 */
	productSellLowestPrice?: number;
	/** 商品最低销售价格(日元价格) */
	productSellLowestPriceJa?: number;
	productSellPrice?: number;
	/** 商品价格范围 */
	productSellPriceRange?: string;
	/** @format int32 */
	productSellQuantity?: number;
	/** @format int32 */
	productStockQuantity?: number;
	/** 商品标题 */
	productTitle?: string;
	/** @format int32 */
	productType?: number;
	productVideo?: string;
	productVideoImg?: string;
	/** 销售价 */
	sellPrice?: number;
	/** 销售价范围 */
	sellPriceRange?: number;
	/**
	 * 0-售卖中、1-待售卖、2-停止售卖、3-待下架、4-删除
	 * @format int32
	 */
	status?: number;
	/** 系统商品标题 */
	systemProductTitle?: string;
	thirdProductCateCode?: string;
	/** 三方平台商详链接 */
	thirdProductDetailUrl?: string;
	thirdSize?: string;
	/** @format int64 */
	thirdWeight?: number;
	/** 类目运输属性 */
	transportAttr?: string;
	transportAttrEmpty?: boolean;
	/**
	 * 更新日期
	 * @format date-time
	 */
	updateTime?: string;
	/**
	 * 重，单位kg
	 * @format int64
	 */
	weight?: number;
	weightEmpty?: boolean;
	/** @format double */
	width?: number;
}

/** ProductPriceLadderDTO */
export interface ProductPriceLadderDTO {
	/** 活动价格 */
	activityPrice?: number;
	/** 活动价格-日元 */
	activityPriceJa?: number;
	/** 基准价 */
	basePrice?: number;
	/**
	 * 阶梯数量
	 * @format int32
	 */
	count?: number;
	/** 溢价率 */
	premiumRate?: number;
	/** pc价格 */
	price?: number;
	/** 销售价格 */
	sellPrice?: number;
	/** 销售价格-日元 */
	sellPriceJa?: number;
}

/** ProductPriceLadderResp */
export interface ProductPriceLadderResp {
	/** app价格 */
	appPrice?: number;
	/** 基准价(原价格 + 国际运费) */
	basePrice?: number;
	/**
	 * 阶梯数量
	 * @format int32
	 */
	count?: number;
	/** 阶梯数量范围 */
	countRange?: string;
	/** 国际运费 */
	internationalFreight?: number;
	/** 原始价格 */
	originPrice?: number;
	/** 溢价率 */
	premiumRate?: number;
	/** 原价格(销售单价(参考价)) */
	price?: number;
	/** 销售单价(参考价) */
	productSellPrice?: number;
	/** 销售价格(基准价 * 溢价率) */
	sellPrice?: number;
}

/** ProductPropDTO */
export interface ProductPropDTO {
	/** 属性id */
	id?: string;
	/** 属性编码 */
	propKey?: string;
	/** 属性值 */
	propValue?: string;
	/** 属性信息 */
	props?: ProductPropDTO[];
	/** @format int64 */
	sort?: number;
	/** 属性类型 */
	type?: string;
	/** 图片url */
	url?: string;
}

/** ProductPropGroupDTO */
export interface ProductPropGroupDTO {
	/** 属性id */
	id?: string;
	/** 属性信息 */
	props?: ProductPropDTO[];
	/** 属性类型 */
	type?: string;
}

/** ProductQueryReqDTO */
export interface ProductQueryReqDTO {
	/** 降序 */
	descendOrder?: boolean;
	/** 排除已删除 */
	excludeDeleted?: boolean;
	/**
	 * 是否校验销售库存
	 * @format int32
	 */
	isVerifyStockQuantity?: number;
	/** 运营标签 */
	marketTag?: string;
	/** 排除商品code */
	noProductCode?: string;
	/** 商品原中文标题 */
	originalProductTitle?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 商品来源平台 */
	platformType?: string;
	/** 价格标签 */
	priceTag?: string;
	/** 类目编码(三级) */
	productCateCodeList?: string[];
	/** 商品code */
	productCode?: string;
	/** 商品code */
	productCodeList?: string[];
	/** 运营标签集合 */
	productMarketTagCodeList?: string[];
	/** 商品番号 */
	productNo?: string;
	/** 价格标签集合 */
	productPriceTagCodeList?: string[];
	/** sku编号 */
	productSku?: string;
	/** 商品标题 */
	productTitle?: string;
	/**
	 * 0-线上，1-线下
	 * @format int32
	 */
	productType?: number;
	/** 最低销售价格截止价格 */
	queryEndProductSellPrice?: number;
	/** 最低销售价格起始价格 */
	queryStartProductSellPrice?: number;
	searchField?: string[];
	/** SKU列表 */
	skuList?: string[];
	/** 排序字段; (默认)更新时间: update_time,product_sell_quantity:销量;product_sell_lowest_price:销售价格 */
	sortType?: string;
	/** @format int32 */
	startIndex?: number;
	/** 站点 */
	stationCode?: string;
	/**
	 * 0-售卖中、1-待售卖、2-停止售卖、3-待下架、4-删除
	 * @format int32
	 */
	status?: number;
	statusList?: number[];
	/** 三方平台商详链接 */
	thirdProductDetailUrl?: string;
	/** 重量是否为空 */
	weightIsNull?: boolean;
}

/** ProductRemarkRespVO */
export interface ProductRemarkRespVO {
	/** 手续费用 */
	handlingPrice?: number;
	/** 质检辅材成本 */
	inspectPrice?: number;
	/** 商品国际运费 */
	internationalFreight?: number;
}

/** ProductSkuStockSaveDTO */
export interface ProductSkuStockSaveDTO {
	productCode?: string;
	/** @format int32 */
	productSellQuantity?: number;
	productSku?: string;
	stationCode?: string;
}

/** ProductStockOperateReqDTO */
export interface ProductStockOperateReqDTO {
	bizId?: string;
	/** @format int32 */
	operateType?: number;
	productSku?: string;
	stationCode?: string;
	/** @format int32 */
	stockQuantity?: number;
}

/** ProductTagReqDTO */
export interface ProductTagReqDTO {
	/** 标签编码 */
	codeList?: string[];
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** @format int32 */
	startIndex?: number;
	/** 站点code */
	stationCode?: string;
	/**
	 * 标签类型， 1-价格标签， 2-运营标签,3-频道标签,4-活动标签
	 * @format int32
	 */
	type?: number;
}

/** ProductTagRespDTO */
export interface ProductTagRespDTO {
	/** 标签编码 */
	code?: string;
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 标签名称 */
	name?: string;
	/** 标签说明 */
	remark?: string;
	/** 站点code */
	stationCode?: string;
	/**
	 * 标签类型， 0-价格标签， 1-运营标签
	 * @format int32
	 */
	type?: number;
	/**
	 * 更新日期
	 * @format date-time
	 */
	updateTime?: string;
	/** 创建人名称 */
	updaterName?: string;
}

/** SearchImageReqDTO */
export interface SearchImageReqDTO {
	/** 图片ID */
	imageId?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 需要过滤的productCode */
	productCode?: string;
	/** @format int32 */
	startIndex?: number;
}

/** SearchImageRespDTO */
export interface SearchImageRespDTO {
	/**
	 * 活动标识, 0-无活动, 1-有活动
	 * @format int32
	 */
	activityFlag?: number;
	/** 活动价 */
	activityPrice?: number;
	/** 日币活动价格 */
	activityPriceJa?: number;
	/**
	 * 活动类型 0-折扣价 1-一口价
	 * @format int32
	 */
	activityType?: number;
	/**
	 * 可用库存
	 * @format int32
	 */
	availableQuantity?: number;
	/**
	 * 是否收藏,1 是, 否：0
	 * @format int32
	 */
	favoriteFlag?: number;
	/** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园;YW:义乌小商品城;  */
	platformType?: string;
	/** 商品编码 */
	productCode?: string;
	/** 汇率 */
	productExchangeRate?: number;
	/** 商品主图 */
	productMainImg?: string;
	/**
	 * 采购类型大写,代采 1, 直采：0
	 * @format int32
	 */
	productPurchaseType?: number;
	/** 销售价格(日元) */
	productSellPriceJa?: number;
	/** 商品标题 */
	productTitle?: string;
	/** 销售价 */
	sellPrice?: number;
	/** 站点编码 */
	stationCode?: string;
	/**
	 * 商品状态：0-待售卖、1-售卖中、2-待下架、3停止售卖、4-删除
	 * @format int32
	 */
	status?: number;
}

/** SearchImageUploadReqDTO */
export interface SearchImageUploadReqDTO {
	/** imageBase64Content编码 */
	imageBase64Content?: string;
	imageUrl?: string;
}

/** SkuDetailRespDTO */
export interface SkuDetailRespDTO {
	/** 活动价 */
	activityPrice?: number;
	/** 活动价-日元 */
	activityPriceJa?: number;
	/**
	 * 可用库存
	 * @format int32
	 */
	availableQty?: number;
	/** 溢价率 */
	premiumRate?: number;
	/** 商品SPU编码 */
	productCode?: string;
	/** 属性标识 */
	productProperties?: string;
	/** 属性名称,格式: 0:1:颜色:粉色;1:5:尺码:XXXL */
	productPropertiesName?: string;
	/** 属性名称-日文 */
	productPropertiesNameJa?: string;
	/** 属性标识排序 */
	productPropertiesSort?: string;
	/** 销售单价 */
	productSellPrice?: number;
	/**
	 * 销售量
	 * @format int32
	 */
	productSellQuantity?: number;
	/** 平台商品SKU */
	productSku?: string;
	/** sku对应图片 */
	productSkuImg?: string;
	/**
	 * 库存
	 * @format int32
	 */
	productStockQuantity?: number;
	/** 销售价 */
	sellPrice?: number;
	/** 销售价-日元 */
	sellPriceJa?: number;
}

/** TestReq */
export interface TestReq {
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** @format int32 */
	startIndex?: number;
}

/** TestResp */
export type TestResp = object;

/** TranslateImgReq */
export interface TranslateImgReq {
	imageUrl?: string[];
	targetLanguage?: string;
}

/** TranslateImgRespDTO */
export interface TranslateImgRespDTO {
	/**
	 * 任务状态
	 * @format int32
	 */
	code?: number;
	/** 翻译后返回的结果数据，和图片上传顺序一致 */
	imgsData?: ImgData[];
	/** 消息 */
	message?: string;
	/** 本次翻译任务请求id */
	requestId?: string;
	/**
	 * 翻译状态，0进行中，1翻译完成
	 * @format int32
	 */
	status?: number;
}

/** UpdateSkuRequVO */
export interface UpdateSkuRequVO {
	/** sku列表 */
	productSkuList?: string[];
	/**
	 * 商品状态:1-上架;0-下架
	 * @format int32
	 */
	status?: number;
}

/** BizResponse«EasyProductResp» */
export interface BizResponseEasyProductResp {
	code?: string;
	data?: EasyProductResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ProductCategoryFrontendMapGroupRespDTO»» */
export interface BizResponseListProductCategoryFrontendMapGroupRespDTO {
	code?: string;
	data?: ProductCategoryFrontendMapGroupRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ProductCategoryFrontendRespDTO»» */
export interface BizResponseListProductCategoryFrontendRespDTO {
	code?: string;
	data?: ProductCategoryFrontendRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ProductCategoryFrontendShortRespDTO»» */
export interface BizResponseListProductCategoryFrontendShortRespDTO {
	code?: string;
	data?: ProductCategoryFrontendShortRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ProductErrorRespDTO»» */
export interface BizResponseListProductErrorRespDTO {
	code?: string;
	data?: ProductErrorRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ProductKeywordRespDTO»» */
export interface BizResponseListProductKeywordRespDTO {
	code?: string;
	data?: ProductKeywordRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ProductManageRespDTO»» */
export interface BizResponseListProductManageRespDTO {
	code?: string;
	data?: ProductManageRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«string»» */
export interface BizResponseListString {
	code?: string;
	data?: string[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«ProductFavoriteRespDTO»» */
export interface BizResponsePageResultProductFavoriteRespDTO {
	code?: string;
	data?: PageResultProductFavoriteRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«ProductKeywordRespDTO»» */
export interface BizResponsePageResultProductKeywordRespDTO {
	code?: string;
	data?: PageResultProductKeywordRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«ProductTagRespDTO»» */
export interface BizResponsePageResultProductTagRespDTO {
	code?: string;
	data?: PageResultProductTagRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«SearchImageRespDTO»» */
export interface BizResponsePageResultSearchImageRespDTO {
	code?: string;
	data?: PageResultSearchImageRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«EasyProductManageResp»» */
export interface BizResponsePageEasyProductManageResp {
	code?: string;
	data?: PageEasyProductManageResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«TestResp»» */
export interface BizResponsePageTestResp {
	code?: string;
	data?: PageTestResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«ProductDetailRespDTO» */
export interface BizResponseProductDetailRespDTO {
	code?: string;
	data?: ProductDetailRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«TranslateImgRespDTO» */
export interface BizResponseTranslateImgRespDTO {
	code?: string;
	data?: TranslateImgRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«boolean» */
export interface BizResponseBoolean {
	code?: string;
	data?: boolean;
	msg?: string;
	success?: boolean;
}

/** BizResponse«int» */
export interface BizResponseInt {
	code?: string;
	/** @format int32 */
	data?: number;
	msg?: string;
	success?: boolean;
}

/** BizResponse«long» */
export interface BizResponseLong {
	code?: string;
	/** @format int64 */
	data?: number;
	msg?: string;
	success?: boolean;
}

/** BizResponse«object» */
export interface BizResponseObject {
	code?: string;
	data?: object;
	msg?: string;
	success?: boolean;
}

/** BizResponse«string» */
export interface BizResponseString {
	code?: string;
	data?: string;
	msg?: string;
	success?: boolean;
}

/** PageResult«ProductFavoriteRespDTO» */
export interface PageResultProductFavoriteRespDTO {
	/** @format int64 */
	current?: number;
	records?: ProductFavoriteRespDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«ProductKeywordRespDTO» */
export interface PageResultProductKeywordRespDTO {
	/** @format int64 */
	current?: number;
	records?: ProductKeywordRespDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«ProductTagRespDTO» */
export interface PageResultProductTagRespDTO {
	/** @format int64 */
	current?: number;
	records?: ProductTagRespDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«SearchImageRespDTO» */
export interface PageResultSearchImageRespDTO {
	/** @format int64 */
	current?: number;
	records?: SearchImageRespDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** Page«EasyProductManageResp» */
export interface PageEasyProductManageResp {
	/** @format int32 */
	limit?: number;
	list?: EasyProductManageResp[];
	/** @format int32 */
	offset?: number;
	/** @format int64 */
	totalCount?: number;
	/** @format int64 */
	totalPage?: number;
}

/** Page«TestResp» */
export interface PageTestResp {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: TestResp[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
	securityWorker?: (
		securityData: SecurityDataType | null
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({
			...axiosConfig,
			baseURL: axiosConfig.baseURL || '//master-gateway.theckb.com:443/easy/goods'
		});
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {})
			}
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === 'object' && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<T> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
			body = JSON.stringify(body);
		}

		return this.instance
			.request({
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
				},
				params: query,
				responseType: responseFormat,
				data: body,
				url: path
			})
			.then((response) => response.data);
	};
}

/**
 * @title API文档
 * @version v1.0.0
 * @baseUrl //master-gateway.theckb.com:443/easy/goods
 * @contact 史尼芙 (https://www.taobaockb.com/)
 *
 * 史尼芙API文档
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	favoriteProduct = {
		/**
		 * No description
		 *
		 * @tags 商品-收藏
		 * @name PostFavoriteProduct
		 * @summary 新增收藏
		 * @request POST:/favoriteProduct/add
		 */
		postFavoriteProduct: (addReq: AddProductFavoriteReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/favoriteProduct/add`,
				method: 'POST',
				body: addReq,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品-收藏
		 * @name Cancel
		 * @summary 取消收藏
		 * @request POST:/favoriteProduct/cancel
		 */
		cancel: (dto: CancelProductFavoriteReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/favoriteProduct/cancel`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品-收藏
		 * @name Page
		 * @summary 收藏分页
		 * @request POST:/favoriteProduct/page
		 */
		page: (pageRequest: ProductFavoritePageReqDTO, params: RequestParams = {}) =>
			this.request<BizResponsePageResultProductFavoriteRespDTO, any>({
				path: `/favoriteProduct/page`,
				method: 'POST',
				body: pageRequest,
				type: ContentType.Json,
				...params
			})
	};
	product = {
		/**
		 * No description
		 *
		 * @tags 商品
		 * @name Detail
		 * @summary 客户端-商品详情
		 * @request POST:/product/detail
		 */
		detail: (detailReqDTO: ProductDetailReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseProductDetailRespDTO, any>({
				path: `/product/detail`,
				method: 'POST',
				body: detailReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品-客户端-搜索
		 * @name SearchCheckSync
		 * @summary 商品同步接口
		 * @request POST:/product/search/check/sync
		 */
		searchCheckSync: (checkSyncReq: CheckSyncReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/product/search/check/sync`,
				method: 'POST',
				body: checkSyncReq,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品-客户端-搜索
		 * @name SearchImage
		 * @summary 图搜接口
		 * @request POST:/product/search/image
		 */
		searchImage: (searchImageReq: SearchImageReqDTO, params: RequestParams = {}) =>
			this.request<BizResponsePageResultSearchImageRespDTO, any>({
				path: `/product/search/image`,
				method: 'POST',
				body: searchImageReq,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品-客户端-搜索
		 * @name SearchImageUpload
		 * @summary H5图搜上传接口
		 * @request POST:/product/search/image/upload
		 */
		searchImageUpload: (searchImageUploadReq: SearchImageUploadReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseString, any>({
				path: `/product/search/image/upload`,
				method: 'POST',
				body: searchImageUploadReq,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品-客户端-搜索
		 * @name SearchKeyword
		 * @summary 关键词搜索
		 * @request POST:/product/search/keyword
		 */
		searchKeyword: (productKeyWordSearchReq: ProductKeyWordSearchReqDTO, params: RequestParams = {}) =>
			this.request<BizResponsePageResultProductKeywordRespDTO, any>({
				path: `/product/search/keyword`,
				method: 'POST',
				body: productKeyWordSearchReq,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品-客户端-搜索
		 * @name SearchKeywordAgent
		 * @summary 关键词搜索代采商品
		 * @request POST:/product/search/keyword/agent
		 */
		searchKeywordAgent: (productKeyWordSearchReq: ProductKeyWordSearchReqDTO, params: RequestParams = {}) =>
			this.request<BizResponsePageResultProductKeywordRespDTO, any>({
				path: `/product/search/keyword/agent`,
				method: 'POST',
				body: productKeyWordSearchReq,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品-客户端-搜索
		 * @name SearchKeywordHistory
		 * @summary 查询商品搜索历史
		 * @request GET:/product/search/keyword/history
		 */
		searchKeywordHistory: (params: RequestParams = {}) =>
			this.request<BizResponseListString, any>({
				path: `/product/search/keyword/history`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品-客户端-搜索
		 * @name SearchKeywordHistoryDelete
		 * @summary 删除搜索历史
		 * @request POST:/product/search/keyword/history/delete
		 */
		searchKeywordHistoryDelete: (params: RequestParams = {}) =>
			this.request<BizResponseBoolean, any>({
				path: `/product/search/keyword/history/delete`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品-客户端-搜索
		 * @name SearchKeywordHot
		 * @summary 查询商品热搜词
		 * @request GET:/product/search/keyword/hot
		 */
		searchKeywordHot: (params: RequestParams = {}) =>
			this.request<BizResponseListString, any>({
				path: `/product/search/keyword/hot`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品-客户端-搜索
		 * @name SearchNewProduct
		 * @summary 每周上新商品
		 * @request POST:/product/search/newProduct
		 */
		searchNewProduct: (reqDTO: ProductKeyWordSearchReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseListProductKeywordRespDTO, any>({
				path: `/product/search/newProduct`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			})
	};
	productCategoryFrontend = {
		/**
		 * No description
		 *
		 * @tags 客户端类目
		 * @name ActivityTree
		 * @summary 客户端-客户端活动类目
		 * @request POST:/productCategoryFrontend/activity/tree
		 */
		activityTree: (
			productCategoryFrontendAllParentNodeDTO: ProductCategoryFrontendAllParentNodeDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListProductCategoryFrontendShortRespDTO, any>({
				path: `/productCategoryFrontend/activity/tree`,
				method: 'POST',
				body: productCategoryFrontendAllParentNodeDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 客户端类目
		 * @name GetProductCategoryFrontend
		 * @summary 客户端-客户端热门类目
		 * @request GET:/productCategoryFrontend/hot
		 */
		getProductCategoryFrontend: (params: RequestParams = {}) =>
			this.request<BizResponseListProductCategoryFrontendShortRespDTO, any>({
				path: `/productCategoryFrontend/hot`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 客户端类目
		 * @name Tree
		 * @summary 客户端-客户端类目tree
		 * @request POST:/productCategoryFrontend/tree
		 */
		tree: (
			productCategoryFrontendAllParentNodeDTO: ProductCategoryFrontendAllParentNodeDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListProductCategoryFrontendShortRespDTO, any>({
				path: `/productCategoryFrontend/tree`,
				method: 'POST',
				body: productCategoryFrontendAllParentNodeDTO,
				type: ContentType.Json,
				...params
			})
	};
	test = {
		/**
		 * No description
		 *
		 * @tags 测试
		 * @name Test1
		 * @summary 分页
		 * @request POST:/test/test1
		 */
		test1: (testReq: TestReq, params: RequestParams = {}) =>
			this.request<BizResponsePageTestResp, any>({
				path: `/test/test1`,
				method: 'POST',
				body: testReq,
				type: ContentType.Json,
				...params
			})
	};
}
