/*
 * @Author: yusha
 * @Date: 2024-01-12 16:01:48
 * @LastEditors: yusha
 * @LastEditTime: 2024-03-18 20:21:17
 * @Description: 一键置顶
 */
// import { Image, YStack } from '@snifftest/tamagui';
import { Image, YStack } from '@snifftest/tamagui';
import { forwardRef, memo } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import * as SDK from '@snifftest/sdk/lib/rn';

/** 返回顶部ICON */
const ICON_BACK_TOP =
	'https://static-s.theckb.com/BusinessMarket/App/dirct/icon/icon_backTop.png';
const BackToTop = forwardRef((props: any, ref: any) => {
	/** 安全距离 */
	const insets = useSafeAreaInsets();
	const { scrollView, visible } = props;
	// useImperativeHandle(ref, () => ({
	// 	// 在子组件中暴露的方法或属性
	// 	scrollToTop: () => {
	// 		if (scrollViewRef && scrollViewRef.current) {
	// 			scrollViewRef.current.scrollTo({ y: 0, animated: true });
	// 		}
	// 	}
	// }));
	// 在子组件中暴露的方法或属性
	const scrollToTop = () => {
		if (!(scrollView && scrollView.current)) {
			return;
		}
		if (scrollView.current.scrollTo) {
			scrollView.current.scrollTo({ y: 0, animated: true });
			return;
		}
		if (scrollView.current.scrollToOffset) {
			scrollView.current.scrollToOffset({ offset: 0, animated: true });
		}
	};

	const getBottom = () => {
		if (SDK.isH5()) {
			if (
				SDK.getDeviceSize().width &&
				SDK.getDeviceSize().height / SDK.getDeviceSize().width > 2
			) {
				return SDK.fitSize(200);
			}
			return SDK.fitSize(100);
		}
		return SDK.fitSize(100) + insets.bottom;
	};

	return (
		<>
			{visible && (
				<YStack
					onPress={scrollToTop}
					width={SDK.fitSize(48)}
					height={SDK.fitSize(48)}
					borderRadius={SDK.fitSize(24)}
					position="absolute"
					bottom={getBottom()}
					right={SDK.fitSize(12)}
					backgroundColor={'#fff'}
					justifyContent="center"
					alignItems="center"
					borderColor={'rgba(38, 35, 33,.1)'}
					borderWidth={SDK.fitSize(0.2)}
					zIndex={999}
					{...props}
				>
					<Image
						width={SDK.fitSize(24)}
						height={SDK.fitSize(24)}
						source={{
							uri: ICON_BACK_TOP
						}}
					/>
				</YStack>
			)}
		</>
	);
});
export default memo(BackToTop);
