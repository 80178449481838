import { Dimensions, Platform, StatusBar } from 'react-native';

const { height, width } = Dimensions.get('window');

const isIPhoneXs = (): any => {
	if (Platform.OS === 'ios') {
		const v = parseInt(`${(100 * height) / width}`) == 216;
		return v;
	}
};

const getStatusBarHeight = (skipAndroid: boolean = false): any => {
	return Platform.select({
		ios: isIPhoneXs() ? 44 : 20,
		android: skipAndroid ? 0 : StatusBar.currentHeight,
		default: 0
	});
};

/**
 * 状态栏高度
 */
const statusBarHeight = () => {
	if (Platform.OS === 'ios') {
		if (isIPhoneXs()) {
			return 44;
		}
		return 20;
	}
	return getStatusBarHeight();
};

/**
 * 导航栏高度
 */
const navBarHeight = () => {
	if (Platform.OS === 'ios') {
		return 44;
	}
	return 56;
};

/**
 * 导航栏总高度
 */
const totalNavBarHeight = () => {
	return navBarHeight() + statusBarHeight();
};

/**
 * 界面自适应(与6屏宽 比较)
 * @param w 尺寸
 */
const layoutWidth = (w: number): any => {
	return (w / 375) * width;
};

// tabBar的高度
const tabBarHeight = () =>
	Platform.OS === 'ios' ? (isIPhoneXs() ? 84 : 64) : 64;
const sfeTabBarHeight = () => tabBarHeight() - 64;

export default {
	ScreenWidth: width,
	ScreenHieght: height,
	ScaleX: layoutWidth, // 界面适配(与屏宽375 比较)
	IsIphoneXs: isIPhoneXs, // 是否为苹果x
	StatusBarHeight: statusBarHeight(), // 状态栏高度
	NavBarHeight: navBarHeight(), // 导航栏高度
	TotalNavBarHeight: totalNavBarHeight(), // 导航栏总高度
	TabarHeight: tabBarHeight(), // tabbar高度
	SafeTabBarHeight: sfeTabBarHeight(), // safe TabBar 安全距离
	CnIcon: '¥'
};
