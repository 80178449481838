/*
 * @Author: tianzhitong laotianwy@163.com
 * @Date: 2023-10-31 19:23:34
 * @LastEditors: lihwang_wf@126.com
 * @LastEditTime: 2024-01-11 14:29:12
 * @FilePath: /ckb-easy-h5-fed/src/component/ShoppItem/index.tsx
 * @Description: 商品信息。商品列表和图搜公用
 */
import { memo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { Button } from 'antd-mobile';
import classNames from 'classnames';
import { useRequest } from 'ahooks';
import { useSearchParams } from 'react-router-dom';
import { User } from '@/Atoms';
import { EnumProductPurchaseType } from '@/common/Enums';
import { debounce2, jumpToApp, formatMoney } from '@/utils';
import Xianshimiaosha from '@/common/icons/Xianshimiaosha';
import Gengduo1moreOne1 from '@/common/icons/Gengduo1moreOne1';
import Shoucanglike2 from '@/common/icons/Shoucanglike2';
import { CollectionActivityRespDTO } from '@/service/easyMarket';
import { goToLogin, request } from '../../config/request/interceptors';
import { CustomImg } from '../CustomImg';
import ModalGoToPurchase from '../ModalGoToPurchase';
import styles from './index.module.scss';
export interface ShoppInfoProps {
	/**
	 * 采购类型大写,代采 1, 直采：0
	 * @format int32
	 */
	productPurchaseType?: number;
	/** 日本价格 */
	productSellPriceJa?: number;
	/** 商品标题 */
	title?: string;
	/** 商品id	 */
	productCode?: string;
	/** 商品主图 */
	productMainImg?: string;
	/** 商品状态 可用库存 === 0 是售罄 */
	status?: number;
	/** 是否展示及时返金 */
	showGoBackPrice?: boolean;
	/** 是否收藏 代采专用 1-收藏 0-未收藏 */
	favoriteFlag?: number;
	/** 价格（人民币） */
	sellPrice?: number;
	/** 是否是活动商品展示 */
	fromHot?: boolean;
	highPriceShow?: boolean;
	/** 商品详情 活动标识, 0-无活动, 1-有活动 */
	activityFlag?: number;
	/** 活动价 */
	activityPrice?: number;
	/** 日币活动价格 */
	activityPriceJa?: number;
	activitySellPriceJpy?: number;
	productLowestPrice?: number;
	createTime?: string;
}
interface ShoppItemProps extends ShoppInfoProps {
	/** 点击 */
	onClick?: (item: ShoppInfoProps) => void;
	/** 点击收藏 */
	onCollect?: (code?: string, item?: CollectionActivityRespDTO) => void;
	/** 点击收藏显示 */
	onSelectCollect?: (code?: string) => void;
	/** 点击收藏显示 */
	maskStatus?: boolean;
	item?: CollectionActivityRespDTO;
}

/** 商品item */
const ShoppItemNew = (props: ShoppItemProps) => {
	const [visible, setVisible] = useState(false);
	const isLogin = useAtomValue(User.isLogin);
	/** 用户信息 */
	const userInfo = useAtomValue(User.userDetail);
	const [searchParams] = useSearchParams();
	const {
		onClick,
		onCollect,
		onSelectCollect,
		productMainImg,
		productCode,
		productSellPriceJa,
		title,
		productPurchaseType,
		showGoBackPrice,
		sellPrice,
		activitySellPriceJpy,
		productLowestPrice,
		highPriceShow,
		favoriteFlag,
		status = 0,
		fromHot,
		activityFlag,
		maskStatus = false,
		item
	} = props;
	console.log(sellPrice, 'sellPrice');
	// 获取直采用户跳转代采是否弹框标识
	const { runAsync: getCustomer2AgencyGoodsSign } = useRequest(
		request.customer.getCustomer2AgencyGoodsSign
			.getCustomer2AgencyGoodsSign,
		{
			manual: true
		}
	);
	/** 是否显示价格 */
	const showPrice = searchParams.get('showPrice');

	/** 点击了收藏功能 */
	const changeCollectStatus = (event) => {
		console.log(event, 'changeCollectStatus');
		event.stopPropagation();
		onCollect?.(productCode, item);
	};
	/** 获取展示价格 */
	const getShowPrice = () => {
		// 直采商品或者是活动商品，只展示价格
		if (productPurchaseType === EnumProductPurchaseType.DIRECT || fromHot) {
			return (
				<div className={classNames(styles['price-show'])}>
					{highPriceShow &&
					activitySellPriceJpy &&
					activitySellPriceJpy < productLowestPrice ? (
						<span>
							<span>
								{activitySellPriceJpy}
								{window._$m.t('元')}
							</span>
							<span
								style={{
									marginLeft: '0.05rem',
									textDecorationLine: 'line-through',
									color: '#00000040'
								}}
							>
								{productLowestPrice}
								{window._$m.t('元')}
							</span>
						</span>
					) : (
						<span>
							{formatMoney(productSellPriceJa)}
							{window._$m.t('円')}
						</span>
					)}

					{isLogin && (
						<div
							className={styles['collect-status']}
							onClick={debounce2(changeCollectStatus, 2100)}
						>
							{favoriteFlag ? (
								<Shoucanglike2
									color={'#FF5010'}
									width={'0.16rem'}
									height={'0.16rem'}
								/>
							) : (
								<img
									src="https://static-jp.theckb.com/static-asset/easy-h5/收藏商品.svg"
									alt=""
								/>
							)}
						</div>
					)}
				</div>
			);
		}
		return (
			<div className={styles['price-show']}>
				<span className={styles['price-show-tips']}>
					{window._$m.t('(约{{x}}円)', {
						data: {
							x: productSellPriceJa
						}
					})}
				</span>
			</div>
		);
	};

	/** 跳转方法 */
	const clickJump = async () => {
		sessionStorage.removeItem('isFromOtherDomain');
		// if (!isLogin) {
		// 	goToLogin();
		// 	return;
		// }
		// 如果是直采直接跳转详情
		if (productPurchaseType === EnumProductPurchaseType.DIRECT) {
			onClick?.({
				productMainImg,
				productCode,
				productSellPriceJa,
				title,
				productPurchaseType,
				showGoBackPrice
			});
			return;
		}
		const isNeedShowModalGoToPurchasel =
			await getCustomer2AgencyGoodsSign();
		// 如果是代采商品，点击显示弹窗,且直采用户跳转代采是否弹框标识为false，false表示展示
		if (!isNeedShowModalGoToPurchasel.data) {
			setVisible(true);
			return;
		}
		if (productCode) {
			jumpToApp({
				jumpLink: `GoodsItemDetail?productCode=${productCode}`
			});
		}
	};
	return (
		<>
			<div
				className={styles['shopp-item']}
				style={{
					height: showGoBackPrice ? '2.36rem' : '2.36rem'
				}}
			>
				<div className={styles['shopp-img']} onClick={clickJump}>
					{/* 售罄标记 */}
					{status === 0 && !maskStatus ? (
						<div className={styles['mask-sold-out']}>
							<div className={styles['sold-out']}>
								{window._$m.t('售罄')}
							</div>
						</div>
					) : null}
					<CustomImg
						className={styles['shopp-img']}
						src={productMainImg}
						style={{
							height: '100%',
							width: '100%',
							background: '#f8f8f8'
						}}
						alt="tips"
					/>

					{activityFlag === 1 && (
						<div className={styles['tag-limit']}>
							<Xianshimiaosha color="#fff" />
							{window._$m.t('限时秒杀')}
						</div>
					)}
				</div>
				<div onClick={clickJump}>
					{/* 标题 */}
					<div className={classNames(styles['shopp-title-view'])}>
						{title}
					</div>
				</div>
				{/* TODO:改到商品详情内部了，进入详情查看  是否展示及时返金 首页全部10个商品才展示 */}
				{/* 是否登录。登录才展示价格 */}
				{/* 收藏 */}

				{getShowPrice()}
			</div>
			{/* 前往代采的弹窗 */}
			<ModalGoToPurchase
				visible={visible}
				onClose={() => setVisible(false)}
				onOk={(val) => {
					// 需要加接口，且添加跳转app逻辑
					setVisible(false);
					if (productCode) {
						jumpToApp({
							jumpLink: `GoodsItemDetail?productCode=${productCode}`
						});
					}
				}}
			/>
		</>
	);
};
export default memo(ShoppItemNew);
