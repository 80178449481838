import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import ZhanweituCkb from '@/common/icons/ZhanweituCkb';

/*
 * @Author: huajian
 * @Date: 2023-11-06 19:55:42
 * @LastEditors: yusha
 * @LastEditTime: 2023-11-13 17:08:57
 * @Description:
 */
interface Props
	extends DetailedHTMLProps<
		ImgHTMLAttributes<HTMLImageElement>,
		HTMLImageElement
	> {
	size?: string | number;
	fill?: string;
	svgClassName?: string;
}
export function CustomImg(props: Props) {
	if (!props.src) {
		return (
			<ZhanweituCkb
				size={props.size}
				className={props.svgClassName}
				style={props.style}
				fill={props.fill || '#fff'}
			/>
		);
	}
	return <img alt="" {...props} />;
}
