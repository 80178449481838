/*
 * @Author: yusha
 * @Date: 2023-11-21 11:24:27
 * @LastEditors: lihwang_wf@126.com
 * @LastEditTime: 2023-12-22 14:00:22
 * @Description: 数据为空展示的页面
 */
import { memo, useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { useSearchParams } from 'react-router-dom';
import { useAsyncEffect, useMount, useRequest } from 'ahooks';
import { omit } from 'ramda';
import classNames from 'classnames';
import { flushSync } from 'react-dom';
import { Toast } from 'antd-mobile';
import { User } from '@/Atoms';
import PurcharseModules from '@/component/PurcharseModules';
import { request } from '@/config/request';
import {
	AddProductFavoriteReqDTO,
	ProductKeyWordSearchReqDTO,
	ProductKeywordRespDTO
} from '@/service/easyGoods';
import { CustomRMImg } from '@/component/CustomRMImg';
import Loading from '@/component/Loading';
import { jumpToApp } from '@/utils';
import styles from './index.module.scss';
const EmptyPage = (props) => {
	// 商品列表长度
	const {
		goodsListLength,
		rootFontSize,
		onlyWatchDirectGoods,
		activeTag,
		onlyWatchActiveGoods,
		cateId
	} = props;
	const [searchParams] = useSearchParams();
	const isLogin = useAtomValue(User.isLogin);
	/** 关键字搜索 */
	const keyword = searchParams.get('keyword') ?? '';
	/** 分类搜索 */
	// const cateId = Number(searchParams.get('cateId'));
	/** 分类搜索带过来的类目名称 */
	const pageTitle = searchParams.get('pageTitle');
	/** 代采模块数据 */
	const [purchaseList, setPurchaseList] = useState<ProductKeywordRespDTO[]>(
		[]
	);
	/** 请求代采商品数据 */
	const { runAsync: searchKeywordAgent, loading: searchKeywordAgentLoading } =
		useRequest(request.easyGoods.product.searchKeywordAgent, {
			manual: true
		});
	/** 收藏取消 */
	const { runAsync: postCancelCollect } = useRequest(
		request.easyGoods.favoriteProduct.cancel,
		{
			manual: true
		}
	);

	/** 收藏 */
	const { runAsync: postAddCollect } = useRequest(
		request.easyGoods.favoriteProduct.postFavoriteProduct,
		{
			manual: true
		}
	);
	/** 计算layout-content高度 */
	const getLayoutContentHeight = () => {
		const noInput = searchParams.get('noInput') === 'true';
		// 若是代采商品列表存在，则将高度设置为原先的值
		if (purchaseList.length) {
			return '100%';
		}
		if (noInput) {
			if (isLogin) {
				return `calc(100% - 1.13rem)`;
			}
			return `calc(100% - 0.57rem)`;
		}
		if (isLogin) {
			return `calc(100% - 1.52rem)`;
		}
		return 'calc(100% - 0.96rem)';
	};
	useAsyncEffect(async () => {
		if (goodsListLength || onlyWatchDirectGoods || activeTag) {
			setPurchaseList([]);
			return;
		}
		// 当数据为空时
		let params: ProductKeyWordSearchReqDTO = {};
		// 词搜搜索
		params = {
			pageNum: 1,
			pageSize: 20,
			keyword,
			productCategoryFrontendId: cateId,
			/** 排序字段:0 综合 1 价格(默认) 2 时间 */
			sortType: 0
		};

		// 如果没有分类id。那么删除该字段
		if (cateId === 0 && 'productCategoryFrontendId' in params) {
			params = omit(['productCategoryFrontendId'], params);
		}
		// 如果没有关键字todo
		if (keyword === window._$m.t('请输入商品名称') && 'keyword' in params) {
			params = omit(['keyword'], params);
		}
		// const data = await searchKeywordAgent(params);
		// const _list = data.data?.records ?? [];
		// // 长度小于2不展示数据
		// if (_list.length < 2) {
		// 	setPurchaseList([]);
		// 	return;
		// }
		// setPurchaseList(_list.slice(0, 2));
	}, [goodsListLength, onlyWatchDirectGoods, onlyWatchActiveGoods, cateId]);
	/** 收藏切换 */
	const changeCollect = async (code?: string) => {
		const collectItem = purchaseList.find(
			(shoppItem) => shoppItem.productCode === code
		);
		if (!collectItem) return;
		const favoriteFlagBoolean = Boolean(collectItem['favoriteFlag']);
		// 取消收藏
		if (favoriteFlagBoolean) {
			const res = await postCancelCollect({
				productCode: code
			});
			if (!res.success) return;
			const newList = purchaseList.map((shoppItem) => {
				if (shoppItem.productCode !== code) return shoppItem;
				return {
					...shoppItem,
					favoriteFlag: 0
				};
			});
			flushSync(() => {
				setPurchaseList(newList);
			});
			Toast.show({
				content: window._$m.t('取消收藏成功')
			});
			return;
		}
		// 收藏
		const { productCode, productMainImg, sellPrice, productTitle } =
			collectItem;
		const payload: AddProductFavoriteReqDTO = {
			productCode,
			productMainImg,
			productSellPrice: sellPrice,
			productTitle
		};
		const res = await postAddCollect({
			...payload,
			favoriteProductType: 0
		});
		if (!res.success) return;
		const newList = purchaseList.map((shoppItem) => {
			if (shoppItem.productCode !== code) return shoppItem;
			return {
				...shoppItem,
				favoriteFlag: 1
			};
		});
		flushSync(() => {
			setPurchaseList(newList);
		});
		Toast.show({
			content: window._$m.t('收藏成功')
		});
	};
	/** 查看更多 */
	const searchMore = () => {
		let _keyword = keyword;
		// 如果是类目搜索，则传类目标题
		if (cateId && pageTitle) {
			_keyword = pageTitle;
		}
		if (_keyword) {
			// 是否包含特殊字符,需要给替换掉
			_keyword = _keyword.replaceAll('&', '/');
			jumpToApp({
				jumpLink: `NewSearchList?keyword=${_keyword}&lan=日本語`
			});
		}
	};
	return (
		<div
			className={classNames(
				styles['shop-cart-empty'],
				!purchaseList.length && styles['empty-no-purchase']
			)}
			style={{
				height: getLayoutContentHeight()
			}}
		>
			{searchKeywordAgentLoading && <Loading />}
			<div
				className={classNames(
					styles['empty-content'],
					!(!goodsListLength && !onlyWatchDirectGoods)
						? styles['empty-content-height']
						: ''
				)}
			>
				<img
					alt="empty"
					src="https://static-jp.theckb.com/static-asset/easy-h5/empty_status_goods.svg"
				/>

				<div className={styles['empty-desc']}>
					{window._$m.t('搜索不到任何商品，再看看其他商品吧')}
				</div>
			</div>
		</div>
	);
};
export default memo(EmptyPage);
