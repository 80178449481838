/*
 * @Author: yusha
 * @Date: 2024-01-08 15:55:07
 * @LastEditors: yusha
 * @LastEditTime: 2024-05-16 16:20:31
 * @Description: 商详主要内容
 */

import { memo, useCallback, useContext, useState } from 'react';
import { Image, XStack, YStack, Text, styled, Stack } from '@snifftest/tamagui';
import * as SDK from '@snifftest/sdk/lib/rn';
import { useAtomValue } from 'jotai';
import { useRequest } from 'ahooks';
import { UserDefaultFreeShippingResp } from '@/outs/service/easyOrder';
import { request } from '@/config/request/interceptors';
import { usePageFocuEffect } from '@/outs/hooks/common/usePageFocuEffect';
import { FreightTemplateDetailRespDTO } from '@/outs/service/easyMarket';
import { ContextPreView } from '../../context';
import ActiveView from './ActiveView';
import GoodsTitle from './GoodsTitle';
import GoodsDetailImage from './GoodsDetailImage';
import CouponActivity from './CouponActivity';
import GoodsFreight from './GoodsFreight';

const fitSize = SDK.fitSize;

const DetailContent = () => {
	const { atomUserDetail, product } = useContext(ContextPreView);
	const { freeShipping } = product;
	const useDetail = useAtomValue(atomUserDetail!);
	const isLogin = !!useDetail?.customerId;
	/** 运费弹窗 */
	const [freightModalVisible, setFreightModalVisible] = useState(false);
	// 包邮信息
	const [freeShipInfo, setFreeShipInfo] =
		useState<UserDefaultFreeShippingResp>({});
	/** 运费模版 */
	const [freightTemplate, setFreightTemplate] =
		useState<FreightTemplateDetailRespDTO>({});
	/** 运费模版 */
	const { runAsync: getfreightTemplateList } = useRequest(
		request.easyMarket.freight.templateShowInfo,
		{
			manual: true
		}
	);
	/** 获取包邮信息 */
	const { runAsync: findDefaultFreeShipping } = useRequest(
		request.easyOrder.userReceivingAddress.findDefaultFreeShipping,
		{
			manual: true
		}
	);
	/** 判断是否展示优惠券 */
	const getIsShowCoupon = () => {
		if (!isLogin) {
			return false;
		}
		// 活动不存在
		if (product && !product.activityInfo) {
			return true;
		}
		// 活动是否可以叠加券 0:不可叠加 1:可叠加
		// @ts-ignore
		if (product.activityInfo?.addCoupon === 1) {
			return true;
		}
		return false;
	};
	/** 初始化包邮信息 */
	const initFreeShipInfo = useCallback(() => {
		(async () => {
			if (!isLogin) {
				setFreeShipInfo({});
				getFreightTemplate({});
				return;
			}
			const data = await findDefaultFreeShipping();
			data.data && setFreeShipInfo(data.data);
			// 请求运费模版
			data.data && getFreightTemplate(data.data);
		})();
		// 只需要依赖这两个
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [findDefaultFreeShipping, isLogin, freeShipping]);
	/** 获取运费模版 */
	const getFreightTemplate = async (data) => {
		// 包邮的话不需要请求运费模版接口
		if (freeShipping !== false) return;
		try {
			const { receiverCountryId, receiverProvinceId } = data;
			const params = {
				// 运费类型 1:普通运费规则，2：顺手捎商品规则
				// 可以写死，目前只存在第二种情况
				freightType: 2,
				receiverCountryId,
				receiverProvinceId
			};
			const res = await getfreightTemplateList(params);
			if (!res.success) {
				setFreightTemplate({});
				return;
			}
			setFreightTemplate(res.data ?? {});
		} catch (error) {
			setFreightTemplate({});
		}
	};

	// 聚焦时触发
	usePageFocuEffect(initFreeShipInfo);

	return (
		<YStack
			style={{
				flex: 1,
				paddingTop: fitSize(8),
				paddingRight: fitSize(12),
				paddingBottom: SDK.isH5() ? fitSize(90) : fitSize(16),
				paddingLeft: fitSize(12),
				borderTopLeftRadius: fitSize(16),
				borderTopRightRadius: fitSize(16),
				overflow: 'hidden',
				marginBottom: fitSize(24),
				backgroundColor: '#fff',
				position: 'relative',
				zIndex: 1,
				marginTop: fitSize(-20)
			}}
		>
			{/* 优惠券 */}
			{getIsShowCoupon() && <CouponActivity />}

			{/* 商品活动 */}
			<ActiveView
				freeShipInfo={freeShipInfo}
				setFreightModalVisible={setFreightModalVisible}
			/>
			{/* 商品标题 */}
			<GoodsTitle />
			{/* 商品运费 */}
			<GoodsFreight
				freightModalVisible={freightModalVisible}
				setFreightModalVisible={setFreightModalVisible}
				freeShipInfo={freeShipInfo}
				freightTemplate={freightTemplate}
			/>

			{/* 商品详情图片区域 */}
			<GoodsDetailImage />
		</YStack>
	);
};
export default memo(DetailContent);
