import React, { useEffect, useRef } from 'react';
import styles from './style.module.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  spin?: boolean;
  rtl?: boolean;
  color?: string;
  fill?: string;
  stroke?: string;
}

export default function ShoppingCartLarge(props: IconProps) {
  const root = useRef<SVGSVGElement>(null);
  const { size = '1em', width, height, spin, rtl, color, fill, stroke, className, ...rest } = props;
  const _width = width || size;
  const _height = height || size;
  const _stroke = stroke || color;
  const _fill = fill || color;
  useEffect(() => {
    if (!_fill) {
      (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-fill]').forEach((item) => {
        item.setAttribute('fill', item.getAttribute('data-follow-fill') || '');
      });
    }
    if (!_stroke) {
      (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-stroke]').forEach((item) => {
        item.setAttribute('stroke', item.getAttribute('data-follow-stroke') || '');
      });
    }
  }, [stroke, color, fill]);
  return (
    <svg
      ref={root}
      width={_width}
      height={_height}
      viewBox="0 0 22 22"
      preserveAspectRatio="xMidYMid meet"
      fill=""
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
      {...rest}>

          <g><g data-follow-fill="currentColor" transform="translate(.1 1.1)" fillRule="evenodd" fill={_fill}><path fillRule="nonzero" d="M3.195 0c1.01 0 1.872.712 2.074 1.687l.027.156.226 1.731h13.914c.086 0 .172.006.257.016l.127.02a2.119 2.119 0 0 1 1.722 2.318l-.022.15-1.513 8.211a2.119 2.119 0 0 1-1.924 1.73l-.16.005H7.2a2.119 2.119 0 0 1-2.074-1.687l-.027-.156L3.511 2.077a.319.319 0 0 0-.25-.27L3.196 1.8H.9A.9.9 0 0 1 .787.007L.9 0h2.295Zm3.69 13.947c.018.136.12.243.25.27l.066.007h10.722c.132 0 .247-.08.295-.199l.018-.062 1.514-8.212a.319.319 0 0 0-.256-.371l-.058-.005-13.677-.001 1.126 8.573Z" /><ellipse ry="1.977" rx="1.926" cy="18.923" cx="6.977" /><ellipse ry="1.977" rx="1.926" cy="18.923" cx="18.101" /></g></g>
        </svg>);

}