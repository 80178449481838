import { CSSProperties } from 'react';

/** 左侧搜索分类 */
export const LEFT_SORT_LIST = [
	{
		id: 1,
		text: () => window._$m.t('综合')
	},
	{
		id: 2,
		text: () => window._$m.t('价格升序')
	},
	{
		id: 3,
		text: () => window._$m.t('价格降序')
	}
] as const;
export interface VirtualizedListProps {
	index: number;
	key: string;
	style: CSSProperties;
}
