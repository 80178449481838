import CollapseDefault from '@/common/icons/CollapseDefault';
import Check from '@/common/icons/Check';
import ExpandDefault from '@/common/icons/ExpandDefault';
import { LEFT_SORT_LIST } from '../../config';
import styles from '../../index.module.scss';
const Filter = (props) => {
	const {
		isLogin,
		leftFilterShow,
		setLeftShow,
		leftFilterText,
		changeLeftPriceFilter,
		comprehensiveSort
	} = props;
	return (
		<div className={styles.filter}>
			<div
				className={styles.content}
				style={{
					height: `${!isLogin ? '0' : '0.46rem'}`
				}}
			>
				{isLogin && (
					<div
						className={`${styles.left} ${leftFilterShow ? styles['active-color'] : ''}`}
						onClick={setLeftShow}
					>
						<span>{leftFilterText}</span>
						{leftFilterShow ? (
							<CollapseDefault
								className={styles['active-image']}
							/>
						) : (
							<ExpandDefault className={styles['img']} />
						)}
					</div>
				)}
			</div>
			{leftFilterShow ? (
				<div
					className={styles['filter-list']}
					style={{
						paddingBottom: '0'
					}}
				>
					{LEFT_SORT_LIST.map((sortItem) => (
						<div
							key={sortItem.id}
							className={styles['list-row']}
							onClick={() => {
								changeLeftPriceFilter(sortItem.id);
							}}
						>
							<span>{sortItem.text()}</span>
							{comprehensiveSort === sortItem.id ? (
								<Check color="#FF5010" />
							) : null}
						</div>
					))}
				</div>
			) : null}
		</div>
	);
};
export default Filter;
