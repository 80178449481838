/*
 * @Author: huajian
 * @Date: 2024-02-29 18:09:14
 * @LastEditors: yusha
 * @LastEditTime: 2024-03-06 15:35:42
 * @Description:
 */
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { memo, useCallback, useEffect, useState } from 'react';
import * as SDK from '@snifftest/sdk/lib/rn';

export const usePageFocuEffect = (fn: () => any) => {
  if (!SDK.isH5()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useFocusEffect(fn);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      fn();
    }, [fn]);
  }
};