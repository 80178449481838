/*
 * @Author: yusha
 * @Date: 2024-03-01 15:46:15
 * @LastEditors: yusha
 * @LastEditTime: 2024-03-01 16:42:54
 * @Description: 图片组件
 */
import FImage from '@sniff/react-native-fast-image';
import * as SDK from '@snifftest/sdk/lib/rn';
import { Image } from '@snifftest/tamagui';
import { memo } from 'react';

const FastImage = (props) => {
  if (SDK.isH5()) {
    return <Image {...props} />;
  }
  return <FImage {...props} />;
};
export default memo(FastImage);