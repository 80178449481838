/*
 * @Author: shiguang
 * @Date: 2023-04-25 10:46:45
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-19 22:12:12
 * @Description: shiguang
 */
import '@sniff/jsbridge';
import VConsole from 'vconsole';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './config/arms-ali';
import App from './App';
import './styles/theme/fonts/font.scss';
import './i18n/i18n';
import './index.scss';

// if (window.location.host !== 'm.3fbox.com') {
// 	const vConsole = new VConsole({ theme: 'dark' });
// 	window.vConsole = vConsole;
// }

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(<App />);
