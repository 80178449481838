/*
 * @Author: yusha
 * @Date: 2023-10-20 13:22:13
 * @LastEditors: yusha
 * @LastEditTime: 2024-05-11 16:32:00
 * @Description: layout-navbar
 */

import { HTMLAttributes, memo, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import Return from '@/common/icons/Return';
import { RouterHistoryContext } from '@/App';
import BackToHome from '@/component/BackToHome';
import Share from '@/component/Share';
import './index.scss';
interface CommonGoBackInter {
	onBack?: () => void;
	navigate: any;
	curPage: any;
	fromSniffApp: string;
	importantGoBack?: () => void;
}
export const commonGoBack = async ({
	onBack,
	navigate,
	curPage,
	fromSniffApp,
	importantGoBack
}: CommonGoBackInter) => {
	// 优先级最高，直接走自定义返回，防着被下面的逻辑给覆盖
	if (importantGoBack) {
		importantGoBack?.();
		return;
	}
	// 如果是嵌入app，且路由有这个字段fromSniffApp
	if (window?.ReactNativeWebView && fromSniffApp) {
		navigate('/user/globalReplaceView?fromSniffApp=true', {
			replace: true
		});
		return;
	}
	if (onBack) {
		return onBack();
	}
	if (
		window.location.href.includes('goods/list') &&
		window.location.href.includes('productCode')
	) {
		navigate(-2);
		return;
	}
	navigate(-1);
};
interface CustomNavbarProps extends HTMLAttributes<HTMLDivElement> {
	navBarRight?: React.ReactNode;
	title?: string;
	onBack?: () => void;
	/** 是否展示tabbar，用于不展示返回键 */
	isShowBackIcon?: boolean;
	// 是否固定
	fixed?: boolean;
	showBackHome?: boolean;
	/** 优先级最高的返回 */
	importantGoBack?: () => void;
}
const CustomNavbar = (props: CustomNavbarProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const {
		title,
		navBarRight,
		className,
		onBack,
		isShowBackIcon = true,
		fixed = false,
		showBackHome,
		importantGoBack,
		...divRestProps
	} = props;
	const routerHistory = useContext(RouterHistoryContext);
	const [curPage, prepage] = routerHistory;
	/** 返回 */
	const changeBack = async () => {
		// 如果是路由带过来的参数
		const searchObj = qs.parse(window.location.search);
		const fromSniffApp = searchObj.fromSniffApp as string;
		// fromSniffApp;
		commonGoBack({
			onBack,
			navigate,
			curPage,
			fromSniffApp,
			importantGoBack
		});
	};
	return (
		<div
			{...divRestProps}
			className={`layout-header ${className ?? ''}`}
			style={{
				position: fixed ? 'fixed' : 'relative'
			}}
		>
			{((isShowBackIcon && window.history.length > 1) ||
				window?.ReactNativeWebView) && (
				<div className="left-style" onClick={() => changeBack()}>
					{window.location.href.includes('collectionGoodsList') ? (
						<img
							style={{
								width: '0.12rem',
								height: '0.12rem'
							}}
							src="https://static-jp.theckb.com/static-asset/easy-h5/return.svg"
							alt=""
						/>
					) : (
						<Return
							style={{
								width: '0.16rem',
								height: '0.16rem'
							}}
						/>
					)}
				</div>
			)}

			{showBackHome && <BackToHome />}
			<div className="title-style">{title ?? ''}</div>
			<div className="right-style">{navBarRight && navBarRight}</div>
			{window.location.href.includes('productCode') && <Share />}
		</div>
	);
};
export default memo(CustomNavbar);
