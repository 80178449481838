/*
 * @Author: shiguang
 * @Date: 2023-08-22 17:43:43
 * @LastEditors: tianzhitong laotianwy@163.com
 * @LastEditTime: 2023-12-08 10:08:24
 * @Description:
 */
import { Dimensions } from 'react-native';

const deviceWidthDp = Dimensions.get('window').width;
const uiWidthPx = 750;

// px 转 dp（设计稿中的 px 转 rn 中的 dp）
export const px2dp = (uiElePx: number) => {
	return ((uiElePx * deviceWidthDp) / uiWidthPx) * 2;
};
