/*
 * @Author: tianzhitong laotianwy@163.com
 * @Date: 2023-08-28 17:41:39
 * @LastEditors: huajian
 * @LastEditTime: 2023-11-03 16:27:04
 * @FilePath: \ckb-smc-client-fed\src\model\Local.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { atom, useAtom } from 'jotai';
import { getLang } from '../i18n/i18n';

const langType = atom(getLang());
const local = atom((get) => get(langType));

// const writeLocal = atom(null, (_, set, update: string) => {
//     // `update` is any single value we receive for updating this atom
//     // set(langType, update);
//     console.log(11111111, set, update)
// });

const getLocal = () => local as unknown as string;

const refreshKey = atom<number>(1);

export default {
	langType,
	getLocal,
	refreshKey
	// writeLocal
};
