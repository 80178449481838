/*
 * @Author: huajian
 * @Date: 2023-12-13 15:59:41
 * @LastEditors: yusha
 * @LastEditTime: 2024-03-14 21:27:41
 * @LastEditors: huajian
 * @LastEditTime: 2024-01-19 09:54:40
 * @Description: 埋点init
 */

import TDAnalytics from '@snifftest/react-native-thinking-data';
import { PartialAnalyzeOrignDataProps } from '@/outs/atom/atomAnalyzeOrignData';
import { getEnv } from '@/config/request/env';

export enum EnumProductPurchaseType {
	/** 直采 */
	DIRECT,
	/** 代采 */
	PROXY
}

/** 埋点初始化 */
const initThinkingData = (analyzeData: PartialAnalyzeOrignDataProps) => {
	const env = getEnv();
	console.log(1111, env);
	// 需要等待tenjin初始化完毕再初始化，iOS需要等待ATT弹窗点击之后再初始化
	TDAnalytics.init({
		enableLog: true,
		appId:
			env === 'prod'
				? '2da850ddb9c04769a0b0de300767a72f'
				: 'a6f9d0a2f07149c0acc53a20db8d69da',
		serverUrl: 'https://global-receiver-ta.thinkingdata.cn',
		batch: true
	});

	const localTime = new Date();
	// 计算UTC偏移量（以分钟为单位）
	const utcOffset = (localTime.getTimezoneOffset() / 60) * -1;
	TDAnalytics.setSuperProperties({
		env,
		zone_offset: utcOffset,
		platform_type: 5,
		bu_type: EnumProductPurchaseType.DIRECT
	});

	/** 上报首页时间。因为首页可能没初始化sdk成功。所以在此上报 */
	TDAnalytics.track({
		eventName: TaEvent.PAGE_VIEW,
		properties: {
			position: 'home_page'
		}
	});

	/** 上报激活事件 */
	TDAnalytics.track({
		eventName: TaEvent.APP_ACTIVATE,
		properties: {
			...analyzeData
		}
	});

	/** 激活App 触发设置用户信息事件 */
	TDAnalytics.userSet({
		...analyzeData
	});
};

// ta 上报时间
enum TaEvent {
	/** 上报激活事件。与tenjin来源数据绑定 */
	APP_ACTIVATE = 'app_activate',
	/** 通用浏览/曝光用 page_view 事件  */
	PAGE_VIEW = 'page_view',
	/** 通用点击用 btn_click 事件 */
	BTN_CLICK = 'btn_click',
	/** Banner点击 */
	BANNER_CLICK = 'banner_click',
	/** 商品搜索 */
	PRODUCT_SEACH = 'product_seach',
	/** 限时活动点击 */
	LIMITED_TIME_ACTIVITY_CLICK = 'limited_time_activity_click',
	/** 商品点击 */
	PRODUCT_CLICK = 'product_click',
	/** 商品详情 */
	PRODUCT_DETAIL_PAGEVIEW = 'product_detail_pageview',
	/** 商品收藏 */
	PRODUCT_COLLECT = 'product_collect',
	/** 加入购物车 */
	ADD_SHOPPING_CART = 'add_shopping_cart',
	/** 购物车点击 */
	SHOPPING_CART_CLICK = 'shopping_cart_click',
	/** 商详购买 */
	PRODUCT_BUY = 'product_buy',
	/** 购物车浏览 */
	SHOPPING_CART_PAGEVIEW = 'shopping_cart_pageview',
	/** 提交订单 */
	ORDER_SUBMIT = 'order_submit',
	/** 订单支付 */
	ORDER_PAY = 'order_pay',
	/** 订单支付点击 */
	ORDER_PAY_CLICK = 'order_pay_click',
	/** 取消支付 */
	CANCELL_PAY = 'cancell_pay',
	/** 弹窗 */
	POPUP_WINDOW = 'pupup_window',
	/** 关闭弹窗 */
	CLOSE_WINDOW = 'close_window'
}

export { initThinkingData, TaEvent };
