/*
 * @Author: shiguang
 * @Date: 2023-10-18 10:38:00
 * @LastEditors: shiguang
 * @LastEditTime: 2023-10-28 17:59:02
 * @Description: 订单相关路由配置 ROUTER_ORDER
 */
import { lazy } from 'react';

export const ROUTER_ORDER = [
	{
		path: '/order/search',
		title: '订单搜索',
		component: lazy(() => import('../../container/order/OrderSearch'))
	},
	{
		path: '/order/list',
		title: '我的订单',
		component: lazy(() => import('../../container/order/List'))
	},
	{
		path: '/order/detail',
		title: '订单详情',
		showHeader: true,
		isNeedNavBarRight: true,
		component: lazy(() => import('../../container/order/Detail'))
	},
	{
		path: '/order/shopcart',
		title: '购物车',
		component: lazy(() => import('../../container/order/ShoppingCart'))
	},
	{
		path: '/order/create',
		title: '确认订单',
		component: lazy(() => import('../../container/order/ConfirmOrder'))
	}
];
