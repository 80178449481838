/*
 * @Author: yusha
 * @Date: 2024-01-10 18:41:53
 * @LastEditors: yusha
 * @LastEditTime: 2024-04-08 15:13:46
 * @Description: 选择sku弹窗
 */

import { memo, useState } from 'react';
import { Modal } from 'react-native';
import { Image, YStack } from '@snifftest/tamagui';
import * as SDK from '@snifftest/sdk/lib/rn';
import { CartAddSkuReq } from '@/outs/service/easyOrder';
import ConfirmBtns from './ConfirmBtns';
import SelectSkuContent from './SelectSkuContent';

const fitSize = SDK.fitSize;
interface SelectSkuPopupProps {
	visible: boolean;
	onClose: () => void;
	/** 三种类型，加购、立即购买、两者都有 */
	type?: 'both' | 'addCart' | 'buyItNow';
	getSelectedSkuInfo?: (val: CartAddSkuReq[]) => void;
}
/** 选择sku后的SPU数量集合 */
export type AmountProps = Record<string, number>;

const SelectSkuPopup = (props: SelectSkuPopupProps) => {
	const { visible, onClose, type, getSelectedSkuInfo } = props;
	/** sku数量统计 */
	const [skuAmount, setSkuAmount] = useState<AmountProps>({});
	/** SPU数量 */
	const [spuAmount, setSpuAmount] = useState<AmountProps>({});

	return (
		<Modal
			transparent={true}
			visible={visible}
			presentationStyle={'overFullScreen'}
			onRequestClose={() => {
				onClose();
			}}
			statusBarTranslucent={true}
		>
			<YStack
				style={{
					backgroundColor: 'rgba(0,0,0,0.4)',
					position: 'absolute',
					left: 0,
					top: 0,
					bottom: 0,
					right: 0,
					zIndex: 2
				}}
			/>

			<YStack
				position="absolute"
				zIndex={3}
				height={'66%'}
				width={'100%'}
				backgroundColor={'#fff'}
				bottom={0}
				left={0}
				borderTopLeftRadius={fitSize(8)}
				borderTopRightRadius={fitSize(8)}
				paddingTop={fitSize(20)}
				paddingBottom={fitSize(12)}
			>
				<SelectSkuContent
					onClose={onClose}
					setSkuAmount={setSkuAmount}
					skuAmount={skuAmount}
					setSpuAmount={setSpuAmount}
					spuAmount={spuAmount}
					type={type}
				/>

				{/* 确认按钮 */}
				<ConfirmBtns
					onClose={onClose}
					setSpuAmount={setSpuAmount}
					setSkuAmount={setSkuAmount}
					skuAmount={skuAmount}
					type={type}
					getSelectedSkuInfo={getSelectedSkuInfo}
				/>

				<Image
					onPress={onClose}
					style={{
						width: fitSize(26),
						height: fitSize(26),
						position: 'absolute',
						top: fitSize(-40),
						right: fitSize(6),
						zIndex: 9999
						// backgroundColor: 'red'
					}}
					source={{
						uri: 'https://static-jp.theckb.com/static-asset/easy-app/Close-one@3.x.png'
					}}
				/>
			</YStack>
		</Modal>
	);
};
export default memo(SelectSkuPopup);
