/*
 * @Author: yusha
 * @Date: 2024-01-10 12:10:54
 * @LastEditors: yusha
 * @LastEditTime: 2024-03-04 10:41:01
 * @Description:
 */

import { memo, useContext, useEffect, useState } from 'react';
import { YStack, Text, Image } from '@snifftest/tamagui';
import { useAsyncEffect } from 'ahooks';
import * as SDK from '@snifftest/sdk/lib/rn';
import FastImage from '@/outs/components/FastImage';
import {
  ResolveImageInfo,
  createPoolRequest,
  returnGetSizePromiseFunc } from
'@/outs/utils';
import { tool } from '@/outs/utils/tool';
import { ContextPreView } from '../../../context';

const fitSize = SDK.fitSize;

const GoodsDetailImage = () => {
  const { product = {} } = useContext(ContextPreView);
  const [productDescImgs, setProductDescImgs] =
  useState<ResolveImageInfo[]>();
  /** 获取详细说明 */
  const getDescText = () => {
    if (!product?.productDescriptionJa) {
      return null;
    }
    const textArr = product?.productDescriptionJa.split('\n');
    return textArr.map((item, index) => {
      return (
        <Text
          fontSize={fitSize(13)}
          lineHeight={fitSize(20)}
          fontWeight={'400'}
          color={'#1c2026'}
          key={index}>

					{item}
				</Text>);

    });
  };

  useAsyncEffect(async () => {
    const tasks: Array<() => Promise<ResolveImageInfo>> = [];
    product.productDescImgs?.forEach((item) => {
      tasks.push(returnGetSizePromiseFunc(item));
    });
    const res = await createPoolRequest(tasks);
    setProductDescImgs(res);
  }, [product]);
  return (
    <YStack
      style={{
        paddingBottom: fitSize(14),
        paddingTop: fitSize(14),
        backgroundColor: '#fff',
        marginTop: fitSize(10),
        borderRadius: fitSize(4)
      }}>

			<Text
        style={{
          fontSize: fitSize(14),
          color: '#000',
          fontWeight: '500',
          marginTop: fitSize(8)
        }}>

				{tool.strings('商品详情')}
			</Text>
			<YStack marginTop={fitSize(8)}>{getDescText()}</YStack>
			<YStack style={{ marginTop: fitSize(10) }}>
				{productDescImgs?.map((item) => {
          return (
            <FastImage
              style={{
                width: SDK.fitSize(375),
                height: item.imgH
              }}
              // resizeMode=''
              // source={{ uri: changeImageCdn(item.url, 375) }}
              // TODO
              source={{ uri: item.url }}
              key={item.url} />);


        })}
			</YStack>
		</YStack>);

};
export default memo(GoodsDetailImage);