/*
 * @Author: yusha
 * @Date: 2023-11-20 13:30:01
 * @LastEditors: huajian
 * @LastEditTime: 2024-03-26 15:40:57
 * @Description: 前往代采弹窗
 */

import { Button, Checkbox, Mask, ModalProps } from 'antd-mobile';
import { useState } from 'react';
import './index.scss';
import { useRequest } from 'ahooks';
import { useAtomValue } from 'jotai';
import { request } from '@/config/request';
import { User } from '@/Atoms';
interface ModalGoToPurchaseProps extends ModalProps {
	onOk: (val: boolean) => void;
}
const ModalGoToPurchase = (props: ModalGoToPurchaseProps) => {
	const { visible, onClose, onOk } = props;
	/** 是否勾选无需询问 */
	const [isSelectDefault, setIsSelectDefault] = useState(false);
	/** 用户信息 */
	const userInfo = useAtomValue(User.userDetail);
	/** 更新直采用户跳转代采商品是否弹框标识  */
	const { runAsync: updateCustomer2AgencyGoodsSign, loading } = useRequest(
		request.customer.updateCustomer2AgencyGoodsSign
			.updateCustomer2AgencyGoodsSign,
		{
			manual: true
		}
	);
	/** 前往代采 */
	const submitInfo = async () => {
		// 只有选中之后才请求该接口
		if (isSelectDefault) {
			await updateCustomer2AgencyGoodsSign();
		}
		onOk(isSelectDefault);
	};
	return (
		<>
			{visible && (
				<Mask
					className="modal-goto-purchase-mask"
					destroyOnClose
					getContainer={() => document.getElementById('root')!}
					style={{
						display: 'flex'
					}}
				>
					<div className="modal-goto-purchase">
						<div className="purchase-scroll">
							<span className="purchase-modal-tip">
								{window._$m.t('该商品为代采会员特惠商品')}
							</span>

							<img
								className="img-title"
								alt=""
								src="https://static-s.theckb.com/BusinessMarket/Easy/H5/easytitle.png"
							/>

							<div
								style={{
									position: 'relative'
								}}
							>
								<img
									className="easy-to-purchase-icon"
									alt=""
									src="https://static-s.theckb.com/BusinessMarket/Easy/H5/icon.png"
								/>

								<img
									className="easy-text"
									alt=""
									src="https://static-s.theckb.com/BusinessMarket/Easy/H5/img_easy_text.png"
								/>
							</div>
							<img
								className="vip-text"
								alt=""
								src="https://static-s.theckb.com/BusinessMarket/Easy/H5/ing_purchase_text.png"
							/>

							{/* <div className="position-relative">
                                                                                                                                                <img
                                                                                                                                                	className="imgs"
                                                                                                                                                	alt=""
                                                                                                                                                	src="https://static-s.theckb.com/BusinessMarket/Easy/H5/purchaseModal.png"
                                                                                                                                                />
                                                                                                                                                <img
                                                                                                                                                	className="qr-code"
                                                                                                                                                	alt=""
                                                                                                                                                	src="https://static-s.theckb.com/BusinessMarket/Easy/PC/QRCode.png"
                                                                                                                                                />
                                                                                                                                                </div> */}
						</div>
						{Boolean(userInfo?.membership?.templateLevel) && (
							<div className="default-select">
								<Checkbox
									onChange={(val) => {
										setIsSelectDefault(val);
									}}
								>
									{window._$m.t('下次自动跳转代采，无需询问')}
								</Checkbox>
							</div>
						)}

						<div className="btns">
							<Button shape="rounded" onClick={onClose}>
								{window._$m.t('留在直采')}
							</Button>
							<Button
								shape="rounded"
								color="primary"
								onClick={submitInfo}
							>
								{window._$m.t('前往代采')}
							</Button>
						</div>
					</div>
				</Mask>
			)}
		</>
	);
};
export default ModalGoToPurchase;
