/*
 * @Author: yusha
 * @Date: 2024-01-08 20:07:09
 * @LastEditors: yusha
 * @LastEditTime: 2024-05-15 14:55:40
 * @Description: 商品活动
 */

import { ReactNode, memo, useContext, useState } from 'react';
import { Text, XStack, Image } from '@snifftest/tamagui';
import { useAtomValue } from 'jotai';
import { TouchableOpacity } from 'react-native';
import * as SDK from '@snifftest/sdk/lib/rn';
import { ACTIVITY_TYPE } from '@/outs/const';
import { UserDefaultFreeShippingResp } from '@/outs/service/easyOrder';
import { formatMoney } from '@/outs/utils';
import { tool } from '@/outs/utils/tool';
import ActiveTag from '../../../component/ActiveTag';
import { ContextPreView } from '../../../context';
import CustomModal from '../../../component/CustomModal';

const fitSize = SDK.fitSize;

interface ActiveViewProps {
	setFreightModalVisible: (val: boolean) => void;
	freeShipInfo: UserDefaultFreeShippingResp;
}
const ActiveView = (props: ActiveViewProps) => {
	const { freeShipInfo = {} } = props;
	const {
		product = {},
		handleCopy,
		atomUserDetail
	} = useContext(ContextPreView);
	const { freeShipping } = product;
	const useDetail = useAtomValue(atomUserDetail!);
	/** 不包邮的提示信息 */
	const [confirmMessageNode, setConfirmMessageNode] =
		useState<ReactNode>(null);

	/** 获取活动 */
	const getActiveView = () => {
		const doms = [] as ReactNode[];
		// 折扣价
		if (
			product.activityInfo?.activityType === ACTIVITY_TYPE.DISCOUNT_RATE
		) {
			doms.push(
				<XStack
					style={{
						marginRight: fitSize(8),
						alignItems: 'center'
					}}
				>
					<ActiveTag
						text={`-${
							product.activityInfo?.discountRate ?? 300
						}%OFF`}
					/>
				</XStack>
			);
		}

		// 新人包邮
		if (
			freeShipInfo.newUserFlag &&
			freeShipInfo.freeShippingAmount &&
			freeShipping !== false
		) {
			doms.push(
				<XStack
					style={{
						marginRight: fitSize(8)
					}}
				>
					<ActiveTag
						text={tool.strings('新人专享！满{{money}}円免配送费', {
							money: formatMoney(freeShipInfo.freeShippingAmount)
						})}
					/>
				</XStack>
			);
		}

		// 包邮
		if (
			freeShipInfo.freeShippingAmount &&
			!freeShipInfo.newUserFlag &&
			freeShipping !== false
		) {
			doms.push(
				<XStack
					style={{
						marginRight: fitSize(8)
					}}
				>
					<ActiveTag
						tips={tool.strings(
							'活动适用于{{cityName}}，具体运费以您的收货地区为准，详见下方运费详情表',
							{
								cityName: freeShipInfo.address
							}
						)}
						text={tool.strings('订单满{{num}}円以上包邮', {
							num: formatMoney(freeShipInfo.freeShippingAmount)
						})}
					/>
				</XStack>
			);
		}

		// 不包邮
		if (freeShipping === false) {
			doms.push(
				<XStack
					style={{
						marginRight: fitSize(8)
					}}
				>
					<ActiveTag
						setConfirmMessageNode={setConfirmMessageNode}
						tipsNode={
							<XStack>
								<Text
									style={{
										textAlign: 'left',
										color: '#1C2026',
										fontSize: fitSize(14),
										fontWeight: '400',
										lineHeight: fitSize(22)
									}}
								>
									{tool.strings(
										'部分特殊商品不参与包邮，此类商品运费单独计算。'
									)}
									<Text
										onPress={(e) => {
											e.preventDefault();
											e.stopPropagation();
											// 关闭当前弹窗并且打开运费弹窗
											setConfirmMessageNode(null);
											props.setFreightModalVisible(true);
										}}
										style={{
											color: '#0069D9',
											fontSize: fitSize(14),
											fontWeight: '400',
											lineHeight: fitSize(22)
										}}
									>
										{tool.strings('查看具体运费>')}
									</Text>
								</Text>
							</XStack>
						}
						textStyle={{
							color: '#505762'
						}}
						text={tool.strings('不参与包邮活动')}
					/>
				</XStack>
			);
		}
		return doms;
	};
	return (
		<XStack
			style={{
				display: 'flex',
				width: '100%',
				flexDirection: 'row',
				alignItems: 'flex-start',
				marginTop: fitSize(8),
				flexWrap: 'wrap'
			}}
		>
			{Boolean(product?.productNo) && (
				<XStack
					flexDirection="row"
					alignItems="center"
					marginRight={fitSize(8)}
					borderWidth={fitSize(1)}
					borderColor={'#e6eaf0'}
					borderRadius={fitSize(4)}
					backgroundColor={'#fff'}
					paddingHorizontal={fitSize(8)}
					paddingVertical={fitSize(5.5)}
				>
					<Text fontSize={fitSize(10)} fontWeight={'500'}>
						{tool.strings('商品番号')}
					</Text>
					<Text
						fontSize={fitSize(10)}
						marginLeft={fitSize(4)}
						fontWeight={'500'}
					>
						{product.productNo}
					</Text>
					<TouchableOpacity
						onPress={() => {
							if (SDK.isH5()) {
								handleCopy?.(product.productNo!);
								return;
							}
							tool.ClipboardText(product.productNo);
						}}
					>
						<Image
							source={{
								uri: 'https://static-jp.theckb.com/static-asset/easy-app/icon_copy.png'
							}}
							width={fitSize(12)}
							height={fitSize(12)}
							marginLeft={fitSize(4)}
						/>
					</TouchableOpacity>
				</XStack>
			)}

			{getActiveView()}

			<CustomModal
				showConfirmBtn={false}
				cancelText={tool.strings('我知道了')}
				visible={Boolean(confirmMessageNode)}
				onClose={() => setConfirmMessageNode(null)}
				maskClosable
			>
				{confirmMessageNode}
			</CustomModal>
		</XStack>
	);
};
export default memo(ActiveView);
