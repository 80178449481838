/*
 * @Author: liangliang
 * @Date: 2023-06-19 10:06:42
 * @LastEditors: huajian
 * @LastEditTime: 2024-03-11 16:52:19
 * @Description:
 */
import Toast from '@sniff/teaset/components/Toast/Toast';
import * as SDK from '@snifftest/sdk/lib/rn';
import langs from '../../i18n/locales/ja_JP.json';
import { px2dp } from './px2dp';
import { strings as t } from './i18n/i18n';
import { GlobalLoading } from './loading/globalLoading';
import {
	STATUS_BAR,
	defColor,
	defWidth,
	defHeight,
	isUrl,
	setImgUrlsAndHeight,
	preventDoubleClick,
	skuImg,
	ClipboardText,
	getNowTimeFull,
	updateTime,
	formatPrice,
	isChinese,
	isJapanese,
	isKoera,
	formatPriceJp,
	isAndroid,
	BORDER_WIDTH,
	groupArray,
	defColor_black,
	defColor_default
} from './utils';
import { getItem, setItem, removeItem, clearItem } from './AsyncStorage';
// STATUS_BAR
export const tool = {
	px2dp,
	defColor,
	strings: (name, params = {}) => {
		if (!SDK.isH5()) {
			return t(name, params);
		}

		let resStr = langs[name] || name;
		if (params && Object.keys(params).length) {
			for (const key in params) {
				if (Object.prototype.hasOwnProperty.call(params, key)) {
					const value = params[key];
					const templateStr = `{{${key}}}`;
					resStr = resStr.replace(templateStr, value);
				}
			}
		}
		return resStr;
	},
	STATUS_BAR,
	defWidth,
	defHeight,
	isUrl,
	setImgUrlsAndHeight,
	preventDoubleClick,
	skuImg,
	ClipboardText,
	GlobalLoading,
	Toast,
	getItem,
	setItem,
	removeItem,
	clearItem,
	getNowTimeFull,
	updateTime,
	formatPrice,
	isChinese,
	isJapanese,
	isKoera,
	formatPriceJp,
	isAndroid,
	BORDER_WIDTH,
	groupArray,
	defColor_black,
	defColor_default
};
