const serverCode = {
	// 服务器返回的 code.js 对应的文本输出
	noMatch: 'エラーあり カスタマーサービスへご連絡下さい',
	24010005:
		'このユーザー名は既に使用されています別のユーザー名を入力してください',
	24010056: 'この電話番号は登録されていません',
	24010021: 'メールアドレス 登録済。別メールアドレスを入力して下さい',
	24010054:
		'認証コード無効再度認証コードを送付し、正しい認証番号を入力して下さい',
	24010055:
		' この電話番号は既に登録されています。他の電話番号を登録してください',
	24010061: '未登録',
	21000000: '店舗連携失敗',
	21000001:
		'店舗連携失敗　当該店舗は他アカウント連携済、現設定解除後、API連携を再試行して下さい',
	21000002: '店舗名 該当なし',
	21190002: '注文取込み中、暫くお待ちください',
	21000003: '当該店舗 API未連携',
	21000004: 'エラーあり 時間をおいて再試行して下さい',
	21000005: '111111111',
	21000006: '接続エラー API接続上限時間をおいて再試行して下さい ',
	21000007: '当該店舗 API未連携',
	21000008: '当該店舗 API未連携',
	21000009: '商品取込み失敗',
	21000010: '商品情報取込み失敗',
	21000011: '必須項目productCodeを入力して下さい ',
	21000012:
		'発注ショップ未紐付け連携済店舗商品と発注ショップ情報を紐付けて下さい ',
	21000013: 'API連携済 商品SKU紐付けを実行して下さい',
	21000014: 'SKU紐付け済商品SKU紐付けを解除して下さい ',
	21000015: 'データ同期中',
	21000016:
		'入力不備　API連携用IDまたはAPI連携用secret入力内容を確認し再実行して下さい ',
	21000017: '指定注文 該当なし',
	21000018: '追跡番号更新エラー',
	21000019: 'エラーあり',
	21000020: '指定商品 該当なし',
	21000021: 'アップロードエラー商品情報のアップロードに失敗しました ',
	210000393:
		'商品のプロパティ数が100個超えました，該当商品は自動登録できません',
	21000022:
		'形式不正 Shopify店舗URL「https: //○○○.myshopify.com」のURLを入力して下さい',
	21000025: '削除不可商品連携済のため削除不可 ',
	21000026: '物流会社 該当なし',
	21000027: '注文処理中、暫くお待ちください',
	21000127: '有効な店舗URLを入力して下さい 例：https://xxx.myshopify.com',
	21000028: '注文完了',
	21000029: '仕入れルート不備あり',
	22040010: '納品書をアップロードして下さい',
	24030001: 'デフォルト設定完了済',
	24030002: '必須項目カテゴリーを選択して下さい ',
	24030003: 'カテゴリー 該当なし',
	24030004: 'オプション 該当なし',
	24030005: '設定内容 該当なし',
	24030006: '発送用オプション 設定済',
	24030007: '発送オプション設定不要B2Bユーザはオプション設定不要です ',
	24030008: '当該オプション削除不可',
	24030009: '資材紐付けエラー',
	24030010: 'オプション 該当なし',
	24030011: '指定発送オプション 該当なし',
	24010001: '必須項目 ユーザー名',
	24010002: 'ユーザー名 登録済別ユーザー名を入力して下さい ',
	24010003:
		'形式不正 ユーザー名またはパスワード正しい形式のユーザー名、 パスワードを入力して下さい ',
	24010006: '形式不正 ユーザー名',
	24010007: '氏名を入力して下さい',
	24010008: '形式不正 氏名',
	24010009: '形式不正 氏名12 文字以内で氏名を入力して下さい ',
	24010011: '必須項目 パスワード',
	24010012: 'パスワード（確認用）を入力して下さい',
	24010013: 'パスワード不一致',
	24010015: '新しいパスワードを入力して下さい',
	24010016: '新しいパスワード（確認用）を入力して下さい',
	24010017: 'パスワード入力不備',
	24010018: 'パスワード変更失敗',
	24010019:
		'パスワード入力不備パスワードは、 6~20 文字以内の半角英数で設定して下さい ',
	24010020:
		'パスワード入力不備新パスワードは現パスワードと相違する値を設定して下さい ',
	24010022: '必須項目 メールアドレス',
	24010023: 'メール送信失敗',
	24010024: '利用規約に同意後、次へ',
	24010025: '必須項目 国',
	24010026: '電話番号を入力して下さい',
	24010027: '認証コードを入力して下さい',
	24010028:
		'認証コード無効認証コードを再送付し、 正しい認証番号を入力して下さい ',
	24010029: '形式不正 メールアドレス',
	24010030: 'メールアドレス 該当なし',
	24010032: 'ユーザー名 該当なし',
	24010033: '当該アカウント停止済',
	24010035: '銀行口座 該当なし',
	24010036: '貨幣種類を入力して下さい',
	24010038: '必須項目為替レートを入力して下さい ',
	24010040: '必須項目商品IDを入力して下さい ',
	24010041: '必須項目ブランド名を入力して下さい ',
	24010042: '更新失敗ユーザー情報更新に失敗しました ',
	24010043: '形式不正 電話番号正しい電話番号を入力して下さい ',
	24010044: '必須項目仕入れ元を入力して下さい ',
	24010045: '仕入れ元情報不備あり発注ショップ情報に不備あり ',
	24010046: '認証コード申請 更新過多時間を置いて、 再実行して下さい ',
	24010049: '形式不正 メールアドレス正しいメールアドレスを入力して下さい ',
	24010068: 'メールアドレス 未登録新規登録を進めて下さい ',
	24020001: '店舗名を入力して下さい',
	24020002: '店舗名 該当なし',
	24020003: '当該店舗 API未連携',
	24020004:
		'子アカウント 店舗作成不可親アカウントにログインし再試行して下さい ',
	24020005: '子アカウント作成完了',
	24020009: '変更不可API連携済のため店舗情報変更不可 ',
	24020008: '連携用ID、連携用secreを入力して下さい',
	240200067: '店舗名 登録済別店舗名を入力して下さい ',
	24200002:
		'上限に達しました本日、 これ以上のおすすめ商品から削除操作はできません ',
	24100001: '店舗名 該当なし',
	24100002: '入力URL不備   URLを確認の上、再度お試し下さい ',
	20000002: 'お支払い中',
	20000003: '注文支払完了',
	20000004: 'お支払い待ち',
	20000005: '発注不可会員登録実施後、 発注可能 ',
	20000007: '商品 該当なし',
	20000008: '注文明細 該当なし',
	20000009: 'お支払い明細 該当なし',
	20000011: '注文清算中',
	20000012: '注文清算完了',
	20000013: 'お支払い失敗',
	20000015: 'キャンセル不可買い付け対応中 ',
	20000017: '必須項目商品IDを入力して下さい ',
	20010001: '残高不足口座残高をチャージして下さい ',
	20020002: '子商品の数を入力して下さい',
	20020003: '必須項目購入数量 ',
	20020004: 'セット組対応済 重複セット組 作成不可 ',
	20020005: 'セット組商品は1点以上設定が必要です',
	20020006: 'セット注文 該当なし',
	20020008: 'セット組注文明細 該当なし',
	20020010: '該当注文書 未精算',
	22050002: '1セット組商品には、単品商品が2件以上必要です',
	22050003: '1注文で最大30点までセット組可能です',
	22050005: 'カート追加失敗',
	22040003: '必須項目発送時オプションを選択して下さい ',
	22040004: '必須項目商品ラベル貼付オプションを選択して下さい ',
	22040005: '国際配送依頼 作成失敗',
	22040006: '配送可能数量不足',
	22040007: '為替レート（RMB→ドル） 検索失敗',
	22040008: '在庫ロック失敗',
	22040009: '必須項目箱ラベル貼付オプションを選択して下さい ',
	22010001: '発注不可 商品在庫切れ',
	22010002: '残高不足口座残高をチャージして下さい ',
	22010003: '注文上限額超過D2C直送の注文合計額上限は2000元 / 1 注文 です ',
	22010007: '商品が存在しません',
	22010010: '変更不可検品中のため資材変更不可 ',
	22010011: '商品選択後、カート追加して下さい',
	22010012: '商品skuを入力して下さい',
	22010013: '必須項目購入数量を入力して下さい ',
	22010014: '発注不可 商品在庫切れ',
	22010019: '1商品につき最大10000点までカート追加可能です',
	22010021: '物流会社 該当なし',
	22010025: '操作不可直送注文、 残在庫を利用不可 ',
	22010026: 'キャンセル不可配送済 ',
	22010040: '取消不可審査中状態のみ取消可能 ',
	22010049: '取消不可在庫保管注文 取消不可 ',
	22010051: '分納発送不可在庫保管商品は処理できません ',
	22010052: '分納発送不可配送待ち商品ではなく処理できません ',
	22010057: '店舗名 該当なし',
	22010058: 'AmazonAPI未連携',
	22010060:
		'選択商品 納品対応中出荷済または納品プランキャンセル後、 再試行して下さい ',
	22010064: '購入数量不備購入数量が最低ロット数を満たしていません ',
	22010068: 'キャンセル可能な商品がありません',
	22030004: '必須項目商品SKUを入力して下さい ',
	22030005: '必須項目日本倉庫の管理SKUを入力して下さい ',
	22030006: '商品取込失敗当該店舗の商品取込が失敗しました ',
	22030007: 'SKU未紐付けAmazonSKUと未紐付け商品あり ',
	22030008:
		'Amazon上 商品登録情報不備ありAmazon上で修正後、 再試行して下さい ',
	24010062: '登録き期限が切れる',
	10000000: 'ログアウト状態',
	22030009: '必須項目 AmazonSKUと紐付けてください ',
	210000390:
		'skuはバインドされていますので、バインドを繰り返さないでください',
	280203014: '口座残高不足 資金をチャージ後、会員費支払いをお願いします',
	24100004: '当商品発注できません、カスタマサービスにご連絡ください',
	28000026: '最低輸送kg数を下回っています',
	210000391: '必須項目：発送用オプションを選択して下さい',
	240300006: '対応中申請があり、処理が完了してから再度申請して下さい',
	24010066: '【ご確認下さい】店舗未登録。店舗作成後、再試行して下さい',
	22010073: '価格を更新された商品あり、最新の商品価格に更新が完了しました。',
	210000394: '在庫不足商品があります',
	26020006: '編集不可　倉庫対応中の商品は編集できません',
	240201001:
		'該当PayPalN支払請求が見つかりません、オンラインカスタマサービスまでご連絡下さい',
	240201002:
		'PayPalからの支払請求ではありません、オンラインカスタマサービスまでご連絡下さい',
	240201003: 'PayPalでの支払いが完了しました',
	240201004:
		'PayPalでの支払い手続きが完了していません、手順に沿ってお支払い手続きを完了してください',
	240201005:
		'PayPalの支払い手続きページには進めません、手順に沿ってお支払い手続きを完了してください',
	240201006: '支払いは完了していません　再度お支払い手続きをお試し下さい',
	240201007:
		'"金融機関に登録されている請求先住所を確認できず、またはカートの利用限度額を超えるため、カード会社にによる決済を拒否されましたお手数ですが、カード発行会社へ直接ご確認下さい"',
	240201008:
		'PayPal での支払いが完了していません　PayPal へ直接お問い合わせ下さい',
	210000395: '商品オプションをご選択下さい',
	26000095:
		'該当物流会社のステータスは表記できないため、お手数ですが、該当物流会社の追跡サイトからご確認くださいませ。',
	22040012: '商品SKUラベルをアップロードして下さい',
	22040011: '箱ラベルをアップロードして下さい',
	22010074: '注文商品数は300を超えてはいけません',
	22040013:
		'アップロードデータは追加された商品SKUと異なり、ご確認頂いた上で、再度アップロードして下さい',
	22010075: '既に発送済の注文には、追跡番号の自動反映を解除できません',
	2000008001: '商品重复请更换商品',
	20000023: '重拍次数达到上限',
	26000082:
		'アフター補償申請不可。アフター補償申請の有効期間は商品購入後2ヶ月以内です',
	24040051: '会员等级模板价格未同步paypal',
	240300009: 'ベータユーザーではない',
	// 240300020: '目前app仅限日本用户使用，请去电脑端使用系统',
	// 28020009: '充值余额失败：请购买或者续费会员',
	// 服务器返回的 apiCode.js 对应的文本输出
	1001: '入力不備　お届け先情報 有効な住所を入力して下さい',
	1002: '注文中のアイテムは存在しません',
	1003: '直行便商品と紐付けない商品があります',
	1004: '発注不可，運送会社設定実施後、発注可能',
	1005: '該当店舗の口座残高不足，資金をチャージ後、発注をお願いします',
	1006: '在庫不足商品があります',
	1007: '商品オプションをご選択下さい',
	1008: '注文上限額超過，D2C直送の注文合計額上限は、2000元/回　です',
	240100468: 'メールボックスは登録されていません',
	33000011:
		'申し訳ございませんが、１円商品は1アカウントにつき1点までとなっており、それ以上の追加はできません',
	33000010: 'すみません、商品は売り切れています',
	33000009: 'すみません、この商品は販売停止となりました',
	24100008: 'すみません、この商品は販売停止となりました',
	33000003:
		'申し訳ありませんが、1 つのアカウントで購入できる有効な製品は 1 つまでに制限されています。すでに購入済みです。',
	33000014: '1円セールの商品はお一人様の購入上限が1点になっております。',
	33000001: '注文者の住所が100を超えることはできません',
	33000002: 'お届け先が100を超えることはできません',
	33000013: 'カートの追加数は200を超えることはできません',
	33000015: '購入数量不備。 購入数量が最低ロット数を満たしていません',
	2002: '口座残高不足，資金をチャージ後、再実行をお願いします',
	3006: 'この注文は既に支払われていますので、重複して支払わないでください',
	33000018:
		'現在、送料が取得できず、注文することができません。ページを更新してから再試行してください',
	33000017: '商品はみつかりません、ページを更新してから再試行してください',
	33000019: '1つの商品の購入SKU数は99,999を超えることはできません',
	33000000: 'カートに追加された数量が正しくない',
	24000000: 'この商品は削除されました'
};

/**
 * 日文字幕 508
 */
export default {
	...serverCode,
	// 直采首页
	资金钱包: '口座一覧',
	元: '円',
	全部: '全て',
	免费注册查看价格: '無料登録して価格を確認',
	// 图搜
	拍摄照片: '写真を撮る',
	从相册选取: 'アルバムから選択',
	代采专属商品: '代理購入限定商品',
	代采: '代理購入',
	取消: 'キャンセル',
	// 侧边栏
	我的订单: '注文履歴',
	待支付: '決済待ち',
	待发货: '未出荷',
	已发货: '出荷済み',
	收藏商品: 'お気に入り商品/特集',
	消息通知: 'お知らせ',
	退出登录: 'ログアウト',
	在线客服: 'カスタマーサービス',
	'抱歉，该商品暂不支持购买': 'すみません、該当商品が買付不可です。',
	直行便用户协议: '直行便利用規約',
	'1688用户协议': '1688利用規約',
	'1688隐私政策': '1688プライバシーポリシー',
	注册成为1688官方认证账号: '直行便 X 1688  アカウントを新規登録',
	请同意: 'に同意する',
	返回上一步: '前のステップに戻る',
	手机验证码找回: '携帯番号入力',
	邮箱验证码找回: 'メールアドレス入力',
	验证码格式非法: '認証コード形式不正',
	请输入手机验证码: '認証コードを入力して下さい',
	手机号登录: '携帯電話でログイン',
	手机验证码: '認証コード',
	'手机号不存在，请重新输入': 'この電話番号は登録されていません',
	首页: 'ホームページ',
	同意直行便用户协议: '直行便利用規約',
	注册完成: '登録完了',
	// 直行便用户协议: '直行便利用規約',
	和: 'と',
	分类搜索: '分類検索',
	'URL、商品名搜索': 'URL、商品名検索',
	日语: '日本語',
	搜索: '検索',
	我的频道: 'MYチャンネル',
	透明购: 'サイト買付',
	市场购: '市場直買付',
	从采购网站: '仕入れサイトから',
	查看更多: 'もっと見る',
	从销售网站: '販売サイトから',
	青蛙频道: 'KAERU MEDIA',
	昨日店铺数据: '昨日店舗情報',
	余额: '残高',
	API下单量: '注文数(API発注)',
	完成订单数量: '完了注文数',
	订单数量: '注文数',
	下单金额: '注文金額',
	我的收藏: 'お気に入り',
	足迹: '閲覧履歴',
	商品: '商品',
	店铺: '店舗',
	购买过: '購入履歴',
	推荐店铺: 'おすすめ店舗',
	推荐商品: 'おすすめ商品',
	男士: 'メンズ',
	女士: 'レディース',
	儿童: 'キッズ',
	杂货: '雑貨',
	热卖排行: '売れ筋ランキング',
	购物车: 'カート',
	消息: 'メッセージ',
	我的: 'マイページ',
	用户名登录: 'ユーザー名ログイン',
	邮箱登录: 'メールアドレスログイン',
	用户名: 'ユーザー名',
	忘记密码: 'パスワードを忘れた方',
	登录: 'ログイン',
	密码: 'パスワード',
	我已阅读并同意: '利用規約に同意する',
	使用条款: '利用規約',
	'还没有账号？去注册': '新規会員登録',
	请输入用户名: 'ユーザー名を入力',
	请输入密码: 'パスワードを入力',
	电子邮箱: 'メールアドレス',
	请输入电子邮箱: 'メールアドレスを入力',
	电子邮箱授权码: '認証コード',
	请输入电子邮箱授权码: '認証コードを入力',
	获取授权码: '認証コード取得',
	用户名不能为空: '必須項目を入力してください',
	密码不能为空: 'パスワードを入力してください',
	密码格式错误: 'パスワード入力誤り',
	请阅读并勾选用户协议: '利用規約に同意する',
	登录成功: 'ログインしました',
	邮箱格式不正确: 'メールアドレス形式不正',
	验证码发送成功: '送信済',
	邮箱授权码不正确: '認証コード　無効',
	注册: '新規登録',
	请输入确认密码: 'パスワード（確認用）を入力',
	确认密码: 'パスワードを入力してください',
	电话号码: '電話番号',
	请输入电话号码: '電話番号を入力',
	确认密码不能为空: 'パスワードを入力してください',
	注册成功: '新規登録完了',
	获取验证码: '認証コード取得',
	两次密码不一致: 'パスワード　不一致',
	密码修改成功: 'パスワード変更完了',
	店舗追加: '店舗追加',
	全部站点: '仕入れ元',
	时尚网站: 'ファッションサイト',
	'3C网站': '3Cサイト',
	搜索记录: '検索履歴',
	人气关键词: '人気キーワード',
	中文: '中国語',
	删除搜索记录: '検索履歴　削除',
	推荐: 'おすすめ',
	热销: '売れている',
	价格升序: '単価の安い順',
	价格降序: '単価の高い順',
	所有来源: '全て',
	淘宝: 'タオバオ',
	天猫: 'Tmall',
	拼多多: 'Pinduoduo',
	广州女装市场: '広州レディース市場',
	广州男装市场: '広州メンズ市場',
	杭州女装市场: '杭州レディース市場',
	杭州男装市场: '杭州メンズ市場',
	广州鞋业市场: '広州靴市場',
	义乌杂货批发市场: 'イーウー雑貨卸売市場',
	'价格区间(元)': '価格帯（元）',
	'０元': '０元',
	无上限: '上限なし',
	重置: 'クリア',
	按此条件搜索: 'この条件で検索する',
	筛选: '検索',
	关闭: '閉じる',
	最小起订量: 'ロット数',
	选择: '選択',
	'颜色分类/尺码': '色/サイズ',
	运费: '国際送料',
	国际运费及购买成本估算: '仕入れコスト（概算）',
	查看原网址: '仕入れ元',
	类似商品: '類似商品',
	类似店铺: '類似店舗',
	关联到我的店铺商品: 'SKU紐付け',
	传输到我的店铺: '自社サイトヘ一括登録',
	'店铺名（支持日文）': '商品名/店舗名（日本語で検索可能）',
	去登录: 'ログイン',
	日语搜索: '日本語で検索',
	暂不查看: '暫くチェックしない',
	立即查看: 'すぐチェックする',
	未搜索到商品: '一致する検索結果なし',
	'感谢反馈，我们将为你推荐更加准确的商品':
		'フィードバックを頂きありがとうございました。 これからより多く関連商品をお勧めいたします',
	'加载更多商品中，请稍后': '読み込み中',
	暂无相关产品: 'データ無し',
	没有更多了: 'これは全部です',
	清爽模式: '清爽模式',
	高效模式: '高效模式',
	常用分类: 'よく使われるカテゴリー',
	更多分类: 'その他',
	找同款更低: '類似商品探す',
	购买成本预估: '合計額（概算）',
	'此页面仅供支持成本预估，最终费用以下单时为准':
		'発注依頼時に、表示の概算費用をお預かりします。注文商品が検品完了次第、お預かり金は全額返金し正式費用を精算します',
	商品数量: '数量',
	国际物流: '国際配送情報',
	公斤: '㎏',
	商品附加项: '商品オプション',
	请选择: '選択',
	点击计算预估成本: '計算',
	去挖更多: 'もっと見る',
	订单管理: '注文管理',
	默认规格: '規格',
	暂未发现收藏的店铺: 'お気に入り店舗がみつかりませんでした',
	商品已添加至购物车: 'カート　追加完了',
	C端直发: 'エンドユーザー直送',
	'确定要删除该商品吗？': '【ご確認下さい】削除してもよろしいでしょうか',
	删除成功: '削除完了',
	请填写收货地址: '入力必須，お届け先情報',
	请选择发货附加项: '入力必須，発送用オプション',
	请选择国际物流线路: '国際運送会社を選択',
	完成: '完了',
	删除: '削除',
	编辑: '編集',
	最小起订量为: '最小ロット数',
	无需选项: 'オプション必要なし',
	'合计(概算)': '合計額(概算):',
	'余额：': '残高：',
	全选: '全て選択',
	提交订单: '提出',
	收货地址: 'お届け先',
	请输入收货人姓名: '受取人を入力',
	收货人: '受取人',
	联系电话: '電話番号',
	请输入手机号: '電話番号を入力',
	都道府县: '都道府県',
	请选择都道府县: '都道府県を選択',
	市: '市',
	请选择城市: '市を選択',
	详细地址: '詳細住所',
	详请输入详细住址: '詳細住所を入力',
	邮编: '郵便番号',
	请输入邮编: '郵便番号を入力',
	请先选择市: '都道府県を選択して下さい',
	收件人名字不能为空: '※必須項目です',
	请输入有效的邮政编码: '有効な郵便番号を入力して下さい',
	保存成功: '保存完了',
	日本: '日本',
	发货附加项: '発送用オプション',
	国际物流线路: '運送会社',
	选择发货附加项: '発送用オプションを選択',
	发货袋附加项必须选择一种:
		'下記の中から、ご希望の発送袋をいずれか1つを選択する必要があります',
	'您还未保存设置，是否离开当前页？':
		'変更は保存されていません　他のページに移行しますか',
	直行便推荐: '直行便お勧め',
	选择商品附加项: '商品オプション',
	费用明细: '費用明細',
	商品预估价格: '商品代金（概算）',
	商品预估附加项价格: 'オプション費（概算）',
	订单预估总价: '合計額（概算）',
	'此金额为预估报价，您下单后，该部分金额将会被冻结。等到该订单采购处理全部完成后，我们会自动解冻这部分金额，并按照实际产生的金额进行扣费。实际扣费详情您可以后续在资金明细页面或订单详情页面查看。':
		'発注依頼時に、表示の概算費用をお預かりします。注文商品が検品完了次第、お預かり金は全額返金し正式費用を精算します。',
	请将收货人信息填写完整后提交订单: '入力必須　お届け先情報',
	订单确认: '確定',
	您能接受的采购价格上涨: '購入単価上限',
	次品付费重拍: '交換',
	接受次品重拍: '交換',
	店铺名: '店舗名',
	订单类型: '注文種類',
	采购到库存: '在庫保有',
	订单标题: '注文タイトル',
	明细: '費用明細',
	去支付: 'お支払いへ',
	支付成功: '注文のお支払いが完了しました',
	建议: '推薦',
	打开消息通知: 'メッセージ通知を開き',
	'，以便我们及时为您发送最': '最新情報を取得可能',
	查看订单: '注文詳細を確認',
	回到首页: 'ホームページへ戻る',
	资金管理: '資金管理',
	主钱包: '親アカウント管理口座',
	'可用余额(元)': '利用可能残高(元)',
	'未入账余额(元)': 'チャージ済金額（審査中）(元)',
	去充值: 'チャージする',
	子钱包: '選択店舗口座',
	'冻结余额(元)': 'お預かり金額(元)',
	查看明细: '詳細',
	充值历史: 'チャージ履歴',
	资金明细: '資金明細',
	充值: 'チャージする',
	输入金额: '※必須項目です',
	充值金额: '振込金額',
	支付方式: 'お支払い方法',
	立即充值: '確定',
	请输入充值金额: '必要項目に入力してください',
	充值金额不能小于0: '振替金額は０円以上の金額を入力してください',
	主钱包明细: '親アカウント管理口座',
	充值成功: 'チャージ完了',
	'充值成功！': 'チャージ完了！',
	感谢您的支持: 'ご入金ありがとうございます',
	充值时间: '振込時間',
	返回资金首页: 'ホームページへ戻る',
	'支付成功后，可能存在支付信息获取延迟，请耐心等待':
		'支払い完了すると、入金の反映が遅れる可能性がありますので、しばらくお待ちください。',
	充值失败: 'チャージ失敗',
	'充值失败！': 'チャージ失敗！',
	请重新充值: '再チャージして下さい',
	重新充值: '再チャージして下さい',
	日期筛选: '日時',
	所有类型: '全て',
	开始日期: '開始日時',
	请选择开始日期: '開始日時を選択',
	结束日期: '締め切り日',
	请选择结束日期: '締め切り日を選択',
	清空: 'リセット',
	充值记录: 'チャージ履歴',
	年: '年',
	月: '月',
	日: '日',
	出账: '出金',
	入账: '入金',
	流水号: '受付番号',
	资金充值: '資金チャージ',
	提现: '残高引出し',
	'转账（入金）': '振替（入金）',
	'转账（出金）': '振替（出金）',
	会员费: '会員費',
	'支付委托书（出金）': '支払依頼書（出金）',
	子钱包明细: '選択店舗口座',
	订单号: '注文番号',
	发货单号: '直行便発送依頼番号',
	搜索订单号: '直行便注文番号を入力',
	商品代金: '商品代金',
	国内送料: '国内送料',
	国際送料: '国際送料',
	售后服务退款: 'アフタサービス返金',
	押金: 'お預かり金',
	押金退还: 'お預かり金返金',
	国際送料扣除差额部分: '国際送料差額分差し引き',
	国際送料追加请求总额: '国際送料追加請求総額',
	見積もり相談費用: '見積もり相談費用',
	見積もり相談費用の返金: '見積もり相談費用の返金',
	返金: '返金',
	客户的退款: 'お客様からの返金',
	提取失败: '引出し失敗',
	国内送料返金: '国内送料返金',
	全部已读: '全て既読',
	查看详情: '詳細',
	详情: '詳細',
	处理中: '処理中',
	可发货: '発送待ち',
	部分发货: '一部発送待ち',
	已完成: '完了',
	订单详情: '詳細',
	加购成功: 'カート追加完了',
	异常订单: '異常注文',
	异常订单记录: '異常注文履歴',
	售后赔偿申请: 'アフター補償申請',
	取消记录: 'キャンセル履歴',
	未处理: '未対応',
	已处理: '対応済み',
	取消发货记录: '発送キャンセル履歴',
	取消采购记录: '買付キャンセル履歴',
	设置: '設定',
	关于直行便: 'THE直行便とは',
	版本号: 'バージョン番号',
	直行便简介: '会社情報',
	给我们评分: 'コメントする',
	联系我们: 'お問い合わせ',
	立即开启: 'すぐ開く',
	用户服务协议: '利用規約',
	隐私政策: 'プライバシーポリシー',
	清除缓存: 'キャッシュクリア',
	立即退出: 'ログアウト',
	'开启推送通知，请在系统设置的通知中心打开':
		'システム設定の通知センターから通知をオンできます',
	暂时不要: '暫く設定しない',
	反馈: 'ご意見はこちら',
	未关联商品: '未紐付け商品',
	经济舱: 'エコノミープラン',
	标准舱: 'スタンダートプラン',
	商务舱: 'ビジネスプラン',
	头等舱: 'ファーストプラン',
	入会天数: '入会日数',
	異常订单: '異常注文',
	API同步: 'API発注',
	购买过的: '購入した',
	'商品/店舗': '商品/店舗',
	浏览历史: '閲覧履歴',
	在庫管理: '在庫管理',
	组合: 'セット組',
	商品一覧: '商品一覧',
	关联管理: '紐付け管理',
	支付委托书: '支払依頼書',
	国際配送管理: '国際配送管理',
	'"FBA納品\n" + "一覧"': 'FBA納品\n' + '一覧',
	'"国際配送\n" + "一覧"': '国際配送\n' + '一覧',
	'"售后\n" + "補償申請"': 'アフター\n' + '補償申請',
	OEM: 'OEM',
	'"OEM\n" + "寻源"': '見積もり\n' + '依頼',
	'"OEM\n" + "寻源管理"': '見積もり\n' + '依頼管理',
	'"OEM\n" + "复购"': '再見積もり\n' + '依頼一覧',
	店舗管理: '店舗管理',
	'"店铺\n" + "设置"': '注文関連\n' + '設定',
	'"API/\n" + "子账号设置"': 'API連携/\n' + '店舗作成',
	会员办理: '会員申請',
	收藏: 'お気に入り',
	买过的: '購入した商品/店舗',
	浏览记录: '閲覧履歴',
	去挑点喜欢的商品加入购物车吧: 'カート追加',
	去逛逛: '商品検索して注文',
	暂无相关数据: 'データなし',
	批量编辑商品附加项: '商品オプション一括編集',
	银行转账支付: '銀行振込',
	货币种类: '貨幣種類',
	转账金额: '振込金額',
	转账银行名: '振込銀行名',
	转账人姓名: '振込人名義',
	我司应收银行: '弊社受取銀行',
	横滨站前支行: '横浜駅前支店',
	账号: '口座番号',
	账户名: '口座名',
	みずほ銀行: 'みずほ銀行',
	店番292: '店番292',
	'普通預金 4014779': '普通預金 4014779',
	'株式会社 SNIFF JAPAN': '株式会社 SNIFF JAPAN',
	'收款·转账凭证': '入金・振込証跡',
	提交: '確定',
	没有开启相册权限: '権限がありません',
	请输入转账人姓名: '必要項目に入力してください',
	请输入转账银行名: '必要項目に入力してください',
	请输入转账金额: '必要項目に入力してください',
	请上传转账凭证: '必要項目に入力してください',
	C端直发: 'エンドユーザー直送',
	国际料金表: '国際料金表',
	客户SKU: 'お客様商品SKU',
	您的姓名: '氏名',
	请输入您的名字: '氏名を入力',
	名字不能为空: '※必須項目です',
	请选择进货方式: '仕入れ方法を選択して下さい',
	创建店铺: '店舗追加',
	'请输入店铺名/品牌名': '店舗名を入力',
	请输入店铺访问链接: '店舗URLを入力',
	请输入店铺或品牌名: '店舗名を入力',
	访问令牌: 'アクセストークン',
	请输入正确的访问令牌: '正しいアクセスコードを入力してください',
	平台名: 'サイト',
	店铺创建成功: '店舗追加完了',
	'店铺/品牌名': '店舗名',
	店铺URL地址: '店舗URL',
	'链接输入有误，重新输入链接': '有効なURLを入力して下さい',
	请选择处理方式: '不良品対応設定',
	请输入链接: 'URLを入力',
	'以后的商品为次品时，请都默认处理为次品退货退款并付费重拍':
		'今後不良品が発生した場合、すべて「不良品返品返金、有料で再発注する」の方式で対応する',
	提交成功: '確定',
	问题处理: '処理',
	问题原因: '連絡理由',
	商品信息: '商品情報',
	确认尺码: '商品のプロパティ',
	处理详情: '明細',
	处理结果: '対応ステータス',
	未结算: '未清算',
	结算中: '清算中',
	已结算: '清算完了',
	结算失败: '清算失敗',
	直行便: '直行便',
	待处理: '対応待ち',
	已撤销: '取消済み',
	'重新发起(处理中)': '再申請（審査中）',
	已拒绝: '拒否済み',
	已退款: '返金済み',
	财务处理中: '審査中',
	业务处理中: '審査中',
	其他: 'その他',
	数量不足: '数量不足',
	相异品: '相違品',
	次品: '返品不可不良品',
	下单数量: '注文数',
	取消数量: '取消数量',
	结算时应退金额: '返金総額',
	申请售后件数: '申請数量',
	售后原因: '理由',
	退款说明: '詳細',
	售后退款金额: '返金額',
	图片: '画像',
	保留库存: '在庫保有',
	取消成功: 'キャンセル済み',
	异常: '異常',
	'已上架(已清算)': '検品済',
	检品中: '検品中',
	已入库: '入庫済み',
	国内运输中: '国内運送中',
	已采购: '買付完了',
	采购中: '買付対応中',
	待采购: '買付待ち',
	平台商品SKU: '直行便SKU',
	'代买手续费/档口手续费': '代行手数料/市場仕入れ手数料',
	国内运费: '中国国内送料',
	入库手续费: '入庫手数料',
	商品附加项费用: 'オプション費用',
	物流详情: '発送詳細',
	发货类型: '配送種類',
	运输公司: '運送方式',
	运单号: '追跡番号',
	客户商品信息: 'お客様商品情報',
	客户商品SKU: 'お客様商品SKU',
	客户商品名称: '商品名',
	客户商品规格: '商品規格',
	客户商品图片: '商品画像',
	采购商品信息: '直行便商品情報',
	商品原网址: '仕入れ元',
	商品状态变更日志: 'ステータス変更履歴',
	直发订单: 'エンドユーザー直送',
	部分转库存: '一部在庫保有',
	囤货订单: '在庫保有',
	订单编号: '注文番号',
	下单时间: '注文日時',
	订单来源: '発注元',
	客户订单编号: 'お客様注文番号',
	物流公司: '運送会社',
	国际物流单号: '追跡番号',
	国际运费: '国際送料',
	计费重量: '請求重量',
	发货时间: '発送日時',
	发货数量: '数量',
	最终采购价: '最終購入単価',
	会员中心: '会員センター',
	'より多くのサービスをご利用頂く場合、下記のリンクをコピーしてパソコンから操作して下さいURL':
		'より多くのサービスをご利用頂く場合、下記のリンクをコピーしてパソコンから操作して下さいURL',
	クリックしてコピー: 'クリックしてコピー',
	主な業務: '主な業務',
	OEMとセット組商品の仕入れはパソコンから操作して下さい:
		'OEMとセット組商品の仕入れはパソコンから操作して下さい',
	仕入れ代行: '仕入れ代行',
	购买数量: '購入数',
	'"検品済み\n" + "(配送可能)"': '検品済み\n' + '(配送可能)',
	'"確認待ち\n" + "納品プラン"': '確認待ち\n' + '納品プラン',
	'"未紐付け\n" + "商品"': '未紐付け\n' + '商品',
	'抱歉，订单正在打包中，不允许修改地址':
		'既に梱包中ですので、商品の配送先を変更できません',
	'チャージ上限額は99999999.99円です': 'チャージ上限額は99999999.99円です',
	发货量: '発送数',
	'店舗未登録，店舗作成後、再試行して下さい':
		'店舗未登録，店舗作成後、再試行して下さい',
	添加新店铺: '店舗追加',
	'当前账号下无店铺，请创建店铺后再执行操作':
		'店舗未登録。 店舗作成後、再試行して下さい',
	お知らせ: 'お知らせ',
	'カラー・サイズ不明': 'カラー・サイズ不明',
	予約販売: '予約販売',
	最小注文ロット数あり: '最小注文ロット数あり',
	不良品: '不良品',
	'不良品(自動再仕入れ回数上限に達していても、出荷可能良品在庫が足りません)':
		'不良品(自動再仕入れ回数上限に達していても、出荷可能良品在庫が足りません)',
	'不良品(不良品対応設定で「返品返金」ご指定)':
		'不良品(不良品対応設定で「返品返金」ご指定)',
	'在庫切れ(自動再仕入れ回数上限に達していても、出荷可能良品在庫が足りません)':
		'在庫切れ(自動再仕入れ回数上限に達していても、出荷可能良品在庫が足りません)',
	全サイト在庫切れ: '全サイト在庫切れ',
	'お客様申請(仕入れキャンセル済)': 'お客様申請(仕入れキャンセル済)',
	不良品返品完了: '不良品返品完了',
	'在庫切れ(自動再仕入れ回数上限に達した)':
		'在庫切れ(自動再仕入れ回数上限に達した)',
	'不良品(自動再仕入れ回数上限に達した)':
		'不良品(自動再仕入れ回数上限に達した)',
	在庫切れ再発注完了: '在庫切れ再発注完了',
	お支払待ち: 'お支払待ち',
	'お支払いますか？': 'お支払いますか？',
	支払いへ: '支払いへ',
	费用明细: '費用明細',
	'枠線を移動すると、検索したい商品図領域を選択可能です':
		'枠線を移動すると、検索したい商品図領域を選択可能です',
	已完成2: '支払完了',
	お気に入り店舗から削除: 'お気に入り店舗から削除',
	最小注文ロットを満たしていない商品があります:
		'最小注文ロットを満たしていない商品があります',
	'メールでのお問い合わせ：info@theckb.com':
		'メールでのお問い合わせ：info@theckb.com',
	'お電話でのお問い合わせ：080-6583-7346':
		'お電話でのお問い合わせ：080-6583-7346',
	'電話受付：平日 10:00～17:00': '電話受付：平日 10:00～17:00',
	LINE公式アカウント: 'LINE公式アカウント',
	'LINEでのお問い合わせ：': 'Lineでのお問い合わせ：',
	電話をする: '電話をする',
	メールアドレスを開く: 'メールアドレスを開く',
	キャッシュクリア完了: 'キャッシュクリア完了',
	商品管理: '商品管理',
	見つかりました: '見つかりました',
	選択商品を選択して下さい: '選択商品を選択して下さい',
	データなし: 'データなし',
	お気に入りの商品を選んでカートに追加しましょう:
		'お気に入りの商品を選んでカートに追加しましょう',
	お気に入り商品はありません: 'お気に入り商品はありません',
	該当する商品はありません: '該当する商品はありません',
	メッセージはまだありません: 'メッセージはまだありません',
	該当する情報はありませんでした: '該当する情報はありませんでした',
	'インターネットに接続されていません。インターネット設定を確認して再度お試し下さい':
		'インターネットに接続されていません。インターネット設定を確認して再度お試し下さい',
	商品種類と数量を選択して下さい: '商品種類と数量を選択して下さい',
	種類を選択: '種類を選択',
	選択済み: '選択済み',
	日本語での検索が可能です: '日本語での検索が可能です',
	該当商品は買付対応出来かねます: '該当商品は買付対応出来かねます',
	在庫: '在庫',
	申請時間: '申請時間',
	スタイルを変更: 'スタイルを変更',
	商品をキャンセル: '商品をキャンセル',
	'B2B-FBA': 'B2B-FBA',
	'B2B-普通': 'B2B-普通',
	D2C: 'D2C',
	異常返金: '異常返金',
	在庫移動: '在庫移動',
	SDカードのアクセス権限: 'SDカードのアクセス権限',
	'カメラ・写真へアクセスを「THECKB]に許可しますか？':
		'カメラ・写真へアクセスを「THECKB]に許可しますか？',
	許可しない: '許可しない',
	許可: '許可',
	'振込日、振込依頼人、お受取人名、振込金額の情報が含まれる画像をお願いします。':
		'振込日、振込依頼人、お受取人名、振込金額の情報が含まれる画像をお願いします。',
	'単品（概算）': '単品（概算）',
	'国際送料（概算）': '国際送料（概算）',
	位置を占める: '位置を占める',
	详细情况请从电脑上确认: '詳細はパソコンから確認して下さい',
	'元/送り状': '元/送り状',
	チェックした商品はありません: 'チェックした商品はありません',
	チェックした店舗はありません: 'チェックした店舗はありません',
	組合せ対応待ち: '組合せ対応待ち',
	組合せ対応中: '組合せ対応中',
	組合せ対応完了: '組合せ対応完了',
	コピー済み: 'コピー済み',
	表示切替: '表示切替',
	'アプリの新しい \nバージョンがあります':
		'アプリの新しい \nバージョンがあります',
	アップデート: 'アップデート',
	'该页为第三方登录页。登录后，即可正常浏览':
		'このページは中国仕入れサイトのログインページですログイン後、当該サイトの商品を閲覧し、さらに直行便カートに追加することができます',

	// 中文翻译
	// 异常订单
	Pinduoduo: 'Pinduoduo',
	商品问题原因: '連絡理由',
	只可选择一种: '1つだけ選択可能',
	更换颜色: 'カラー変更',
	取消商品: 'キャンセル',
	换个链接: '商品URL変更',
	直行便订单编号: '注文番号',
	申请售后时间: '申請日時',
	售后进度: '対応ステータス',
	导出订单: 'ダウンロード',
	重新发起: '再申請',
	撤销: 'キャンセル',
	申请售后: 'アフター補償申請',
	撤销售后: 'アフター補償申請の取り消し',
	申请时间: '申請日時',
	取消进度: '取消ステータス',
	'该操作，会将已发起售后商品信息撤销，请确认是否撤销该商品售后？':
		'【ご確認下さい】 申請済のアフター補償申請を取り消すと、当該商品では再度受領後返金を申請出来ません。申請を取り消しますか',
	撤销商品不可再次发起撤销: '撤销商品不可再次发起撤销',
	请选择售后原因: '申請理由',
	详细说明: '詳細',
	申请售后数量: '申請数量',
	'点击上传图片(只能上传jpg/png文件，且不超过10M)':
		'画像のアップロード（形式:jpg/png 10M KB以内）',
	撤销成功: 'アフター補償申請　提出取消',
	问题处理2: '異常注文履歴',
	售后: 'アフター補償',
	申请取消: 'キャンセル申請',
	商品取消履历: 'キャンセル履歴',
	已拒绝2: '支払拒否',
	'重新发起（处理中）': '再申請（審査中）',
	次品2: '不良品',
	问题生成时间: '連絡日',
	处理方式: '対応方式',
	退款金额: '返金額',
	拒绝原因: '理由',
	拒绝原因2: '拒否する理由',
	退款总金额: '返金総額',
	不可优先发货: '不可',
	'可优先发货(订单下部分商品已全部上架)': '優先発送可（検品済商品）',
	// 我的订单
	商品sku: '商品sku',
	组合附加项: 'セット組オプション',
	下单组合数: 'セット組注文数',
	组合完成数: 'セット組完了数',
	'服务费（元）': '手数料（元）',
	组合状态: '対応ステータス',
	商品画像: '商品画像',
	本单质检上架数: '良品数',
	转结落单库存数: '残在庫利用数',
	组合落单库存处理情况: '本注文残在庫',
	国家: '国',
	都道府県: '都道府県',
	序号: 'No.',
	商品单价: '商品単価',
	'总价（RMB）': '合計額（RMB）',
	商品状态: 'ステータス',
	详情费用: '費用明細',
	代买手续费: '代行手数料',
	质检手续费: '検品手数料',
	发货手续费: '発送手数料',
	生成时间: '作成日時',
	国际运单号: '追跡番号',
	重量g: '重量（g）',
	是否发货: '配送状況',
	商品番号: '商品番号',
	其他费用: 'その他',
	费用类型: '種類',
	时间: '日時',
	地址详情: '詳細住所',
	详情地址: '詳細住所',
	编辑地址: '編集',
	取消并退款: '返金済み',
	已上架: '検品済み',
	查看: '詳細',
	发货单详情: '発送詳細',
	未发货: '出荷待ち',
	编辑收货地址: 'お届け先編集',
	费用详情: '費用明細',
	请确认取消信息: 'キャンセル情報',
	'确认是否取消？': 'ご確認下さい',
	合: '合',
	等待审核: '審査待ち',
	下单成功: '発注完了',
	国际物流信息详情: '国際配送情報',
	业务类型: '仕入れ元',
	取消结果: 'キャンセル状況',
	所有订单: '全て',
	待付款: '決済待ち',
	是否合单: '発送状態',
	Base: 'Base',
	Shopify: 'Shopify',
	Stores: 'Stores',
	Amazon: 'Amazon',
	可优先发货: '可',
	未组合: '対応待ち',
	组合中: '対応中',
	组合完成: '対応完了',
	取消组合: 'キャンセル',
	日志: '操作ログ',
	优先发货: '分納発送',
	是否去支付: 'お支払いへ',
	国际物流单号信息: '国際配送依頼番号',
	商品日志详情: '操作ログ',
	结束时间: '締め切り日',
	商品搜索: '商品検索',
	下单日: '注文日',
	发货日: '発送日',
	采购商品名称: '直行便商品名',
	采购商品规格: '直行便商品規格',
	采购商品图片: '直行便商品画像',
	商品采购渠道: '仕入れ元',
	操作记录: '操作ログ',
	变更时间: '変更日時',
	'已上架（已清算）': '検品済',

	// 授权店铺
	'你授权的xxx店铺取消授权后，店铺订单和商品将不再同步，是否继续取消？': `API連携解除により、xxxデータは読み込み不可になります。
    API連携を解除しますか`,
	是: '連携解除',
	否: 'キャンセル',
	解绑成功: '解除完了',
	创建时间: '作成日時',
	状态: '状態',
	店铺钱包可用余额: '残高',
	店铺账号: 'アカウント情報',
	api权限: '権限',
	店铺管理: '店舗管理',
	授权店铺管理: '店舗管理',
	激活子账号: '子アカウント作成',
	配置店铺: '店舗設定',
	开启自动同步下单: '自動発注',
	取消授权: '連携解除',
	创建成功: '紐付け完了',
	创建成功2: '保存しました',
	修改成功: '変更完了',
	店铺修改成功: '店舗情報 変更完了',
	物流配置修改成功: '変更が成功しました',
	// 我的钱包
	水洗标制作: '洗濯タグ作成',
	日本配送公司: '運送会社',
	国际发货单号: '国際配送依頼番号',
	偏远地区附加费: '遠方輸送手数料',
	'出账 / 入账': '出入金',
	日期: '作成日時',
	可用余额: '利用可能残高',
	直行发货单号: '直行便発送依頼番号',
	转账: '振替',
	表格导出: 'ダウンロード',
	的钱包: '口座一覧',
	主账号钱包: '親アカウント口座一覧',
	我的店铺钱包: '口座一覧',
	冻结金额: 'お預かり金額',
	未入账金额: 'チャージ済金額（審査中)',
	查询: '検索',
	收据下载: '入金領収書ダウンロード',
	银行名: '振込銀行名',
	金额: '振込金額',
	币种: '貨幣種類',
	上传凭证: 'クリックしてアップロード',
	'振込金額(日本円)': '振込金額(日本円)',
	公司名称: '会社名',
	店铺主页入口: 'ホームページ',
	姓氏: '氏',
	名字: '名',
	邮箱地址: '連絡用メールアドレス',
	国际: '国籍',
	存入金额: '振込金額',
	'加载完成！': '読込み完了!',
	收款账号: '振替先口座情報',
	最多可转账金额: '振替可能額',
	'商品sku/店铺商品sku/海外仓sku': '商品sku/お客様商品sku/日本倉庫SKU(FNSKU)',
	选择地址: '住所選択',
	输入的手机号位数不正确: '正しい電話番号を入力して下さい',
	区町村不能为空: '区町村不能为空',
	最少3个文字以上: '3文字以上を入力して下さい',
	请输入正确的邮编: '正しい郵便番号を入力して下さい',
	地址添加成功: '新住所の追加完了',
	'sku/平台商品信息': 'sku/商品情報',
	库存数量: '良品数',
	客户商品sku: 'お客様SKU',
	海外仓sku: '日本倉庫SKU',
	店铺名称: '店舗名',
	需要填写清关地址: 'Importer住所を入力してください',
	选择清关地址: 'Importer住所を選択',
	添加清关地址: 'Importer住所を追加',
	补货批次标题: '納品タイトル',
	箱唛上传: '箱ラベル',
	物流方式: '運送会社',
	kg: 'kg',
	计算: '計算',
	预估物流价: '参考送料',
	'注意：您可以在此输入预计的重量 大致估算出物流价格，货品发出前将根据实际扣除物流费用，请确保您的账户余额充足，余额不足无法扣款将影响您的发货进度。':
		'【ご注意下さい】 送料は概算費用になります。実発送重量に基づき、確定した国際送料を口座引落し致します。',
	填写: '入力',
	移除: '削除',
	请选择商品: '商品を選択してください',
	'区町村-详细地址': '区町村ー詳細住所',
	国际发货标题不能为空: '※必須項目です',
	必填: '※必須項目です',
	物流方式不能为空: '※必須項目です',
	'文件大小不超过50m；支持格式doc;excel;pdf;': `形式：doc、excel、pdf
    容量：50Ｍ以内`,
	箱唛上传不能为空: '※必須項目です',
	拣货中: 'ピッキング作業中',
	打标中: 'ラベル貼り付け中',
	打包中: '梱包中',
	打包完成: '梱包完了',
	标题: '納品タイトル',
	大件fba: '大口配送（FBA）',
	大件普通: '大口配送（FBA以外）',
	小件: '小口配送',
	次品退货: '不良品返品',
	异常退货: '異常品返品',
	仓库调拨: '倉庫調達中',
	创建补货计划: 'Amazon納品プランを作成',
	'Amazon sku': 'Amazon sku',
	sku: 'sku',
	补货批次号: 'Amazon納品番号',
	国际运费号: '国際配送依頼番号',
	运输方式: '運送方式',
	合计总额: '合計額',
	AMAZON商品名称: 'Amazon商品名',
	计划发货数量: '配送予定数',
	基本详情: '配送情報',
	发货重量: '総重量（梱包後の重量）',
	国际发货状态: '配送状況',
	'收货仓/地址': '納品先',
	配送件数: '配送件数',
	箱数: '箱数',
	装箱清单: '納品書',
	装箱清单2: 'パッキングリスト',
	商品内容: '商品詳細',
	创建国际发货: '国際配送依頼',
	// oem
	寻源表: '見積依頼書',
	寻源种类: '商品品類',
	类别: 'プラン',
	X类: 'X類',
	类别注明: 'サービス内容',
	产品名称: '商品名',
	参考图片: '参考画像',
	提供项: '参考情報',
	是否提供: '提供済',
	具体内容: '詳細',
	参考链接: '参考URL',
	样衣: 'サンプル',
	物流单号: '追跡番号',
	需要改动的地方: '変更したいところ',
	'描述内容(材质，设计，颜色，尺寸等)':
		'デザイン説明（生地、デザイン、カラー、サイズ等）',
	附件: '添付ファイル',
	式样书: '仕様書',
	'附件(包含产品的色卡、尺寸、特殊工艺、面料信息等)':
		'添付ファイル（商品のカラー、サイズ、デザイン、生地などの情報をご入力ください）',
	面料: '生地',
	需求描述: '変更したいところの説明',
	面料信息: '生地詳細',
	色卡信息: 'カラー',
	Logo: 'Logo',
	'详细说明(颜色、尺寸、位置、工艺等)':
		'説明（カラー、サイズ、ロゴのプリント位置）',
	辅料种类: '資材種類',
	直行便预选方案选择: '直行便の推薦方案を選択',
	分类: '項目',
	选择方案: '詳細',
	定制补充部分: 'お客様ご要望',
	文字描述: '説明',
	材质方案: '生地方案',
	款式方案: 'デザイン方案',
	尺寸方案: 'サイズ方案',
	颜色方案: 'カラー方案',
	款式描述: 'デザイン詳細',
	材质描述: 'サイズ詳細',
	尺寸描述: '尺寸描述',
	颜色描述: 'カラー詳細',
	设计图或参考图片: '画像',
	产品信息: '商品詳細',
	具体尺码信息: 'サイズガイド',
	对应关系: '詳細',
	自己提供: 'サイズガイドを提出する',
	尺码表: 'サイズガイド',
	参考链接尺寸: '商品ページの寸法を参考',
	直行便提供: '直行便提案',
	其他补充: '備考',
	下单需求: '注文情報',
	期望价格: '希望価格',
	期望交期: '希望納期',
	期望起订量范围: '希望ロット数',
	侧重点: '重視すること',
	品质: '品質',
	交期: '納期',
	Logo需求情况: 'ロゴ詳細を述べてください',
	需要: 'はい',
	不需要: 'いいえ',
	'工厂、资质要求': '指定工場、許可証に関するご要望',
	特殊需求: 'その他のご要望',
	服装: 'アパレル',
	辅料: '資材',
	内容: '詳細',
	备注说明: '備考',
	操作人: 'ユーザー',
	报价信息: '見積書',
	是否加Logo: 'Logo入り',
	是否为特殊面料: '特殊生地',
	次品处理方式: '不良品処理',
	风险: 'リスク',
	打样费: 'サンプル代',
	打样时间: 'サンプル納期',
	天: '日',
	时: '時',
	分: '分',
	秒: '秒',
	单尺码: 'ワンサイズ',
	多尺码: '複数サイズ',
	商品数量区间: '商品数',
	阶梯价格: '価格',
	报价有效期: '見積書有効期間',
	国际运费说明:
		'※商品より工場から弊社倉庫までへの国内配送料が発生する場合があります。配送料が発生する場合、残高より費用をお引き落としいたします。',
	全部订单: '注文リスト一覧',
	未发布: '未提出',
	等待报价: '見積もり待ち',
	已报价: '見積もり確認',
	寻源成功: '見積完了',
	报价失效: '見積書有効期間切れ',
	已取消: 'キャンセル済',
	已取消2: '支払取消',
	重新发布: '再提出',
	再次买入单号: '再購入番号',
	类型: '品類',
	申请表: '申請リスト',
	商品名: '商品名',
	发起时间: '作成日時',
	寻源单编号: '見積書依頼書番号',
	拒绝备注: '備考',
	是否确认编辑: '再編集しますか',
	是否确认发布寻源单: '発注',
	发布: '提出',
	是否确认取消待发布寻源单: 'キャンセルしますか',
	是否确定下样品单: 'サンプル発注しますか',
	下样品单: 'サンプル発注',
	是否确定下大货单: '量産をしますか',
	下大货单: '量産',
	是否确认申请退款: '返金しますか',
	申请退款: '返金リクエスト',
	报价过期: '見積書有効期間切れ',
	是否确认申请复购:
		'見積もり有効期間が切れました、最新の見積もりを依頼しますか',
	复购申请: '再購入',
	是否确认重新编辑: '再編集しますか',
	重新编辑: '編集',
	重新编辑2: '再編集する',
	操作日志: '詳細',
	发布成功: '提出完了',
	退款成功: '返金完了',
	已提交申请复购: '已提交申请复购',
	复购申请中: '見積もり依頼中',
	复购已拒绝: '拒否済み',
	复购已报价: '見積もり確認中',
	//
	未关联: '未紐付け',
	已关联: '紐付け済み',
	关联异常: '紐付けエラー',
	待关联: '紐付け待ち',
	售卖价格: 'お客様販売価格',
	关联状态: 'SKU紐付け',
	已关联SKU数量: 'SKU紐付け済',
	采购商品名: '直行便商品名',
	采购价格: '仕入れ価格',
	采购商品图: '直行便商品画像',
	创建关联商品: 'SKU紐付け',
	'该店铺未授权同步数据功能，授权后可绑定商品，享受自动下单功能。':
		'API未連携，API連携により注文自動処理可能',
	'因采购链接发生变动，请重新绑定':
		'仕入れ先URL変更済，再度仕入れ先紐付け処理をして下さい',
	编辑关联: '紐付け変更',
	番号保存成功: '保存完了',
	组合商品SKU: 'セット組番号',
	组合在库状态: '残在庫',
	'单价（元）': '単価（元）',
	'待组合库存数量（截止时间）': '単品残在庫数(組合せ期限)',
	组合商品管理: 'セット組商品一覧',
	扩大加购: 'カートに入れる',
	完结: '単品として管理',
	加购: 'カートに入れる',
	附加项修改成功: 'オプションの変更が完了しました',
	添加成功: '追加完了',
	完结成功: '単品商品管理に変更完了',
	存在待组合: '残在庫あり',
	不存在待组合: '残在庫なし',
	找相似: '類似商品を探す',
	断开: '解除',
	店铺商品sku解绑成功: '紐付けを解除しました',
	选定: '選択',
	已连接其他SKU: '紐付け済み',
	断开原有链接: '紐付けを解除する',
	请选择采购商品sku: '直行便商品を選択してください',
	请选择店铺商品sku: 'お客様商品を選択してください',
	请选择商品附加项: '商品オプションを選択してください',
	店铺商品sku绑定成功: '直行便商品と紐付けが完了しました',
	同步成功: '取込みが完了しました',
	'10-20元': '10-20元',
	'20-30元': '20-30元',
	'30-50元': '30-50元',
	'50-100元': '50-100元',
	'100-150元': '100-150元',
	'200-300元': '200-300元',
	'300元以上': '300元以上',
	选择要链接的商品: '仕入れ元商品を選択',
	选择要链接商品的SKU: 'SKUと紐付け',
	自动同步采购订单: '発注情報を自動取込み',
	'同步已授权的店铺内商品，并进行绑定':
		'店舗商品情報を取込み、仕入れURLと紐付け',
	搜索青蛙商品: '商品を検索する',
	商品链接或商品名: 'URL、商品名で検索して注文',
	类目筛选: 'カテゴリー',
	价格筛选: '価格',
	采购商品: '仕入れ元',
	店铺商品: 'お客様商品',
	重新选择商品: '仕入れ元再設定',
	创建关联: 'SKU紐付け',
	系统查询到您有辅料未添加到附加项配置: 'オプションに資材を関連されてません',
	平均单价: '平均単価',
	锁定: '利用予定',
	可用: '利用可能在庫',
	待处理次品: '不良品（返品不可）',
	辅料图片: '画像',
	辅料名称: '資材名',
	关联附加项: 'オプション紐付け',
	辅料规格: '規格',
	辅料skuid: '資材SKU',
	待入库: '入庫待ち',
	次品图: '画像',
	说明: '詳細',
	说明2: 'ご注意',
	丢弃: '廃棄',
	转为正常商品: '良品として入庫',
	我的商品库存: '商品在庫管理',
	我的物料: '資材在庫管理',
	未关联附加项: '未紐付け',
	'未关联附加项的辅料，将不会再订单商品中自动使用': `オプション適用 未設定資材あり
    注文時のオプションで資材利用不可`,
	配置附加项: 'オプション紐付け',
	辅料绑定提醒: '資材のオプション適用を設定して下さい',
	'物料名/sku': '商品名/SKU',
	已有关联附加项: '紐付け済',
	未有关联附加项: '未紐付け',
	可用库存数量筛选: '在庫数',
	至: '-',
	批量加入购物车: '一括でカートに追加',
	'关闭自动续费后，您的会员将于':
		'会員費自動支払いの解除より、現会員クラスの利用が ',
	到期请前往paypal平台关闭自动续费:
		'Paypal公式サイトにてご自身で自動支払いの設定を解除して下さい',
	'到期是否现在关闭？': 'に終了します',
	'恭喜！！！已为您开启自动续费，记得保持余额充足':
		'会員費の自動引き落としをONにしました',
	购买明细: 'お支払い明細',
	会员信息: '会員プラン',
	原价: '商品単価',
	优惠金额: 'おまとめ割引',
	ヶ月: 'ヶ月',
	有效期: '有効期間',
	总计: '合計',
	立即支付: 'お支払いへ',
	立即支付2: '即時支払',
	'购买会员到期后我们会为您自动续费，您可在工作台-会员管理中关闭自动续费服务':
		'有効期限失効前に会員費を口座から自動引落し可能。自動引落設定の解除は、会員情報ページから変更可能',
	每月: '月',
	选择办理会员周期: '利用期間',
	请联系主账号更改会员等级: '親アカウントのみ変更可能',
	您的账户余额不足: '口座残高不足',
	确认支付: 'お支払いへ',
	'立即购买（自动续费）': 'お支払いへ',
	'购买后新会员等级立即生效，原有等级作废，如有疑问请联系客服':
		'即時で変更後会員プラン適用可能',
	请选择会员套餐: '会員プランを選択',
	自动续费中: '会員費自動引き落とし',
	开通自动续费: '会員費自動支払い ON',
	到期: '終了日',
	关闭成功: '会員費自動支払い OFF 変更完了',
	// 我的消息
	我的消息: 'メッセージ',
	已读: '既読',
	'未读/已读': '未読/既読',
	// 表单
	姓名: '氏名',
	请输入姓名: '受取人を入力してください',
	手机号: '電話番号',
	请输入市区町村: '市区町村を入力してください',
	请输入详细地址: '詳細住所を入力してください',
	请输入邮箱: 'メースアドレス',
	'你的子账号没有修改权限，请联系主账号管理者修改你的密码':
		'サブアカウントでの情報修正は出来ません。メインアカウトからご対応下さい',
	无效内容: '無効値',
	无效的地址: '住所が無効です',
	收件人姓名不能为空: '※必須項目です',
	市不能为空: '※必須項目です',
	添加地址: '保存',
	// 确认框
	确定删除该商品: 'この商品を削除しますか？',
	确定不再显示该商品: 'この商品を非表示にしますか？',
	确定清空购物车: 'カートの商品を全て削除しますか？',
	确定删除此配置: 'この設定内容を削除しますか？',
	确定删除此地址: 'この住所を削除しますか？',
	'当前页面的修改内容未保存，是否离开':
		'変更は保存されていません　他のページに移行しますか',
	// 消息框
	请至少选择一个配置类目: '少なくとも1つのカテゴリーを選択してください',
	'单笔订单价值不得超过XX元，请调整商品数量':
		'注文上限額超過，D2C直送の注文合計額上限は、XX元/回　です',
	添加附加项模板成功: 'ディフォルトオプションを追加しました',
	修改附加项模板成功: 'ディフォルトオプションの変更が完了しました',
	修改邮箱成功: 'メールアドレスの変更が完了しました',
	// 登录
	请登录: 'ログイン',
	请先登录: 'ログイン',
	密码登录: 'ユーザー名ログイン',
	邮箱验证登录: 'メールアドレスログイン',
	'没有账号?去注册会员': '新規会員登録',
	'用户名或邮箱不正确，请重新输入1-10位用户名或邮箱': `ユーザー名入力誤り。
    ユーザー名は、1~18以内の半角英数文字で設定して下さい`,
	'输入的密码格式不正确，密码由6-32位字母或数字组成':
		'パスワード入力誤り。パスワードは、6~32文字以内の半角英数で設定して下さい',
	'邮箱格式不正确，请重新输入': 'メールアドレス形式不正',
	'验证码格式不正确，请重新输入':
		'認証コードは無効。最新のコードを入力してください',
	'验证码正在发送到您的邮箱，请注意查收': `認証コードを登録メールアドレスに送信しました。
    ご確認いただき、認証コードを入力して下さい`,
	'密码不正确，请重新输入': 'パスワード入力誤り。',
	'用户名或密码不正确，请重新输入': 'ユーザー名入力誤り。',
	'邮箱或验证码错误，请重新输入':
		'認証コード無効。再度認証コードを送付し、正しい認証番号を入力して下さい',
	输入用户名: 'ユーザー名を入力',
	输入密码: 'パスワードを入力',
	输入邮箱: 'メールアドレスを入力',
	输入验证码: '認証コードを入力',
	找回密码: 'パスワードお忘れの場合',
	重设密码: 'パスワード再設定',
	注册邮箱: 'メールアドレス',
	邮箱验证码: '認証コード',
	新密码设置: '新しいパスワード',
	再次确认新密码: '新しいパスワード(確認用)',
	'密码修改成功，请重新登录': `パスワード再設定が完了しました。
  ただいま再設定したログインパスワードでログインしてください`,
	// 注册
	免费注册: '新規登録',
	'已有会员，': '',
	立即登录: 'ログイン',
	请选择适合你的业务类型: '仕入れ方法を選択して下さい',
	适合想要进货到日本仓库的用户: '日本倉庫へ納品（大口配送）',
	适合想要从中国直发商品到末端客户的用户: 'エンドユーザーへ直送（小口配送）',
	// 占行
	立即进入: 'ホームページへ',
	联系人姓名: '氏名',
	再次确认密码: 'パスワード（確認用）',
	性别: '性別',
	先生: '男性',
	邮箱: 'メールアドレス',
	验证码: '認証コード',
	已发送: '送信済',
	该用户名已被注册: `このユーザー名は既に使用されています
  別のユーザー名を入力してください`,
	'用户名不可用，请重新设置1-18位用户名不支持标点符号': `ユーザー名入力誤り。
    ユーザー名は、1~18以内の文字で設定して下さい`,
	'用户名不可用，请重新设置6-12位用户名不支持标点符号': `ユーザー名入力誤り。
  ユーザー名は、6~12以内の文字で設定して下さい`,
	联系人姓名不能为空: '※必須項目です',
	'格式不正确，请输入真实姓名，包括假名和文字及英文':
		'氏名の形式が正しくありません。氏名は漢字・ひらがな・カタカナカナと英字のみを使用してください',
	'输入的密码格式不正确，请设置6-32位字母或数字，区分大小写':
		'パスワード入力誤り。パスワードは、6~32文字以内の半角英数字で設定して下さい',
	邮箱已被注册:
		'メールアドレス変更要。入力メールアドレスは既に利用されています',
	无效的手机号码: '有効な電話番号を入力して下さい',
	请确认您的手机号码为10到11位数字:
		'お電話番号を10桁または11桁の半角数字で入力してください',
	请设置用户名: 'ユーザー名を入力',
	'你的名字是？': '氏名',
	设置你的登陆密码: 'パスワードを入力',
	请再次输入您的密码: 'パスワード(確認用）を入力',
	'请输入您的手机号码（选填）': '(任意）電話番号を入力',
	'请输入你的Chatwork ID （选填）': 'Chatwork ID (任意）',
	'请输入你的Skype （选填）': 'Skype (任意）',
	注册并登录: '新規登録',
	同意以下条款: '利用規約に同意する',
	'已有账号，马上登录': 'すでにアカウントをお持ちの方　ログイン',
	返回首页: 'ホームページへ',
	默认业务类型: 'デフォルト仕入れ方法',
	新密码: '新しいパスワード',
	原密码: '現ログインパスワード',
	旧密码重置密码: 'ログインパスワードを用いて変更',
	邮箱重置密码: 'メールアドレスを用いて変更',
	请输入原密码: '現ログインパスワード',
	请输入新密码: '新しいパスワード',
	// 提现
	提现渠道: '引出先',
	银行名称: '受取銀行',
	支店名: '支店番号',
	口座类别: '口座種類',
	银行账号: '銀行口座',
	收款人名称: '受取人名',
	'提现金额（RMB）': '返金金額（RMB）',
	汇率: '為替レート',
	费率: '料率',
	可提现金额: '返金可能額',
	提现手续费: '引出し手数料',
	'提现金额（JPY）': '返金金額（JPY）',
	提现原因: '引出し原因',
	电子邮件地址: 'メールアドレス',
	银行卡: '銀行口座',
	普通: '普通',
	发送: '发送',
	请选择提现渠道: '引出先',
	请输入银行名称: '振込銀行名',
	请输入支店名: '支店番号',
	请选择口座类别: '口座種類',
	请输入银行账号: '銀行口座',
	请输入收款人名称: '受取人名を入力',
	请输入提现金额: '引出し金額を入力',
	请输入验证码: '４桁の認証コードを入力',
	请输入提现原因: '引出し理由を入力',
	请输入邮箱验证码: '認証コード',
	// Bar
	个人中心: 'マイページ',
	帮助: 'ヘルプ',
	客服: 'サービス',
	历史订单: '注文履歴',
	我的库存: '在庫管理',
	用户信息: 'ユーザー情報',
	OEM寻源单: 'OEMにしてメニュー化',
	OEM发起寻源: 'OEM見積もり依頼',
	同步的订单: '注文管理(API発注)',
	插件安装: 'KAERUツール',
	购买会员等级: '会員ランク',
	我的店铺: '店舗管理',
	我的钱包: '口座一覧',
	登录账户管理: '会員情報管理',
	授权店铺: '店舗連携',
	最近加入的商品: '最近追加した商品',
	选择你经常使用的功能: 'トップメニューの構成を調整する',
	最多可选择X个: '最大X個まで選択してください',
	请选择你经常使用的类目: 'よく使われるカテゴリーを選択してください',
	// 用户
	欢迎来到直行便: 'ようこそ THE直行便システムへ',
	会员等级: '会員ランク',
	切换账号: 'アカウント切り替え',
	账号设置: 'アカウント設定',
	登录账号管理: '会員情報管理',
	我收藏的商品和店铺: 'お気に入り商品/店铺',
	我买过的商品和店铺: '購入済 商品/店舗',
	我看过的商品和店铺: '閲覧履歴',
	关联商品: 'SKU紐付け',
	当前账号已退出: 'ログアウトしました',
	修改邮箱: 'メールアドレスを変更',
	新邮箱地址: '新しいメールアドレス',
	注销子账号: '子アカウントを削除',
	用户信息更新成功: '更新完了',
	邮箱修改成功: 'メールアドレスを変更しました',
	目前您还没有创建店铺: '子アカウント作成完了',
	快速创建: '店舗追加',
	店铺追加: '店舗追加',
	切换B2B囤货业务: 'B2B（有在庫）システムへ',
	切换D2C代发业务: 'D2C（無在庫）システムへ',
	店铺采购管理: '仕入れ設定',
	我的地址: '受取住所',
	// 用户配置项
	店铺平台: 'サイト',
	店铺配置: '注文関連設定',
	登录信息: '登録情報',
	修改密码: 'ログインパスワード変更',
	采购配置: '仕入れ設定',
	附加项配置: 'オプション設定',
	国际物流配置: '国際配送設定',
	次品付费重购: '不良品対応設定',
	$接受次品重拍文案$:
		'『商品交換』を指定すると、各商品につきX回まで無料で交換対応可能です。\nX回以上は毎回既存品買付手数料が発生します（最大交換回数は3回）。\n自動交換回数が上限に達する場合、ご注文商品が自動的にキャンセルされます。',
	'接受次品重拍，有助于我们更好的为您完成C端订单':
		'D2C会員様は『交換』を推奨します',
	次品退货退款: '返品返金',
	$次品退货退款文案$:
		'『返品返金』を指定すると、不良品が発生次第、自動でショップへ返品・商品代金を返金可能です。\n未指定の場合は、不良品が発生次第、都度対応方法を確認します。\n対応ご指示があるまで商品は待機状態が継続し配送遅延にもつながるため、事前の対応指定を推奨します。',
	'采购商品时，没有指定类目附加项时，使用全部附加项，两者不重复':
		'カートから発注場合は、カテゴリ毎のデフォルトオプション設定がなければ、全商品に適用済のデフォルトオプションを利用します',
	'当您订单下有该类目商品时，我们将默认为您添加以下附加服务':
		'カートから発注場合は、カテゴリ毎のデフォルトオプション設定済であれば、該当カテゴリの注文商品オプションは自動設定可能です',
	水洗标信息配置: '洗濯タグ設定',
	配置品牌专属水洗标: '洗濯表示タグーデザイン指定',
	打开全部: '編集',
	去配置: '資材紐付け設定へ',
	使用类目: '指定カテゴリー',
	类目失效: '利用不可のカテゴリー',
	选择物流公司: '運送会社',
	请选择物流公司: '運送会社',
	国际面单主设定: '依頼主設定',
	国际面单依赖主设定: '依頼主設定',
	联系方式: '電話番号',
	添加新地址: '新しい住所を追加',
	会社编号: '法人番号',
	日文收货地址: '日本語の場合',
	英文收货地址: '英語の場合',
	修改地址: '住所を変更',
	// 附加项
	一键自动配置: '直行便お勧め',
	一键配置: '直行便お勧め',
	我的类目: 'カテゴリー',
	选择您要配置的类目: 'カテゴリーを選択-検索',
	选择商品附加服务: 'オプション',
	选择发货附加服务: '発送用オプションを選択',
	选择我的物料: '資材を選択',
	附加项: 'オプション',
	辅料sku: '資材SKU',
	辅料名: '資材名',
	预计在库数: '有効在庫数',
	去补货: '在庫補充',
	编辑国际物流附加项: '発送用オプション設定',
	// 首页头部
	D2C无仓库代发: 'D2C（無在庫）',
	B2B仓库囤货: 'B2B（有在庫）',
	采购平台: '仕入れサイト',
	类目: 'カテゴリー',
	中国语入力: '中国語入力',
	日本语入力: '日本語入力',
	详细检索: 'こだわり条件',
	// 搜索
	1688: '1688',
	TaoBao: 'タオバオ',
	Tmall: 'Tmall',
	搜款网: '搜款网',
	网商园: '网商园',
	全部类目: '全てのカテゴリー',
	搜索范围: 'ECサイト',
	搜索关键词: 'キーワード',
	重置条件: 'クリア',
	搜索历史: '検索履歴',
	热门推荐词: '人気キーワード',
	// 搜索列表
	采购渠道: '仕入れルート',
	采购市场: '仕入れ元',
	关联我的商品: 'SKU紐付け',
	// 商详
	商品来源: '仕入れ元',
	加入收藏夹: 'お気に入りに追加',
	商品金额: '商品代金',
	已选清单: '選択した商品',
	手续费总额: '手数料',
	附加项费用: 'オプション費用',
	单价预估成本: '仕入れコスト（概算）',
	请选择附加项: 'オプション紐付け',
	单品预估成本价: '仕入れコスト（概算）',
	相似商品: '類似商品',
	相似店铺: '類似店舗',
	下载商品图: '商品画像をダウンロード',
	X件可售: '在庫数X件',
	商品添加至购物车: 'カート追加完了',
	该商品无法下载商品图: 'この商品は商品図をダウンロードできません',
	商品图下载成功: 'ダウンロード完了',
	'部分图片读取失败，请按需手动下载，正在开启自动下载': `一部の画像が読み取れませんでした
    必要に応じて手動でダウンロードしてください
    自動ダウンロードが有効になっています。`,
	// 子账号店铺
	创建子账号: '子アカウント作成',
	开店平台: 'サイト',
	店铺品牌名: '店舗名/ブランド',
	店铺密钥: '店铺密钥',
	创建并授权店铺数据: '创建并授权店铺数据',
	请输入店铺所在的平台名称: 'サイト名を入力してください（任意）',
	如需授权店铺API请阅读并同意: 'API利用規約に同意します',
	利用规约: '利用規約',
	店铺地址不正确: '店舗URLが不正',
	创建店铺成功: '店舗作成完了',
	修改店铺成功: '店舗変更完了',
	店铺授权成功: 'API連携済',
	修改店铺信息: '店舗情報を変更',
	授权状态: '連携状況',
	保存店铺: '保存',
	未授权: '未連携',
	已授权: 'API連携済',
	去授权: 'API連携へ',
	应用密钥: 'API連携用secret',
	应用ID: 'API連携用ID',
	// 类目
	站外搜索: '外部サイト検索',
	// 市场购
	中国档口七日爆款: '売れ筋ランキング（7日間）',
	推荐档口: 'おすすめ店舗',
	进入店铺: 'もっと見る',
	// 我的频道
	我收藏的店铺: 'お気に入り店舗',
	我看过的店铺: 'チェック済店舗',
	我买过的店铺: '購入済店舗',
	'担当推荐店铺（含档口）': 'スタッフ推薦店舗（市場店舗含む）',
	'推荐店铺（含档口）': '推薦店舗(市場直買付店舗含む）',
	我看过的商品: '閲覧履歴',
	我买过的商品: '購入済商品',
	我收藏的商品: 'お気に入り商品',
	担当推荐商品: 'スタッフ推薦商品',
	猜你喜欢的商品: '推薦商品',
	// 平台频道
	平台频道: 'KAERUチャンネル',
	去购买: '商品を購入へ',
	去选品: '詳細へ',
	去看看: '詳細へ',
	主题市场: 'テーママーケット',
	猜你喜欢的店铺: 'おすすめ店舗',
	品类市场: 'カテゴリー',
	市场: '市場',

	// 青蛙频道
	更多最新文章: 'もっと見る',
	采购相关: '仕入れについて',
	物流相关: '物流について',
	优惠相关: 'キャンペーンについて',
	OME相关: 'OEMについて',
	他社合作相关: '他社業務提携について',
	热门文章: '人気記事',
	档案: 'カテゴリ',
	// 文章
	上一篇: '前へ',
	下一篇: '次へ',
	// 购物车
	选择店铺: '店舗選択',
	请选择店铺: '店舗を選択してください',
	检索平台: 'ECサイト',
	价格浮动百分比: '購入単価上限率',
	'原有的商品缺货时，我们会帮你查找新的采购链接，所以请告诉我您能接受的采购价格区间变动':
		'指定ショップで買付できない場合、設定された単価上限を目安に別ショップを検索します，初回設定値を、買付時のデフォルト値に設定します　，変更は、情報設定ページで編集して下さい',
	创建组合商品: 'セット組商品を作成',
	确认并支付: 'お支払いへ',
	收件人: '受取人',
	市区町村: '市区町村',
	区町村: '区町村',
	自定义订单编号: 'お客様注文番号',
	购买店铺: '店舗名',
	购买类型: '注文種類',
	收件人不能为空: '※必須項目です',
	联系电话不能为空: '※必須項目です',
	都道府县不能为空: '※必須項目です',
	详细地址不能为空: '※必須項目です',
	邮编不能为空: '※必須項目です',
	当前余额: '残高',
	'提示：此金额为预估报价，您下单后，该部分金额将会被冻结。等到该订单采购处理全部完成后，我们会自动解冻这部分金额，并按照实际产生的金额进行扣费。实际扣费详情您可以后续在资金明细页面或订单详情页面查看。':
		'発注依頼時に、表示の概算費用をお預かりします。注文商品が検品完了次第、お預かり金は全額返金し正式費用を精算します',
	// 组合
	组合商品名称不能为空: '※必須項目です',
	组合商品名称: 'セット組商品名',
	组合商品规格: 'セット組規格',
	商品图片: '商品画像',
	商品规格: '商品のプロパティ',
	单组数量: '単品数量',
	保存并加入购物车: '保存してカートに追加',
	组合商品附加项: 'セット組オプション',
	选择附加项: 'オプション編集',
	组合详情: 'セット組み内訳',
	组合图片: 'メイン画像',
	// 通用
	返回: '戻る',
	平台: 'サイト',
	变更: '変更',
	商品名称: '商品名',
	规格: '規格',
	商品属性: '商品のプロパティ',
	在库数量: '在庫数',
	'可用库存/采购数量': '良品数/購入数',
	起订量: 'ロット数',
	起订量件: 'ロット数(件)',
	单价: '単価',
	单价元: '単価(元)',
	总价元: '合計額(元)',
	版费元: '版费(元)',
	小计: '小計',
	操作: '操作',
	修改: '編集',
	合计: '点数',
	合计2: '合計',
	支付: '支付',
	工作台: 'マイページ',
	意见反馈: 'ご意見',
	提示: 'お知らせ',
	提示2: 'ご確認下さい',
	创建: '作成',
	确定: '確定',
	确认: '確認',
	保存: '保存',
	同意: '同意する',
	撤回: 'キャンセル',
	价格区间: '価格帯',
	风格: 'スタイル',
	颜色: '生地のカラー',
	尺码: 'サイズ',
	其他条件: '他の条件',
	综合: 'おすすめ',
	价格: '価格',
	成交额: '販売実績',
	信用: 'レビュー評価順',
	销量: '売れている',
	数量: '数量',
	排序: '並べ替え',
	自定义: '価格帯',
	去结算: 'ご注文手続きへ',
	默认: 'デフォルト',
	'上传中...': '商品データ同期中...',
	'正在发送请求，请稍侯': '正在发送请求，请稍侯',
	离开: 'キャンセル',
	注意: 'ご注意',
	无: '無',
	暂无: '無し',
	选填: '選択肢',
	批量删除: '一括削除',
	公司名: '会社名',
	清关地址: 'Importer住所',
	设为默认: '既定の住所（デフォルト）に設定',
	姓名En: '氏名（英語）',
	都道府县En: '都道府県（英語）',
	市区町村En: '市区町村（英語）',
	详细地址En: '詳細住所（英語）',
	添加商品: '商品を追加',
	购物车商品: 'カート',
	我的商品: '発注履歴',
	商品类型: '注文種類',
	至少选一个: '少なくとも1つを選択してください',
	'商品名称/SKU': '商品名/SKU',
	加入组合: '追加',
	普通商品: '既製品',
	OEM商品: 'OEM商品',
	组合商品: 'セット組',
	购物车为空: 'カートに商品はありません',
	上传图片: '画像をアップロード',
	// 长文字说明
	'仅支持已授权店铺上传商品，该店铺未授权': `商品をアップロードできません
    この店舗は未連携で、商品をアップロードできません`,
	'当您采购的商品价格出现变化时，或不能采购时我们将为您寻找其他相同的商品，所以请告诉我您能接受的价格变动幅度。':
		'購入単価上限を入力して下さい。指定ショップで買付できない場合、設定された単価上限を目安に別ショップを検索します',
	'当商品出现问题时，我们将为您免费重新换货X次。超过X次每次Y元，最多不超过Z次。当不勾选时，每次出现次品时，我们都会询问您的意见，在进行处理，会影响您的购买效率。': `『商品交換』を指定すると、各商品につきX回まで無料で交換対応可能です
    X回以上はY元/回の交換手数料が発生します（最大交換回数はZ回）
    『返品返金』を指定すると、不良品が発生次第、自動でショップへ返品・商品代金を返金可能です
    未指定の場合は、不良品が発生次第、都度対応方法を確認します
    対応ご指示があるまで商品は待機状態が継続し配送遅延にもつながるため、事前の対応指定を推奨します`,
	// OEM问题
	创建寻源单: '見積もり依頼',
	寻源单列表: '見積もり依頼管理',
	复购申请列表: '見積もり再購入',
	待接单: '受理待ち',
	待报价: '見積もり待ち',
	待商榷: '相談待ち',
	寻源失败: '返金済み',
	发布时间: '依頼日',
	起始日: '開始日',
	结束日: '終了日',
	寻源单详情: '依頼書',
	订单状态: '注文状況',
	下一步: '次へ',
	拒绝: '拒否する',
	拒绝2: '支払拒否',
	确定下样品单: 'サンプル発注しますか',
	确定下大货单: '量産をしますか',
	评价: '評価',
	有: 'ある',
	没有: '無し',
	取消寻源单: 'キャンセル',
	确认下单: '発注',
	确认重新编辑: '再編集しますか',
	确认申请退款: '返金しますか',
	确认申请复购: '見積もり有効期間が切れました、最新の見積もりを依頼しますか',
	'发布此寻源单需要收取XXX元服务费，请问是否确定发布':
		'XXX元対応料金がかかり、見積もり依頼を提出しますか？',
	'发布此寻源单无需收费，请问是否确定发布':
		'無料サービス、見積もり依頼を提出しますか？',
	示例: '例',
	以上没有我需要的: 'その他',
	'您想要一个什么类型的商品？': '商品品類を選択して下さい',
	'请问你要做什么辅料？': 'ご希望の資材を選択して下さい',
	请填写您需要的辅料: '資材を選択してください',
	文件上传: 'ファイルをアップロード',
	'请问你有自己的方案吗？': '自分の計画をご用意してますか？',
	'我有自己的想法，请直行便根据我的要求帮我生产':
		'自分のアイデアがあり、それに応えて生産してください',
	'我没有自己的想法，希望直行便帮我推荐方案':
		'自分のアイデアがない、直行便から計画書を提案してください',
	'我有自己的想法，但是我也想看一下直行便的推荐方案':
		'自分のアイデアがありますが、直行便からの推薦方案も参考希望です',
	请详细表述并上传您需要定制的辅料设计图或参考图片:
		'ご希望の内容詳細を入力してください',
	请详细描述您需要定制的款式信息: 'ご希望のデザイン詳細を述べてください',
	请详细描述您需要定制的材质信息: 'ご希望の生地詳細を述べてください',
	请详细描述您需要定制的尺寸信息: 'ご希望のサイズを述べてください',
	请查看我们的款式推荐方案: '直行便のデザイン推薦方案をご覧ください',
	请查看我们的材质推荐方案: '直行便の生地推薦方案をご覧ください',
	请查看我们的尺寸推荐方案: '直行便のサイズ推薦方案をご覧ください',
	请查看我们的颜色推荐方案: '直行便のカラー推薦方案をご覧ください',
	请选择Logo印刷方案: 'ロゴ印刷方案をご選択ください',
	价格和起订量: '価額と最低注文数',
	总价: '合計額',
	版费: '金型費用',
	选择色卡: 'カラーチャート選択',
	搜索色号: 'カラーチャート番号検索',
	色号或色卡名称: 'カラーチャット番号',
	请至少选择一种色卡: '1点以上のカラーチャートをお選びください',
	已选色卡: 'カラーチャート選択済み',
	//
	'请问你对本次杂货OEM的初步需求是怎样的？':
		'今回の雑貨OEMについてご要望はどうなさいますか？',
	'根据图片或样品，帮我生产出一模一样的产品。（包含加Logo）':
		'画像やサンプルに応じて、商品生地 ＋ デザインが一致する商品を生産してください。',
	'在既成品的基础上，我有一些改良的需求 。（包括颜色、材质、 功能、尺寸、设计等的改良）':
		'既製品から変更を行い商品を生産してください。（カラー、素材、サイズ、デザインなどの改善）',
	'既无参考图片也无式样书的情况，无法发起寻源，请联系对应担当提供制作式样书服务':
		'弊社の担当者にご連絡ください。\n弊社でのOEM生産に必要な仕様書のご提供が必要となるため、詳細を担当からご案内します。',
	'请问您是否有样品可邮寄？': '御社からサンプルを郵送できますか？',
	'请问您需要附加Logo吗？': 'ロゴをプリントしますか？',
	请完善Logo信息: 'ロゴデザインを提供してください',
	'请对logo颜色、尺寸、工艺、位置做说明':
		'ロゴのカラー、サイズ、プリント位置をご説明ください。',
	'请问您对本次寻源的工厂或产品资质有特殊要求吗？':
		'見積もり依頼工場、許可証に関するご要望がありますか？',
	资质要求: '許可証に関するご要望',
	请上传您的参考链接或图片: '参考URL或は参考画像をアップロード',
	'请问您是否有式样书？': '仕様書がありますか？',
	'我需要定制全新的产品，目前市场上没有既成品可参考。':
		'オリジナル商品を生産したいです。既製品がありません。',
	请上传式样书: '仕様書をアップロード',
	'式样书必须包含产品的色卡、尺寸、特殊工艺、面料信息等':
		'（仕様書）必ず商品のカラーチャート、サイズ、 生地などの情報をご入力ください',
	使用直行便帮助您制作式样书: '直行便に仕様書作成を依頼する',
	'由于信息不足，直行便暂时无法为您提供寻源服务':
		'情報不備のため、しばらく見積もり依頼サービスを提供しかねます。',
	请详细说明您需要改动的地方: '変更したいところを説明してください。',
	'如果需要改动的点已经在式样书中完全展现，可以略过，否则可做补充说明':
		'変更したいところを既に仕様書に表記された場合は、入力なしで問題ございません',
	补充说明: '補充説明',
	上传文件: 'ファイルをアップロード',
	请完善信息: 'サンプルを下記の住所に送ってください',
	请问您是否可以提供样衣邮寄: '御社からサンプルを郵送できますか？',
	我有样衣: 'サンプルがあります。',
	我没有样衣: 'サンプルがありません。',
	直行便地址: '直行便住所',
	请填写物流单号: '追跡番号をご記入ください。',
	//
	'请问你对本次服装OEM的初步需求是怎样的？':
		'ご要望のOEM商品生産パターンを選択して下さい',
	'只需要生产出和图片或链接看起来一样的产品，对面料没有任何要求，且不需要有任何设计上的改动。':
		'商品画像または商品URLのデザインのみ一致する商品を生産したい。\n対応スピードを最優先し、商品生地の指定なし。',
	'风险告知：A的情况，此选项不寄实物样品给客户，只通过样衣图片在线上进行确认。此选项优点为出样快，缺点为直行便只保证成衣外观和客户提供的图片接近一致无法保证和原链接衣服材质保持一致。请知悉！': `他プランに比べ、スピード最優先で対応するプランです。
    商品デザインが一致する事を重視し、サンプル確認も実物ではなく画像で確認いただきます。
    商品はデザインの一致のみを保証し、生地や質感の保証は致しかねますのでご留意ください。
    ※サイズ、生地指定がある場合は、BまたはCプランを選択してください。`,
	'我有样衣，希望直行便帮我生产出和样衣一模一样的衣服。':
		'商品サンプルがあり、生地、サイズなどサンプルと同一の商品を生産したい。',
	'我有样衣，但我对设计/面料/尺寸等，有希望改动的地方。':
		'商品サンプルがあり、デザイン、生地、サイズなどを変更して生産したい。',
	'我只有参考图片或链接，且我有需要改动的地方或我对面料有拘泥。':
		'商品サンプルがなく、商品画像または商品URLのみあり。デザイン、生地、サイズなど変更要望あり。',
	'此种情况您需要尽可能提供一件样衣到直行便作为参考，或者您需要提供详细的式样书，如果您没有式样书，可以联系直行便使用式样书制作服务':
		'参考となるサンプル品又は仕様書をご提出ください。仕様書がない場合には、弊社に仕様書の作成依頼も可能です。',
	'我没有样衣或参考图片/链接，但我有式样书，希望直行便根据式样书帮我进行生产。':
		'商品の仕様書のみあり（サンプルや参考画像、商品URLなし）。仕様書に沿った商品生産を行いたい。',
	'既无参考图片也无式样书的情况，无法发起寻源，请联系对应担当提供制作式样书服务；式样书必须包含产品的色卡、尺寸、特殊工艺、面料信息等':
		'弊社の担当者にご連絡ください。\n弊社でのOEM生産に必要な仕様書のご提供が必要となるため、詳細を担当からご案内します。',
	请填写你想定制产品的基本信息:
		'予定する本発注に関する情報を入力してください',
	请填写产品名称: '商品名をご入力ください',
	请至少填写一条完整的产品信息: '情報不足ので見積書依頼できません',
	产品信息说明:
		'商品のサイズ分類を記載してください。サイズが複数ある場合は、行追加しサイズを入力してください。例 M,L,XL,30cm＊40cm＊20cm　など',
	'不知道，但是我可以邮寄面料样布，希望直行便帮我找到和样布一样的面料':
		'生地のサンプルが発送可能です',
	'请问你是否知道面料具体信息？': '生地情報のご説明',
	'知道，我可以提供面料信息和具体的色卡信息': '生地情報と色を入力する',
	'不知道，希望直行便帮我找到和样衣或参考图片一样的面料':
		'サンプルに参考する',
	'不知道，希望直行便可以根据我的需求，给我推荐合适的面料': '直行便提案',
	请提供具体的色卡信息: '希望の生地情報（素材、カラー）を入力してください',
	请描述一下您对面料的需求: '生地をご指定ください',
	'请大致描述一下希望布料的外观或功能上的需求，如:弹性好，柔软等':
		'生地の説明をしてください、例えば： 弾力性に優れたやわらかい生地',
	上传面料图片: '生地の画像をインポートする',
	'请大致描述一下希望改动哪些方面,如:材质、设计、颜色、尺寸等':
		'変更したいところを説明してください、例えば：生地、カラー、サイズ、デザインの変更など',
	'请问你能够提供尺码的具体尺寸吗？':
		'商品の詳しいサイズガイドをご提出できますか',
	'我有尺码对应的尺寸数值。': 'サイズ情報を入力',
	'根据服装生产标准，尺寸相差 1-3cm 都属于正常现象，不作为不良品判断，请知悉':
		'アパレル見積もり生産は、サイズに1-3cmの誤差がある場合がございます、不良品とは扱いませんので、予めご了承ください',
	'我没有数值，请参考链接尺寸。': '商品ページの寸法を参考',
	'我没有数值，请直行便提供。': '直行便提案',
	请提供尺码的具体尺寸: '商品の詳細サイズをご入力ください',
	尺码模板: 'テンプレートファイル',
	模板下载: 'テンプレートダウンロード',
	上传模板: 'インポートする',
	'请先下载对应模板，填写完成后上传':
		'テンプレートファイルをダウンロードして、詳細の情報を入力してからインポートしてください',
	请输入产品的下单需求: '注文情報を入力して下さい',
	'直行便的报价与希望起订量和价格是否匹配，是我们判定是否为您寻源成功的判断依据。请您客观的填写。如果您的希望和市场行情差异过大，我们会之后先联系您，提出修改建议，再开始为您寻源报价。':
		'ご入力いただいた希望ロット数と希望価格により工場を探しますが、ご要望が実際の市場の相場と外れてる場合は、実際の相場により見積もりを出します',
	寻源备注: '備考',
	'如果您对本次寻源还有其他特殊需求，请在以下输入您的需求':
		'見積もり依頼に何かご要望があれば遠慮なくご入力ください',
	已成功创建寻源单: '見積書依頼済',
	创建寻源单说明:
		'まだ、対応開始されていません。管理画面で内容確認の上、依頼提出をお願いします',
	'式样书(例)': '仕様書（例）',
	棉100: '綿100%',
	可多选: '複数選択可能',
	尺码信息: 'サイズ',
	是否需要加logo: 'ロゴ刻印、プリントする場合、ロゴ資料を提出',
	期望交期时间: '希望納期',
	期望最低起订量: '希望ロット数',
	客户补充部分: 'お客様ご要望',
	附加说明: '説明',
	您可以点击此处: '仕様書を依頼する',
	产品图片: '商品画像',
	产品规格: '商品規格',
	备注: '備考',
	附加费选项: '商品オプション',
	品牌: 'ブランド名',
	总计数量: '合計数量',
	'总计(折扣前RMB)': '合計（割引前）',
	'总计(折扣后RMB)': '合計（割引後）',
	估计金额: '見積金額',
	购物车数量不能小于X: '最低追加数量（X）を満たしません',
	普通订单: '既製品注文',
	组合商品订单: '組合せ商品',
	OEM订单: '見積もり商品',
	//
	报价已过期: '見積書の有効期間が切れました',
	复购申请编号: '見積更新依頼',
	申请状态: 'ステータス',
	//
	请填写: 'をご入力ください',
	请上传: 'をアップロード',
	请至少选择一种色卡或上传图片:
		'少なくとも、1つカラーチャートを選んでください。カラーチャートがない場合、写真をアップロードしでください',
	链接和图片必须填一个: 'リンク、あるいは写真をご入力ください',
	请提供面料材质和色卡信息:
		'布地の材質とカラーチャートの情報をご提供ください',
	请填写具体的面料材质信息: '布地の材質の情報をご提供ください',
	'我没有数值，请参考样衣尺寸。': 'サンプルの寸法を参考',
	'以上为参考报价，具体报价需要以最终报价为准 ':
		'あくまでも参考の見積もりなので、実際の見積もりは実際の条件に従うものとします',
	该订单不支持修改属性: '該当商品の規格を修正できません',
	请至少选择或上传一种色卡: '1点以上のカラーチャートをお選びください',
	请填写款式说明: 'ご希望のデザイン詳細を述べてください',
	请填写材质详细说明: 'ご希望の生地詳細を述べてください',
	请填写尺寸详细说明: 'ご希望のサイズを述べてください',
	请上传Logo: 'ブランドlogoをアップロードしてください',
	请上传尺码表: 'サイズをアップロードしてください',
	请填写期望价格: '希望価格を入力してください',
	请填写期望最低起订量: '最低ロット数を入力してください',
	请选择期望交期时间: '希望納期を入力してください',
	重新创建寻源单: '見積更新依頼',
	查看寻源单列表:
		'作成完了の見積もり依頼は「未提出」ページにてご確認ください',
	请输入您想要的详细信息: '予定する本発注に関する情報を入力してください',
	直行便的款式推荐方案: '直行便のデザイン推薦方案をご覧ください',
	直行便的材质推荐方案: '直行便の生地推薦方案をご覧ください',
	直行便的尺寸推荐方案: '直行便のサイズ推薦方案をご覧ください',
	印刷方案: 'ロゴ印刷方案をご選択ください',
	请输入您的订单信息: 'ご希望を入力してください',
	规格及起订量: '価額と最低注文数',
	'棉100%': '綿100%',
	'上次寻源未完成，是否继续':
		'作成完了してない見積もり依頼があり、引き続き操作しますか？',
	重新寻源: '再見積もり依頼',
	店铺不存在: 'この店舗が見つかりませんでした',
	暂无平台: 'プラットフォームはまだありません',
	输入FAQs关键词: 'キーワードを入力',
	收藏店铺: 'お気に入り店舗に追加',
	多为我推荐此类型商品: 'おすすめ商品に設定',
	多为我推荐此类型店铺: 'おすすめ店舗に設定',
	不再推荐此商品: 'おすすめ商品から除外',
	不再推荐此店铺: 'おすすめ店舗から除外',
	商品收藏成功: 'お気に入り商品に追加しました',
	店铺收藏成功: 'お気に入り店舗に追加しました',
	'感谢反馈，我们将为你推荐更加准确的店铺': `フィードバックを頂きありがとうございました。
    これからより多く関連店舗をお勧めいたします。`,
	注销账号: 'アカウント削除',
	'您的账号中存在以下未处理完结事件，不能注销。': `アカウント削除不可
    処理中の項目があるため、アカウント削除は出来ません`,
	账户余额X元: '残高X元',
	提现申请: '引出し申請',
	X笔采购订单: '注文数：X',
	X条充值任务未完成: 'Xご入金（確認中）',
	批量编辑附加项: '一括オプションを編集',
	工厂直营: '直行便厳選',
	组合商品不能再组合: 'セット組対応済 重複したセット組は作成不可',
	'订单已生成，请随时关注订单动态':
		'注文完了。注文状況から進捗をご確認下さい　',
	'5s后跳转订单详情': '5秒後に注文状況画面に遷移します',
	'系统检查到，您未安装采购扩展程序，现在安装采购更方便直接在1688，天猫，淘宝官网THE直达商品可以添加到采购系统购物车中。现在马上安装': `ツール利用で、中国主要サイトから直接、THE直行便システムでの商品買付可能
    商品買付がとっても便利になります！
    すぐに【補助ツール(KAERUツール)】を追加する`,
	包邮: '中国国内送料無料',
	赠送退货运费险: '中国国内返品送料保険提供',
	新品: '最新商品',
	正品保障: '正規品',
	公益宝贝: '寄付つき商品',
	'7+天内退货': '7日以内理由によらず返品対応可能',
	折扣: 'sale',
	搭配满减: '条件満たすと値引き',
	满就减: '条件満たすと値引き',
	货到付款: '代金引換',
	'48小时内发货': '48時間以内出荷',
	实力商家: '優良店舗',
	超级工厂: '優良サプライヤー',
	加工定制: 'オリジナル生産',
	人气: '売れている順',
	请填写正确的访问令牌: '正しいアクセスコードを入力してください',
	组合商品规格不能为空: '必須項目。セット組規格を入力してください',
	选择一张图片作为组合商品主图: '1つ画像を選択してください',
	查看组合列表: 'セット組一覧を確認',
	当前购物车共X种商品: 'カート内の商品数：X点',
	X件商品已添加至购物车: 'X点　カート追加完了',
	'库存过低不能下单，请调整发货量或及时补充库存':
		'在庫が少なすぎて注文できません。発送数を調整するかまたは在庫を補充してください',
	同步商品: '店舗商品を取込み',
	fnsku: 'FNSKU',
	asin: 'ASIN',
	'请输入Amazon sku': 'Amazon sku を入力してください',
	'请搜索需要绑定的Amazon sku': 'Amazon sku を入力してください',
	附加项未配置箱唛: '箱ラベルのオプションを追加してください',
	附加项未配置贴标签: '商品ラベルのオプションを追加してください',
	'海外仓sku(fnsku)': '日本倉庫SKU(FNSKU)',
	补货数量: '発送数',
	未提出: '未提出',
	已提出: '提出済',
	已作废: 'キャンセル済',
	'FNSKU/ASIN': 'FNSKU/ASIN',
	'商品名称/Amazon sku': '商品名/Amazon SKU',
	'品名/平台sku': '商品名/直行便SKU',
	计划发货量: '配送件数',
	是否确认提出: '提出してよろしいでしょうか',
	是否确认取消: 'キャンセルしてよろしいでしょうか',
	作废成功: 'キャンセルしました',
	国际发货单标题: '納品タイトル',
	发货状态: 'ステータス',
	批量加购: '一括再購入',
	'点击完结 单品可以转普通商品库存':
		'点击完结 単品商品としての在庫保管に変更可能',
	'存在待组商品 请尽快加购组合。': '存在待组商品 请尽快加购组合。',
	物流信息: 'お届け先情報',
	国际单号回传后不可修改: '再編集不可 出荷済',
	收件人邮箱: 'メールアドレス',
	选择订单时间: '注文日時を選択',
	订单付款日期区间: 'お支払い日時',
	未下单: '未発注',
	已下单: '発注済',
	店铺订单号: 'お客様注文番号',
	订单时间: '注文日時',
	采购状态: 'ステータス',
	收货信息: 'お届け先',
	商品图: '画像',
	'商品名/sku': '商品名/SKU',
	采购链接sku: '直行便SKU',
	同步订单数据: '注文取込み',
	一键下单: '発注',
	'编辑物流/地址': '住所を編集',
	是否删除: '削除してよろしいでしょうか',
	发货附加项编辑成功: '発送用オプション　編集完了',
	留在此页: 'OK',
	开始时间: '開始日時',
	质检中: '検品',
	'当前申请取消的商品，存在多个商品状态，确认取消后：':
		'キャンセル処理を実行すると、',
	'“X”状态商品，申请取消成功后，系统将自动取消商品的采购与发货，冻结金额在订单结算后返回至可用余额内。':
		'“X”状態の商品は商品の買付と発送を停止しお預かり金を全額返金します',
	'“X”状态，申请取消成功后，系统将自动取消商品的发货行为，并将商品保留库存。':
		'“X”状態の商品は商品の発送を停止し在庫保管します',
	'请确认是否申请取消？': 'キャンセルを実行しますか',
	'“X”状态商品，系统将自动取消商品的采购，冻结金额在订单结算后返回至可用余额内；':
		'“X”状態の商品キャンセルは、商品の買付と発送を停止しお預かり金を全額返金します',
	'“X”状态商品，不可申请取消（系统将自动忽略）':
		'“X”状態の商品はキャンセル出来ません',
	'当前申请取消的商品，商品状态均为“X”状态,': '“X”状態の商品キャンセル',
	'申请取消成功后，系统将自动取消商品的采购与发货，冻结金额在订单结算后返回至可用余额内。':
		'キャンセル処理を実行すると、商品の買付と発送を停止しお預かり金を全額返金します',
	'当前申请取消的商品，商品状态均为“X”状态，': '“X”状態の商品は',
	'当前申请取消的商品，商品状态均为“X”状态，申请取消成功后，系统将自动取消商品的采购，冻结金额在订单结算后返回至可用余额内':
		'“X”状態の商品キャンセルは、商品の買付を停止しお預かり金を全額返金します',
	再次下单: '再購入',
	组合信息: 'セット組情報',
	结算中心查看明细: '口座一覧から明細を確認',
	费用名称: '種類',
	'结算总价（元）': '合計額（元）',
	问题联络: '問題連絡',
	冻结: 'お預かり金',
	解冻: 'お預かり金返金',
	结算: 'ご注文手続きへ',
	重拍冻结: 'お預かり金（交換）',
	销售单价: '単価',
	结算数量: '数量',
	结算总价: '合計額',
	点击上传凭证: 'クリックしてアップロード',
	商品代金结算: '商品代金',
	国际物流费用: '国際送料',
	国际运费重量差补扣: '国際送料差額分返金',
	国际运费附加项补扣: '国際送料追加請求総額',
	销毁费补扣: '廃棄手数料',
	偏远地区费补扣: '遠方輸送手数料',
	仓储费补扣: '物流倉庫保管費',
	超长超重费补扣: 'オーバーサイズ料金',
	关税补扣: '関税',
	改派费补扣: '配達先変更手数料',
	特殊处理费补扣: '特別対応費用',
	扣件费补扣: '配送ストップ手数料',
	退件费补扣: '返送手数料',
	冻结总价: '買付仮払金額総額',
	解冻总价: '合計額',
	重拍冻结商品代金: '買付仮払金額返金',
	冻结商品代金: '買付仮払金額',
	解冻商品代金: 'お預かり金返金',
	冻结总数量: '購入総数',
	解冻总数量: '総数',
	手续费: '代行手数料',
	次品重买总次数: '不良品交換回数',
	纸箱总数量: '箱数',
	装箱总数量: '発送数',
	气泡膜总数量: 'プチプチ総数',
	托盘总数量: 'パレット総数',
	箱子总数量: '箱数',
	结算总数量: '総数',
	我的默认附加项: 'デフォルトオプション設定（全商品）',
	我的类目附加项: 'デフォルトオプション設定（商品カテゴリー毎に設定）',
	商品总金额: '合計金額',
	发货单: 'パッキングリスト',
	箱: '箱',
	钱包: '口座一覧',
	记录: '記録',
	购买记录: '購入履歴',
	没有可优先发货的商品: '分納発送可能な商品はありません',
	是否确认优先发货: '【ご確認下さい】この商品を分納発送しますか',
	优先发货成功: '分納発送設定しました',
	没有可申请取消的商品: 'キャンセル可能な商品がありません',
	是否确认删除: '【ご確認下さい】削除してもよろしいでしょうか',
	搜索商品: '商品を検索',
	'您将要删除X条商品数据，删除后将无法恢复':
		'【ご確認下さい】選択した●件商品データを削除すると、元に戻すことはできません。商品を削除しますか',
	请输入商品名: '何をお探しですか？',
	选择收货地址: 'お届け先を選択してください',
	国际发货标题: '納品タイトル',
	是否确定加购大货: '【ご確認下さい】この商品を量産しますか',
	加购大货: '量産する',
	确定加购样品: 'サンプル発注',
	加购样品: 'サンプル発注',
	库存还有X件商品或辅料: 'X件の商品（資材）の在庫があります',
	去处理: '詳細',
	'账号注销后我们将不再保存您的任何账号信息，是否注销？':
		'【ご確認下さい】アカウント削除すると、直行便システムの全情報が削除され元に戻すことが出来ませんアカウントを削除しますか',
	直行台: '会員センター',
	账户概览: '会員センター',
	同步订单: '注文管理（API発注）',
	关联商品库: '紐付け管理',
	我的组合商品: 'セット組',
	国际发货: '国際配送',
	国际发货列表: '国際配送一覧',
	国际发货详情: '詳細',
	补货计划: '納品タイトル',
	资金管理2: '口座一覧',
	授权管理: 'API連携',
	再购入: '再見積もり依頼',
	下单: '発注',
	同步商品信息: '店舗商品を取込み',
	库存数: '良品',
	可售: '在庫あり',
	可用库存: '良品数',
	已锁定: '利用予定',
	待处理次品数: '返品不可不良品',
	导出成功: 'ダウンロードしました',
	直行便发货单号: '直行便発送依頼番号',
	复购: '再購入',
	筛选条件: '検索条件',
	'客户商品sku/海外仓sku': '貴社SKU/日本倉庫SKU',
	'平台客户sku/商品名称': '商品SKU/商品名',
	更新: '更新',
	平台商品信息: '商品情報',
	'AMAZON库存(可售)': 'Amazon（在庫あり）',
	'AMAZON库存(入库)': 'Amazon（入庫待ち）',
	'AMAZON库存(不可售)': 'Amazon（販売不可）',
	预计总库存: '合計',
	绑定: 'SKU紐付け',
	解绑: '紐付けを解除する',
	确认签收: '受取',
	'是否确认签收？': 'この商品を受け取りますか',
	'是否确认取消国际发货？ 仓库已在作业中的发货单无法取消。':
		'キャンセル不可当該配送依頼は倉庫作業中です　詳細は担当へご連絡下さい',
	'小计（元）': '小計（元）',
	实际发货数量: '実際配送数',
	提出: '提出',
	已确认: '処理中',
	尺寸: 'サイズ',
	/**
	 *
	 * 补充翻译3
	 */
	寻源单号: '見積もり依頼番号',
	大货单: '量産',
	预计可发货数: '最大発送数',
	查看订单详情: '注文詳細を確認',
	// 购买数量不可为空: '必須項目 購入数量',
	购买数量不可低于起订量: `購入数量不備。
  購入数量が最低ロット数を満たしていません`,
	子商品总数不能少于2件: '1セット組商品には、単品商品が2件以上必要です',
	'存在不足起订量的商品，请修改数量': `購入数量不備。
  購入数量が最低ロット数を満たしていません`,
	需要填写收货地址: 'お届け先住所を入力してください',
	添加收货地址: 'お届け先を追加',
	payoneer充值记录: 'Payoneerチャージ履歴',
	我知道了: '閉じる',
	'存入金额（USD）': '振込金額（USD）',
	待初审: '初回審査待ち',
	待终审: '最終審査待ち',
	已通过: '審査終了',
	上传成功: 'アップロードしました',
	参考单价: '単価',
	冻结数量: '購入数',
	扣费日: '計算日',
	上次扣费日: '在庫保管期間（期首）',
	上架日: '在庫保管期間（期末）',
	免费保管天数: '無料保管日数',
	计费天数: '日数',

	/**
	 *
	 * 补充翻译4
	 */
	普通发货: 'Amazon FBA納品以外',
	FBA发货: 'Amazon FBA納品',
	收货仓: '納品先',
	'如果您当前存在部分订单未完成的话，您账户可用余额中我们将会自动为您留下当下账户冻结金额*1的金额，来确保账户有足够的金额来支付国际运费后顺利发货。所以您目前可转账的金额为：账户可用余额-冻结金额': `対応中の注文がある場合は、国際送料をお支払うのに十分な金額があることを確保するために、利用可能額から（当アカウントのお預かり金＊１）の金額を一旦利用不可と設定させて頂きました。
     現在、振替可能額は、利用可能額からお預かり金を差し引いた金額です。`,
	本公司接受银行: '弊社受取銀行みずほ銀行',
	汇款人姓名: '振込人名義',
	汇款时间: '振込日時',
	汇款凭证: '入金・振込証跡',
	点击上传: 'クリックしてアップロード',
	良品入库: '入庫（良品）',

	付费重拍: '有料交換',
	本次重拍费用X円: '有料、今回の交換手数料はX円',
	'接受次品/缺货自动付费重拍（接受自动重拍，有助于我们更快的为您完成订单采购）':
		'D2C会員様は『交換』を推奨します',
	更换尺码: 'サイズ変更',
	更换款式: 'デザイン変更',
	'请输入链接(必填)': 'URLを入力（必須項目）',
	同款链接: '同一商品URL',
	点击可查看: 'クリックして確認可能',
	类似款链接: '類似商品URL',
	等待原链接: '元URL待ち',
	下单原链接: '元URL購入',
	'下单原链接（多出数量保留库存）': '元URL購入（残り在庫は在庫保管）',
	申请成功: '申請完了',
	请确认尺码: 'サイズのご確認',
	请确认颜色: '色のご確認',
	'请确认设计（款式）': 'デザインのご確認',
	'全网断货，建议取消': `【ご確認下さい】
  全サイト在庫切れ、キャンセルをお勧め致します`,
	'原商品缺货，找到了类似商品，请确认': `【ご確認下さい】
    元URLは在庫切れ、在庫ある類似商品が見つかりました
    購入するかどうかをご確認ください
    `,
	'预售商品。预计下单后X天后能发货，要等吗？': `【ご確認下さい】
     元URLは予約商品で、注文後X天出荷可能です
     購入するかどうかをご確認ください`,
	'找到新的现货链接，是否可以购买？': `【ご確認下さい】
  在庫あるURLが見つかりました
  購入するかどうかをご確認ください`,
	'此商品的最小起订量是X件。请确认是否购买。': `【ご確認下さい】
     当商品の最小ロット数はX件です
     購入するかどうかをご確認ください`,
	'找到符合下单量的新链接，是否可以购买。': `【ご確認下さい】
     希望購入数を購入できるURLが見つかりました
     購入するかどうかをご確認ください`,
	'原商品质量不佳，推荐在新链接采购。': `【ご確認下さい】
  何度交換しても不良品が届きましたので、新URLで発注をお勧めます`,
	到错货: '注文した商品とは異なる物が届いた',
	少件: '注文した数より多く商品が届いた',
	多件: '注文した数より少なく商品が届いた',
	快递扫不出: 'QRコードを読み取れない',
	'配件差异（口袋，腰带，纽扣，拉链，拉绳等）': '付属品が違い',
	'设计差异（压线，领口，袖口，花纹，衣服设计等）': 'デザインが違い',
	'材质/面料差异': '生地が違い',
	色差: '不良理由：色差あり\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	直行便档口: '直行便市場店舗',
	'我们的 OEM 仅适用于商务舱和头等舱成员。如果您想使用OEM，请从[会员信息]更改会员等级。如果您有任何问题，请联系我们的在线客服。':
		'OEMサービスはエコノミー以上の会員様が利用可能です。ご利用の場合は会員ランクの変更を行ってください。',
	充值会员: '会員ランク変更へ',
	购买数量不可低于X件: '最低ロット数は X です！',
	/**
	 *
	 */
	'平台库存（处理中）': '直行便在庫（処理中）',
	'平台库存（已锁定）': '直行便在庫（利用予定）',
	'平台库存（可用库存）': '直行便在庫（良品数）',
	'平台库存（待处理次品数）': '直行便在庫（返品不可不良品）',
	转账金额不能为0: '振替金額は０円以上の金額を入力してください',
	'同步中...': '取込中...',
	'同步完成-执行刷新': '取込完了',
	我的工作台: 'マイページ',
	API同步订单: '注文管理（API発注）',
	库存管理: '在庫管理',
	寻源列表: '見積もり依頼管理',
	OEM复购: '再見積もり依頼一覧',
	'API/子账号配置': 'API連携/店舗作成',
	会员相关: '会員センター',
	国际配送管理: '国際配送管理',
	FBA补货计划: 'FBA納品一覧',
	国际发货管理: '国際配送一覧',
	国际发货售后: 'アフター補償申請',
	主账号资金明细: '親アカウント口座一覧',
	店铺资金明细: '店舗口座一覧',
	OEM寻源: '見積もり依頼',
	全部店铺数据: '店舗情報',
	这是您与直行便相处的第X天: '入会日数：X日',
	升级会员: '会員プランを変更',
	钱包余额: '利用可能額',
	未关联的商品: '未紐付け商品',
	API同步异常订单: '異常注文（API発注）',
	商品在库: '商品在庫管理',
	在库辅料: '資材在庫管理',
	采购金额: '注文金額',
	采购量: '注文数',
	待发库存数: '検品済み（配送可能）',
	待确认补货计划: '確認待ち納品プラン',
	未绑定商品: '未紐付け商品',
	/**
	 *
	 */
	对折: '二つ折り',
	不使用商品番号: '商品番号を使用しません',
	不使用客户商品sku: 'お客様SKUを使用しません',
	关联商品库填写: '紐付け内容を入力',
	请填写会社名: '会社名を入力してください',
	请填写会社地址: '会社住所を入力してください',
	'图片格式支持jpg，png。':
		'アップロード可能な形式は「jpg」「png」のみです。',
	'LOGO图片比例为1:1，在水洗标打印时转变为黑白色':
		'ロゴ画像の画面比率は1：1で、白黒で印刷します。',
	缝制样式: '縫製スタイル',
	请选择商品番号填写方式: '商品番号の入力方式を選択してください',
	'更新中...': '更新中...',
	'更新完成-执行刷新': '更新完了',
	Amazon在庫数更新: 'Amazon在庫数更新',
	购买时间: '購入日時',
	收藏时间: '追加日時',
	/**
	 *
	 */
	更新时间: '更新日時',
	请选择需要复购的商品: '再購入したい商品を選択して下さい',
	请选择需要优先发货的商品: '分割発送したい商品を選択して下さい',
	请选择需要取消的商品: 'キャンセルしたい商品を選択して下さい',
	请选择需要售后的商品: '受領後返金を申請したい商品を選択して下さい',
	请选择需要导出的订单: 'ダウンロードしたい注文を選択して下さい',
	'自動発注をOFFにしました。': '自動発注をOFFにしました。',
	店铺url和访问令牌不能为空: '店舗URLとアクセスコードを入力して下さい',
	未关联sku数量: 'SKU未紐付け',
	星期一: '月曜日',
	星期二: '火曜日',
	星期三: '水曜日',
	星期四: '木曜日',
	星期五: '金曜日',
	星期六: '土曜日',
	星期日: '日曜日',
	平台库存: '直行便在庫',
	AMAZON库存: 'Amazon在庫',
	不可售: '販売不可',
	入库: '入庫',
	/**
	 *
	 */
	'商品“已上架”之前的状态下的总数量': '発注してまだ倉庫に到着してない数',
	已使用准备发给您的商品: '現在該当商品の配送予定数',
	可使用的正品数量: '現在倉庫にある良品数',
	'仓库发现商品次品且无法退款，进入您的次品库存':
		'現在倉庫にある返品不可の不良品数',
	弊社受取銀行: '弊社受取銀行',
	横浜駅前支店: '横浜駅前支店',
	口座番号: '口座番号',
	'普通預金  4014779': '普通預金  4014779',
	口座名: '口座名',
	'株式会社  SNIFF JAPAN': '株式会社  SNIFF JAPAN',
	料金表: '料金表',
	国際配送依頼番号: '国際配送依頼番号',
	收据: '入金領収書',
	/**
	 *
	 */
	一键绑定: 'SKU自動紐付け',
	导出: 'ダウンロード',
	请确认售后信息: 'アフター補償申請',
	解冻数量: '数量',
	通过FBA补货计划发货: 'Amazon FBA納品',
	海外仓sku不能为空: '日本倉庫SKUをご入力ください',
	/**
	 *
	 */
	缺货: '在庫切れ',
	传输商品信息到店铺: '店舗に一括登録',
	'创建成功，并已为您自动创建关联':
		'作成完了、紐付けが自動的に作成されました',
	查看关联商品库: '紐付け管理を確認',
	领收书下载: '領収書ダウンロード',
	领收书: '領収書',
	流水: '明細',
	是否确定加购样品: 'サンプル発注しますか？',
	查看报价信息: '見積書確認',
	汇款金额必须大于0: '送金の金額をゼロ以上にしなればなりません。',
	确认取消: '確定',
	'请绑定 Amazon sku':
		'Amazon SKU未指定商品あり　AmazonSKUと紐付けして下さい',
	/**
	 *
	 */
	换绑: '紐付け変更',
	'绑定Amazon sku': 'SKU紐付け',
	'申请取消成功后，系统将自动取消商品的发货行为，并将商品保留库存。': `
  キャンセル処理を実行するとエンドユーザーへの直送を停止し在庫保管します<`,
	是否确认取消授权: 'API連携を解除しますか',
	残高からお支払い: '残高からお支払い',
	申込月数: '申込月数',
	转账成功: '振替完了',
	銀行振込: '銀行振込',
	'您的余额不足，请充值后再次支付':
		'口座残高不足，資金をチャージ後、再実行をお願いします',
	'5s后跳转充值页面': '5秒後に資金チャージ画面に遷移します',
	'5s后跳转附加项配置': '5秒後にオプション設定ページへ遷移します',
	下单失败: '発注失敗',
	/**
	 *
	 */
	请确认本次重拍费用: '交換手数料を確認',
	当前单价: '単価',
	商品关联列表: '紐付け管理リスト',
	修改关联: '紐付け変更',
	编辑附加项: 'オプション編集',
	'为您的商品编辑的附加项，会再API下单时直接使用。如需修改请点击此处。':
		'商品オプション設定済であれば、API発注の注文商品オプションは自動設定可能です。 「編集」ボタンをクリックすると、再編集することができます。',
	请上传图片: 'ロゴ画像をアップロード',
	一键删除: '一括削除',
	店铺商品信息: '商品情報',
	'如果是个人，请输入无': '個人の場合、なしをご入力ください',
	订单结算费用详情: '費用明細',
	订单搜索: '注文検索',
	发货详情: '国際配送情報',
	输入你的登陆密码: 'ログインパスワードを入力',
	是否确认复购: 'カートに商品を追加しますか',
	复购成功: 'カート追加完了',
	不同意: '同意しない',
	'请填写商品的尺寸分类。如果有多个大小，请添加行并输入大小。例M、L、XL、30cm*40cm*20cm等': `商品のサイズ分類を記載してください。
  サイズが複数ある場合は、行追加しサイズを入力してください。
  例 M,L,XL,30cm＊40cm＊20cm など `,
	'请输入正确的Amazon sku': '正しい Amazon skuを入力して下さい',
	打印标签模板: '商品ラベル',
	预览: 'プレビュー',
	设为默认地址: '既定の住所に設定',
	'Amazon sku绑定成功': 'Amazon Sku紐付け成功',
	'未处理，请点击【提出】按钮': '未処理\n ※正式対応には提出クリック',
	OEM报价: 'OEM見積もり',
	'处理中，需要1～2分钟': '処理中、所要時間1～2分',
	我的OEM商品: 'OEM商品',
	转到运输公司设置: '運送会社設定へ',
	'点击上方按钮编辑你常用的<span>快捷功能</span>':
		'チェックボックスで画面表示メニューを指定可能です',
	'拉动板块可<span>调整功能排序</span>哦！':
		'各メニューを選択し、構成を変更すると画面表示順を変更できます',
	'编辑后的功能入口在上方显示，你需要的功能直接点击前往即可':
		'編集した表示メニューは画面左上に表示されます\nシステム内の各メニュー遷移に利用します',
	'<span>透明购</span>: 为首页，并为您推荐1688、淘宝、天猫的商品查找专区。\n<span>市场购</span>: 直行便扩展的市场采购货品更全，价格更低。\n<span>我的频道</span>: 你在本平台的喜欢的商品都在这里。 \n任何情况下，点击<span>logo</span>回到本页面':
		'<span>ECサイト購入</span>：中国主要ECサイト(1688、タオバオ、Tmall)で商品検索・買付を行います\n<span>市場仕入れ</span>：直行便が提携する卸売市場から商品検索・買付を行います\n<span>MYチャンネル</span>：直行便からの推薦仕入れ先、お気に入り商品などを表示します\n※カエルアイコンクリックで、ECサイト購入　へ遷移します',
	'选择你感兴趣的<span>平台</span>和<span>类目</span>，再输入关键词，搜索更精准':
		'検索ECサイト、カテゴリーを指定し、商品検索キーワードを入力すれば関連商品をシステム内で検索可能です',
	'点击“X”在直行便平台搜索':
		'【X】クリックで、指定条件を元に直行便システム内で商品検索を行います',
	'点击“X”跳转你选择的平台搜索。':
		'【X】クリックで、指定条件を元に指定ECサイトで直接商品検索を行います',
	'直行便为你提供了多个选购平台。根据你的<span>采购类型迅速选品</span>。':
		'商品選定の便利サイトを紹介します',
	'点击上方切换你喜欢的品类，我们将根据该品类进行推荐。':
		'表示するカテゴリーを選択します\n選択カテゴリーの推薦商品を表示します',
	'如果您有多个店铺可点击这里切换店铺，为不同的店铺采购。直行便也会为不同的店铺推荐不同的商品。':
		'複数店舗を切り替えて、店舗毎に商品購入可能です\n店舗毎に直行便から推薦商品を提示します',
	'你的专属担当根据你的采购习惯，为您甄选的店铺会展示在这里。':
		'専属マネージャーからの推薦ショップを表示します',
	'此处由系统根据你的采购习惯，为你甄选的店铺会展示在这里。':
		'購入履歴を元に、お客様にあった推薦ショップを表示します',
	'下方为商品的推荐，会根据你的购买习惯更新商品哦！！！':
		'システムから購入履歴を元に推薦商品を表示します',
	'你还没有店铺，快去创建一个你的店铺，用来采购你的商品。':
		'店舗が未作成です\n発注には店舗作成が必要です',
	跳过: 'ガイドを終了',
	国际发货创建成功: '国際配送依頼　作成完了',
	处理中的次品和异常: '不良品・異常（対応中）',
	不可退货次品: '不良品（返品不可）',
	去查看采购订单: '注文詳細へ',
	修改商品附加项: '編集',
	订单: '注文',
	申请取消成功: 'キャンセル申請済',
	选择同款: '同一商品URLで発注',
	'是否确认取消国际发货？': 'キャンセルを実行しますか',
	'海外仓（fnsku）/asin': '日本倉庫（fnsku）/asin',
	'Amazon sku/AMAZON商品名称': 'Amazon sku/Amazon商品名',
	'搜索Amazon sku/FNSKU/ASIN/SKU': 'Amazon sku/fnskuを入力して下さい',
	下单中: '注文処理中、暫くお待ちください',
	无效的URL: '有効なURLを入力して下さい',
	请选择打印模板: '商品ラベルを選択して下さい',
	商品更新: '商品更新',
	最新价格为X元:
		'価格を更新された商品あり、最新の注文価格にX元，更新が完了しました。',
	请选择要翻译的语种: '言語を選択：',
	请输入直行便订单编号: '直行便注文番号を入力',
	发货标题: '納品タイトル',
	请设置商品附加项: '商品の検品用オプションを設定して下さい',
	通过点击关联符合您要求的sku: 'クリックで対象商品を紐付け',
	商品附加项要保存吗: '検品用オプションを保存しますか',
	签收成功: '荷物受取完了',
	已签收: '受取完了',
	API状态: 'API連携',
	请上传箱唛: '必須箱ラベルアップロード',
	箱唛文件: '箱ラベル',
	上传箱唛: '箱ラベルアップロード',
	修改箱唛: '配送ラベル変更',
	无法取消附加项: 'キャンセルできません',
	商品情报: '商品情報',
	箱番号: '箱番号',
	请上传装箱清单: '納品書をアップロードして下さい',
	乐天: '楽天',
	乐天sku: '楽天SKU',
	上传文件只能是X格式:
		'アップロードファイルが不正　「X」形式ファイルをアップロードして下さい',
	装箱明细: '納品詳細',
	档口手续费: '市場仕入れ手数料',
	出库手续费: '出庫手数料',
	店铺所在仓库: '店舗倉庫所在地',
	杭州仓: '杭州ルート',
	广州仓: '広州ルート',
	不需要检品: 'オプション必要なし',
	附加项验证: '商品オプションをご選択下さい',
	一键配置店铺配置商品附加项: '設定された商品オプションを利用',
	一键配置店铺配置发货附加项: '設定された発送オプションを利用',
	平台SKU: '直行便SKU',
	复制成功: 'コピー完了',
	'Amazon 库存': 'Amaozon 在庫',
	'Amazon 商品名称': 'Amazon 商品名',
	// 可用库存: '在庫あり',
	不可用: '販売不可',
	关联商品信息: '仕入れ先',
	仅良品数大于0的可创建国际发货单:
		'良品件数が1件未満の場合は、国際配送依頼を作成できません',
	'无法按拼团价进行采购，需按原价进行采购':
		'共同購入の買付期間内で購入条件に達していないため、通常購入価格で購入する必要があります',
	'我们会尽可能按拼团价为您购买，如无法按照拼团价购买，将会按照原价进行购买':
		'共同購入価格の指定条件に達しない場合、通常商品価格で購入します',
	'剩余XX天hh:mm:ss自动扣款': '自動引落まで後XX日hh:mm:ss',
	我的账单: '支払依頼書',
	待支付总额: '未支払額',
	账单名称: '取引内容',
	扣款说明: '詳細',
	应付总额: '支払金額合計',
	钱包金额: '钱包金额',
	主账户账单: '親アカウント支払依頼書',
	子账户账号: '店舗支払依頼書',
	编辑组合商品: 'セット組商品を作成',
	请选择订单: '注文未選択　注文を選択して下さい',
	统一编辑发货附加项: '一括発送オプション編集',
	'引き落としに成功した場合は、商品を発送いたします。':
		'引き落としに成功した場合は、商品を発送いたします。',
	'残高不足の原因で引落できなかった場合は、出荷作業を停止して別途支払のご案内をお送りいたします。':
		'残高不足の原因で引落できなかった場合は、出荷作業を停止して別途支払のご案内をお送りいたします。',
	'ご入金確認ができ次第、商品を発送いたします。':
		'ご入金確認ができ次第、商品を発送いたします。',
	'発送の影響を与えるないように、商品発送前にはシステム口座に十分な残高を用意しておいて下さい。':
		'発送の影響を与えるないように、商品発送前にはシステム口座に十分な残高を用意しておいて下さい。',
	'点击【确定】按钮，系统将自动从余额扣除当前账单<br />应付总额XX元，确定支付吗':
		'「確定」ボタンをクリックすると、システム口座からXX元を引き落としいたしますよろしいでしょうか',
	请输入拒绝原因: '拒否する理由を入力',
	请选择清关地址: '未設定　IMPORTER住所を設定してください ',
	请选择收货地址: '未設定　受取住所を設定してください',
	按原价采购: '通常購入価格で購入',
	拼团价: '共同購入価格',
	'商品采购的最终价格，部分渠道如拼多多存在拼团失败需要按原价购买的情况':
		'最終購入単価Pinduoduoでは条件に達しない場合、共同購入価格ではなく通常単価で購入する場合があります',
	最终采购价详情: '詳細',
	取消类型: 'キャンセル種類',
	客户取消: 'お客様都合キャンセル',
	系统取消: '直行便都合キャンセル',
	提交并下载: 'ダウンロート',
	请求对象姓名: 'お客様名',
	请求金额: '振込金額',
	点击下载: 'クリックしてダウンロード',
	请求书下载: 'デポジット振込申請書',
	请确认优先发货信息: '配送情報の詳細',
	'余额扣费汇率全部以：XX円 = 1元，进行换算，请确认后再进行支付':
		'為替レートは「XX円＝1元」で計算させて頂きます',
	编辑裁剪图片: '画像のサイズ変更',
	名称: '箱番号',
	实际重量: '実重量',
	体积重: '容積重量',
	长: '縦',
	宽: '横',
	高: '高さ',
	箱子: '段ボール箱',
	编辑剪切区域和移动图片: 'サイズ変更　画像を移動する',
	适合正方形logo: '正方形のデザインに適するログ',
	适合长方形logo: '長方形のデザインに適するログ',
	'目前该运输方式暂时无法显示物流状态，烦请自主到物流公司网址进行查询':
		'該当物流会社のステータスは表記できないため、お手数ですが、下記追跡URLにご確認くださいませ。',
	'每一个商品需要上传一个对应的标签文件，格式为PDF，参考如下':
		'PDF形式で各商品ラベルをアップロードして下さい',
	点击查看更多: '詳しい手順についてはこちらへご参照ください',
	'文件SKU和商品列表SKU不一致，请检查后重新上传':
		'アップロードデータは追加された商品SKUと異なり、ご確認頂いた上で、再度アップロードして下さい',
	商品条码上传方式: '商品SKUアップロード設定',
	所有商品一个文件上传: '全SKU一括アップロード',
	一个商品一个文件上传: 'SKUごとに１つファイルとしてアップロード',
	请上传SKU文件: 'ファイルをアップロードしてください',
	'客户资材必须得提前先下好，保存在仓库，否则无法对应':
		'【ご注意ください】 \n 資材は事前にご用意が必要なもので、在庫がないなら対応ができません。在庫ない場合は、まずOEM見積もり依頼を提出して下さい。',
	跳转外链操作步骤:
		'検索先のECサイトを指定→日本語キーワード入力→指定サイトで商品検索可能',
	slogon: 'もっと簡単 もっと透明',
	chrome插件: 'KAERU ツール',
	插件下载: '拡張機能へ追加',
	插件简介: '中国商品リサーチ 発注をもっと簡単に！',
	立即安装: '今すぐインストール',
	站内搜索: 'サイト内検索',
	外部搜索: '外部検索',
	请确认: '【ご確認下さい】',
	'在X系统中追加购买商品的购物车，正在选择中的系统是否符合顾客的意向，请再次确认':
		'Xシステム で買付商品のカート追加が実行されました \n 選択中のシステムがお客様のご意向に沿っているか、再度ご確認下さい',
	d2cb2b系统介绍: 'D2C / B2B システムの補足：',
	系统说明: `D2Cシステム：1点からエンドユーザーへ直送する業務をサポート
  B2Bシステム：在庫を保持し国内EC関連倉庫や自社倉庫へ納品する業務をサポート
  Topメニュー 右側 B2B / D2C アイコンをクリックでシステム切替可能`,
	'系统说明ps(X元Y円)':
		'D2Cシステムは小口配送前提です。1配送単位の商品代金がX元（約Y円）以上の場合、B2Bシステムをご利用下さい',
	会员条约: '会員規約',
	使用条约: '利用規約',
	ホーム: 'ホーム',
	会社情報: '会社情報',
	隐私权政策: 'プライバシーポリシー',
	解除: '解消する',
	商品类目: 'カテゴリー',
	操作取消: '操作を取り消し',
	仅限输入英文字符: '英字のみで入力して下さい',
	仅限输入英文和数字字符: '英数字のみで入力して下さい',
	无效的邮编: '有効な郵便番号を入力して下さい',
	国际物流配置id不能为空:
		'未入力項目あり   運送会社と重量を両方入力してから、計算して下さい',
	'输入的支店名格式不正确，请设置1-20位字符':
		'支店名入力誤り 支店名は、1~20文字以内で入力して下さい',
	销售额取值范围: 'ご注文期間',
	返现类型: '還元方式',
	返现: '固定金額',
	返现2: '還元金額',
	返点: '還元率',
	销售额区间: 'ご注文合計額帯',
	'销售额X%': 'ご注文合計額X%還元',
	为期: '付与計算期間',
	X个月: 'Xか月',
	返现发放时间: '現金付与時期',
	每月X日自动返: '毎月X日に自動付与',
	每季度自动返: '四半期毎に自動付与',
	每半年自动返: '半年毎に自動付与',
	订单是否结算: '清算状況',
	发货取消结果: '配送キャンセル状況',
	查看异常联络处理记录: '処理履歴へ',
	结算时应退款金额: '返金額',
	'商品代金，订单结算后解冻至账户余额':
		'ご注文清算が完了後、キャンセルされた商品の(仮払)商品代金・商品オプション費用がシステム残高に返金されます',
	取消原因: 'キャンセル原因',
	'お客様申請(発送キャンセル済)': 'お客様申請(発送キャンセル済)',
	'お客様申請(発送キャンセル済・在庫保有)':
		'お客様申請(発送キャンセル済・在庫保有)',
	異常注文処理履歴: '異常注文処理履歴',
	在庫切れ: '在庫切れ',
	お客様申請: 'お客様申請',
	取最小注文ロット数あり消原因: '最小注文ロット数あり',
	查看处理详情: '対応詳細確認へ',
	主: '親口座',
	子: '店舗口座',
	'如果您想定制其他复杂产品，比如服饰杂货等，请升级商务舱，我们会有专门的客户经理单独一对一和您对接。': `資材以外（アパレル、雑貨など）のOEMサービスをご利用したい場合は、会員プランにてビジネスクラスにご変更ください。
資材より、資材以外のOEM製品の開発流れは長くて複雑です。
ビジネスクラスに対して、OEM経験が豊かな専任スタッフを付き、もっと細かく効率的な対応ができますので、ご興味のある方は、ぜひご検討の程よろしくお願いいたします`,
	模板效果预览: 'テンプレート閲覧',
	检品标准: '検品標準一覧',
	取消尺码变更: 'キャンセル',
	'填写尺码 如：Ｆ，XS，S，M，2XL等':
		'変更後のサイズをご入力　例：Ｆ，XS，S，M，2X',
	月销售额区间: '月销售额区间',
	活动有效期: '活动有效期',
	次品退货退款并付费重拍: '交換対応',
	'以后的商品为次品时，请都默认处理为次品退货退款并付费重拍。':
		'不良品対応設定：\n今後不良品が発生した場合は、すべて「不良品返品返金、有料で再発注する」の方式で対応する',
	'因原商品质量不佳，我们为您重拍时自动寻找了以下新链接，但新链接价格不符合您的预期，请您确认':
		'指定ショップURL　不良品率高\n交換しても不良品改善の可能性なし（ロット生産のため）\n類似商品の代替ショップで買付可能\n→購入単価上限を超過のため、買付可否をご確認下さい，代替ショップURL：urlLink単価：X元',
	'因原商品质量不佳，我们为您自动寻找了以下新链接，请您确认是否需要再新链接下重拍':
		'指定ショップURL　不良品率高\n交換しても不良品改善の可能性なし（ロット生産のため）\n類似商品の代替ショップで買付可能，代替ショップURL：urlLink単価：X元',
	'以后的商品为次品时，请都默认处理为原商品退货退款并取消商品采购。':
		'不良品対応設定：\n今後不良品が発生した場合は、すべて「不良品返品返金、買付キャンセル」の方式で対応する',
	确认颜色: '指定カラーでの買付不可\n購入カラーを再度ご確認下さい',
	确认款式: '指定デザインでの買付不可\n購入デザインを再度ご確認下さい',
	全网断货: '指定URL在庫切れ\nキャンセル推奨',
	'原商品缺货-推荐同款-价格不符合客户预期':
		'指定URL在庫切れ　代替ショップ買付可能\n→購入単価上限を超過のため、買付可否をご確認下さい，代替ショップURL：urlLink単価：X元',
	'原商品缺货-推荐类似款':
		'指定URL在庫切れ　代替ショップ買付可能，代替ショップURL：urlLink単価：X元',
	'预售商品-推荐同款商品':
		'当該商品は予約注文となり、X日後の出荷予定\n即日出荷可能な代替ショップあり，代替ショップURL：urlLink',
	'预售商品-无同款商品推荐': '当該商品は予約注文となり、X日後の出荷予定',
	'不满足最小起量-推荐同款商品':
		'最小注文ロットを満たしていません\n最小ロット：X件　注文数：Y件\n※指定注文数で購入可能な代替ショップあり，代替ショップURL：urlLink',
	'不满足最小起量-无同款商品推荐':
		'最小注文ロットを満たしていません\n最小ロット：X件　注文数：Y件',
	'更换同款链接-价格不符合客户预期':
		'指定ショップURL　不良品率高\n交換後も不良品改善が見られません\n代替ショップでの買付推奨，代替ショップURL：urlLink',
	颜色差异:
		'商品の色差あり\n交換しても不良品改善の可能性なし（ロット生産のため）\n※問題商品数が多く、納期遅延の可能性大',
	logo差异:
		'Logoが違い\n交換しても不良品改善の可能性なし（ロット生産のため）\n※問題商品数が多く、納期遅延の可能性大',
	'材质、面料差异':
		'生地が違い\n交換しても不良品改善の可能性なし（ロット生産のため）\n※問題商品数が多く、納期遅延の可能性大',
	配件差异:
		'付属品が違い\n交換しても不良品改善の可能性なし（ロット生産のため）\n※問題商品数が多く、納期遅延の可能性大',
	商品上有中文:
		'中国語表記\n交換しても不良品改善の可能性なし（ロット生産のため）\n※問題商品数が多く、納期遅延の可能性大',
	尺码差异:
		'サイズが違い\n交換しても不良品改善の可能性なし（ロット生産のため）\n※問題商品数が多く、納期遅延の可能性大',
	设计差异:
		'デザインが違い\n交換しても不良品改善の可能性なし（ロット生産のため）\n※問題商品数が多く、納期遅延の可能性大',
	其他不良:
		'不良理由：その他\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	'污渍/斑点/水印':
		'不良理由：汚れ・シミ・水シミあり\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	破损: '不良理由：破損あり\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	线头: '不良理由：糸くず・ほつれあり\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	接缝不良:
		'不良理由：接缝不良\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	抽丝: '不良理由：伝線あり\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	针眼: '不良理由：針の穴あり\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	霉菌: '不良理由：カビあり\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	生锈: '不良理由：サビあり\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	商品有中文:
		'不良理由：中国語表記\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	纽扣破损:
		'不良理由：ボダン破損あり\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	拉链破损:
		'不良理由：ファスナー破損あり\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	附属品缺陷:
		'不良理由：付属品欠陥\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	有气味: '不良理由：匂いあり\n検品基準により、不良品と判断されました\n不良品発生時の対応をご指定下さい',
	'原URL缺货，新的URL内商品是X元，请确认':
		'指定URL在庫切れ　代替ショップ買付可能\n→購入単価上限を超過のため、買付可否をご確認下さい，代替ショップURL：urlLink単価：X元',
	请在我指定的新链接购买: '以下ショップで買付希望URL：',
	取消商品采购: '買付キャンセル',
	'取消商品采购+商品发送': '買付およびエンドユーザ発送をキャンセル',
	请在我指定的新链接下采购: '以下ショップで買付希望URL：',
	请在直行便为我推荐的此链接下采购: '代替ショップURLで購入',
	'可以等待，请继续在原链接采购':
		'指定ショップURLで購入\n→発送待ち時間を許容',
	'我接受起订量，请继续在原链接采购':
		'最小ロット数に注文数量を変更し元ショップで購入\n(残った在庫は、一旦直行便倉庫に保管され、新注文で利用される予定です)\n→最小ロット数を許容',
	'我接受起订量，请继续在原链接采购（多采部分商品无异常上架后将作为库存在直行便仓库代为保管，并可匹配其他直发订单发出）':
		'最小ロット数に注文数量を変更し元ショップで購入\n(残った在庫は、一旦直行便倉庫に保管され、新注文で利用される予定です)\n→最小ロット数を許容',
	'问题不大，我能接受，请当成良品入库':
		'上記の商品問題を許容し、良品として入庫',
	'此问题我不能接受，请依然当成不良品处理':
		'色差を許容できず不良品として返品返金し、買付キャンセル',
	'我有可能有货的采购链接，请在这个链接再尝试购买':
		'以下ショップで買付希望URL：',
	'我等不及，请在我指定的新链接下采购':
		'以下ショップで買付希望\n→発送待ち時間を許容できずURL：',
	'我等不及，请在直行便为我推荐的此链接下采购':
		'代替ショップURLで購入\n→発送待ち時間を許容できず',
	'我不接受起订量，请在我指定的新链接下采购':
		'以下ショップで買付希望\n→最小ロット数を許容できずURL：',
	'我不接受起订量，请在直行便为我推荐的此链接下采购':
		'代替ショップURLで購入\n→最小ロット数を許容できず',
	'直行便推荐的链接价格等不合适，请在我指定的新链接下采购':
		'以下ショップで買付希望\n→代替ショップURLの価格を許容できずURL：',
	'此问题我不能接受，请依然当成不良品处理，并在我指定的新链接购买':
		'上記の商品問題を許容できず不良品として返品返金し、以下ショップで買付希望',
	我还是要继续在原链接采购: '指定ショップURLで購入\n→不良品率を許容',
	取消商品重拍: '再買付キャンセル',
	'取消商品重拍+商品发送': '再買付およびエンドユーザ発送をキャンセル',
	次品退货退款并取消商品采购: '不良品として返品返金し、買付キャンセル',
	'次品退货退款并取消商品采购+商品发送':
		'不良品として返品返金し、買付およびエンドユーザ発送をキャンセル',
	确定后将按照新的货代进行发货:
		'「確定」ボタンを押すと、変更後の運送会社で出荷いたします。\nよろしいでしょうか',
	'确定后将取消您的国际发货单，您需重新创建':
		'キャンセルを実行すると、該当国際配送依頼をキャンセルされ、再度国際配送依頼を作成する必要があります。\nキャンセルを実行しますか',
	关闭后将不再向您店铺同步物流单号及订单状态:
		'追跡番号の自動反映をオフにすると、自社サイトへの国際追跡番号・発送状況の自動反映が停止されます\n追跡番号の自動反映を解除しますか',
	关闭提醒: 'ご確認下さい',
	批量搜索: '一括検索',
	'每行一个（支持excel复制黏贴）': `1行に1つずつをご入力、Enterキーを押して改行する
        Excelデータを直接ペースト可能
        ※最大100件まで検索できます`,
	双击批量搜索: 'ダブルクリックで一括検索',
	选择类似款: '代替ショップ買付',
	'此问题我不能接受，请依然当成不良品处理，并取消商品采购':
		'上記の商品問題を許容できず不良品として返品返金し、買付キャンセル',
	'此问题我不能接受，请依然当成不良品处理，并取消商品采购+商品发送':
		'上記の商品問題を許容できず不良品として返品返金し、買付およびエンドユーザー発送をキャンセル',
	后台导入: '移行されたデータ',
	确认重拍: '交換対応',
	$直行便通用纸箱说明$:
		'国際配送向けの高強度で丈夫な段ボール箱を使う、高質で在庫も十分',
	$直行便发货袋说明$:
		'国際運送向けの高強度で丈夫な発送袋を使う、高質で在庫も十分',
	$厂家原箱说明$:
		'破損、国際発送強度に適さない可能性があり、出荷スピードに影響するリスクあり',
	纸箱类附加项必须选择一种:
		'下記の中から、ご希望の段ボール箱をいずれか１つを選択する必要があります',
	和日本工作人员在Line上商量:
		'ビジネス以上に変更したい\n日本人スタッフとLineで相談',
	取消发货: '発送キャンセル',
	'同意一个包裹无法装下商品时，直行便将为您拆成多个包裹进行发货':
		'1梱包重量が小口輸入規定を超した場合、ご注文の商品を分割して発送させて頂きます',
	'由于该订单商品无法在一个包裹中装下，我们为您拆成了多个包裹进行发货':
		'1梱包重量が小口輸入規定を超したため、ご注文の商品を分割して発送致しました',
	拆: '自動分納',
	优: '分納',
	下单提醒: 'ご注意',
	下次不再提醒: '次回から表示しない',
	采购来源: '仕入れ元',
	人气购物网站: '人気通販サイト',
	'发现海量中国商品，助您更简单，更透明地下单购物！':
		'より多くの商品を見つけることができ、中国仕入れをもっと簡単に！',
	使用tips: '使い方',
	'1.指定采购来源': '1.検索先の仕入れ元を指定',
	'2.选择商品类目或输入关键字': '2.日本語キーワード入力＆カテゴリー選択',
	'3.即可前往指定采购来源站点搜索相关商品':
		'3.指定仕入れ元のサイトで商品検索可能',
	失效: '有効期間切れ',
	'很抱歉！您的店铺（X）因token失效需重新绑定，如需帮助请联系客服':
		'API連携失効（X）連携を解除して再連携をして下さい',
	所选日期区间长度不可超过X天: '開始日を含めX日以内の日付しか選択できません',
	能够在中国找到丰富的供应商: '豊富な中国仕入れルートを開拓可能',
	'按照日本质量标准进行检验（隶属于日本公司）':
		'日本品質基準で検品可能(日本企業提携）',
	'最低国际运费保证，可运往23个国家/地区':
		'国際送料業界最安保証、23カ国·各地域へ発送可能',
	可靠的支持和即时的客户服务: '即時対応のカスタマーサービスで安心サポート',
	我的会员: 'マイプラン',
	最低: '最安',
	会员规则变更通知: '会員プラン改定のお知らせ',
	'尊敬的用户，直行便团队经过谨慎考虑，为了更好的为用户提供服务，决定在经历了一段时间的免费运营后，于2023/1/11开始只对付费会员提供服务。':
		'THE直行便をご利用頂き、誠にありがとうございます。\nTHE直行便ではシステム品質の向上を図り、お客様にご満足頂けるサービス提供のため、\n誠に勝手ながら、以下の通り「0円会員プラン」の有料化を行うこととなりました\n急な変更となり誠に申し訳ございませんが、ご理解の程宜しくお願いいたします\n変更日：<span style="color:#eb4545">2023/1/11</span>\n変更内容：<span style="color:#eb4545">エコノミープラン　0円　→ 1980円　/月額</span>\n※有料のエコノミープランへ自動アップデート済（有効期間：<span style="color:#eb4545">2023/01/31まで</span>）',
	'您也不用担心，您是陪伴我们成长的首批用户，我们已为您自动升级到新会员，并且免费送您会员到1月31日（原价1980日元/月)！赶快去试用体验吧！':
		'プラン有料化に伴う主な変更点及び、お客様にご協力をお願いしたい事項は以下となります\n＜サービス変更点＞\n★エコノミープラン　買付手数料（5％）→無料★\n★2023年1月中　会員費無料　＆　Lineお友達登録で更に30日間会員費無料★\n＜システム機能の変更点＞※今後順次追加予定\n★携帯専用アプリのサービス開始★　2023年3月\n★新たなシステム連携先追加（ヤフショ、メルカリ、Stores　など）★　2023年\n★新たな中国買付ルート追加★　2023年',
	'到期后会员将失效，将无法享受下单服务，欢迎随时充值新会员。':
		'＜ご協力頂きたい事項＞\n新エコノミープランの無料期間以降（無料期間：〜2023/01/31）、お客様ご自身で変更後プランの選択をお願いします\n※新プラン選択を行わない場合、システム利用が不可となります。ご注意下さい。',
	新会员权益介绍: '＞詳細はこちら ',
	开通新会员: '新会員プラン変更へ',
	查看新会员: '新会員プラン一覧へ',
	'开通会员，立享下单代买服务。请联系主账号开通。':
		'新規会員登録で中国仕入れが可能！※会員登録は親アカウントから操作して下さい',
	'开通会员，立享下单代买服务': '新規会員登録で中国仕入れが可能！',
	'开通会员，即可享受下单代买服务。': '新規会員登録で中国仕入れが可能！',
	'请联系主账号开通。': '※会員登録は親アカウントから操作して下さい。',
	'即可享受下单代买服务。': '即可享受下单代买服务。',
	'有效期：': '会員資格終了日：',
	已失去会員: '会員資格停止中',
	未登録: '未登録',
	未登录: 'ログアウト状態',
	'会员等级变更！': '会員プラン変更完了のお知らせ',
	您当前的会员等级已变更为V: 'ご利用プランがVに変更されました',
	立即享用会员: 'システムTOPへ',
	'您的会员已到期！': '会員資格期限切れのお知らせ',
	'您当前的会员已到期，为了不影响正常下单和使用，请及时开通哦！（不影响历史订单集账户余额充值）':
		'選択中会員プランの期限が切れています、極力早めの会員費チャージ手続きをお勧め致します。',
	查看会员: '会員プラン一覧へ',
	// 新会员
	'直行便会员，为您的买卖生活提供一切所需。':
		'THE直行便会員プラン，中国仕入れをもっと簡単に！',
	入会优享超多服务:
		'会員登録で、中国輸入を簡単に行える様々なサービスをご利用可能！',
	开通会员: '会員登録へ',
	有效期至: '会員資格終了日',
	自动续费已开启: '会員費自動支払ON ',
	自动续费已关闭: '会員費自動支払OFF',
	成为: 'に変更予定', // xxxx 成为xxx会员
	去续费: '会員資格を延長する',
	当前自动续费不可开启: '会費の自動引き落とし設定ができません',
	'您在持有预充会员【XX】期间，暂不可开启自动续费。在该预充会员正式开启后，我们会自动为您开启会员的自动续费，敬请谅解。':
		'【XX】プラン変更を予約済のため、会費の自動引き落とし設定ができません\n該当プランが有効化されましたら、自動で会費の自動引き落としをオンにします',
	知道了: '閉じる',
	'请及时关闭PayPal账户内不需要的自动续费，如未关闭，我们会将这笔钱充值到您的余额，可能需要您承担部分手续费。':
		'Paypalの自動支払い設定済で会員プラン更新を行う場合、以前のPaypal自動支払いの解除を行なって下さい。\n解除されない場合、自動で費用チャージが実施されます。\n誤った費用チャージ分の返金は返金手数料のご負担をお願いするのでご注意下さい。',
	关闭PayPal自动续费教程: '自動支払いの解除マニュアルへ',
	关闭paypal自动续费教程: '自動支払いの解除マニュアル',
	续费提示: '会員資格延長に関するお知らせ',
	'续费后，会在XX开始自动延长YY时间':
		'支払完了すると、選択済プランの会員YYの有効期限はXXから延長されます',
	'检测到您当前有T1至T2的预充会员，续费成功后，会取消原来预充的会员，预充会员的<span style="color:#EB4545;">钱款不予退还</span>。':
		'お客様は既に【V】プラン（有効期間：T1〜T2）変更を予約済のため、プラン更新は実施しないで下さい。\n万が一、プラン更新された場合、予約済の【V】は無効となり<span style="color:#EB4545;">事前支払済費用の返金不可となります</span>。',
	升舱提示: '会員プランアップに関するお知らせ',
	'您当前为V1，想开通V2，您想选择何时生效？开通成功后，选择将不可更改':
		'現プランはV1です。V2に変更する場合は、今すぐV2を有効にする \nORV1の有効期間終了後にV2を有効にするかを選択できます。',
	'检测到您当前有T1至T2的预充会员，降仓成功后，会取消预充会员，预充会员的<span style="color:#EB4545;">钱款不予退还</span>。':
		'お客様は既に【V1】プラン（有効期間：T1〜T2）変更を予約済のため、プラン更新は実施しないで下さい。\n万が一、プラン更新された場合、予約済の【V1】は無効となり<span style="color:#EB4545;">事前支払済費用の返金不可となります</span>。',
	降舱提示: '会員プランダウンに関するお知らせ',
	'续费后，会在T开始自动延长V时间':
		'支払完了すると、選択済プランの会員V終了日がTから延長されます。',
	'您当前为V1，想开通V2，为保障您当前的会员权益，会等原会员套餐到期（T）后开始实行降仓会员，是否要进行降仓？':
		'現プランはV1です。V2に変更する場合は、V1の有効期間終了後にV2を有効にします。',
	'检测到您当前有2段会员，升舱成功后，会取消当前会员和预充会员，<span style="color:#EB4545;">钱款不予退还</span>。':
		'会員プラン変更すると、【V1】と【V2】会員資格は無効となります。<span style="color:#EB4545;">会員費の返金はできませんので、予めご了承下さい。</span>',
	'检测到您当前有T1至T2的预充会员，升舱成功后，会取消预充会员，预充会员的<span style="color:#EB4545;">钱款不予退还</span>':
		'お客様は既に【V】プラン（有効期間：T1〜T2）変更を予約済のため、プラン更新は実施しないで下さい。\n万が一、プラン更新された場合、予約済の【V】プランは無効となり<span style="color:#EB4545;">事前支払済費用の返金不可となります</span>。',
	立即生效: '今すぐ有効',
	开始生效: 'から有効化',
	定时升舱: 'プランアップ予約',
	利用期间: '申込月数',
	'%off': '%割引',
	'折合X円/月': '割引後価格 X円/月',
	已成功开通预充会员: '会員費支払完了',
	已成功开通会员: '会員費支払完了',
	'已为您开启预充会员V，有效期为Y':
		'Vの会員費支払が完了しました\n有効期間：Y',
	'已为您开启V，有效期为Y': 'Vの会員費支払が完了しました\n有効期間：Y',
	'为了不影响您的后续使用，请保持PayPal内开启自动续费。':
		'PayPalでの自動支払設定を推奨しています、未登録の方は是非設定を！',
	已优惠: 'X円OFF',
	确认协议并立即支付: '規約に同意してお支払いへ',
	请阅读: '',
	会员服务协议: '会員利用規約',
	及: '',
	自动续费服务规则: '自動支払規約', // 整句话 请阅读“会员服务协议”及“自动续费服务规则”
	高级会员专享服务: 'Lineでのご相談',
	'如需开通商务舱或头等舱会员，请先用line扫描右侧二维码联系我们，获取专属咨询服务。':
		'※スタンダードクラス会員以上の利用者様。以下、QRコードをスキャンして相談可能。',
	恭喜成功开通: 'のご利用ありがとうございます。',
	'为了更好地帮您履行下单履约，您需要提前填写以下信息。':
		'注文に関する対応を円滑に進めるため、以下ショップ設定を事前にご対応下さい。',
	添加店铺: '店舗追加',
	采购设置: '仕入れ設定',
	国际配送设置: '国際配送設定',
	暂不设置: '今は設定しない ',
	保存设置: '保存',
	请填写完成国际配送设置: '国際配送設定を行って下さい',
	请填写完成采购设置: '仕入れ設定を行って下さい',
	请填写完成店铺信息: '店舗情報を入力して下さい',
	去开通: 'お申し込み',
	是否要关闭自动续费: 'ご確認下さい',
	'关闭自动续费后，您的V将于T到期。关闭后会影响您后续的正常使用，请谨慎关闭。是否现在关闭？':
		'会員費自動支払を解除すると、現会員プランVの利用がTに終了します。',
	'为了能正常关闭自动续费，请务必前往paypal关闭自动续费。':
		'※会員プラン変更を行う場合、Paypalの自動支払い設定の解除を行なって下さい。\n解除されない場合、自動で費用チャージが実施されます。\n誤った費用チャージ分の返金は返金手数料のご負担をお願いするのでご注意下さい。',
	'已为您开启V自动续费，记得保持余额充足。':
		'口座からの自動引落設定を行なった場合：\n残高不足で自動支払を失敗した場合は、発注不可となるため、口座に十分な残高をご準備下さい。',
	'为了能正常打开自动续费，请务必前往PayPal开启自动续费。':
		'PayPal自動支払を選択した場合：\npaypal公式サイトで自動支払いを設定して下さい。',
	支付金额: '合計額',
	'尊敬的用户，您需办理会员后，方可下单，请联系主账号办理':
		'発注機能を利用する場合、まず会員登録を行っていただく必要があります\n<b>親アカウントから会員登録をして下さい</b>',
	办理会员: '会員登録へ',
	'尊敬的用户，您于T变更为非会员用户，我们已发站内信告知您：开启自动同步下单功能及按钮已关闭（变为非会员后，不影响历史订单及账户余额提现）':
		'Tより会員資格が停止されましたため、APIの自動発注機能を一時的に停止しました（既に対応された注文、および残高の引出しには影響がありませんのでご安心下さい）\n詳しくはシステム内のメッセージからご確認お願い致します。',
	'尊敬的用户，您需办理会员成功后，方可同订单数据':
		'注文取込機能を利用する場合、まず会員登録を行っていただく必要があります',
	'尊敬的用户，您需办理会员成功后，方可开启自动同步下单，请联系主账号办理':
		'自動発注機能を利用する場合は、まず会員登録を行っていただく必要があります\n<b>親アカウントから会員登録をして下さい</b>',
	'尊敬的用户，您需办理会员成功后，方可开启自动同步下单':
		'自動発注機能を利用する場合は、まず会員登録を行っていただく必要があります',
	'尊敬的用户，您于T变更为非会员用户，已授权的API被禁用，需办理会员成功后，方可同步订单数据，请联系主账号办理':
		'Tより会員資格が停止されましたため、連携済状態のAPI機能が利用出来なくなりました。継続利用を希望する場合は、まず会員登録を行っていただく必要があります\n<b>親アカウントから会員登録をして下さい</b>',
	'尊敬的用户，您需办理会员后，方可授权绑定子店铺，请联系主账号办理':
		'API連携機能を利用する場合、まず会員登録を行っていただく必要があります\n<b>親アカウントから会員登録をして下さい</b>',
	'尊敬的用户，您需办理会员成功后，方可授权绑定子店铺':
		'API連携機能を利用する場合、まず会員登録を行っていただく必要があります',
	授权店铺API绑定变更: 'API連携状態変更のお知らせ',
	'授权店铺API绑定变更尊敬的用户，您于T变更为非会员用户，已授权店铺绑定的API变更为禁用状态（不影响历史订单及账户余额提现）':
		'API連携状態変更のお知らせ\nTより会員資格が停止されましたため、連携済状態のAPI機能が利用出来なくなりました（既に対応された注文、および残高の引出しには影響がありませんのでご安心下さい）',
	'已授权（禁用）': '連携済（利用不可）',
	'円/月': '円/月',
	绑定信用卡支付: 'クレジットカードを追加',
	你还没有绑定信用卡: '現在利用できるカードがございません',
	有効期限: '有効期限',
	请选择信用卡: 'クレジットカードを選択して下さい',
	'是否删除信用卡？': 'このクレジットカード情報を削除しますか？',
	信用卡卡号: 'クレジットカード番号',
	信用卡安全码: 'セキュリティコード',
	仅限数字: '数字のみ入力可能',
	仅限输入3位数字安全码: '3桁の数字のみ入力可能',
	'仅限数字，例：05/2022': '数字のみ入力可能　例：05/2022',
	请检查卡信息是否有误: 'クレジットカード情報が正しいかを確認して下さい',
	'支付失败，请重新操作！': '支払い失敗、再度お試し下さい',
	paypal信用卡: 'クレジットカード',
	充值失败: 'チャージ失敗',
	请联系主账号更改: '親アカウントから操作して下さい',
	已为您开启自动续费: '会員費の自動支払を ON にしました',
	已失去会员: '会員資格停止中',
	未入会: '会員未登録状態',
	设为默认支付方式: 'デフォルトのお支払い方法に設定する',
	支付失败: '支払失敗のお知らせ',
	'余额不足，请先充值余额后重试。':
		'口座残高不足、月額会員費の資金チャージをお願いします。',
	每次询问我处理次品方式: '都度対応方式を確認する',
	'尊敬的用户，您需办理会员后，方可下单':
		'発注機能を利用する場合、まず会員登録を行っていただく必要があります',
	会費: '会費',
	PayPal加载中: 'PayPalロード中',
	'余额不足，请先绑定店铺后充值，再重试':
		'口座残高不足、店舗追加し資金チャージしてから、再度お試し下さい',
	'信用卡-由PayPal提供，支持Visa、MasterCard、AMEX、JCB等类型':
		'VISA、MasterCard、AMEX、JCBブランドのクレジットカードをご利用頂けます',
	'您可以在顶部左侧切换/添加店铺，以及在右侧切换业务类型。':
		'店舗追加、店舗切替が可能です，システムの仕入れ方法を切替可能です',
	'B2B：向日本仓库囤货 (大宗囤货)': 'B2B：日本倉庫へ納品(大口配送)',
	'D2C：向用户直接送货 (小件送货)': 'D2C：エンドユーザー直送(小口配送)',
	'您可以在这里搜索到优质低价的海量中国商品。':
		'商品キーワード/URLを入力して、より多くの商品を検索可能です。',
	'您可以在这里直接前往源头站点搜索低价好物，也可以在本地站点搜寻更低价同款好物。':
		'中国の仕入サイトへジャンプ可能です。',
	'您可以在“工作台”查看和管理您的订单、商品、资金等等。':
		'アカウントに関連する注文、商品、資金情報などが表示されます。',
	'点击“去挖更多”浮窗，即可前往源头站点搜索更多低价好物。':
		'気に入り商品が見つからない場合、ここをクリックすると、指定条件を元に仕入れサイトでより多くの商品を検索可能です。',
	'点击“加入购物车”，即可添加商品到本平台购物车。':
		'当該商品を直行便カートに追加可能です',
	'点击“日语搜索”浮窗，即可调起日文搜索框。':
		'ここをクリックすると、日本語検索ボックスを開く可能です。',
	已开启: 'オン',
	复制: 'コピー',
	直行便SKU: '直行便SKU',
	'目前的app版本，异常订单只支持查看，有未处理异常订单的情况，请至pc端处理。':
		'現状、アプリ版は異常注文の閲覧のみ可能で、未対応の異常注文がある場合はパソコン側で処理して下さい。',
	查看商品: 'もっとみる',
	收起: '閉じる',
	お気に入り店舗はありません: 'お気に入り店舗はありません',
	'目前的app版本，订单记录只支持查看，订单取消、优先发货等操作功能请至pc端处理。':
		'現状、アプリ版は注文履歴の閲覧のみ可能で、注文のキャンセル、優先配送の設定などはパソコン側で処理して下さい。',
	'追跡URL：': '追跡URL：',
	'元/发货单': '元/送り状',
	'次品退货退款并付费重拍（本次重拍费用X円）':
		'交換対応（有料、今回の交換手数料はX円）',
	请选择sku: 'skuを選択してください',
	'输入的密码格式不正确，请设置6-32个字符，字母和数字的组合，字母区分大小写':
		'パスワード入力誤り，パスワードは、6~32文字の英数字混在で設定して下さい（英字は大文字、小文字を識別しますのでご注意下さい）',
	加购提示:
		'以下の商品は、お取り扱いができませんのでご了承下さい。 \n不明確な場合は仕入の前に、都度確認をお願い致します。 \n①著作権、特許権を侵害するもの \n②麻薬、向精神薬、大麻、あへん、けしがら、覚醒剤 \n③けん銃、小銃、機関銃、砲、これらの銃砲弾及びけん銃部品 \n④爆発物 \n⑤火薬類 \n⑥貨幣類',
	自动续费: '自動支払',
	折合: '割引後価格',
	该商品不可代购: '仕入れ不可',
	确认协议并支付: '利用規約に同意してお支払いへ',
	'去登录(仅限内测用户)': 'ログイン(内部テストユーザーのみ)',
	'非常抱歉，本App仅对内侧用户开放。如有需要，请联系客服':
		'バージョンアップのため、一部のユーザー限定公開しております事前体験したい方、カスタマーサービス窓口にご連絡ください',
	系统升级中: '現在バージョンアップ中',
	'支持输入直行便订单编号、客户订单编号、shopify订单编号':
		'直行便注文番号、お客様注文番号、Shopify注文番号を入力可能',
	'点击输入平台商品SKU、商品名称': 'クリックで直行便SKUと商品名を入力',
	异常订单搜索: '異常注文検索',
	一键搜索: '一括検索',
	'支持输入直行便订单编号、客户订单编号、shopify订单编号、平台商品SKU':
		'直行便注文番号、お客様注文番号、Shopify注文番号、直行便SKUを入力可能',
	问题处理状态: 'ステータス',
	点击选择: 'クリックで選択',
	'问题处理(未处理)': '異常注文履歴（未対応）',
	'问题处理(已处理)': '異常注文履歴（対応済み）',
	'商品取消履历(取消发货任务记录)': 'キャンセル履歴（発送キャンセル履歴）',
	'商品取消履历(取消采购记录)': 'キャンセル履歴（買付キャンセル履歴)',
	'支持输入直行便订单编号、订单标题':
		'直行便注文番号、注文タイトルを入力可能',
	'点击输入平台商品SKU、客户商品SKU、商品名称':
		'クリックで直行便SKU、お客様商品SKU、商品名を入力',
	'支持输入平台商品SKU、直行便订单编号、客户订单编号':
		'直行便SKU、直行便注文番号、お客様注文番号を入力可能',
	'支持输入订单标题、平台商品SKU、直行便订单编号':
		'注文タイトル、直行便SKU、直行便注文番号を入力可能',
	详细说明和上传图片: '詳細、画像アップロード',
	'补充说明，有助于我们更好地帮您处理售后问题':
		'補足説明を追加する場合には、アフター補償申請をより早く対応することができます',
	不可申请取消: 'キャンセル出来ません',
	当前状态用户不可发起申请取消: 'クレジットカード（PayPalによるサービス提供',
	'信用卡(PayPal提供)': 'クレジットカード \n(PayPalによるサービス提供)',
	PayPal支付: 'PayPal',
	添加信用卡: 'クレジットカードを追加',
	请稍后重试: '接続エラー　しばらくしてからもう一度お試し下さい',
	PayPal信用卡管理: 'PayPalクレジットカード管理',
	操作成功: '成功しました',
	请输入金额: '送金の金額をゼロ以上にしなればなりません',
	可手动修改充值金额: '手動で振込金額を修正可能',
	优惠汇率: '割引レート',
	今日汇率: '本日の為替レート',
	取消支付: '支払取消',
	售后数量大于下单数量: '申請数量が注文数より多い',
	搜索内容不能为空: '検索内容を入力して下さい',
	已拆包: '分割発送済み',
	当前状态不可申请售后: 'アフター補償申請不可',
	'当前商品不是待发货状态，不允许优先发货':
		'分納発送不可  配送待ち商品ではなく処理できません',
	没有可取消商品: 'キャンセル可能商品なし',
	表格下单: 'Excel一括注文',
	文件大小不超过10M: 'アップロードできるファイルサイトは10MBまでです',
	点击前往PayPal支付: 'PayPalの決済画面へ',
	'「Excel一括注文」相关功能请前往pc端操作。':
		'「Excel一括注文」をご利用頂く場合、下記のリンクをコピーしてパソコンから操作してください',
	'该页为第三方登录页。登录后，即可正常浏览第三方商品详情页，以及正常使用直行便的加购功能。':
		'外部サイトのログイン画面になります。ログイン後、該当サイトの商品検索、閲覧、直行便カートに追加することなどが可能',
	不写区号: 'その他',
	请输入有效的电话号码: '有効な電話番号を入力して下さい',
	语言: '言語',
	简体中文: '简体中文',
	日本語: '日本語',
	正在设置语言: '正在设置语言...',
	'商品详情加载中，请稍后再试': 'データ読込中、 少々お待ちください',
	当前等级: 'マイプラン',
	未开通: '未登録',
	钱包余额不足: '口座残高不足，資金をチャージ後、再実行をお願いします',
	添加商品失败: '追加購入失敗',
	'注册或登录后，将为您推荐更多个性化内容。':
		'新規登録 OR ログインして、お客様の好みに合うコンテンツを確認可能',
	邀请码: '招待コード',
	请输入邀请码: '招待コードを入力',
	'邀请码无效，请重新输入': '該当招待コードに誤りがあります',
	'欢迎来到THE CKB！': 'ザ・直行便へようこそ',
	恭喜您获得: 'おめでとうございます。',
	'会员免费试用！':
		'X日間 の【Y】メンバーシップ無料トライアルを獲得しました。',
	关闭自动续费问卷: '关闭自动续费问卷',
	'取消自动缴纳会费的原因（可多选）':
		'会員費自動支払いを解除する理由(複数選択可)',
	我想按照自己的节奏使用会员服务: '自分のペースで会員サービスを利用したい',
	我想更改会员费支付方式: '会員費支払い方法を変更したい',
	'我对会员费自动缴费服务功能不满意（焦虑）':
		'会員費自動支払いサービス機能に不満がある（不安）',
	我想取消乘坐直飞航班: 'THE直行便の利用を中止したい',
	其他的: 'その他',
	'请告诉我们您选择“我想取消直飞航班”的原因（可多选）':
		'「THE直行便の利用を中止したい」で選択した理由を教えてください(複数選択可)',
	不常使用: '利用頻度が低い',
	昂贵的: '料金が高い',
	系统可操作性差: 'システムの操作性が悪い',
	对运送速度不满意: '発送スピードに不満がある',
	对产品质量不满意: '商品品質に不満がある',
	我对支持和询问的答复不满意: 'サポートや問い合わせなどの対応に不満がある',
	我没有得到我想要的服务: '自分にあったサービスがあまり提供されなかった',
	'如果您想使用任何其他服务，请告知我们。':
		'その他、ご意見・ご要望などがありましたら自由にご記入ください',
	'如果您还有其他意见或要求，请随时填写。':
		'その他、ご意見・ご要望などがありましたら自由にご記入ください',
	请选择需要的项目: '必須項目を選択してください。',
	'新客优惠活动还剩：': '新規入会キャンペーン応募期間：残り',
	'新客限定·X折': 'X％割引 - 新規限定-',
	'新客限定·1円': '1円 - 新規限定-',
	名额仅剩X名: '残りわずかX名',
	新客特惠: '新規限定',
	'※对于新用户专享活动，如果在会员到期前关闭自动续费，则不会产生使用费。只要没有办理关闭自动续费，就会从下个月开始以正常价格续费。':
		'※新規ユーザー限定価格が適用されるのは初月のみとなります。翌月から通常の料金で自動引落しになります。自動引落設定の解除は、会員情報ページから変更可能。',
	'次月续费X円/月': '翌月から毎月X円',
	次月续费: '翌月から',
	'X円/月': '毎月X円',
	您已选择关闭自动付款: '自動支払設定OFF を選択されました',
	'★请再次确认★': '★再確認下さい★',
	'[推荐设置！ 超过90%的用户设定]': '【設定推奨！9割以上の利用者が設定】',
	'我们建议您自动支付支付每月的会员费。':
		'毎月の月額会員費は＜自動支払設定＞をお勧めいたします',
	'*如果自动付款设置为“关闭”，则需要采取以下措施。':
		'※自動支払設定「OFF」の場合、以下の対応が必要となります',
	'① 每月需要缴纳会费工作': '①毎月自身での会員費支払い作業が必要',
	'（2）如果会员有效期满而未采取第（1）项中的措施，则将无法使用所有会员服务。':
		'②①の対応を行わず、会員有効期間を経過すると、全ての会員様向けサービスが利用不可',
	'→需再次办理入会手续': '→再度会員手続きが必要',
	'不可续费功能，超过有效期后无法使用': '継続なし有効期間経過後 利用不可機能',
	'· 直接从中国EC网站购买购物车': '·中国ECサイトからの直接カート買付',
	日语和图片产品搜索: '·日本語&画像での商品検索',
	产品订单: '商品発注',
	'· 直接采购中国产品，最高品质检验': '·中国商品の直購入＆最高品質検品対応',
	'·行业最快的物流服务': '·業界最速の物流サービス',
	'最快、最便宜的国际运输': '·最速&最安値での国際発送',
	'· 与其他网站（BASE、shopify、亚马逊等）的链接':
		'·他サイト連携（BASE、shopify、Amazonなど）',
	'· 自动下单、批量产品注册、FBA发货等':
		'·自動発注、商品一括登録、FBA納品など',
	'如果取消自动付款，过期后将不会自动续充会员':
		'自動支払いを解除された場合、会員資格停止後、',
	'您的订单将自动停止处理，这可能会导致交货延迟。':
		'ご注文処理が自動的に停止され、配送遅延にもつながります。',
	'您确定要关闭会员费自动续费功能吗？':
		'本当に会員費自動支払いを「OFF」にしてよろしいでしょうか？',
	発送: '発送',
	保留自动续费: '自動支払設定継続(ON)',
	关闭自动续费: '自動支払設定解除(OFF)',
	请填写投诉内容: '不満点についてご記入ください',
	关闭会员成功: '会員費自動支払い OFF 変更完了',
	会员试用中: '特別割引体験中',
	必须: '必須',
	请解释原因: '理由についてご記入ください',
	请填写计入: 'ご記入ください',
	找同款: '画像検索（1688）',
	'抱歉，当前邀请码无法注册app，app现阶段只支持日本站点用户使用':
		'抱歉，当前邀请码无法注册app，app现阶段只支持日本站点用户使用',
	首月: '初月',
	'1688国家货盘': '1688国家货盘',
	女装: 'レディースファッション',
	男装: 'メンズファッション',
	童装: '子供服',
	家纺家饰: 'インテリア',
	运动户外: 'スポーツ＆アウトドア',
	宠物及园艺: 'ペット用品',
	日用餐厨饮具: 'キッチン用品',
	箱包皮具: 'バッグ',
	收纳防尘: '収納',
	'服饰配件、饰品': 'アクセサリー',
	'数码、电脑': 'スマホ用品',
	汽车用品: '車用品',
	'获取相机权限拒绝，请添加权限':
		'カメラアクセスが拒否されました。カメラアクセスを許可するようにデバイス設定を変更してください。',
	提交并关闭自动续费: '回答提出(自動支払設定OFF)',
	取消并关闭自动续费: 'キャンセル(自動支払設定ON)',
	展开: '詳細',
	复购率: 'リピーター率',
	由1688提供部分技术服务支持:
		'1688による一部の技術サポートサビースを提供しています',
	同时注册1688账户和直行便账户: '同时注册1688账户和直行便账户',
	应用程序相机权限: 'APPカメラの許可',
	应用程序需要访问您的相机: 'APPはカメラにアクセスする必要があります',
	履约质量: '品質レビュー',
	综合体验: '総合評価',
	认证工厂: '認証工場',
	'24小时揽收率': '24時間以内の荷物発送率',
	'48小时揽收率': '48時間以内の荷物発送率',
	'5星': '5つ星',
	'4.5星ー5星': '4.5から5つ星',
	'4星ー4.5星': '4から4.5つ星',
	'4星以下': '4つ星以下',
	服务保障: 'サービス保障',
	当日发货: '当日出荷',
	'24小时发货': '24時間以内出荷',
	'48小时发货': '48時間以内出荷',
	'7天无理由': '7日間の無条件の返品',
	了解国家专属搜索框和普通搜索框的区别:
		'直行便 X 1688 検索と1688.com検索の違い？',
	'该品牌店铺已下架，不可下单':
		'【エラー】当該ブランド店舗のページが切れました',
	'该店铺未授权，不可下单，请前往PC端授权':
		'【エラー】発注にはブランドライセンス取得が必要',
	我确认并同意以上内容: '上記の内容につき、確認・同意する',
	拖动选择您需要搜索的商品:
		'トリミング機能を使い、画像検索に適したサイズに調整しましょう',
	亚马逊: '亚马逊',
	乐天: '乐天',
	速卖通: '速卖通',
	阿里巴巴国际站: '阿里巴巴国际站',
	ZOZO: 'ZOZO',
	Mercari: 'Mercari',
	YAHOO: 'YAHOO',
	NETSEA: 'NETSEA',
	日本热门贩卖平台一键找同款: '日本通販サイトから同じ商品を検索',
	一键在中国采购平台找同款: '中国通販サイトで類似商品を検索',
	'拉动上方高亮区域边框，以确定您需要搜索的商品图片':
		'拉动上方高亮区域边框，以确定您需要搜索的商品图片',
	去挑选: '今すぐ探す',
	'该商品近30天不良率超过X%，请谨慎选择。':
		'該当商品の不備率がX%を超えています。違うショップで購入するのがおすすめです',
	'该商品近30天回货速度慢，请谨慎选择。':
		'該当店舗の出荷スピードが平均より下です。違うショップで購入するのがおすすめです',
	'※商品不良包含：次品、到错货、多件/少件情况。':
		'以下のいずれかに該当する場合は、不備と判断します:欠陥品・相違品・欠品',
	'※回货时长超过15天。': '※出荷までの日数が15日以上かかります',
	去找同款: '類似品を探す',
	通过修剪调整搜索图像的大小: 'トリミングで検索画像のサイズを調整',
	'目前app仅限日本用户使用，请去电脑端使用系统':
		'現在のアプリは日本人ユーザー限定ですので、パソコン側でシステムをご利用ください',
	直营商城用户协议: '直売モール利用規約',
	使用手机号注册: ' 携帯番号で新規登録',
	使用邮箱注册: 'メールアドレスで新規登録',
	'国家/地区': '国籍／地域',
	'都道府县/州/地区': '都道府县/\n州/地区',
	'都道府县/州/地区2': '都道府县/州/地区',
	'支持日/英文搜索': ' 日本語または英語で検索',
	选择国家: '国籍／地域',
	'选择都道府县/州/地域': '选择都道府县/州/地域',
	支持英文搜索: '英語で検索',
	支持日文搜索: '日本語で検索',
	暂无相关搜索结果: '検索結果はありません',
	注册并绑定您的CKB账户: '直行便アカウントの登録と連携',
	'只需绑定一次，后续可用x直接登录':
		'連携に通じて、{platform} アカウントでログインできます',
	'已有CKB账户，去': '既に直行便アカウントをお持ちの場合は、',
	登录并绑定: 'ログインして連携',
	绑定您的CKB账户: 'ログインして、所有のCKBアカウントと連携する',
	'我还没有CKB账户，现在去': '直行便のアカウント未登録のため、',
	注册并绑定: '【登録・API連携】',
	绑定失败: 'API連携失敗',
	'您的直行便账户已绑定了{thirdName}账户，无法再绑定，请先解绑原先的后再进行绑定。':
		'持ちのアカウントは既に別のアカウントに連携されているため、現在のアカウントとの連合ができません。',
	确定绑定: '确定绑定',
	售罄1: '売り切れ',
	'1688日本官方合作伙伴': '1688日本官方合作伙伴',
	'请确认要退出登录吗？': 'ログアウトしますか？',
	'~没有啦，我也是有底线的~': '～ここまで～',
	'下列商品被取消了，取消原因：{{reason}}':
		'下記商品はキャンセルされました。キャンセル理由：{{reason}}',
	退款事宜可与客服进行联系确认:
		'返金の確認はカスタマーサービスにご連絡ください',
	稍后查看: '後でチェックします',
	账户信息: '会員情報',
	使用指南: 'ご利用ガイド',
	限时活动: '期間限定イベント',
	距离结束: '終了まで',
	'下列商品被取消，取消原因：{{reason}}':
		'下記商品はキャンセルされました。キャンセル理由：{{reason}}',
	'快人一步获得秒杀活动预告，还有':
		'素早く手に入れた最新フラッシュセールの予告、',
	'优质商品上新提醒、生动的商品测评分享、日本讲师教学课程，':
		'新商品情報、生き生きとした商品レポート、日本語講師プログラム、',
	'并配有专属人员答疑解惑。': '専任スタッフが質問にお答えします。',
	商品详情: '商品詳細',
	取消收藏成功: 'お気に入り削除成功',
	收藏成功: 'お気に入り！',
	商品已售罄: '商品は売り切れています',
	加入购物车: 'カートに入れる',
	立即订购: '今すぐ注文',
	同款特惠: 'よりお得な価格で同じ商品を探す',
	'新人专享！满{{money}}円免配送费':
		'初めての方限定！ {{money}}円以上送料無料',
	'活动适用于{{cityName}}，具体运费以您的收货地区为准，详见下方运费详情表':
		'送料は{{cityName}}に適用されます。送料は配送地域によって異なりますので、下記の送料詳細表をご覧ください',
	'订单满{{num}}円以上包邮': '{{num}}円以上のお買上で送料無料',
	运费活动: '送料',
	该商品已停止售卖: 'この商品は販売終了しました',
	该商品还未开放售卖: 'この商品はまだ販売されていません',
	该商品已删除: 'この商品は削除されました',
	'很遗憾，这个商品不符合日本代购条件，可以看看其他商品':
		'残念ながらこの商品は日本から購入できる条件を満たしていませんので、他の商品もチェックしてみてください。',
	购买数量不可为空: '必須項目 購入数量',
	商品SKU不能超过200: '製品SKUは200を超えることはできません',
	活动商品不能超过1个: '活動商品は1個を超えることはできません',
	加入购物车成功: 'カートに追加成功しました',
	售罄: '売り切れ',
	库存: '在庫',
	件: '個数',
	'{{num}}件': '{{num}}件',
	已选: 'を選択中',
	该商品为代采会员特惠商品: '代理購入会員様向けの商品です',
	'下次自动跳转代采，无需询问':
		'次回は自動的に代理購入ページへ、再び尋ねる必要はありません！',
	留在直采: '直接購入ページに滞在',
	前往代采: '代理購入ページへ',
	'很遗憾，该商品已售罄': '残念ながら、この商品は売り切れました！',
	'加入社群，及时获取1日元秒杀活动消息！':
		'コミュニティに参加して、1円セールの情報をいち早くゲット。',
	"快人一步获得秒杀活动预告，还有<span className='special-text-style'>优质商品上新提醒、生动的商品测评分享、日本讲师教学课程，</span>并配有专属人员答疑解惑。":
		"素早く手に入れた最新フラッシュセールの予告、<span class='special-text-style'>新商品情報、生き生きとした商品レポート、日本語講師プログラム、</span>専任スタッフが質問にお答えします。",
	'开通会员，即可享受': '会員になると、ご利用いただけます',
	'采购更透明，更便捷': '購買をより透明、より便利',
	'会员权益超有料，快来开通吧！': '会員特典は有料で、ぜひご加入ください！　',
	直行便会员卡: 'メンバーズカード',
	'· 超低商品价格，更透明！': '· 超低商品価格、さらに透明性を高めました！',
	'· 大量采购更省钱！': '· 大量購入でさらにお得！',
	'· 专属客服服务，更快响应！':
		'· 専属のカスタマーサービスでより迅速な対応！',
	立即开通: '即座に開通',
	'展开剩余{{num}}件': '残り{{num}}点を表示',
	'取消原因：': 'キャンセル理由：',
	'取消时间：': 'キャンセル時間：',
	订单金额: '注文金額',
	前往代采商城: '',
	拍摄图片: '写真を撮る',
	从文件中选取: 'ファイルから選択',
	请输入商品名称: '何をお探しですか？',
	'(约{{x}}円)': '(約{{x}}円)',
	限时秒杀: 'タイムセール',
	正常: '普通',
	已下架: '販売終了',
	'新人专享！': '初めての方限定！',
	'1日元活动': '1円のセール',
	限时: '残り',
	円: '円',
	'抱歉，此功能暂只支持日本站， 请重新确认账号':
		'申し訳ございませんが、3Fboxは日本地域のみご利用可能で、ご利用頂くには日本のアカウントが必要になります。',
	人气搜索: '人気検索',
	$最后一行谢谢$: '',
	优惠券: 'クーポン',
	退款记录: '返金履歴'
};
