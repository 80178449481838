import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Popup } from 'antd-mobile';
import Hoursehome from '@/common/icons/BottomnavigationHomepageDefault';
import { jsBridge } from '@/utils/jsBridge';
import styles from './index.module.scss';
interface ShareProps {
	product?: any;
}
function Share(props: ShareProps) {
	const { product } = props;
	const [visible, setVisible] = useState(false);
	const navigate = useNavigate();
	/**
	 * 快速分享到Facebook
	 */
	const facebookShare = () => {
		const url = encodeURIComponent(window.location.href);
		const facebook = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
		window.open(facebook, '_blank');
	};
	/**
	 * 快速分享到twitter
	 */
	const twitterShare = () => {
		const url = encodeURIComponent(window.location.href);
		const twitter = `https://twitter.com/intent/tweet?text=${url}`;
		window.open(twitter, '_blank');
	};
	const openShareModal = async () => {
		// 如果在app里面，那么触发app的打开弹窗逻辑
		if (window?.ReactNativeWebView) {
			await jsBridge.postMessage({
				type: 'DIRCT_OPEN_SHARE_MODAL',
				payload: {
					product
				}
			});
			return;
		}
		setVisible(true);
	};
	return (
		<div className={styles.backToHome}>
			<img
				src="https://static-jp.theckb.com/static-asset/easy-h5/share.svg"
				alt=""
				onClick={openShareModal}
			/>

			<Popup
				visible={visible}
				onMaskClick={() => {
					setVisible(false);
				}}
				onClose={() => {
					setVisible(false);
				}}
				bodyStyle={{
					borderTopLeftRadius: '8px',
					borderTopRightRadius: '8px',
					height: '1.94rem'
				}}
			>
				<div className={styles.shareBox}>
					<div
						className={styles.shareItem}
						onClick={() => {
							facebookShare();
						}}
					>
						<img
							alt=""
							className={styles.shareImg}
							src="https://static-jp.theckb.com/static-asset/easy-h5/Facebook.png"
						/>

						<div>フェイスブック</div>
					</div>
					<div
						className={styles.shareItem}
						onClick={() => {
							twitterShare();
						}}
					>
						<img
							alt=""
							className={styles.shareImg}
							src="https://static-jp.theckb.com/static-asset/easy-h5/Twitter.png"
						/>

						<div>ツイッター</div>
					</div>
				</div>
				<Button
					block
					shape="rounded"
					size="large"
					style={{
						border: '2px solid #CDD2DA',
						width: '90vw',
						fontSize: '14px',
						fontWeight: '600',
						marginLeft: '5vw'
					}}
					onClick={() => {
						setVisible(false);
					}}
				>
					{window._$m.t('取消')}
				</Button>
			</Popup>
		</div>
	);
}
export default Share;
