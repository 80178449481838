/*
 * @Author: yusha
 * @Date: 2024-01-11 15:29:04
 * @LastEditors: yusha
 * @LastEditTime: 2024-03-18 10:28:22
 * @Description: 选择sku弹窗
 */

import { ScrollView, Text, XStack, YStack } from '@snifftest/tamagui';
import { ImageViewer } from 'antd-mobile';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import * as SDK from '@snifftest/sdk/lib/rn';
import FastImage from '@/outs/components/FastImage';
import { changeImageCdn, formatMoney } from '@/outs/utils';
import { tool } from '@/outs/utils/tool';
import { PRODUCT_TYPE } from '@/outs/const';

import {
	ProductPropDTO,
	ProductPropGroupDTO,
	SkuDetailRespDTO
} from '@/outs/service/easyGoods';
import Stepper from '@/outs/components/Stepper';
import useEvent from '@/outs/hooks/base/useEvent';
import { CustomRMImg } from '@/component/CustomRMImg';
import { ContextPreView } from '../../../context';
import { BACK_UP_IMG } from '../../..';

const fitSize = SDK.fitSize;
interface SPUProps extends ProductPropGroupDTO {
	selected?: ProductPropDTO;
}
/** 最后的一个SPU */
interface lastSpuListItemProps extends ProductPropDTO {
	sku: SkuNumDetail;
}
/** 包含销售数量的 */
interface SkuNumDetail extends SkuDetailRespDTO {
	num?: number;
}
/** 选择sku后的SPU数量集合 */
type AmountProps = Record<string, number>;
interface SelectSkuContentProps {
	setSkuAmount: (val: AmountProps) => void;
	skuAmount: AmountProps;
	setSpuAmount: (val: AmountProps) => void;
	spuAmount: AmountProps;
	onClose: () => void;
	/** 三种类型，加购、立即购买、两者都有 */
	type?: 'both' | 'addCart' | 'buyItNow';
}
const SelectSkuContent = (props: SelectSkuContentProps) => {
	const { setSkuAmount, skuAmount, setSpuAmount, spuAmount, onClose, type } =
		props;
	const {
		product = {},
		firstSpuSoldOut,
		getImgSkuPrewCallBack
	} = useContext(ContextPreView);
	const { skuList = [], productPropGroupJaList = [] } = product;
	const navigation = SDK.useNavigate() as any;
	/** 除最后一个外的spu */
	const [otherSpuList, setOtherSpuList] = useState<SPUProps[]>([]);

	const [lastSpuBySku, setLastSpuBySku] = useState<lastSpuListItemProps[]>(
		[]
	);
	const [previewUrl, setPreviewUrl] = useState('');

	/** 利用sku的key去除 不需要的规格属性productPropGroupList */
	const filterNoSkuProps = (
		propList: ProductPropGroupDTO[],
		skus: SkuDetailRespDTO[]
	) => {
		if (!skus?.length || !propList?.length) {
			return [];
		}
		let keys: string[] = [];
		skus.forEach((item) => {
			keys = [...keys, ...(item?.productProperties?.split(';') ?? [])];
		});
		return propList.map((item) => {
			return {
				...item,
				props: item?.props?.filter((i) =>
					keys.includes(i.propKey || '')
				)
			};
		});
	};
	/** 计算过滤后的spu。sku包含的spu */
	const filterSPUList = useMemo(
		() => filterNoSkuProps(productPropGroupJaList, skuList),
		[productPropGroupJaList, skuList]
	);
	/** 初始化后默认选择第一个spu */
	useEffect(() => {
		const props = filterSPUList.slice(0, -1).map((item: SPUProps) => {
			item.selected = item.props?.[0];
			return item;
		});
		setOtherSpuList(props);
		// 初始化默认选择第一个spu之后，规格图片也要进行改变
		setPreviewUrl(
			props?.[0]?.props?.[0].url || product.productMainImg! || BACK_UP_IMG
		);
	}, [filterSPUList, product.productMainImg]);

	/** 计算之前选择中的spu的props属性 */
	const seletSpuProps = useMemo(() => {
		return otherSpuList.map((item) => item.selected?.propKey);
	}, [otherSpuList]);
	/** arr2map 获取映射 */
	function arr2map<T>(proplist: T[], propfn = (v: T) => ''): Map<string, T> {
		const map = new Map();
		proplist.forEach((v) => {
			map.set(propfn(v), v);
		});
		return map;
	}
	/** 所有的sku都转化为productProperties的集合为主键 */
	const skuToMap = useMemo(() => {
		return arr2map(skuList, (v) => v.productProperties || '');
	}, [skuList]);

	/** 最后一个spu */
	const lastSpuProps = useMemo(
		() => filterSPUList[filterSPUList.length - 1],
		[filterSPUList]
	);

	/** 最后一个spu，挂载所有的sku */
	useEffect(() => {
		const lastPropsSku = lastSpuProps?.props
			?.map((prop) => {
				// 之前选中的props的key+最后一级spu中key组合城sku的key，然后获取sku数据
				const sku =
					(skuToMap.get(
						seletSpuProps.concat(prop.propKey).join(';')
					) as SkuNumDetail) ?? {};
				const nProps = {
					...prop,
					sku
				};
				return nProps;
			})
			.filter((prop) => prop.sku.productCode);
		lastPropsSku && setLastSpuBySku(lastPropsSku);
	}, [lastSpuProps, seletSpuProps, skuToMap]);
	/** 初始化 */

	/** 计算SPU数量 */
	const getSpuNum = (key: string, skuAmount: AmountProps) => {
		return Object.keys(skuAmount)
			.filter((item) => item.includes(key))
			.reduce((pre, cur) => {
				return pre + skuAmount[cur];
			}, 0);
	};
	/** 改变spu数量，计步器加减 */
	const changeSku = useEvent((e: number, key: string = '') => {
		const newSkuAmount = {
			...skuAmount,
			[key]: e
		};
		const firstProps = otherSpuList[0];
		if (firstProps) {
			firstProps?.props?.forEach(({ propKey = '' }) => {
				spuAmount[propKey] = getSpuNum(propKey, newSkuAmount);
			});
		}
		setSpuAmount({
			...spuAmount
		});
		setSkuAmount(newSkuAmount);
	});

	/** 选择spu */
	const selectPropItem = (
		idx: number,
		j: ProductPropDTO,
		url: string | undefined
	) => {
		if (idx >= 0) {
			const newProps = otherSpuList.slice(0);
			newProps[idx].selected = j;
			setOtherSpuList(newProps);
		}
		url && setPreviewUrl && setPreviewUrl(url ?? BACK_UP_IMG);
	};
	/** 商品售价计算 */
	const sellprice = useMemo<string>(() => {
		const min = formatMoney(product.productSellLowestPriceJa);
		const max = formatMoney(product.productSellHighestPriceJa);
		if (min === max) {
			return `${min}`;
		}
		return `${min} - ${max}`;
	}, [product.productSellHighestPriceJa, product.productSellLowestPriceJa]);

	/** 活动售价计算 */
	const activePrice = useMemo<string>(() => {
		const min = formatMoney(product.activityInfo?.activityLowestPriceJa);
		const max = formatMoney(product.activityInfo?.activityHighestPriceJa);
		if (min === max) {
			return `${min}`;
		}
		return `${min} - ${max}`;
	}, [
		product.activityInfo?.activityLowestPriceJa,
		product.activityInfo?.activityHighestPriceJa
	]);
	/** 获取最大数量 */
	const getNumMax = (num: number) => {
		// 1元活动，最大数量只能为1
		if (product.oneBuyFlag) {
			return 1;
		}
		if (product.productType === PRODUCT_TYPE.ON_LINE) {
			return 99999;
		}
		return num;
	};
	return (
		<ScrollView
			showsHorizontalScrollIndicator={false}
			showsVerticalScrollIndicator={false}
		>
			<YStack paddingLeft={fitSize(12)} paddingRight={fitSize(12)}>
				{/* 商品标题区域 */}
				<XStack
					style={{
						flexDirection: 'row'
					}}
				>
					{!SDK.isH5() ? (
						<YStack
							style={{
								width: fitSize(64),
								height: fitSize(64),
								borderRadius: fitSize(8),
								overflow: 'hidden'
							}}
							onPress={() => {
								navigation.navigate('ImageView', {
									list: [previewUrl],
									index: 0,
									callBack: getImgSkuPrewCallBack
								});
								onClose();
							}}
						>
							<FastImage
								style={{
									width: fitSize(64),
									height: fitSize(64)
								}}
								source={{
									// TODO
									uri: previewUrl ?? BACK_UP_IMG
								}}
							/>
						</YStack>
					) : (
						<CustomRMImg
							customizeMaskClass="zIndexImportant-9999"
							src={previewUrl}
							isPreView={true}
							imgSize={50}
							alt=""
							height={50}
							width={50}
						/>
					)}

					<YStack style={{ marginLeft: fitSize(12), flex: 1 }}>
						<Text
							style={{
								fontSize: fitSize(14),
								fontWeight: 'bold',
								color: '#1c2026',
								lineHeight: fitSize(22)
							}}
							numberOfLines={1}
						>
							{product.productTitle}
						</Text>
						<Text
							style={{
								color: '#1c2026',
								fontSize: fitSize(13),
								fontWeight: '600',
								lineHeight: fitSize(20)
							}}
						>
							{product.activityInfo ? activePrice : sellprice}
							{tool.strings('円')}
						</Text>
					</YStack>
				</XStack>
				{/* 规格区域 */}
				{otherSpuList.map((item, otherRowIndex) => (
					<YStack paddingTop={fitSize(20)} key={item.type}>
						<Text
							style={{
								fontSize: fitSize(14),
								fontWeight: '500',
								color: '#333'
							}}
						>
							{item.type}
						</Text>
						<XStack
							style={{
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'wrap',
								marginBottom: fitSize(16)
							}}
						>
							{item.props?.map((prop) => (
								<XStack
									key={prop.propKey}
									style={{
										padding: fitSize(8),
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										textAlign: 'center',
										borderRadius: fitSize(8),
										marginRight: fitSize(15),
										marginBottom: fitSize(8),
										position: 'relative',
										borderWidth: fitSize(1),
										borderColor:
											item.selected === prop
												? '#1c2026'
												: '#cdd2da',
										backgroundColor: '#fff'
									}}
									onPress={() =>
										selectPropItem(
											otherRowIndex,
											prop,
											productPropGroupJaList.length >=
												2 && otherRowIndex === 0
												? prop.url
												: ''
										)
									}
								>
									{productPropGroupJaList.length >= 2 &&
										otherRowIndex === 0 && (
											<FastImage
												style={{
													width: fitSize(44),
													height: fitSize(44)
												}}
												source={{
													// TODO
													uri: prop.url ?? BACK_UP_IMG
													// uri: changeImageCdn(
													// 	prop.url ?? BACK_UP_IMG,
													// 	200
													// )
												}}
											/>
										)}

									{Boolean(spuAmount[prop.propKey ?? '']) && (
										<XStack
											style={{
												minWidth: fitSize(25),
												height: fitSize(19),
												paddingLeft: fitSize(3),
												paddingRight: fitSize(3),
												backgroundColor: '#ff5010',
												borderRadius: fitSize(19),
												position: 'absolute',
												top: fitSize(-8),
												right: fitSize(-12),
												zIndex: 100,
												justifyContent: 'center'
											}}
										>
											<Text
												style={{
													fontSize: fitSize(11),
													fontWeight: '600',
													color: '#fff',
													lineHeight: fitSize(19)
												}}
											>
												x
											</Text>
											<Text
												style={{
													fontSize: fitSize(11),
													fontWeight: '600',
													color: '#fff',
													lineHeight: fitSize(19)
												}}
											>
												{spuAmount[prop.propKey ?? ''] >
												99
													? '99+'
													: spuAmount[
															prop.propKey ?? ''
													  ]}
											</Text>
										</XStack>
									)}

									{otherRowIndex === 0 &&
									(firstSpuSoldOut[prop.propKey ?? ''] ??
										0) <= 0 ? (
										<Text
											style={{
												paddingLeft: fitSize(4),
												paddingRight: fitSize(4),
												backgroundColor: '#c5c5c5',
												borderRadius: fitSize(12),
												position: 'absolute',
												top: fitSize(-8),
												right: fitSize(-12),
												zIndex: 10,
												fontSize: fitSize(10),
												color: '#fff'
											}}
										>
											{tool.strings('售罄')}
										</Text>
									) : null}
									<Text>{prop.propValue}</Text>
								</XStack>
							))}
						</XStack>
					</YStack>
				))}
				{/* sku加减数量区域 */}
				<YStack marginTop={fitSize(24)}>
					<Text
						style={{
							fontSize: fitSize(14),
							fontWeight: 'bold',
							color: '#333',
							lineHeight: fitSize(22),
							marginBottom: fitSize(8)
						}}
					>
						{lastSpuProps?.type}
					</Text>
					{lastSpuBySku.map((item, index) => (
						<XStack
							// display="flex"
							marginBottom={fitSize(8)}
							backgroundColor={'#fff'}
							// alignItems="center"
							key={item.propKey}
							// flexDirection="row"
							onPress={() =>
								selectPropItem(
									-1,
									item,
									productPropGroupJaList.length === 1
										? item.url
										: ''
								)
							}
						>
							{item.url &&
								productPropGroupJaList.length === 1 && (
									<FastImage
										key={index}
										source={{
											// TODO
											uri: item.url ?? BACK_UP_IMG
											// uri: changeImageCdn(
											// 	item.url ?? BACK_UP_IMG,
											// 	200
											// )
										}}
										width={fitSize(44)}
										height={fitSize(44)}
										borderRadius={fitSize(8)}
									/>
								)}

							<YStack
								marginLeft={fitSize(8)}
								// display="flex"
								// flexDirection="column"
								// height={'100%'}
								flex={1}
								// justifyContent="center"
							>
								<Text
									color={'#1c2026'}
									fontSize={fitSize(14)}
									fontWeight={'bold'}
									lineHeight={fitSize(22)}
								>
									{item.propValue}
								</Text>
								<XStack>
									<Text
										color={'#1c2026'}
										fontSize={fitSize(12)}
										fontWeight={'400'}
										lineHeight={fitSize(20)}
										marginRight={fitSize(8)}
									>
										{!product.activityInfo
											? formatMoney(item.sku.sellPriceJa)
											: formatMoney(
													item.sku.activityPriceJa
											  )}
										{tool.strings('円')}
									</Text>
								</XStack>
							</YStack>
							<XStack>
								<Stepper
									disable={(item.sku.availableQty ?? 0) <= 0}
									max={getNumMax(item.sku.availableQty!)}
									min={0}
									defaultValue={0}
									value={
										skuAmount[
											item.sku.productProperties ?? ''
										] || 0
									}
									onChange={(value) => {
										selectPropItem(
											-1,
											item,
											productPropGroupJaList.length === 1
												? item.url
												: ''
										);

										changeSku(
											value,
											item.sku.productProperties
										);
									}}
								/>
							</XStack>
						</XStack>
					))}
				</YStack>
			</YStack>
		</ScrollView>
	);
};
export default memo(SelectSkuContent);
