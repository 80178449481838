/*
 * @Author: huajian
 * @Date: 2024-02-26 15:36:34
 * @LastEditors: yusha
 * @LastEditTime: 2024-03-11 16:44:02
 * @Description:
 */
export * from 'react-native-web';
export const PermissionsAndroid: any = null;
export const BeautyBg = () => null;
export default () => null;
export const useNavigationWebViewH5 = () => null;
export const useSafeAreaInsets = () => {
	return { top: 0 };
};

export const useNavigation = () => {};
export const useNavWebViewH5 = () => {};
export const INCLUDE_DOMAIN = '';
export const atomUpdateGlobalWebview = null;
export const atomWebViewPageState = null;
export const APP_ENV = 'prod';
export const navigate = () => {};
export const CameraRoll: any = () => {};
export const ShareDialog: any = () => null;
