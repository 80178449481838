/*
 * @Author: yusha
 * @Date: 2024-01-12 13:54:01
 * @LastEditors: yusha
 * @LastEditTime: 2024-04-03 10:30:30
 * @Description: 弹窗(不完善)
 */

import { Text, XStack, YStack } from '@snifftest/tamagui';
import React, { memo } from 'react';
import { Modal } from 'react-native';
import * as SDK from '@snifftest/sdk/lib/rn';
import { tool } from '@/outs/utils/tool';

const fitSize = SDK.fitSize;
interface CustomModalProps {
	visible: boolean;
	onClose: () => void;
	/** 是否展示确认按钮 */
	showConfirmBtn?: boolean;
	footer?: React.ReactNode;
	onText?: string;
	cancelText?: string;
	onOk?: () => void;
	children?: React.ReactNode;
	/** 是否支持蒙层关闭 */
	maskClosable?: boolean;
}

const CustomModal = (props: CustomModalProps) => {
	const {
		visible,
		onClose,
		showConfirmBtn = true,
		onText = tool.strings('确认'),
		cancelText = tool.strings('取消'),
		footer,
		onOk,
		children,
		maskClosable = false
	} = props;
	if (!visible) {
		return;
	}
	return (
		<Modal
			transparent={true}
			visible={visible}
			presentationStyle={'overFullScreen'}
			onRequestClose={(e) => {
				e.preventDefault();
				onClose();
			}}
			statusBarTranslucent={true}
		>
			<YStack
				style={{
					backgroundColor: 'rgba(0,0,0,0.4)',
					position: 'absolute',
					left: 0,
					top: 0,
					bottom: 0,
					right: 0,
					zIndex: 6,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
				onPress={(e) => {
					if (!maskClosable) {
						return;
					}
					e.preventDefault();
					e.stopPropagation();
					onClose();
				}}
			>
				<YStack
					backgroundColor={'#fff'}
					borderRadius={fitSize(8)}
					padding={fitSize(20)}
					maxWidth={'91%'}
					zIndex={7}
				>
					<YStack marginBottom={fitSize(12)}>
						{/* <Text textAlign="center">请确认是否取消支付</Text> */}
						{children}
					</YStack>
					{footer ? (
						footer
					) : (
						<XStack justifyContent="center">
							<XStack
								paddingLeft={fitSize(16)}
								paddingTop={fitSize(10)}
								paddingRight={fitSize(16)}
								paddingBottom={fitSize(10)}
								borderWidth={fitSize(2)}
								borderColor={'#CDD2DA'}
								borderRadius={fitSize(24)}
								backgroundColor={'#fff'}
								onPress={(e) => {
									e.preventDefault();
									onClose();
								}}
							>
								<Text
									fontSize={fitSize(14)}
									fontWeight={'bold'}
									color={'#1C2026'}
								>
									{cancelText}
								</Text>
							</XStack>
							{showConfirmBtn && (
								<XStack
									paddingLeft={fitSize(16)}
									paddingTop={fitSize(10)}
									paddingRight={fitSize(16)}
									paddingBottom={fitSize(10)}
									borderRadius={fitSize(24)}
									backgroundColor={'#1C2026'}
									marginLeft={fitSize(12)}
									onPress={onOk}
								>
									<Text
										fontSize={fitSize(14)}
										fontWeight={'bold'}
										color={'#fff'}
									>
										{onText}
									</Text>
								</XStack>
							)}
						</XStack>
					)}
				</YStack>
			</YStack>
		</Modal>
	);
};
export default memo(CustomModal);
