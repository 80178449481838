import { useRequest } from 'ahooks';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { request } from '@/config/request/interceptors';
import { CouponRespDTO } from '@/service/market';
import { formatDateCN2JP } from '@/utils';
import styles from './index.module.scss';
const HeaderCoupon = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [id] = useState(() => searchParams.get('couponCustomId'));
	const [couponDetail, setCouponDetail] = useState<CouponRespDTO>({});
	const { runAsync: apiGetCustomerDetail } = useRequest(
		request.market.coupon.customerDetail,
		{
			manual: true
		}
	);
	useEffect(() => {
		if (id) {
			apiGetCustomerDetail({
				id
			}).then((res) => {
				setCouponDetail(res.data || {});
			});
		}
	}, [id, apiGetCustomerDetail]);
	return (
		<div className={styles['header-coupon']}>
			{couponDetail.title}
			{couponDetail.feeToCut
				? couponDetail.feeToCut + window._$m.t('円可用')
				: couponDetail.numToCut + window._$m.t('件可用')}
			<div>{window._$m.t('以下商品可使用')}</div>
			<div
				style={{
					fontSize: '.11rem',
					color: '#fd8357',
					fontWeight: '500',
					marginTop: '0.01rem'
				}}
			>
				{formatDateCN2JP(couponDetail.startDate, 'YYYY-MM-DD HH:mm')} -{' '}
				{formatDateCN2JP(couponDetail.endDate, 'YYYY-MM-DD HH:mm')}
			</div>
		</div>
	);
};
export default HeaderCoupon;
