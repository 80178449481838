/*
 * @Author: liangliang
 * @Date: 2023-05-22 11:07:22
 * @LastEditors: huajian
 * @LastEditTime: 2024-03-08 10:54:57
 * @Description:
 */
import I18n from '@sniff/react-native-i18n';
import commonJP from '../../../i18n/locales/ja_JP.json';
import en_US from './en_US';
import zh_CN from './zh_CN';
import ja_JP from './ja_JP';

// I18n.defaultLocale = "en"; //设置默认的语言

I18n.fallback = true; // 设置

// I18n.locale = "en"; //设置当前的语言环境

I18n.translations = {
	// 支持的语言列表
	en_US,
	zh_CN,
	ja_JP: { ...ja_JP, ...commonJP }
};

export function strings(name, params = {}) {
	return I18n.t(name, params);
}

export function setLanguage(lan) {
	I18n.locale = lan;
}

export default I18n;
