/*
 * @Author: yusha
 * @Date: 2024-01-08 15:53:06
 * @LastEditors: yusha
 * @LastEditTime: 2024-03-13 18:15:11
 * @Description: 活动头部
 */

import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { ImageBackground } from 'react-native';
import { XStack, Text, styled, Stack } from '@snifftest/tamagui';
import dayjs from 'dayjs';
import { TDate } from 'ahooks/lib/useCountDown';
import { useMount, useUnmount } from 'ahooks';
import * as SDK from '@snifftest/sdk/lib/rn';
import { tool } from '@/outs/utils/tool';
import { formatMoney } from '@/outs/utils';
import { ACTIVITY_TYPE } from '@/outs/const';
import { useCountdown } from '@/outs/hooks/base/useCountdownVal';
import { ContextPreView } from '../../context';

const fitSize = SDK.fitSize;
const calcLeft = (target?: TDate) => {
	if (!target) {
		return 0;
	}
	const left = dayjs(target).valueOf() - Date.now();
	return left < 0 ? 0 : left;
};

const ActiveHeader = () => {
	const { product = {} } = useContext(ContextPreView);
	const navigation = SDK.useNavigate() as any;
	const route = SDK.useParams();
	const activityEndTime = useMemo(() => {
		return calcLeft(
			new Date(Number(product.activityInfo?.activityEndTime) ?? 0)
		);
	}, [product.activityInfo?.activityEndTime]);

	const [showTime, { startCountdown, stopCountdown, isTimeout }] =
		useCountdown(product.activityInfo?.activityEndTime);

	useEffect(() => {
		if (isTimeout) {
			stopCountdown();
			if (!SDK.isH5()) {
				navigation.replace('GoodDetail', {
					...route.params
				});
				return;
			}
			window.location.reload();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isTimeout, stopCountdown]);

	useMount(() => {
		// 开始倒计时
		if (activityEndTime > 0) {
			startCountdown();
		}
	});

	useUnmount(() => {
		stopCountdown();
	});

	/** 商品售价计算 */
	const sellprice = useMemo<string>(() => {
		const min = formatMoney(product.productSellLowestPriceJa);
		const max = formatMoney(product.productSellHighestPriceJa);
		if (min === max) {
			return `${min}`;
		}
		return `${min} - ${max}`;
	}, [product.productSellHighestPriceJa, product.productSellLowestPriceJa]);

	/** 活动售价计算 */
	const activePrice = useMemo<string>(() => {
		const min = formatMoney(product.activityInfo?.activityLowestPriceJa);
		const max = formatMoney(product.activityInfo?.activityHighestPriceJa);
		if (min === max) {
			return `${min}`;
		}
		return `${min} - ${max}`;
	}, [
		product.activityInfo?.activityLowestPriceJa,
		product.activityInfo?.activityHighestPriceJa
	]);
	/** 是否显示原价 */
	const isShowOirginPrice = useMemo<boolean>(() => {
		const productSellLowestPriceJa = product?.productSellLowestPriceJa ?? 0;
		const activityLowestPriceJa =
			product.activityInfo?.activityLowestPriceJa ?? 0;
		const value = productSellLowestPriceJa - activityLowestPriceJa;
		return value > 0;
	}, [
		product.activityInfo?.activityLowestPriceJa,
		product?.productSellLowestPriceJa
	]);
	return (
		<ImageBackground
			style={{
				// flex: 1,
				zIndex: 1,
				paddingTop: fitSize(10),
				paddingBottom: fitSize(8),
				paddingLeft: fitSize(16),
				paddingRight: fitSize(16),
				borderTopLeftRadius: fitSize(16),
				borderTopRightRadius: fitSize(16),
				overflow: 'hidden',
				marginTop: fitSize(-20),
				flexDirection: 'column',
				height: fitSize(100)
			}}
			source={{
				uri: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/背景2.png'
			}}
		>
			<FlexRowStyle
				style={{
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<FlexRowStyle
					style={{
						alignItems: 'center'
					}}
				>
					<Text
						style={{
							fontSize: fitSize(12),
							color: '#fff',
							marginRight: fitSize(6)
						}}
					>
						{tool.strings('限时活动')}
					</Text>
					<Text
						style={{
							fontSize: fitSize(18),
							fontWeight: '600',
							color: '#fff'
						}}
					>
						{activePrice}
						{tool.strings('円')}
					</Text>
				</FlexRowStyle>
				<Text style={{ color: '#fff' }}>
					{tool.strings('距离结束')}
				</Text>
			</FlexRowStyle>
			<XStack
				style={{
					justifyContent: 'space-between',
					alignItems: 'center',
					flexDirection: 'row'
				}}
			>
				<XStack
					style={{
						marginTop: fitSize(3),
						opacity: 0.6,
						flexDirection: 'row'
					}}
				>
					{isShowOirginPrice && (
						<>
							<Text
								style={{ fontSize: fitSize(12), color: '#fff' }}
							>
								{tool.strings('原价')}
							</Text>
							<Text
								style={{
									marginLeft: fitSize(4),
									color: '#fff',
									fontSize: fitSize(12),
									textDecorationLine: 'line-through'
								}}
							>
								{product.activityInfo?.activityType ===
								ACTIVITY_TYPE.PRICE
									? product.productSellLowestPriceJa
									: sellprice}
								{tool.strings('円')}
							</Text>
						</>
					)}
				</XStack>
				<XStack
					style={{
						marginTop: fitSize(8),
						flexDirection: 'row'
					}}
				>
					<FlexRowStyle
						style={{
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<TimeBoxStyle>{showTime?.days}</TimeBoxStyle>
						<ColorSizeStyle>{tool.strings('天')}</ColorSizeStyle>
					</FlexRowStyle>
					<FlexRowStyle
						style={{
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<TimeBoxStyle>{showTime?.hours}</TimeBoxStyle>
						<ColorSizeStyle>{tool.strings('时')}</ColorSizeStyle>
					</FlexRowStyle>
					<FlexRowStyle
						style={{
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<TimeBoxStyle>{showTime?.minutes}</TimeBoxStyle>
						<ColorSizeStyle>{tool.strings('分')}</ColorSizeStyle>
					</FlexRowStyle>
					<FlexRowStyle
						style={{
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<TimeBoxStyle>{showTime?.seconds}</TimeBoxStyle>
						<ColorSizeStyle>{tool.strings('秒')}</ColorSizeStyle>
					</FlexRowStyle>
				</XStack>
			</XStack>
		</ImageBackground>
	);
};

const ColorSizeStyle = styled(Text, {
	color: '#fff',
	fontSize: fitSize(10)
});
const FlexRowStyle = styled(Stack, {
	flexDirection: 'row'
});
const TimeBoxStyle = styled(Text, {
	height: fitSize(20),
	lineHeight: fitSize(20),
	minWidth: fitSize(24),
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: fitSize(2),
	backgroundColor: 'rgba(255, 255, 255, 0.3)',
	marginLeft: fitSize(4),
	marginRight: fitSize(4),
	fontWeight: '500',
	color: '#fff',
	fontSize: fitSize(12),
	textAlign: 'center'
});
export default memo(ActiveHeader);
