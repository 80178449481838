/*
 * @Author: huajian
 * @Date: 2023-05-17 11:04:37
 * @LastEditors: huajian
 * @LastEditTime: 2023-05-25 11:59:09
 * @Description:
 */

type env = 'dev' | 'prod' | 'pre';
// eslint-disable-next-line
const m = window.location.host.match(/(^[^\-]+)-/);
let env = m ? m[1] : 'prod';
if (window.location.port === '3666') {
	env = 'dev';
}
export const Config = {
	isLogin: false,
	env: env as env,
	envUrl: m ? m[0] : ''
};
