/*
 * @Author: yusha
 * @Date: 2023-11-11 14:50:38
 * @LastEditors: yusha
 * @LastEditTime: 2023-11-11 15:06:23
 * @Description: loading组件
 */

import { memo } from 'react';
const Loading = () => {
	return (
		<div
			style={{
				position: 'fixed',
				width: '100vw',
				height: '100vh',
				top: 0,
				left: 0,
				bottom: 0,
				zIndex: '1000',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
				// background: 'rgba(0,0,0,0.1)'
			}}
		>
			<div
				style={{
					width: '.7rem',
					height: '.7rem',
					background: 'rgba(0,0,0,0.65)',
					borderRadius: '.04rem',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<img
					style={{
						width: '.36rem',
						height: '.36rem'
					}}
					src="https://static-s.theckb.com/BusinessMarket/Easy/H5/image-search-loading.gif"
					alt="loading"
				/>
			</div>
		</div>
	);
};
export default memo(Loading);
