/*
 * @Author: tianzhitong laotianwy@163.com
 * @Date: 2023-10-24 15:28:42
 * @LastEditors: yusha
 * @LastEditTime: 2023-11-13 18:24:10
 * @FilePath: \ckb-easy-h5-fed\src\component\BackToTop\index.tsx
 * @Description: 【回到顶部】组件。需要在使用组件的地址传入一个 滚动的id名字。最外层classname必须是layout-content
 */
import { memo, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { FloatingBubble } from 'antd-mobile';
import { useMount, useUnmount } from 'ahooks';
import { useEvent } from '@/utils/hooks/useEvent';
import Gotopzhidingbu from '@/common/icons/Gotopzhidingbu';
import './index.scss';
interface BackToTopProps {
	/** 滚动的id */
	scrollViewIdName: string;
	/** 监听滚动的id */
	listenScrollId: string;
}
export interface BackToTopRefProps {
	clickToTop: () => void;
}
const BackToTop = forwardRef((props: BackToTopProps, ref: any) => {
	const { scrollViewIdName, listenScrollId } = props;
	const [visible, setVisible] = useState(false);
	const [layoutBottomHeight, setLayoutBottomHeight] = useState(0);
	const [rootFontSize, setRootFontSize] = useState(0);
	const scrollDomRef = useRef<Element | null>(null);
	const clickToTop = useEvent(() => {
		document?.getElementById(scrollViewIdName)?.scrollIntoView({
			block: 'end',
			inline: 'nearest',
			behavior: 'smooth'
		});
	});
	useMount(() => {
		setTimeout(() => {
			// 获取底部高度。底部高度 = tabbar高度 + fixedbottom高度
			getBottomHeight();

			// 获取页面滚动距离，判断置顶是否显示
			scrollDomRef.current = document.getElementById(listenScrollId);
			if (scrollDomRef.current) {
				scrollDomRef.current.addEventListener(
					'scroll',
					scrollCallBackFunc
				);
			}
		}, 0);
	});
	useUnmount(() => {
		if (scrollDomRef.current) {
			scrollDomRef.current.removeEventListener(
				'scroll',
				scrollCallBackFunc
			);
		}
	});
	useImperativeHandle(
		ref,
		(): BackToTopRefProps => ({
			clickToTop
		}),
		[clickToTop]
	);
	const scrollCallBackFunc = useEvent(() => {
		const scrollHeight =
			document.getElementById(listenScrollId)?.scrollTop || 0;
		if (scrollHeight > window.screen.height) {
			if (!visible) {
				setVisible(true);
			}
		} else {
			if (visible) {
				setVisible(false);
			}
		}
	});
	const splitStringPX = (str: string) => {
		return Number(str.split('px')[0]);
	};
	const getDomHeight = (domRef: HTMLElement): number => {
		if (!domRef) return 0;
		const domHeight =
			domRef?.offsetHeight ??
			0 + splitStringPX(getComputedStyle(domRef, null).marginTop);
		return domHeight;
	};
	const getBottomHeight = () => {
		const layoutTabbarBottomDom = document.getElementById('layout-footer');
		const layoutFixedBottom = document.getElementById('fixed-bottom');
		const getTabbarHeight = getDomHeight(layoutTabbarBottomDom!);
		const getFixedHeight = getDomHeight(layoutFixedBottom!);
		setLayoutBottomHeight(getTabbarHeight + getFixedHeight);
	};
	const getRootFontSize = () => {
		const rootPX = Number(
			document.documentElement.style.fontSize.split('px')[0]
		);
		setRootFontSize(rootPX);
	};
	useMount(() => {
		getRootFontSize();
	});
	return (
		<>
			{visible ? (
				<FloatingBubble
					axis="x"
					magnetic="x"
					style={
						{
							'--initial-position-bottom': `calc(.36rem + ${layoutBottomHeight}px)`,
							'--initial-position-right': '0.16rem',
							'--edge-distance': '24px',
							'--adm-color-primary': '#fff',
							'--z-index': '999',
							'--size': rootFontSize * 0.48
						} as any
					}
				>
					<div className="move-view" onClick={clickToTop}>
						<Gotopzhidingbu size={30} />
					</div>
				</FloatingBubble>
			) : null}
		</>
	);
});
export default memo(BackToTop);
